import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Platform,
  Switch,
  Modal,
  KeyboardAvoidingView,
  TextInput,
  ActivityIndicator,
  Picker,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
// import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
// import Ionicons from 'react-native-vector-icons/Ionicons';
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
// import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { FlatList } from "react-native-gesture-handler";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import Styles from "../constant/Styles";
// import * as User from '../util/User';
import AsyncStorage from "@react-native-async-storage/async-storage";
// import CheckBox from 'react-native-check-box';
import moment from "moment";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
// import {isTablet} from 'react-native-device-detection';
import { CommonStore } from "../store/commonStore";
import { OutletStore } from "../store/outletStore";
// import {MerchantStore} from '../store/merchantStore';
import { UserStore } from "../store/userStore";
// import Upload from '../assets/svg/Upload';
// import Download from '../assets/svg/Download';
import {
  convertArrayToCSV,
  generateEmailReport,
  sortReportDataList,
} from "../util/common";
import {
  EMAIL_REPORT_TYPE,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  ORDER_TYPE,
  MERCHANT_DATA_FILTER,
  APP_TYPE,
} from "../constant/common";
// import RNFetchBlob from 'rn-fetch-blob';
// import {useKeyboard} from '../hooks';
// import XLSX from 'xlsx';
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
// import RNPickerSelect from 'react-native-picker-select';
// import AsyncImage from '../components/asyncImage';
// import Feather from 'react-native-vector-icons/Feather';
// import Tooltip from 'react-native-walkthrough-tooltip';

import firebase from "firebase/app";
// import firestore from '@react-native-firebase/firestore';
import { Collections } from "../constant/firebase";

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
import { useLinkTo } from "@react-navigation/native";

import BigNumber from "bignumber.js";

const { nanoid } = require("nanoid");
// const RNFS = require('react-native-fs');

window.outletLastOrderDict = {};
window.outletFilterDict = {};

const KCRMGMVScreenV2 = (props) => {
  const { navigation } = props;

  // const linkTo = useLinkTo();

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const keyboardHeight = 0;
  const [visible, setVisible] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [pageReturn, setPageReturn] = useState(1);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
  const [rev_date, setRev_date] = useState(
    moment().subtract(6, "month").startOf("day")
  );
  const [rev_date1, setRev_date1] = useState(
    moment().endOf(Date.now()).endOf("day")
  );

  const userName = UserStore.useState((s) => s.name);

  const [exportEmail, setExportEmail] = useState("");

  const [showDetails, setShowDetails] = useState(false);

  const [exportModalVisibility, setExportModalVisibility] = useState(false);

  const merchantId = UserStore.useState((s) => s.merchantId);
  const isLoading = CommonStore.useState((s) => s.isLoading);
  const [isCsv, setIsCsv] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const allOutletsEmployees = OutletStore.useState(
    (s) => s.allOutletsEmployees
  );

  const [allOutletsEmployeesAction, setAllOutletsEmployeesAction] = useState(
    []
  );

  const [allOutletsEmployeesDetails, setAllOutletsEmployeesDetails] = useState(
    []
  );

  const [toggleCompare, setToggleCompare] = useState(true);

  /////////////////////////////////////////////////////////////////

  const [merchantDataFilterType, setMerchantDataFilterType] = useState(MERCHANT_DATA_FILTER.ACTIVE_24H);
  const [merchantDataAllLength, setMerchantDataAllLength] = useState(0);
  const [merchantData24HLength, setMerchantData24HLength] = useState(0);
  const [merchantData7DLength, setMerchantData7DLength] = useState(0);
  const [merchantData1MLength, setMerchantData1MLength] = useState(0);
  const [merchantData3MLength, setMerchantData3MLength] = useState(0);

  const [outletUserActionDict, setOutletUserActionDict] = useState({});

  /////////////////////////////////////////////////////////////////

  // 2022-10-24 - Add the support of changing date range also will update details list

  const [showDetailsOutletId, setShowDetailsOutletId] = useState('');

  /////////////////////////////////////////////////////////////////

  const merchantsOnboarded = CommonStore.useState(s => s.merchantsOnboarded);
  const outletsOnboarded = CommonStore.useState(s => s.outletsOnboarded);
  const employeeClocks = CommonStore.useState(s => s.employeeClocks);
  const userActions = CommonStore.useState(s => s.userActions);

  const userEmail = UserStore.useState((s) => s.email);

  const gmvStartDate = CommonStore.useState(s => s.gmvStartDate);
  const gmvEndDate = CommonStore.useState(s => s.gmvEndDate);
  const gmvOrders = CommonStore.useState(s => s.gmvOrders);

  //////////////////////////////////////////////

  // 2022-10-18 - To get the active/online status for each outlet

  // useEffect(async () => {
  //   var startDate24H = moment().startOf('day').valueOf();
  //   var endDate24H = moment().endOf('day').valueOf();

  //   var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
  //   var endDate7D = moment().endOf('day').valueOf();

  //   var startDate1M = moment().subtract(1, "month").startOf('day').valueOf();
  //   var endDate1M = moment().endOf('day').valueOf();

  //   var startDate3M = moment().subtract(3, "month").startOf('day').valueOf();
  //   var endDate3M = moment().endOf('day').valueOf();

  //   for (var i = 0; i < outletsOnboarded.length; i++) {
  //     const outlet = outletsOnboarded[i];

  //     if (!window.outletFilterDict[outlet.uniqueId]) {
  //       window.outletFilterDict[outlet.uniqueId] = {
  //         is24H: false,
  //         is7D: false,
  //         is1M: false,
  //         is3M: false,
  //       };
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder24HSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate24H)
  //       .where('createdAt', '<', endDate24H)
  //       .limit(1)
  //       .get();

  //     if (userOrder24HSnapshot && !userOrder24HSnapshot.empty) {
  //       window.outletFilterDict[outlet.uniqueId]['is24H'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder7DSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate7D)
  //       .where('createdAt', '<', endDate7D)
  //       .limit(1)
  //       .get();

  //     if (userOrder7DSnapshot && !userOrder7DSnapshot.empty) {
  //       window.outletFilterDict[outlet.uniqueId]['is7D'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder1MSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate1M)
  //       .where('createdAt', '<', endDate1M)
  //       .limit(1)
  //       .get();

  //     if (userOrder1MSnapshot && !userOrder1MSnapshot.empty) {
  //       window.outletFilterDict[outlet.uniqueId]['is1M'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder3MSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate3M)
  //       .where('createdAt', '<', endDate3M)
  //       .limit(1)
  //       .get();

  //     if (userOrder3MSnapshot && !userOrder3MSnapshot.empty) {
  //       window.outletFilterDict[outlet.uniqueId]['is3M'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     // actual update the status (outlet active number)

  //     const outletLastOrderArray = Object.entries(window.outletFilterDict).map(
  //       ([key, value]) => ({ key: key, value: value }),
  //     );

  //     setMerchantDataAllLength(outletsOnboarded.length);

  //     // var startDate24H = moment().subtract(1, "day").startOf('day').valueOf();
  //     // var endDate24H = moment().subtract(1, "day").endOf('day').valueOf();
  //     setMerchantData24HLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate24H).isSameOrBefore(temp.value) &&
  //       //   moment(endDate24H).isAfter(temp.value)) {
  //       if (temp.value['is24H']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     // var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
  //     // var endDate7D = moment().subtract(1, "day").endOf('day').valueOf();
  //     setMerchantData7DLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate7D).isSameOrBefore(temp.value) &&
  //       //   moment(endDate7D).isAfter(temp.value)) {
  //       if (temp.value['is7D']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     // var startDate1M = moment().subtract(1, "month").startOf('month').startOf('day').valueOf();
  //     // var endDate1M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
  //     setMerchantData1MLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate1M).isSameOrBefore(temp.value) &&
  //       //   moment(endDate1M).isAfter(temp.value)) {
  //       if (temp.value['is1M']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     // var startDate3M = moment().subtract(3, "month").startOf('month').startOf('day').valueOf();
  //     // var endDate3M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
  //     setMerchantData3MLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate3M).isSameOrBefore(temp.value) &&
  //       //   moment(endDate3M).isAfter(temp.value)) {
  //       if (temp.value['is3M']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     /////////////////////////////////////////////////////////////
  //   }

  //   /////////////////////////////////////////////////////////////

  //   // CommonStore.update(s => {
  //   //   s.outletFilterDict = window.outletFilterDict;
  //   // });

  //   /////////////////////////////////////////////////////////////
  // }, [outletsOnboarded]);

  // const outletFilterDict = CommonStore.useState(s => s.outletFilterDict);

  // useEffect(() => {
  //   window.outletFilterDict = outletFilterDict;
  // }, [outletFilterDict]);

  /////////////////////////////

  useEffect(async () => {
    // if (userEmail === '') {
    //   // linkTo('/login');

    //   window.location.href = '/statistics/login';
    // }

    const emailParsed = await readFromStorage();

    if (emailParsed === '') {
      // linkTo('/login');

      window.location.href = '/statistics/login';
    }
  }, [userEmail]);

  const readFromStorage = async () => {
    console.log('\n Reading from asyncStorage \n')

    var emailParsed = '';

    const userStoreDataRaw = await AsyncStorage.getItem('@userStoreV2');
    if (userStoreDataRaw !== null) {
      const userStoreData = JSON.parse(userStoreDataRaw);
      // UserStore.replace(userStoreData);

      if (userStoreData && userStoreData.email) {
        emailParsed = userStoreData.email;
      }
    }

    return emailParsed;
  }

  // no need this
  // useEffect(async () => {
  //   var outletUserActionDictTemp = {};

  //   for (var i = 0; i < outletsOnboarded.length; i++) {
  //     var outletId = outletsOnboarded[i].uniqueId;

  //     var userAction = await retrieveLatestUserAction(outletId);

  //     outletUserActionDictTemp[outletId] = userAction;
  //   }

  //   setOutletUserActionDict(outletUserActionDictTemp);
  // }, [outletsOnboarded]);

  const retrieveLatestUserAction = async (outletId) => {
    const userActionSnapshot = await firebase.firestore()
      .collection(Collections.UserAction)
      .where('outletId', '==', outletId)
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .get();

    var userAction = null;
    if (userActionSnapshot && !userActionSnapshot.empty) {
      userAction = userActionSnapshot.docs[0].data();
    }

    return userAction;
  };

  useEffect(() => {
    if (showDetails && allOutletsEmployeesDetails) {
      setPageReturn(currentPage);
      console.log("currentPage value is");
      console.log(currentPage);
      setCurrentDetailsPage(1);
      setPageCount(Math.ceil(allOutletsEmployeesDetails.length / perPage));
    }
  }, [showDetails, allOutletsEmployeesDetails, perPage]);

  useEffect(async () => {
    const outletFilterDictRaw = await AsyncStorage.getItem('outletFilterDict');

    if (outletFilterDictRaw) {
      const outletFilterDictParsed = JSON.parse(outletFilterDictRaw);

      if (typeof outletFilterDictParsed === 'object') {
        window.outletFilterDict = outletFilterDictParsed;
      }
    }
  }, []);

  useEffect(async () => {
    // const userActionSnapshot = await firebase
    //   .firestore()
    //   .collection(Collections.KCRMAgreement)
    //   .orderBy("email", "asc")
    //   .get();

    // const userAction = userActionSnapshot.docs.map((doc) => doc.data());

    // group the records by the email and store the details in detailsList

    var startDate24H = moment().startOf('day').valueOf();
    var endDate24H = moment().endOf('day').valueOf();

    var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
    var endDate7D = moment().endOf('day').valueOf();

    var startDate1M = moment().subtract(1, "month").startOf('day').valueOf();
    var endDate1M = moment().endOf('day').valueOf();

    var startDate3M = moment().subtract(3, "month").startOf('day').valueOf();
    var endDate3M = moment().endOf('day').valueOf();

    var tempDataBefore = outletsOnboarded.filter(outlet =>
      // moment(outlet.createdAt).isSameOrAfter(rev_date, 'day') &&
      // moment(outlet.createdAt).isSameOrBefore(rev_date1, 'day') &&
      merchantsOnboarded.find(merchant => outlet.merchantId === merchant.uniqueId)
    ).map(outlet => {
      var merchant = merchantsOnboarded.find(merchant => outlet.merchantId === merchant.uniqueId);

      var lastActivity = outletUserActionDict[outlet.uniqueId];
      if (lastActivity) {
        lastActivity = lastActivity.updatedAt;
      }

      if (!window.outletFilterDict[outlet.uniqueId]) {
        window.outletFilterDict[outlet.uniqueId] = {
          is24H: false,
          is7D: false,
          is1M: false,
          is3M: false,
        };
      }
      else {
        window.outletFilterDict[outlet.uniqueId]['is24H'] = false;
      }

      return {
        merchantName: merchant.name,
        outletName: outlet.name,
        createdAt: outlet.createdAt,
        onboarderEmail: outlet.onboarderEmail || '-',
        // lastActivity: merchant.updatedAt > merchant.updatedAt ? merchant.updatedAt : outlet.updatedAt,
        lastActivity: lastActivity ? lastActivity : outlet.createdAt,

        merchantId: merchant.uniqueId,
        outletId: outlet.uniqueId,

        //////////////////////////////////////////////

        // new info

        userAppAndWebDineInOrdersAmount: 0,
        userAppAndWebDineInOrdersQty: 0,

        merchantAppOrdersAmount: 0,
        merchantAppOrdersQty: 0,

        waiterAppOrdersAmount: 0,
        waiterAppOrdersQty: 0,

        userAppAndWebTakeawayOrdersAmount: 0,
        userAppAndWebTakeawayOrdersQty: 0,

        userAppAndWebDeliveryOrdersAmount: 0,
        userAppAndWebDeliveryOrdersQty: 0,

        totalOrdersAmount: 0,
        totalOrdersQty: 0,

        gmvCommission: BigNumber(0),
        gmvPayout: BigNumber(0),
        gmvFees: BigNumber(0),

        lastOrderPlacedDateTime: null,

        //////////////////////////////////////////////

        // details info

        detailsList: [],

        //////////////////////////////////////////////
      };
    });

    var outletDataDict = Object.assign({}, ...tempDataBefore.map(data => ({
      [data.outletId]: data,
    })));

    //////////////////////////////////////////////////////////////////////////

    // calculate orders

    // var userAppAndWebDineInOrdersAmount = 0;
    // var userAppAndWebDineInOrdersQty = 0;

    // var merchantAppOrdersAmount = 0;
    // var merchantAppOrdersQty = 0;

    // var waiterAppOrdersAmount = 0;
    // var waiterAppOrdersQty = 0;

    // var userAppAndWebTakeawayOrdersAmount = 0;
    // var userAppAndWebTakeawayOrdersQty = 0;

    // var userAppAndWebDeliveryOrdersAmount = 0;
    // var userAppAndWebDeliveryOrdersQty = 0;

    // var totalOrdersAmount = 0;
    // var totalOrdersQty = 0;

    // var lastOrderPlacedDateTime = null;

    // var gmvOrdersFiltered = gmvOrders.filter(order => order.outletId === outlet.uniqueId);

    const gmvOrdersFiltered = gmvOrders;

    console.log('loop');
    console.log(gmvOrdersFiltered.length);

    for (let i = 0; i < gmvOrdersFiltered.length; i++) {
      if (outletDataDict[gmvOrdersFiltered[i].outletId]) {
        // var gmvRate = 0;
        // if (gmvOrdersFiltered[i].settlementDetails) {
        //   if (gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.processingRateTotalSalesActive) {
        //     gmvRate = gmvOrdersFiltered[i].settlementDetails.processingRateTotalSales;
        //   }
        //   else if (gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.processingRateRazerActive) {
        //     gmvRate = gmvOrdersFiltered[i].settlementDetails.processingRateRazer;
        //   }
        // }

        // push to details list
        outletDataDict[gmvOrdersFiltered[i].outletId].detailsList.push({
          dateTime: moment(gmvOrdersFiltered[i].createdAt).format('YYYY/MM/DD HH:mm'),
          orderId: `#${gmvOrdersFiltered[i].orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${gmvOrdersFiltered[i].orderId}`,
          // uniqueId: gmvOrdersFiltered[i].uniqueId,
          isQrOrder: gmvOrdersFiltered[i].appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No',
          netAmount: BigNumber(gmvOrdersFiltered[i].finalPrice).minus(gmvOrdersFiltered[i].tax).minus(gmvOrdersFiltered[i].sc).dp(2).toNumber(),
          tax: BigNumber(gmvOrdersFiltered[i].tax).dp(2).toNumber(),
          sc: BigNumber(gmvOrdersFiltered[i].sc).dp(2).toNumber(),
          totalAmount: BigNumber(gmvOrdersFiltered[i].finalPrice).dp(2).toNumber(),
          razerCharges: BigNumber((gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.commissionFeeFinal) ? gmvOrdersFiltered[i].settlementDetails.commissionFeeFinal : 0).dp(2).toNumber(),
          koodooCharges: BigNumber((gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.koodooProcessingFee) ? gmvOrdersFiltered[i].settlementDetails.koodooProcessingFee : 0).dp(2).toNumber(),
          outletPayout: BigNumber(
            (gmvOrdersFiltered[i].paymentDetails &&
              (gmvOrdersFiltered[i].paymentDetails.txn_ID !== undefined ||
                gmvOrdersFiltered[i].paymentDetails.txnId !== undefined)
              &&
              (gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
            )
              ?
              gmvOrdersFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee
              :
              0)
            .dp(2).toNumber(),
          settlementDate: gmvOrdersFiltered[i].settlementDate ? moment(gmvOrdersFiltered[i].settlementDate).format('YYYY/MM/DD') : 'N/A',

          isSettled: gmvOrdersFiltered[i].settlementDate ? true : false,

          // gmvRate: gmvRate,
        });

        outletDataDict[gmvOrdersFiltered[i].outletId].gmvCommission = BigNumber(outletDataDict[gmvOrdersFiltered[i].outletId].gmvCommission).plus(BigNumber((gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.koodooProcessingFee) ? gmvOrdersFiltered[i].settlementDetails.koodooProcessingFee : 0)).toNumber();
        outletDataDict[gmvOrdersFiltered[i].outletId].gmvFees = BigNumber(outletDataDict[gmvOrdersFiltered[i].outletId].gmvFees).plus(BigNumber((gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.commissionFeeFinal) ? gmvOrdersFiltered[i].settlementDetails.commissionFeeFinal : 0)).toNumber();
        outletDataDict[gmvOrdersFiltered[i].outletId].gmvPayout = BigNumber(outletDataDict[gmvOrdersFiltered[i].outletId].gmvPayout).plus(BigNumber(
          (gmvOrdersFiltered[i].paymentDetails &&
            (gmvOrdersFiltered[i].paymentDetails.txn_ID !== undefined ||
              gmvOrdersFiltered[i].paymentDetails.txnId !== undefined)
            &&
            (gmvOrdersFiltered[i].settlementDetails && gmvOrdersFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
          )
            ?
            gmvOrdersFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee
            :
            0)).toNumber();

        if ((gmvOrdersFiltered[i].appType === APP_TYPE.MERCHANT ||
          gmvOrdersFiltered[i].appType === undefined ||
          gmvOrdersFiltered[i].appType === APP_TYPE.UNKNOWN) &&
          !gmvOrdersFiltered[i].isOnlineOrdering) {
          outletDataDict[gmvOrdersFiltered[i].outletId].merchantAppOrdersAmount += gmvOrdersFiltered[i].finalPrice;
          // console.log(outletDataDict[gmvOrdersFiltered[i].outletId].merchantAppOrdersAmount);
          outletDataDict[gmvOrdersFiltered[i].outletId].merchantAppOrdersQty += 1;

          outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

          checkOutletFilterDict({
            uniqueId: gmvOrdersFiltered[i].outletId,
          }, gmvOrdersFiltered[i].createdAt, {
            startDate24H,
            endDate24H,
            startDate7D,
            endDate7D,
            startDate1M,
            endDate1M,
            startDate3M,
            endDate3M,
          });

          continue;
        }

        if (gmvOrdersFiltered[i].appType === APP_TYPE.USER ||
          gmvOrdersFiltered[i].appType === APP_TYPE.WEB_ORDER) {
          if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.DINEIN) {
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersAmount += gmvOrdersFiltered[i].finalPrice;
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;

            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersFiltered[i].outletId,
            }, gmvOrdersFiltered[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }
          else if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.PICKUP) {
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += gmvOrdersFiltered[i].finalPrice;
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersFiltered[i].outletId,
            }, gmvOrdersFiltered[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }
          else if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.DELIVERY) {
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += gmvOrdersFiltered[i].finalPrice;
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersFiltered[i].outletId,
            }, gmvOrdersFiltered[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }
        }

        ////////////////////////////////////////////////////////////

        // to compatible with previous web orders

        if (gmvOrdersFiltered[i].appType === undefined &&
          gmvOrdersFiltered[i].isOnlineOrdering) {
          if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.DINEIN) {
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersAmount += gmvOrdersFiltered[i].finalPrice;
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;

            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersFiltered[i].outletId,
            }, gmvOrdersFiltered[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }
          else if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.PICKUP) {
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += gmvOrdersFiltered[i].finalPrice;
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersFiltered[i].outletId,
            }, gmvOrdersFiltered[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }
          else if (gmvOrdersFiltered[i].orderType === gmvOrdersFiltered.DELIVERY) {
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += gmvOrdersFiltered[i].finalPrice;
            outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
              outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersFiltered[i].outletId,
            }, gmvOrdersFiltered[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }
        }

        ////////////////////////////////////////////////////////////

        if (gmvOrdersFiltered[i].appType === APP_TYPE.WAITER) {
          outletDataDict[gmvOrdersFiltered[i].outletId].waiterAppOrdersAmount += gmvOrdersFiltered[i].finalPrice;
          outletDataDict[gmvOrdersFiltered[i].outletId].waiterAppOrdersQty += 1;

          outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt >
            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt :
            outletDataDict[gmvOrdersFiltered[i].outletId].lastOrderPlacedDateTime;

          checkOutletFilterDict({
            uniqueId: gmvOrdersFiltered[i].outletId,
          }, gmvOrdersFiltered[i].createdAt, {
            startDate24H,
            endDate24H,
            startDate7D,
            endDate7D,
            startDate1M,
            endDate1M,
            startDate3M,
            endDate3M,
          });

          continue;
        }

        console.log('orders not categorized');
        console.log(gmvOrdersFiltered[i]);

        // outletDataDict[gmvOrdersFiltered[i].outletId].totalOrdersAmount = outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersAmount +
        //   outletDataDict[gmvOrdersFiltered[i].outletId].merchantAppOrdersAmount + outletDataDict[gmvOrdersFiltered[i].outletId].waiterAppOrdersAmount +
        //   outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount + outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount;
        // outletDataDict[gmvOrdersFiltered[i].outletId].totalOrdersQty = outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersQty +
        //   outletDataDict[gmvOrdersFiltered[i].outletId].merchantAppOrdersQty + outletDataDict[gmvOrdersFiltered[i].outletId].waiterAppOrdersQty +
        //   outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersQty + outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersQty;
      }
    }

    //////////////////////////////////////////////////////////////////////////

    // var tempData = outletsOnboarded.filter(outlet =>
    //   // moment(outlet.createdAt).isSameOrAfter(rev_date, 'day') &&
    //   // moment(outlet.createdAt).isSameOrBefore(rev_date1, 'day') &&
    //   merchantsOnboarded.find(merchant => outlet.merchantId === merchant.uniqueId)
    // ).map(outlet => {
    //   var merchant = merchantsOnboarded.find(merchant => outlet.merchantId === merchant.uniqueId);

    //   var lastActivity = outletUserActionDict[outlet.uniqueId];
    //   if (lastActivity) {
    //     lastActivity = lastActivity.updatedAt;
    //   }

    //   //////////////////////////////////////////////////////////////////////////

    //   // calculate orders

    //   var userAppAndWebDineInOrdersAmount = 0;
    //   var userAppAndWebDineInOrdersQty = 0;

    //   var merchantAppOrdersAmount = 0;
    //   var merchantAppOrdersQty = 0;

    //   var waiterAppOrdersAmount = 0;
    //   var waiterAppOrdersQty = 0;

    //   var userAppAndWebTakeawayOrdersAmount = 0;
    //   var userAppAndWebTakeawayOrdersQty = 0;

    //   var userAppAndWebDeliveryOrdersAmount = 0;
    //   var userAppAndWebDeliveryOrdersQty = 0;

    //   var totalOrdersAmount = 0;
    //   var totalOrdersQty = 0;

    //   var lastOrderPlacedDateTime = null;

    //   var gmvOrdersFiltered = gmvOrders.filter(order => order.outletId === outlet.uniqueId);

    //   for (var i = 0; i < gmvOrdersFiltered.length; i++) {
    //     if ((gmvOrdersFiltered[i].appType === APP_TYPE.MERCHANT ||
    //       gmvOrdersFiltered[i].appType === undefined ||
    //       gmvOrdersFiltered[i].appType === APP_TYPE.UNKNOWN) &&
    //       !gmvOrdersFiltered[i].isOnlineOrdering) {
    //       merchantAppOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //       merchantAppOrdersQty += 1;

    //       lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime;

    //       checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //         startDate24H,
    //         endDate24H,
    //         startDate7D,
    //         endDate7D,
    //         startDate1M,
    //         endDate1M,
    //         startDate3M,
    //         endDate3M,
    //       });

    //       continue;
    //     }

    //     if (gmvOrdersFiltered[i].appType === APP_TYPE.USER ||
    //       gmvOrdersFiltered[i].appType === APP_TYPE.WEB_ORDER) {
    //       if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.DINEIN) {
    //         userAppAndWebDineInOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //         userAppAndWebDineInOrdersQty += 1;

    //         lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime;

    //         checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //           startDate24H,
    //           endDate24H,
    //           startDate7D,
    //           endDate7D,
    //           startDate1M,
    //           endDate1M,
    //           startDate3M,
    //           endDate3M,
    //         });

    //         continue;
    //       }
    //       else if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.PICKUP) {
    //         userAppAndWebTakeawayOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //         userAppAndWebTakeawayOrdersQty += 1;

    //         lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime;

    //         checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //           startDate24H,
    //           endDate24H,
    //           startDate7D,
    //           endDate7D,
    //           startDate1M,
    //           endDate1M,
    //           startDate3M,
    //           endDate3M,
    //         });

    //         continue;
    //       }
    //       else if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.DELIVERY) {
    //         userAppAndWebDeliveryOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //         userAppAndWebDeliveryOrdersQty += 1;

    //         lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime;

    //         checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //           startDate24H,
    //           endDate24H,
    //           startDate7D,
    //           endDate7D,
    //           startDate1M,
    //           endDate1M,
    //           startDate3M,
    //           endDate3M,
    //         });

    //         continue;
    //       }
    //     }

    //     ////////////////////////////////////////////////////////////

    //     // to compatible with previous web orders

    //     if (gmvOrdersFiltered[i].appType === undefined &&
    //       gmvOrdersFiltered[i].isOnlineOrdering) {
    //       if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.DINEIN) {
    //         userAppAndWebDineInOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //         userAppAndWebDineInOrdersQty += 1;

    //         lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime;

    //         checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //           startDate24H,
    //           endDate24H,
    //           startDate7D,
    //           endDate7D,
    //           startDate1M,
    //           endDate1M,
    //           startDate3M,
    //           endDate3M,
    //         });

    //         continue;
    //       }
    //       else if (gmvOrdersFiltered[i].orderType === ORDER_TYPE.PICKUP) {
    //         userAppAndWebTakeawayOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //         userAppAndWebTakeawayOrdersQty += 1;

    //         lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime; lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt;

    //         checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //           startDate24H,
    //           endDate24H,
    //           startDate7D,
    //           endDate7D,
    //           startDate1M,
    //           endDate1M,
    //           startDate3M,
    //           endDate3M,
    //         });

    //         continue;
    //       }
    //       else if (gmvOrdersFiltered[i].orderType === gmvOrdersFiltered.DELIVERY) {
    //         userAppAndWebDeliveryOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //         userAppAndWebDeliveryOrdersQty += 1;

    //         lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime;

    //         checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //           startDate24H,
    //           endDate24H,
    //           startDate7D,
    //           endDate7D,
    //           startDate1M,
    //           endDate1M,
    //           startDate3M,
    //           endDate3M,
    //         });

    //         continue;
    //       }
    //     }

    //     ////////////////////////////////////////////////////////////

    //     if (gmvOrdersFiltered[i].appType === APP_TYPE.WAITER) {
    //       waiterAppOrdersAmount += gmvOrdersFiltered[i].finalPrice;
    //       waiterAppOrdersQty += 1;

    //       lastOrderPlacedDateTime = gmvOrdersFiltered[i].createdAt > lastOrderPlacedDateTime ? gmvOrdersFiltered[i].createdAt : lastOrderPlacedDateTime;

    //       checkOutletFilterDict(outlet, gmvOrdersFiltered[i].createdAt, {
    //         startDate24H,
    //         endDate24H,
    //         startDate7D,
    //         endDate7D,
    //         startDate1M,
    //         endDate1M,
    //         startDate3M,
    //         endDate3M,
    //       });

    //       continue;
    //     }
    //   }

    //   totalOrdersAmount = userAppAndWebDineInOrdersAmount +
    //     merchantAppOrdersAmount + waiterAppOrdersAmount +
    //     userAppAndWebTakeawayOrdersAmount + userAppAndWebDeliveryOrdersAmount;
    //   totalOrdersQty = userAppAndWebDineInOrdersQty +
    //     merchantAppOrdersQty + waiterAppOrdersQty +
    //     userAppAndWebTakeawayOrdersQty + userAppAndWebDeliveryOrdersQty;

    //   //////////////////////////////////////////////////////////////////////////

    //   // if (
    //   //   gmvOrdersFiltered[i].createdAt
    //   //   // && !moment(lastOrderPlacedDateTime).isSame(moment(), 'day')
    //   // ) {
    //   //   // if (!window.outletLastOrderDict[outlet.uniqueId]) {
    //   //   //   window.outletLastOrderDict[outlet.uniqueId] = lastOrderPlacedDateTime;
    //   //   // }
    //   //   // else if (lastOrderPlacedDateTime > window.outletLastOrderDict[outlet.uniqueId]) {
    //   //   //   window.outletLastOrderDict[outlet.uniqueId] = lastOrderPlacedDateTime;
    //   //   // }        

    //   //   if (!window.outletFilterDict[outlet.uniqueId]) {
    //   //     window.outletFilterDict[outlet.uniqueId] = {
    //   //       is24H: false,
    //   //       is7D: false,
    //   //       is1M: false,
    //   //       is3M: false,
    //   //     };
    //   //   }

    //   //   if (moment(startDate24H).isSameOrBefore(gmvOrdersFiltered[i].createdAt) &&
    //   //     moment(endDate24H).isAfter(gmvOrdersFiltered[i].createdAt)) {
    //   //     window.outletFilterDict[outlet.uniqueId]['is24H'] = true;
    //   //   }

    //   //   if (moment(startDate7D).isSameOrBefore(gmvOrdersFiltered[i].createdAt) &&
    //   //     moment(endDate7D).isAfter(gmvOrdersFiltered[i].createdAt)) {
    //   //     window.outletFilterDict[outlet.uniqueId]['is7D'] = true;
    //   //   }
    //   //   if (moment(startDate1M).isSameOrBefore(gmvOrdersFiltered[i].createdAt) &&
    //   //     moment(endDate1M).isAfter(gmvOrdersFiltered[i].createdAt)) {
    //   //     window.outletFilterDict[outlet.uniqueId]['is1M'] = true;
    //   //   }

    //   //   if (moment(startDate3M).isSameOrBefore(gmvOrdersFiltered[i].createdAt) &&
    //   //     moment(endDate3M).isAfter(gmvOrdersFiltered[i].createdAt)) {
    //   //     window.outletFilterDict[outlet.uniqueId]['is3M'] = true;
    //   //   }
    //   // }

    //   return {
    //     merchantName: merchant.name,
    //     outletName: outlet.name,
    //     createdAt: outlet.createdAt,
    //     onboarderEmail: outlet.onboarderEmail || '-',
    //     // lastActivity: merchant.updatedAt > merchant.updatedAt ? merchant.updatedAt : outlet.updatedAt,
    //     lastActivity: (lastActivity ? lastActivity : outlet.createdAt) > lastOrderPlacedDateTime ? (lastActivity ? lastActivity : outlet.createdAt) : lastOrderPlacedDateTime,

    //     merchantId: merchant.uniqueId,
    //     outletId: outlet.uniqueId,

    //     //////////////////////////////////////////////

    //     // new info

    //     userAppAndWebDineInOrdersAmount: userAppAndWebDineInOrdersAmount,
    //     userAppAndWebDineInOrdersQty: userAppAndWebDineInOrdersQty,

    //     merchantAppOrdersAmount: merchantAppOrdersAmount,
    //     merchantAppOrdersQty: merchantAppOrdersQty,

    //     waiterAppOrdersAmount: waiterAppOrdersAmount,
    //     waiterAppOrdersQty: waiterAppOrdersQty,

    //     userAppAndWebTakeawayOrdersAmount: userAppAndWebTakeawayOrdersAmount,
    //     userAppAndWebTakeawayOrdersQty: userAppAndWebTakeawayOrdersQty,

    //     userAppAndWebDeliveryOrdersAmount: userAppAndWebDeliveryOrdersAmount,
    //     userAppAndWebDeliveryOrdersQty: userAppAndWebDeliveryOrdersQty,

    //     totalOrdersAmount: totalOrdersAmount,
    //     totalOrdersQty: totalOrdersQty,

    //     lastOrderPlacedDateTime: lastOrderPlacedDateTime,

    //     //////////////////////////////////////////////
    //   };
    // });

    // outletDataDict[gmvOrdersFiltered[i].outletId].totalOrdersAmount = outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersAmount +
    //   outletDataDict[gmvOrdersFiltered[i].outletId].merchantAppOrdersAmount + outletDataDict[gmvOrdersFiltered[i].outletId].waiterAppOrdersAmount +
    //   outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount + outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount;

    // outletDataDict[gmvOrdersFiltered[i].outletId].totalOrdersQty = outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDineInOrdersQty +
    //   outletDataDict[gmvOrdersFiltered[i].outletId].merchantAppOrdersQty + outletDataDict[gmvOrdersFiltered[i].outletId].waiterAppOrdersQty +
    //   outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebTakeawayOrdersQty + outletDataDict[gmvOrdersFiltered[i].outletId].userAppAndWebDeliveryOrdersQty;

    console.log('outletDataDict');
    console.log(outletDataDict);

    ////////////////////////////////////////////////////////////

    // update details automatically

    if (outletDataDict[showDetailsOutletId] && outletDataDict[showDetailsOutletId].detailsList !== undefined) {
      // var detailsListTemp = outletDataDict[showDetailsOutletId].detailsList;
      // detailsListTemp.sort(sortByDateTime);
      // setAllOutletsEmployeesDetails(detailsListTemp);
      // setToggleCompare(!toggleCompare);

      setAllOutletsEmployeesDetails(outletDataDict[showDetailsOutletId].detailsList);
    }

    ////////////////////////////////////////////////////////////

    var tempData = Object.entries(outletDataDict).map(([key, value]) => {
      // console.log('total');
      // console.log(value.userAppAndWebDineInOrdersAmount);
      // console.log(value.merchantAppOrdersAmount);
      // console.log(value.waiterAppOrdersAmount);
      // console.log(value.userAppAndWebTakeawayOrdersAmount);
      // console.log(value.userAppAndWebDeliveryOrdersAmount);

      return {
        ...value,
        totalOrdersAmount: value.userAppAndWebDineInOrdersAmount + value.merchantAppOrdersAmount + value.waiterAppOrdersAmount +
          value.userAppAndWebTakeawayOrdersAmount + value.userAppAndWebDeliveryOrdersAmount,
        totalOrdersQty: value.userAppAndWebDineInOrdersQty + value.merchantAppOrdersQty + value.waiterAppOrdersQty +
          value.userAppAndWebTakeawayOrdersQty + value.userAppAndWebDeliveryOrdersQty,
      };
    });

    console.log('tempData');
    console.log(tempData);

    const outletLastOrderArray = Object.entries(window.outletFilterDict).map(
      ([key, value]) => ({ key: key, value: value }),
    );

    setMerchantDataAllLength(tempData.length);

    // var startDate24H = moment().subtract(1, "day").startOf('day').valueOf();
    // var endDate24H = moment().subtract(1, "day").endOf('day').valueOf();
    setMerchantData24HLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate24H).isSameOrBefore(temp.value) &&
      //   moment(endDate24H).isAfter(temp.value)) {
      if (temp.value['is24H']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
    // var endDate7D = moment().subtract(1, "day").endOf('day').valueOf();
    setMerchantData7DLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate7D).isSameOrBefore(temp.value) &&
      //   moment(endDate7D).isAfter(temp.value)) {
      if (temp.value['is7D']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // var startDate1M = moment().subtract(1, "month").startOf('month').startOf('day').valueOf();
    // var endDate1M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
    setMerchantData1MLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate1M).isSameOrBefore(temp.value) &&
      //   moment(endDate1M).isAfter(temp.value)) {
      if (temp.value['is1M']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // var startDate3M = moment().subtract(3, "month").startOf('month').startOf('day').valueOf();
    // var endDate3M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
    setMerchantData3MLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate3M).isSameOrBefore(temp.value) &&
      //   moment(endDate3M).isAfter(temp.value)) {
      if (temp.value['is3M']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // CommonStore.update(s => {
    //   s.outletFilterDict = window.outletFilterDict;
    // });

    // setMerchantData3MLength(tempData.filter(temp => {
    //   // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 3) {
    //   if (moment(startDate3M).isSameOrBefore(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId]) &&
    //     moment(endDate3M).isAfter(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId])) {
    //     if (temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId]) {
    //       return true;
    //     }
    //     else {
    //       return false;
    //     }
    //   }
    //   else {
    //     return false;
    //   }
    // }).length);

    if (merchantDataFilterType === MERCHANT_DATA_FILTER.ALL) {
      tempData = tempData;
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'hour') <= 24) {
        if (moment(startDate24H).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate24H).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'day') <= 7) {
        if (moment(startDate7D).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate7D).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 1) {
        if (moment(startDate1M).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate1M).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 3) {
        if (moment(startDate3M).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate3M).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }

    //////////////////////////////////////////////////////////////////

    // sort by default

    tempData.sort(sortByTotalOrdersAmountDesc);

    //////////////////////////////////////////////////////////////////

    const tempKCRMarray = tempData;

    console.log("detailsList", tempKCRMarray);

    setAllOutletsEmployeesAction(tempKCRMarray);

    setPageCount(Math.ceil(tempKCRMarray.length / perPage));

    //////////////////////////////////////////////////////////////////

    // save data

    await AsyncStorage.setItem('outletFilterDict', JSON.stringify(window.outletFilterDict));

    //////////////////////////////////////////////////////////////////
  }, [
    merchantsOnboarded, outletsOnboarded, gmvStartDate, gmvEndDate, merchantDataFilterType,
    outletUserActionDict,
    gmvOrders,
  ]);

  const checkOutletFilterDict = (outlet, orderDateTime, dateTimeConst) => {
    const {
      startDate24H,
      endDate24H,
      startDate7D,
      endDate7D,
      startDate1M,
      endDate1M,
      startDate3M,
      endDate3M,
    } = dateTimeConst;

    if (!window.outletFilterDict[outlet.uniqueId]) {
      window.outletFilterDict[outlet.uniqueId] = {
        is24H: false,
        is7D: false,
        is1M: false,
        is3M: false,
      };
    }

    if (moment(startDate24H).isSameOrBefore(orderDateTime) &&
      moment(endDate24H).isAfter(orderDateTime)) {
      window.outletFilterDict[outlet.uniqueId]['is24H'] = true;
    }

    if (moment(startDate7D).isSameOrBefore(orderDateTime) &&
      moment(endDate7D).isAfter(orderDateTime)) {
      window.outletFilterDict[outlet.uniqueId]['is7D'] = true;
    }
    if (moment(startDate1M).isSameOrBefore(orderDateTime) &&
      moment(endDate1M).isAfter(orderDateTime)) {
      window.outletFilterDict[outlet.uniqueId]['is1M'] = true;
    }

    if (moment(startDate3M).isSameOrBefore(orderDateTime) &&
      moment(endDate3M).isAfter(orderDateTime)) {
      window.outletFilterDict[outlet.uniqueId]['is3M'] = true;
    }
  }

  // useEffect(async () => {
  //   const tempData = allOutletsEmployeesAction.map(merchant => {
  //     // var outlet = outletsOnboarded.find(outlet => outlet.merchantId === merchant.uniqueId);

  //     var employeeClock = userActions.find(clock => clock.merchantId === merchant.merchantId);      

  //     if (employeeClock) {
  //       return {
  //         ...merchant,
  //         lastActivity: employeeClock.updatedAt > merchant.lastActivity ? employeeClock.updatedAt : merchant.lastActivity,
  //       };
  //     }
  //     else {
  //       return merchant;
  //     }
  //   });

  //   const tempKCRMarray = tempData;

  //   const isEqual = JSON.prune(allOutletsEmployeesAction) === JSON.prune(tempKCRMarray);

  //   if (!isEqual) {
  //     setAllOutletsEmployeesAction(tempKCRMarray);

  //     setPageCount(Math.ceil(tempKCRMarray.length / perPage));
  //   }
  // }, [allOutletsEmployeesAction, userActions]);

  // useEffect(async () => {
  //   const tempData = allOutletsEmployeesAction.map(merchant => {
  //     // var outlet = outletsOnboarded.find(outlet => outlet.merchantId === merchant.uniqueId);

  //     var employeeClock = employeeClocks.find(clock => clock.merchantId === merchant.merchantId);

  //     if (employeeClock) {
  //       return {
  //         ...merchant,
  //         lastActivity: employeeClock.updatedAt > merchant.lastActivity ? employeeClock.updatedAt : merchant.lastActivity,
  //       };
  //     }
  //     else {
  //       return merchant;
  //     }
  //   });

  //   const tempKCRMarray = tempData;

  //   const isEqual = JSON.prune(allOutletsEmployeesAction) === JSON.prune(tempKCRMarray);

  //   if (!isEqual) {
  //     setAllOutletsEmployeesAction(tempKCRMarray);

  //     setPageCount(Math.ceil(tempKCRMarray.length / perPage));
  //   }
  // }, [allOutletsEmployeesAction, employeeClocks]);

  ///////////////////////////////////////////////////////////////////////////////////

  // useEffect(async () => {
  //   const tempData = allOutletsEmployeesAction.map(async merchant => {
  //     var outlet = outletsOnboarded.find(outlet => outlet.merchantId === merchant.uniqueId);

  //     const employeeClockSnapshot = await firebase.firestore().collection(Collections.EmployeeClock)
  //       .where('merchantId', '==', merchant.merchantId)
  //       .orderBy('updatedAt', 'desc')
  //       .limit(1)
  //       .get();

  //     var employeeClock = null;
  //     if (!employeeClockSnapshot.empty) {
  //       employeeClock = employeeClockSnapshot.docs[0].data();
  //     }

  //     if (employeeClock) {
  //       return {
  //         ...merchant,
  //         lastActivity: employeeClock.updatedAt,
  //       };
  //     }
  //     else {
  //       return merchant;
  //     }
  //   });

  //   const tempKCRMarray = tempData;

  //   const isEqual = JSON.prune(allOutletsEmployeesAction) === JSON.prune(tempKCRMarray);

  //   if (!isEqual) {
  //     setAllOutletsEmployeesAction(tempKCRMarray);

  //     setPageCount(Math.ceil(tempKCRMarray.length / perPage));
  //   }
  // }, [allOutletsEmployeesAction]);

  ///////////////////////////////////////////////////////////////////////////////////

  const setState = () => { };

  //   navigation.setOptions({
  //     headerLeft: () => (
  //       <View
  //         style={{
  //           width: Dimensions.get("screen").width * 0.17,
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Image
  //           style={{
  //             width: 124,
  //             height: 26,
  //           }}
  //           resizeMode="contain"
  //           source={require("../assets/image/logo.png")}
  //         />
  //       </View>
  //     ),
  //     headerTitle: () => (
  //       <View
  //         style={[
  //           {
  //             justifyContent: "center",
  //             alignItems: "center",
  //             // bottom: -2,
  //             bottom: switchMerchant ? "2%" : 0,
  //           },
  //           Dimensions.get("screen").width >= 768 && switchMerchant
  //             ? { right: Dimensions.get("screen").width * 0.1 }
  //             : {},
  //           Dimensions.get("screen").width <= 768
  //             ? { right: Dimensions.get("screen").width * 0.12 }
  //             : {},
  //         ]}
  //       >
  //         <Text
  //           style={{
  //             fontSize: switchMerchant ? 20 : 24,
  //             // lineHeight: 25,
  //             textAlign: "center",
  //             fontFamily: "NunitoSans-Bold",
  //             color: Colors.whiteColor,
  //             opacity: 1,
  //           }}
  //         >
  //           KCRM Agreement Report
  //         </Text>
  //       </View>
  //     ),
  //     headerRight: () => (
  //       <View
  //         style={{
  //           flexDirection: "row",
  //           alignItems: "center",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         {outletSelectDropdownView()}
  //         <View
  //           style={{
  //             backgroundColor: "white",
  //             width: 0.5,
  //             height: Dimensions.get("screen").height * 0.025,
  //             opacity: 0.8,
  //             marginHorizontal: 15,
  //             bottom: -1,
  //           }}
  //         ></View>
  //         <TouchableOpacity
  //           onPress={() => navigation.navigate("Setting")}
  //           style={{ flexDirection: "row", alignItems: "center" }}
  //         >
  //           <Text
  //             style={{
  //               fontFamily: "NunitoSans-SemiBold",
  //               fontSize: 16,
  //               color: Colors.secondaryColor,
  //               marginRight: 15,
  //             }}
  //           >
  //             {userName}
  //           </Text>
  //           <View
  //             style={{
  //               marginRight: 30,
  //               width: Dimensions.get("screen").height * 0.05,
  //               height: Dimensions.get("screen").height * 0.05,
  //               borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
  //               alignItems: "center",
  //               justifyContent: "center",
  //               backgroundColor: "white",
  //             }}
  //           >
  //             <Image
  //               style={{
  //                 width: Dimensions.get("screen").height * 0.035,
  //                 height: Dimensions.get("screen").height * 0.035,
  //                 alignSelf: "center",
  //               }}
  //               source={require("../assets/image/profile-pic.jpg")}
  //             />
  //           </View>
  //         </TouchableOpacity>
  //       </View>
  //     ),
  //   });

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };

  const nextDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage + 1 > pageCount
        ? currentDetailsPage
        : currentDetailsPage + 1
    );
  };

  const prevDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1
    );
  };

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setShowDetails(true);
          setAllOutletsEmployeesDetails(item.detailsList);

          setShowDetailsOutletId(item.outletId);
        }}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          paddingHorizontal: 3,
          paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "5%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "20%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.merchantName}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.userAppAndWebDineInOrdersAmount.toFixed(2)} (${item.userAppAndWebDineInOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.merchantAppOrdersAmount.toFixed(2)} (${item.merchantAppOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.waiterAppOrdersAmount.toFixed(2)} (${item.waiterAppOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.userAppAndWebTakeawayOrdersAmount.toFixed(2)} (${item.userAppAndWebTakeawayOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.userAppAndWebDeliveryOrdersAmount.toFixed(2)} (${item.userAppAndWebDeliveryOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.totalOrdersAmount.toFixed(2)} (${item.totalOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.gmvCommission.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.gmvPayout.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.gmvFees.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.lastOrderPlacedDateTime ? moment(item.lastOrderPlacedDateTime).format('YYYY-MM-DD hh:mm A') : 'N/A'}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const renderItemDetails = ({ item, index }) => {
    return (
      <View
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.dateTime}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.orderId}
            {/* {item.uniqueId} */}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.isQrOrder}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.netAmount.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.tax.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.sc.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.totalAmount.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.razerCharges.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.koodooCharges.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.outletPayout.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.settlementDate}
          </Text>
        </View>
      </View>
    );
  };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    if (!showDetails) {
      for (var i = 0; i < allOutletsEmployeesAction.length; i++) {
        var excelRow = {
          "Employee Name": allOutletsEmployeesAction[i].name,
          "Num of actions": allOutletsEmployeesAction[i].detailsList.length,
        };

        excelData.push(excelRow);
      }
    } else {
      for (var i = 0; i < allOutletsEmployeesDetails.length; i++) {
        var excelRow = {
          "Action type": allOutletsEmployeesDetails[i].actionType,
          Date: moment(allOutletsEmployeesDetails[i].actionDate).format(
            "DD MMM hh:mm A"
          ),
        };

        excelData.push(excelRow);
      }
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  const emailVariant = () => {
    const excelData = convertDataToExcelFormat();

    var body = {
      // data: CsvData,
      //data: convertArrayToCSV(todaySalesChart.dataSource.data),
      data: JSON.stringify(excelData),
      //data: convertDataToExcelFormat(),
      email: exportEmail,
    };

    ApiClient.POST(API.emailDashboard, body, false).then((result) => {
      if (result !== null) {
        Alert.alert(
          "Success",
          "Email has been sent",
          [{ text: "OK", onPress: () => { } }],
          { cancelable: false }
        );
      }
    });

    setVisible(false);
  };

  const flatListRef = useRef();

  const filterItem = (item) => {
    if (search !== "") {
      if (item.merchantName.toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else if (item.outletName.toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const filterItemDetails = (item) => {
    if (search !== "") {
      if (item.companyName.toLowerCase().includes(search.toLowerCase())) {
        return true;
        //   } else if (
        //     moment(item.actionDate, "x")
        //       .format("DD MMM YYY hh:mma")
        //       .toLowerCase()
        //       .includes(search.toLowerCase())
        //   ) {
        //     return true;
      } else {
        return false;
      }
    }
    // else {
    //   // check if there is data between the dates
    //   return moment(item.actionDate, "x").isBetween(rev_date, rev_date1);
    // }
    return true;
  };

  // sort func
  const sortingAlphabetically = (a, b, compareName) => {
    if (a[compareName] < b[compareName]) {
      return -1;
    }
    if (a[compareName] > b[compareName]) {
      return 1;
    }
    return 0;
  };
  const sortingAlphabeticallyDesc = (a, b, compareName) => {
    if (a[compareName] < b[compareName]) {
      return 1;
    }
    if (a[compareName] > b[compareName]) {
      return -1;
    }
    return 0;
  };

  // sort by date
  const sortByCreatedDate = (a, b) =>
    moment(a["createdAt"]).valueOf() - moment(b["createdAt"]).valueOf();
  const sortByCreatedDateDesc = (a, b) =>
    moment(b["createdAt"]).valueOf() - moment(a["createdAt"]).valueOf();

  const sortByStartDate = (a, b) =>
    moment(a["createdAt"]).valueOf() - moment(b["createdAt"]).valueOf();
  const sortByStartDateDesc = (a, b) =>
    moment(b["createdAt"]).valueOf() - moment(a["createdAt"]).valueOf();

  const sortByCompName = (a, b) => {
    let compareName = "companyName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompNameDesc = (a, b) => {
    let compareName = "companyName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByCompRegistration = (a, b) => {
    let compareName = "companyRegistrationNo";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompRegistrationDesc = (a, b) => {
    let compareName = "companyRegistrationNo";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByCompAddr = (a, b) => {
    let compareName = "companyAddress";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompAddrDesc = (a, b) => {
    let compareName = "companyAddress";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByDirectorName = (a, b) => {
    let compareName = "directorName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByDirectorNameDesc = (a, b) => {
    let compareName = "directorName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByMerchantEmail = (a, b) => {
    let compareName = "merchantEmail";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantEmailDesc = (a, b) => {
    let compareName = "merchantEmail";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByMerchantName = (a, b) => {
    let compareName = "merchantName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantNameDesc = (a, b) => {
    let compareName = "merchantName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOutletName = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOutletNameDesc = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByOnboarderEmail = (a, b) => {
    let compareName = "onboarderEmail";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOnboarderEmailDesc = (a, b) => {
    let compareName = "onboarderEmail";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByLastActivityDate = (a, b) =>
    moment(a["lastActivity"]).valueOf() - moment(b["lastActivity"]).valueOf();
  const sortByLastActivityDateDesc = (a, b) =>
    moment(b["lastActivity"]).valueOf() - moment(a["lastActivity"]).valueOf();

  //////////////////////////////////////////////////////////////

  const sortByUserAppAndWebDineInOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDineInOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDineInOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDineInOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByMerchantAppOrdersAmount = (a, b) => {
    let compareName = "merchantAppOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantAppOrdersAmountDesc = (a, b) => {
    let compareName = "merchantAppOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByWaiterAppOrdersAmount = (a, b) => {
    let compareName = "waiterAppOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByWaiterAppOrdersAmountDesc = (a, b) => {
    let compareName = "waiterAppOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebTakeawayOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebTakeawayOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebTakeawayOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebTakeawayOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebDeliveryOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDeliveryOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDeliveryOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDeliveryOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTotalOrdersAmount = (a, b) => {
    let compareName = "totalOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTotalOrdersAmountDesc = (a, b) => {
    let compareName = "totalOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvCommission = (a, b) => {
    let compareName = "gmvCommission";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvCommissionDesc = (a, b) => {
    let compareName = "gmvCommission";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvFees = (a, b) => {
    let compareName = "gmvFees";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvFeesDesc = (a, b) => {
    let compareName = "gmvFees";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvPayout = (a, b) => {
    let compareName = "gmvPayout";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvPayoutDesc = (a, b) => {
    let compareName = "gmvPayout";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByLastOrderPlacedDateTime = (a, b) => {
    let compareName = "lastOrderPlacedDateTime";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByLastOrderPlacedDateTimeDesc = (a, b) => {
    let compareName = "lastOrderPlacedDateTime";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  // for details

  const sortByDateTime = (a, b) => {
    let compareName = "dateTime";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByDateTimeDesc = (a, b) => {
    let compareName = "dateTime";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOrderId = (a, b) => {
    let compareName = "orderId";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOrderIdDesc = (a, b) => {
    let compareName = "orderId";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  // const sortByOrderId = (a, b) => {
  //   let compareName = "orderId";
  //   return sortingAlphabetically(a, b, compareName);
  // };
  // const sortByOrderIdDesc = (a, b) => {
  //   let compareName = "orderId";
  //   return sortingAlphabeticallyDesc(a, b, compareName);
  // };

  const sortByIsQrOrder = (a, b) => {
    let compareName = "isQrOrder";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByIsQrOrderDesc = (a, b) => {
    let compareName = "isQrOrder";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByNetAmount = (a, b) => {
    let compareName = "netAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByNetAmountDesc = (a, b) => {
    let compareName = "netAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTax = (a, b) => {
    let compareName = "tax";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTaxDesc = (a, b) => {
    let compareName = "tax";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortBySc = (a, b) => {
    let compareName = "sc";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByScDesc = (a, b) => {
    let compareName = "sc";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTotalAmount = (a, b) => {
    let compareName = "totalAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTotalAmountDesc = (a, b) => {
    let compareName = "totalAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByRazerCharges = (a, b) => {
    let compareName = "razerCharges";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByRazerChargesDesc = (a, b) => {
    let compareName = "razerCharges";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByKoodooCharges = (a, b) => {
    let compareName = "koodooCharges";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByKoodooChargesDesc = (a, b) => {
    let compareName = "koodooCharges";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOutletPayout = (a, b) => {
    let compareName = "outletPayout";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOutletPayoutDesc = (a, b) => {
    let compareName = "outletPayout";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortBySettlementDate = (a, b) => {
    let compareName = "settlementDate";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortBySettlementDateDesc = (a, b) => {
    let compareName = "settlementDate";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  return (
    <View
      style={[styles.container, { height: windowHeight, width: windowWidth }]}
    >
      {/* <View
        style={[
          styles.sidebar,
          switchMerchant
            ? {
                // width: '10%'
              }
            : {},
        ]}
      >
        <SideBar
          navigation={props.navigation}
          selectedTab={8}
          expandReport={true}
        />
      </View> */}
      <ScrollView horizontal={true}>
        <Modal
          style={{}}
          visible={exportModalVisibility}
          supportedOrientations={["portrait", "landscape"]}
          transparent={true}
          animationType={"fade"}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: Colors.modalBgColor,
              alignItems: "center",
              justifyContent: "center",
              top:
                Platform.OS === "android"
                  ? 0
                  : keyboardHeight > 0
                    ? -keyboardHeight * 0.45
                    : 0,
            }}
          >
            <View
              style={{
                backgroundColor: Colors.whiteColor,
                borderRadius: 12,
                padding: windowWidth * 0.03,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TouchableOpacity
                disabled={isLoading}
                style={{
                  position: "absolute",
                  right: windowWidth * 0.02,
                  top: windowWidth * 0.02,

                  elevation: 1000,
                  zIndex: 1000,
                }}
                onPress={() => {
                  setExportModalVisibility(false);
                }}
              >
                <AntDesign
                  name="closecircle"
                  size={switchMerchant ? 15 : 25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>
              <View
                style={{
                  alignItems: "center",
                  top: "20%",
                  position: "absolute",
                }}
              >
                <Text
                  style={{
                    fontFamily: "NunitoSans-Bold",
                    textAlign: "center",
                    fontSize: switchMerchant ? 16 : 24,
                  }}
                >
                  Download Report
                </Text>
              </View>
              <View style={{ top: switchMerchant ? "14%" : "10%" }}>
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 20,
                    fontFamily: "NunitoSans-Bold",
                  }}
                >
                  Email Address:
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={{
                    backgroundColor: Colors.fieldtBgColor,
                    width: switchMerchant ? 240 : 370,
                    height: switchMerchant ? 35 : 50,
                    borderRadius: 5,
                    padding: 5,
                    marginVertical: 5,
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    paddingLeft: 10,
                    fontSize: switchMerchant ? 10 : 14,
                  }}
                  placeholderStyle={{ padding: 5 }}
                  placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                  placeholder="Enter Your Email"
                  onChangeText={(text) => {
                    setExportEmail(text);
                  }}
                  value={exportEmail}
                />
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 20,
                    fontFamily: "NunitoSans-Bold",
                    marginTop: 15,
                  }}
                >
                  Send As:
                </Text>

                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <TouchableOpacity
                    disabled={isLoading}
                    style={{
                      justifyContent: "center",
                      flexDirection: "row",
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: "#4E9F7D",
                      borderRadius: 5,
                      width: switchMerchant ? 100 : 100,
                      paddingHorizontal: 10,
                      height: switchMerchant ? 35 : 40,
                      alignItems: "center",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                      marginRight: 15,
                    }}
                    onPress={() => {
                      if (exportEmail.length > 0) {
                        CommonStore.update((s) => {
                          s.isLoading = true;
                        });

                        setIsExcel(true);

                        const excelData = convertDataToExcelFormat();

                        generateEmailReport(
                          EMAIL_REPORT_TYPE.EXCEL,
                          excelData,
                          "KooDoo Add-Ons Sales Report",
                          "KooDoo Add-Ons Sales Report.xlsx",
                          `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                          exportEmail,
                          "KooDoo Add-Ons Sales Report",
                          "KooDoo Add-Ons Sales Report",
                          () => {
                            CommonStore.update((s) => {
                              s.isLoading = false;
                            });

                            setIsExcel(false);

                            Alert.alert(
                              "Success",
                              "Report will be sent to the email address soon"
                            );

                            setExportModalVisibility(false);
                          }
                        );
                      } else {
                        Alert.alert("Info", "Invalid email address");
                      }
                    }}
                  >
                    {isLoading && isExcel ? (
                      <ActivityIndicator
                        size={"small"}
                        color={Colors.whiteColor}
                      />
                    ) : (
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          //marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        EXCEL
                      </Text>
                    )}
                  </TouchableOpacity>

                  <TouchableOpacity
                    disabled={isLoading}
                    style={{
                      justifyContent: "center",
                      flexDirection: "row",
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: "#4E9F7D",
                      borderRadius: 5,
                      width: switchMerchant ? 100 : 100,
                      paddingHorizontal: 10,
                      height: switchMerchant ? 35 : 40,
                      alignItems: "center",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                    }}
                    onPress={() => {
                      if (exportEmail.length > 0) {
                        CommonStore.update((s) => {
                          s.isLoading = true;
                        });

                        setIsCsv(true);

                        const csvData = convertArrayToCSV(
                          allOutletsEmployeesAction
                        );

                        generateEmailReport(
                          EMAIL_REPORT_TYPE.CSV,
                          csvData,
                          "KooDoo Add-Ons Sales Report",
                          "KooDoo Add-Ons Sales Report.csv",
                          `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                          exportEmail,
                          "KooDoo Add-Ons Sales Report",
                          "KooDoo Add-Ons Sales Report",
                          () => {
                            CommonStore.update((s) => {
                              s.isLoading = false;
                            });

                            setIsCsv(false);

                            Alert.alert(
                              "Success",
                              "Report will be sent to the email address soon"
                            );

                            setExportModalVisibility(false);
                          }
                        );
                      } else {
                        Alert.alert("Info", "Invalid email address");
                      }
                    }}
                  >
                    {isLoading && isCsv ? (
                      <ActivityIndicator
                        size={"small"}
                        color={Colors.whiteColor}
                      />
                    ) : (
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          //marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        CSV
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>

        <DateTimePickerModal
          isVisible={showDateTimePicker}
          mode={"date"}
          onConfirm={(text) => {
            // setRev_date(moment(text).startOf("day"));

            CommonStore.update(s => {
              s.gmvStartDate = moment(text).startOf("day").valueOf();
            });

            setShowDateTimePicker(false);
          }}
          onCancel={() => {
            setShowDateTimePicker(false);
          }}
          maximumDate={moment(gmvStartDate).toDate()}
        />

        <DateTimePickerModal
          isVisible={showDateTimePicker1}
          mode={"date"}
          onConfirm={(text) => {
            // setRev_date1(moment(text).endOf("day"));

            CommonStore.update(s => {
              s.gmvEndDate = moment(text).endOf("day").valueOf();
            });

            setShowDateTimePicker1(false);
          }}
          onCancel={() => {
            setShowDateTimePicker1(false);
          }}
          minimumDate={moment(gmvEndDate).toDate()}
        />

        <Modal
          style={{ flex: 1 }}
          visible={visible}
          transparent={true}
          animationType="slide"
        >
          <KeyboardAvoidingView
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              minHeight: windowHeight,
              top:
                Platform.OS === "ios" && keyboardHeight > 0
                  ? -keyboardHeight * 0.5
                  : 0,
            }}
          >
            <View style={styles.confirmBox1}>
              <Text
                style={{
                  fontSize: 24,
                  justifyContent: "center",
                  alignSelf: "center",
                  marginTop: 40,
                  fontFamily: "NunitoSans-Bold",
                }}
              >
                Enter your email
              </Text>
              <View
                style={{
                  justifyContent: "center",
                  alignSelf: "center",
                  alignContent: "center",
                  marginTop: 20,
                  flexDirection: "row",
                  width: "80%",
                }}
              >
                <View style={{ justifyContent: "center", marginHorizontal: 5 }}>
                  <Text
                    style={{ color: Colors.descriptionColor, fontSize: 20 }}
                  >
                    email:
                  </Text>
                </View>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={[styles.textInput8, { paddingLeft: 5 }]}
                  placeholder="Enter your email"
                  // style={{
                  //     // paddingLeft: 1,
                  // }}
                  //defaultValue={extentionCharges}
                  onChangeText={(text) => {
                    // setState({ exportEmail: text });
                    setExportEmail(text);
                  }}
                  placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                  value={exportEmail}
                />
              </View>
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "NunitoSans-Bold",
                  marginTop: 25,
                  justifyContent: "center",
                  alignSelf: "center",
                  alignContent: "center",
                }}
              >
                Share As:
              </Text>

              {/* Share file using email */}
              <View
                style={{
                  justifyContent: "space-between",
                  alignSelf: "center",
                  marginTop: 10,
                  flexDirection: "row",
                  width: "80%",
                }}
              >
                <TouchableOpacity
                  style={[
                    styles.modalSaveButton1,
                    {
                      zIndex: -1,
                    },
                  ]}
                  onPress={() => { }}
                >
                  <Text
                    style={[
                      styles.modalDescText,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Excel
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={[
                    styles.modalSaveButton1,
                    {
                      zIndex: -1,
                    },
                  ]}
                  onPress={() => { }}
                >
                  <Text
                    style={[
                      styles.modalDescText,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    CSV
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.modalSaveButton1,
                    {
                      zIndex: -1,
                    },
                  ]}
                  onPress={() => { }}
                >
                  <Text
                    style={[
                      styles.modalDescText,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    PDF
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  alignSelf: "center",
                  marginTop: 20,
                  justifyContent: "center",
                  alignItems: "center",
                  // width: 260,
                  width: windowWidth * 0.2,
                  height: 60,
                  alignContent: "center",
                  flexDirection: "row",
                  marginTop: 40,
                }}
              >
                <TouchableOpacity
                  onPress={emailVariant}
                  style={{
                    backgroundColor: Colors.fieldtBgColor,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: 60,
                    borderBottomLeftRadius: 10,
                    borderRightWidth: StyleSheet.hairlineWidth,
                    borderTopWidth: StyleSheet.hairlineWidth,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 22,
                      color: Colors.primaryColor,
                      fontFamily: "NunitoSans-SemiBold",
                    }}
                  >
                    Email
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    // setState({ visible: false });
                    setVisible(false);
                  }}
                  style={{
                    backgroundColor: Colors.fieldtBgColor,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: 60,
                    borderBottomRightRadius: 10,
                    borderTopWidth: StyleSheet.hairlineWidth,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 22,
                      color: Colors.descriptionColor,
                      fontFamily: "NunitoSans-SemiBold",
                    }}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </KeyboardAvoidingView>
        </Modal>

        <View style={[styles.content, {}]}>
          <View style={{ flex: 1 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
                justifyContent: "space-between",
                paddingHorizontal: 18,
                marginTop: 5,
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontSize: switchMerchant ? 20 : 26,
                  fontFamily: "NunitoSans-Bold",
                }}
              >
                KooDoo Merchants GMV
              </Text>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                {/* <TouchableOpacity
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    //width: 140,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                    marginRight: 10,
                  }}
                  onPress={() => {
                    setExportModalVisibility(true);
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon
                      name="download"
                      size={switchMerchant ? 10 : 20}
                      color={Colors.whiteColor}
                    />
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      DOWNLOAD
                    </Text>
                  </View>
                </TouchableOpacity> */}

                {/* <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 5,
                    paddingHorizontal: 15,
                    height: switchMerchant ? 35 : 39,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    marginTop: 1,
                    paddingTop: 1,

                    marginRight: 15,
                  }}
                  onPress={() => {
                    navigation.navigate('KooDoo Statistics');
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {`Agreements`}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 5,
                    paddingHorizontal: 15,
                    height: switchMerchant ? 35 : 39,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    marginTop: 1,
                    paddingTop: 1,

                    marginRight: 15,
                  }}
                  onPress={() => {
                    navigation.navigate('Merchants - KooDoo Statistics');
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {`Merchants`}
                  </Text>
                </TouchableOpacity> */}

                <View
                  style={[
                    {
                      height: switchMerchant ? 35 : 40,
                    },
                  ]}
                >
                  <View
                    style={{
                      width: switchMerchant ? 200 : 250,
                      height: switchMerchant ? 35 : 40,
                      backgroundColor: "white",
                      borderRadius: 5,
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",

                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    }}
                  >
                    <Icon
                      name="search"
                      size={switchMerchant ? 13 : 18}
                      color={Colors.primaryColor}
                      style={{ marginLeft: 15 }}
                    />
                    <TextInput
                      editable={!loading}
                      underlineColorAndroid={Colors.whiteColor}
                      style={{
                        width: switchMerchant ? 180 : 220,
                        fontSize: switchMerchant ? 10 : 15,
                        fontFamily: "NunitoSans-Regular",
                        paddingLeft: 5,
                        height: 45,
                      }}
                      placeholderTextColor={Platform.select({
                        ios: "#a9a9a9",
                      })}
                      clearButtonMode="while-editing"
                      placeholder=" Search"
                      onChangeText={(text) => {
                        setSearch(text);
                      }}
                      value={search}
                    />
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                //backgroundColor: '#ffffff',
                justifyContent: "space-between",
                //padding: 18,
                marginTop: 20,
                width: "100%",
                paddingLeft: switchMerchant
                  ? 0
                  : windowWidth <= 1823 && windowWidth >= 1820
                    ? "1.5%"
                    : "1%",
                paddingRight: switchMerchant
                  ? 0
                  : windowWidth <= 1823 && windowWidth >= 1820
                    ? "1.5%"
                    : "1%",
              }}
            >
              <TouchableOpacity
                style={[
                  {
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    opacity: !showDetails ? 0 : 100,
                    // opacity: 100,
                  },
                ]}
                onPress={() => {
                  setShowDetails(false);
                  setPageCount(
                    Math.ceil(allOutletsEmployeesAction.length / perPage)
                  );
                  setCurrentPage(pageReturn);

                  setShowDetailsOutletId('');

                  console.log("Returning to page");
                  console.log(pageReturn);
                }}
                disabled={!showDetails}
              >
                <AntDesign
                  name="arrowleft"
                  size={switchMerchant ? 10 : 20}
                  color={Colors.whiteColor}
                  style={{}}
                />
                <Text
                  style={{
                    color: Colors.whiteColor,
                    marginLeft: 5,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: "NunitoSans-Bold",
                  }}
                >
                  Summary
                </Text>
              </TouchableOpacity>

              <View style={{ flexDirection: "row" }}>
                <View
                  style={[
                    {
                      paddingHorizontal: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      paddingVertical: 10,
                      justifyContent: "center",
                      backgroundColor: Colors.whiteColor,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                    },
                  ]}
                >
                  <View
                    style={{ alignSelf: "center", marginRight: 5 }}
                    onPress={() => {
                      setState({
                        pickerMode: "date",
                        showDateTimePicker: true,
                      });
                    }}
                  >
                    <GCalendar
                      width={switchMerchant ? 15 : 20}
                      height={switchMerchant ? 15 : 20}
                    />
                  </View>

                  {/* <TouchableOpacity
                    onPress={() => {
                      setShowDateTimePicker(true);
                      setShowDateTimePicker1(false);
                    }}
                    style={{
                      marginHorizontal: 4,
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                              fontSize: 10,
                              fontFamily: "NunitoSans-Regular",
                            }
                          : { fontFamily: "NunitoSans-Regular" }
                      }
                    >
                      {moment(rev_date).format("DD MMM yyyy")}
                    </Text>
                  </TouchableOpacity> */}

                  <DatePicker
                    // selected={rev_date.toDate()}
                    selected={moment(gmvStartDate).toDate()}
                    onChange={(date) => {
                      // setRev_date(moment(date).startOf('day'));

                      CommonStore.update(s => {
                        s.gmvStartDate = moment(date).startOf("day").valueOf();
                      });
                    }}
                    maxDate={moment(gmvEndDate).toDate()}
                  />

                  <Text
                    style={
                      switchMerchant
                        ? { fontSize: 10, fontFamily: "NunitoSans-Regular" }
                        : { fontFamily: "NunitoSans-Regular" }
                    }
                  >
                    -
                  </Text>

                  {/* <TouchableOpacity
                    onPress={() => {
                      setShowDateTimePicker(false);
                      setShowDateTimePicker1(true);
                    }}
                    style={{
                      marginHorizontal: 4,
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontSize: 10,
                            fontFamily: "NunitoSans-Regular",
                          }
                          : { fontFamily: "NunitoSans-Regular" }
                      }
                    >
                      {moment(rev_date1).format("DD MMM yyyy")}
                    </Text>
                  </TouchableOpacity> */}

                  <DatePicker
                    // selected={rev_date1.toDate()}
                    selected={moment(gmvEndDate).toDate()}
                    onChange={(date) => {
                      // setRev_date1(moment(date).endOf('day'));

                      CommonStore.update(s => {
                        s.gmvEndDate = moment(date).startOf("day").valueOf();
                      });
                    }}
                    minDate={moment(gmvStartDate).toDate()}
                  />
                </View>
              </View>
            </View>

            <View style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',

              marginTop: 20,
              padding: 20,
              paddingBottom: 0,
              zIndex: -50,

              // backgroundColor: 'red',
            }}>
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  setMerchantDataFilterType(MERCHANT_DATA_FILTER.ALL);

                  CommonStore.update(s => {
                    s.gmvStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                    s.gmvEndDate = moment().endOf('day').valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`All (${merchantDataAllLength} in total${merchantDataAllLength > 1 ? 's' : ''})`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_24H);

                  CommonStore.update(s => {
                    s.gmvStartDate = moment().startOf('day').valueOf();
                    s.gmvEndDate = moment().endOf('day').valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Today (${merchantData24HLength} active${merchantData24HLength > 1 ? 's' : ''})`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_7D);

                  CommonStore.update(s => {
                    s.gmvStartDate = moment().subtract(7, "day").startOf('day').valueOf();
                    s.gmvEndDate = moment().endOf('day').valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Last 7d (${merchantData7DLength} active${merchantData7DLength > 1 ? 's' : ''})`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_1M);

                  CommonStore.update(s => {
                    s.gmvStartDate = moment().subtract(1, "month").startOf('day').valueOf();
                    s.gmvEndDate = moment().endOf('day').valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Last 1m (${merchantData1MLength} active${merchantData1MLength > 1 ? 's' : ''})`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_3M);

                  CommonStore.update(s => {
                    s.gmvStartDate = moment().subtract(3, "month").startOf('day').valueOf();
                    s.gmvEndDate = moment().endOf('day').valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Last 3m (${merchantData3MLength} active${merchantData3MLength > 1 ? 's' : ''})`}
                </Text>
              </TouchableOpacity>
            </View>

            {/* /////////////////////////////////////////////////////// */}

            <View style={{ minWidth: windowWidth, width: "100%", marginTop: 0, padding: 20, zIndex: -50 }}>
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  width: "100%",
                  height: windowHeight * 0.66,
                  marginTop: 0,
                  marginHorizontal: 30,
                  marginBottom: 10,
                  alignSelf: "center",
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}
              >
                {!showDetails ? (
                  <View style={{ marginTop: 10, flexDirection: "row" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "5%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"No.\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", opacity: 0 }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "20%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByMerchantName);
                          } else {
                            temp.sort(sortByMerchantNameDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Merchant\nName"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByUserAppAndWebDineInOrdersAmount);
                          } else {
                            temp.sort(sortByUserAppAndWebDineInOrdersAmountDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"User\nOrders"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByMerchantAppOrdersAmount);
                          } else {
                            temp.sort(sortByMerchantAppOrdersAmountDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"POS\nOrders"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByWaiterAppOrdersAmount);
                          } else {
                            temp.sort(sortByWaiterAppOrdersAmountDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Waiter\nOrders"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByUserAppAndWebTakeawayOrdersAmount);
                          } else {
                            temp.sort(sortByUserAppAndWebTakeawayOrdersAmountDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Takeaway\nOrders"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByUserAppAndWebDeliveryOrdersAmount);
                          } else {
                            temp.sort(sortByUserAppAndWebDeliveryOrdersAmountDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Delivery\nOrders"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByTotalOrdersAmount);
                          } else {
                            temp.sort(sortByTotalOrdersAmountDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Total\nOrders"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    {/* /////////////////////// */}

                    {/* 2022-11-26 - New columns */}

                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByGmvCommission);
                          } else {
                            temp.sort(sortByGmvCommissionDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"GMV\nCommission"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByGmvPayout);
                          } else {
                            temp.sort(sortByGmvPayoutDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"GMV\nPayout"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "7%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByGmvFees);
                          } else {
                            temp.sort(sortByGmvFeesDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"GMV\nFees"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    {/* /////////////////////// */}

                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesAction];

                          if (toggleCompare) {
                            temp.sort(sortByLastOrderPlacedDateTime);
                          } else {
                            temp.sort(sortByLastOrderPlacedDateTimeDesc);
                          }

                          setAllOutletsEmployeesAction(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Last\nOrder"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : (
                  // for details page

                  <View style={{ marginTop: 10, flexDirection: "row" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByDateTime);
                          } else {
                            temp.sort(sortByDateTimeDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Date\nTime"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", opacity: 100 }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByOrderId);
                          } else {
                            temp.sort(sortByOrderIdDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Order\nID\n(Joined)"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByIsQrOrder);
                          } else {
                            temp.sort(sortByIsQrOrderDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"QR\nOrder"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByNetAmount);
                          } else {
                            temp.sort(sortByNetAmountDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Net\nAmount"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByTax);
                          } else {
                            temp.sort(sortByTaxDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Tax\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortBySc);
                          } else {
                            temp.sort(sortByScDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"SC\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByTotalAmount);
                          } else {
                            temp.sort(sortByTotalAmountDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Total\nAmount"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByRazerCharges);
                          } else {
                            temp.sort(sortByRazerChargesDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Razer\nCharges"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByKoodooCharges);
                          } else {
                            temp.sort(sortByKoodooChargesDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"KooDoo\nCharges"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByOutletPayout);
                          } else {
                            temp.sort(sortByOutletPayoutDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Outlet\nPayout"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortBySettlementDate);
                          } else {
                            temp.sort(sortBySettlementDateDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Settlement\nDate"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}

                {!showDetails ? (
                  <>
                    {allOutletsEmployeesAction.filter((item) => {
                      return filterItem(item);
                    }).length > 0 ? (
                      <FlatList
                        showsVerticalScrollIndicator={false}
                        ref={flatListRef}
                        data={allOutletsEmployeesAction
                          .filter((item) => {
                            return filterItem(item);
                          })
                          .slice(
                            (currentPage - 1) * perPage,
                            currentPage * perPage
                          )}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                      />
                    ) : (
                      <View
                        style={{
                          height: windowHeight * 0.5,
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          {/* <Text style={{ color: Colors.descriptionColor }}>
                            Loading...
                          </Text> */}
                        </View>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {allOutletsEmployeesDetails.filter((item) => {
                      return filterItemDetails(item);
                    }).length > 0 ? (
                      <FlatList
                        showsVerticalScrollIndicator={false}
                        ref={flatListRef}
                        data={allOutletsEmployeesDetails
                          .filter((item) => {
                            return filterItemDetails(item);
                          })
                          .slice(
                            (currentDetailsPage - 1) * perPage,
                            currentDetailsPage * perPage
                          )}
                        renderItem={renderItemDetails}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                      />
                    ) : (
                      <View
                        style={{
                          height: windowHeight * 0.5,
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          {/* <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text> */}
                        </View>
                      </View>
                    )}
                  </>
                )}
              </View>

              {!showDetails ? (
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                    width: "100%",
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "flex-end",
                    top:
                      Platform.OS == "ios"
                        ? pushPagingToTop && keyboardHeight > 0
                          ? -keyboardHeight * 1
                          : 0
                        : 0,
                    borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                    paddingHorizontal:
                      pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                  }}
                >
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Items Showed
                  </Text>
                  <View
                    style={{
                      width: Platform.OS === "ios" ? 65 : "13%", //65,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                      //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                      // paddingTop: '-60%',
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      marginRight: "1%",
                    }}
                  >
                    {/* <RNPickerSelect
                        useNativeAndroidPickerStyle={false}
                        style={{
                          inputIOS: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            textAlign: "center",
                          },
                          inputAndroid: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            justifyContent: "center",
                            textAlign: "center",
                            height: 40,
                            color: "black",
                          },
                          inputAndroidContainer: { width: "100%" },
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: "none",
                          },
                          chevronDown: {
                            display: "none",
                          },
                          chevronUp: {
                            display: "none",
                          },
                        }}
                        items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: "All",
                          value: !showDetails
                            ? allOutletsEmployeesAction.length
                            : allOutletsEmployeesDetails.length,
                        })}
                        value={perPage}
                        onValueChange={(value) => {
                          setPerPage(value);
                        }}
                      /> */}
                    <Picker
                      selectedValue={perPage}
                      style={{}}
                      onValueChange={(value, text) => {
                        setPerPage(value);
                      }}
                    >
                      {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: "All",
                        value: !showDetails
                          ? allOutletsEmployeesAction.length
                          : allOutletsEmployeesDetails.length,
                      }).map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker>
                  </View>

                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Page
                  </Text>
                  <View
                    style={{
                      width: switchMerchant ? 65 : 70,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    }}
                  >
                    {console.log("currentPage")}
                    {console.log(currentPage)}

                    <TextInput
                      onChangeText={(text) => {
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp
                        );
                      }}
                      placeholder={
                        pageCount !== 0 ? currentPage.toString() : "0"
                      }
                      placeholderTextColor={Platform.select({
                        ios: "#a9a9a9",
                      })}
                      style={{
                        color: "black",
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                        marginTop: Platform.OS === "ios" ? 0 : -15,
                        marginBottom: Platform.OS === "ios" ? 0 : -15,
                        textAlign: "center",
                        width: "100%",
                      }}
                      value={pageCount !== 0 ? currentPage.toString() : "0"}
                      defaultValue={
                        pageCount !== 0 ? currentPage.toString() : "0"
                      }
                      keyboardType={"numeric"}
                      onFocus={() => {
                        setPushPagingToTop(true);
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginLeft: "1%",
                      marginRight: "1%",
                    }}
                  >
                    of {pageCount}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      prevPage();
                    }}
                  >
                    <ArrowLeft color={Colors.whiteColor} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      nextPage();
                    }}
                  >
                    <ArrowRight color={Colors.whiteColor} />
                  </TouchableOpacity>
                </View>
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                    width: '100%',
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "flex-end",
                    top:
                      Platform.OS == "ios"
                        ? pushPagingToTop && keyboardHeight > 0
                          ? -keyboardHeight * 1
                          : 0
                        : 0,
                    borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                    paddingHorizontal:
                      pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                  }}
                >
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Items Showed
                  </Text>
                  <View
                    style={{
                      width: Platform.OS === "ios" ? 65 : "13%", //65,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                      //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                      // paddingTop: '-60%',
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      marginRight: "1%",
                    }}
                  >
                    {/* <RNPickerSelect
                        useNativeAndroidPickerStyle={false}
                        style={{
                          inputIOS: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            textAlign: "center",
                          },
                          inputAndroid: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            justifyContent: "center",
                            textAlign: "center",
                            height: 40,
                            color: "black",
                          },
                          inputAndroidContainer: { width: "100%" },
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: "none",
                          },
                          chevronDown: {
                            display: "none",
                          },
                          chevronUp: {
                            display: "none",
                          },
                        }}
                        items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: "All",
                          value: !showDetails
                            ? allOutletsEmployeesAction.length
                            : allOutletsEmployeesDetails.length,
                        })}
                        value={perPage}
                        onValueChange={(value) => {
                          setPerPage(value);
                        }}
                      /> */}
                    <Picker
                      selectedValue={perPage}
                      style={{}}
                      onValueChange={(value, text) => {
                        setPerPage(value);
                      }}
                    >
                      {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: "All",
                        value: !showDetails
                          ? allOutletsEmployeesAction.length
                          : allOutletsEmployeesDetails.length,
                      }).map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker>
                  </View>

                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Page
                  </Text>
                  <View
                    style={{
                      width: switchMerchant ? 65 : 70,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    }}
                  >
                    {console.log("currentDetailsPage")}
                    {console.log(currentDetailsPage)}

                    <TextInput
                      onChangeText={(text) => {
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;
                        console.log("currentDetailsPage pending");
                        console.log(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp
                        );
                        setCurrentDetailsPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp
                        );
                      }}
                      placeholder={
                        pageCount !== 0 ? currentDetailsPage.toString() : "0"
                      }
                      placeholderTextColor={Platform.select({
                        ios: "#a9a9a9",
                      })}
                      style={{
                        color: "black",
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                        marginTop: Platform.OS === "ios" ? 0 : -15,
                        marginBottom: Platform.OS === "ios" ? 0 : -15,
                        textAlign: "center",
                        width: "100%",
                      }}
                      value={
                        pageCount !== 0 ? currentDetailsPage.toString() : "0"
                      }
                      defaultValue={
                        pageCount !== 0 ? currentDetailsPage.toString() : "0"
                      }
                      keyboardType={"numeric"}
                      onFocus={() => {
                        setPushPagingToTop(true);
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginLeft: "1%",
                      marginRight: "1%",
                    }}
                  >
                    of {pageCount}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      prevDetailsPage();
                    }}
                  >
                    <ArrowLeft color={Colors.whiteColor} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      nextDetailsPage();
                    }}
                  >
                    <ArrowRight color={Colors.whiteColor} />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    flexDirection: "row",
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    // padding: 20,
    paddingVertical: 20,
    backgroundColor: Colors.highlightColor,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  confirmBox: {
    // width: '30%',
    // height: '30%',
    // borderRadius: 30,
    // backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.3,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.2,
    width: Dimensions.get("screen").width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.02,
    top: Dimensions.get("screen").width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: Dimensions.get("screen").width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  modalSaveButton1: {
    width: Dimensions.get("screen").width * 0.1,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  confirmBox1: {
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.4,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  submitText: {
    height:
      Platform.OS == "ios"
        ? Dimensions.get("screen").height * 0.06
        : Dimensions.get("screen").height * 0.07,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
});
export default KCRMGMVScreenV2;
