export const RAZER_BANK_TYPE = {
    INDIVIDUAL: 'Individual',
    BUSINESS: 'Business',
};

export const RAZER_ID_TYPE = {
    NRIC: 'NRIC',
    OLDNRIC: 'OLDNRIC',
    PASSPORT: 'PASSPORT',
};

export const RAZER_CHANNEL_COMMISIONS_MYR_RATE_VENDOR = {
    credit: 1.3, // 2.4
    Credit: 1.3, // 2.4
    'CIL_Mastercard': 1.3,
    'CIL_Visa': 1.3,

    'TNG-EWALLET': 1.3, // 2024-04-22 - update to 1.5 // 2024-09-27 - 1.5 -> 1.3
    ShopeePay: 1.3, // 2024-09-27 - 1.4 -> 1.3
    GrabPay: 1.3, // 2024-09-27 - 1.4 -> 1.3
    BOOST: 1.3, // 2024-09-27 - 1.6 -> 1.3
    'MB2U_QRPay-Push': 1.2,

    'Cash-711': 2.4,

    FPX: 1.3,
    FPX_MB2U: 1.3,
    MB2u: 1.3,
    FPX_CIMBCLICKS: 1.3,
    'CIMB-Clicks': 1.3,
    FPX_RHB: 1.3,
    'RHB-ONL': 1.3,
    FPX_PBB: 1.3,
    'PBeBank': 1.3,
    FPX_HLB: 1.3,
    'HLB-ONL': 1.3,
    FPX_BIMB: 1.3,
    FPX_AMB: 1.3,
    'AMB-W2W': 1.3,
    FPX_ABMB: 1.3,
    'ALB-ONL': 1.34,
    FPX_ABB: 1.3,
    'Affin-EPG': 1.3,
    FPX_BMMB: 1.3,
    FPX_BKRM: 1.3,
    FPX_BSN: 1.3,
    FPX_OCBC: 1.3,
    FPX_UOB: 1.3,
    FPX_HSBC: 1.3,
    FPX_SCB: 1.3,
    FPX_KFH: 1.3,
    'FPX-TPA': 1.3,
    FPX_AGROBANK: 1.3,

    FPX_B2B: 0,
    FPX_B2B_ABBM: 0,
    FPX_B2B_ABMB: 0,
    FPX_B2B_AGROBANK: 0,
    FPX_B2B_AMB: 0,
    FPX_B2B_BIMB: 0,
    FPX_B2B_BKRM: 0,
    FPX_B2B_BMMB: 0,
    FPX_B2B_BNP: 0,
    FPX_B2B_CIMB: 0,
    FPX_B2B_CITIBANK: 0,
    FPX_B2B_DEUTSCHE: 0,
    FPX_B2B_HLB: 0,
    FPX_B2B_HSBC: 0,
    FPX_B2B_KFH: 0,
    FPX_B2B_OCBC: 0,
    FPX_B2B_PBB: 0,
    FPX_B2B_PBBE: 0,
    FPX_B2B_RHB: 0,
    FPX_B2B_SCB: 0,
    FPX_B2B_UOB: 0,
    FPX_B2B_UOBR: 0,
    FPX_M2E: 0,

    'Offline-Cash': 0,
    'Offline-Credit-Card': 0,
    'Offline-Bank-Transfer': 0,
    'Offline-Alipay': 0,
    'Offline-Boost': 0,
    'Offline-favePAY': 0,
    'Offline-TouchnGo-eWallet': 0,
    'Offline-WeChat-Pay': 0,
    'Offline-Credit-Amex': 0,
    'Offline-Bcard-Points': 0,
    'Offline-e-pay': 0,
    'Offline-Maybank-QRPAY': 0,
    'Offline-RAZER-CASH': 0,
    'Offline-RAZER-PAY': 0,
    'Offline-WEBCASH': 0,
    'Offline-Debit-Card': 0,
    'Offline-Credit-VISA': 0,
    'Offline-Credit-MasterCard': 0,
};

export const RAZER_CHANNEL_COMMISIONS_MYR_RATE = {
    credit: 2.4, // 2.4
    Credit: 2.4, // 2.4
    'CIL_Mastercard': 2.4,
    'CIL_Visa': 2.4,

    'TNG-EWALLET': 1.5, // 2024-04-22 - update to 1.5 // 2024-09-27 - 1.5 -> 1.3
    ShopeePay: 1.4, // 2024-09-27 - 1.4 -> 1.3
    GrabPay: 1.4, // 2024-09-27 - 1.4 -> 1.3
    BOOST: 1.6, // 2024-09-27 - 1.6 -> 1.3
    'MB2U_QRPay-Push': 1.2,

    'Cash-711': 2.4,

    FPX: 2.4,
    FPX_MB2U: 2.4,
    MB2u: 2.4,
    FPX_CIMBCLICKS: 2.4,
    'CIMB-Clicks': 2.4,
    FPX_RHB: 2.4,
    'RHB-ONL': 2.4,
    FPX_PBB: 2.4,
    'PBeBank': 2.4,
    FPX_HLB: 2.4,
    'HLB-ONL': 2.4,
    FPX_BIMB: 2.4,
    FPX_AMB: 2.4,
    'AMB-W2W': 2.4,
    FPX_ABMB: 2.4,
    'ALB-ONL': 2.4,
    FPX_ABB: 2.4,
    'Affin-EPG': 2.4,
    FPX_BMMB: 2.4,
    FPX_BKRM: 2.4,
    FPX_BSN: 2.4,
    FPX_OCBC: 2.4,
    FPX_UOB: 2.4,
    FPX_HSBC: 2.4,
    FPX_SCB: 2.4,
    FPX_KFH: 2.4,
    'FPX-TPA': 2.4,
    FPX_AGROBANK: 2.4,

    FPX_B2B: 0,
    FPX_B2B_ABBM: 0,
    FPX_B2B_ABMB: 0,
    FPX_B2B_AGROBANK: 0,
    FPX_B2B_AMB: 0,
    FPX_B2B_BIMB: 0,
    FPX_B2B_BKRM: 0,
    FPX_B2B_BMMB: 0,
    FPX_B2B_BNP: 0,
    FPX_B2B_CIMB: 0,
    FPX_B2B_CITIBANK: 0,
    FPX_B2B_DEUTSCHE: 0,
    FPX_B2B_HLB: 0,
    FPX_B2B_HSBC: 0,
    FPX_B2B_KFH: 0,
    FPX_B2B_OCBC: 0,
    FPX_B2B_PBB: 0,
    FPX_B2B_PBBE: 0,
    FPX_B2B_RHB: 0,
    FPX_B2B_SCB: 0,
    FPX_B2B_UOB: 0,
    FPX_B2B_UOBR: 0,
    FPX_M2E: 0,

    'Offline-Cash': 0,
    'Offline-Credit-Card': 0,
    'Offline-Bank-Transfer': 0,
    'Offline-Alipay': 0,
    'Offline-Boost': 0,
    'Offline-favePAY': 0,
    'Offline-TouchnGo-eWallet': 0,
    'Offline-WeChat-Pay': 0,
    'Offline-Credit-Amex': 0,
    'Offline-Bcard-Points': 0,
    'Offline-e-pay': 0,
    'Offline-Maybank-QRPAY': 0,
    'Offline-RAZER-CASH': 0,
    'Offline-RAZER-PAY': 0,
    'Offline-WEBCASH': 0,
    'Offline-Debit-Card': 0,
    'Offline-Credit-VISA': 0,
    'Offline-Credit-MasterCard': 0,
};

export const RAZER_CHANNEL_COMMISIONS_MYR_MIN = {
    credit: 0,
    Credit: 0,
    'CIL_Mastercard': 0,
    'CIL_Visa': 0,

    'TNG-EWALLET': 0,
    ShopeePay: 0,
    GrabPay: 0,
    BOOST: 0,
    'MB2U_QRPay-Push': 0,

    'Cash-711': 0.8,

    FPX: 0.6,
    FPX_MB2U: 0.6,
    MB2u: 0.6,
    FPX_CIMBCLICKS: 0.6,
    'CIMB-Clicks': 0.6,
    FPX_RHB: 0.6,
    'RHB-ONL': 0.6,
    FPX_PBB: 0.6,
    'PBeBank': 0.6,
    FPX_HLB: 0.6,
    'HLB-ONL': 0.6,
    FPX_BIMB: 0.6,
    FPX_AMB: 0.6,
    'AMB-W2W': 0.6,
    FPX_ABMB: 0.6,
    'ALB-ONL': 0.6,
    FPX_ABB: 0.6,
    'Affin-EPG': 0.6,
    FPX_BMMB: 0.6,
    FPX_BKRM: 0.6,
    FPX_BSN: 0.6,
    FPX_OCBC: 0.6,
    FPX_UOB: 0.6,
    FPX_HSBC: 0.6,
    FPX_SCB: 0.6,
    FPX_KFH: 0.6,
    'FPX-TPA': 0.6,
    FPX_AGROBANK: 0.6,

    FPX_B2B: 2.5,
    FPX_B2B_ABBM: 2.5,
    FPX_B2B_ABMB: 2.5,
    FPX_B2B_AGROBANK: 2.5,
    FPX_B2B_AMB: 2.5,
    FPX_B2B_BIMB: 2.5,
    FPX_B2B_BKRM: 2.5,
    FPX_B2B_BMMB: 2.5,
    FPX_B2B_BNP: 2.5,
    FPX_B2B_CIMB: 2.5,
    FPX_B2B_CITIBANK: 2.5,
    FPX_B2B_DEUTSCHE: 2.5,
    FPX_B2B_HLB: 2.5,
    FPX_B2B_HSBC: 2.5,
    FPX_B2B_KFH: 2.5,
    FPX_B2B_OCBC: 2.5,
    FPX_B2B_PBB: 2.5,
    FPX_B2B_PBBE: 2.5,
    FPX_B2B_RHB: 2.5,
    FPX_B2B_SCB: 2.5,
    FPX_B2B_UOB: 2.5,
    FPX_B2B_UOBR: 2.5,
    FPX_M2E: 2.5,

    'Offline-Cash': 0,
    'Offline-Credit-Card': 0,
    'Offline-Bank-Transfer': 0,
    'Offline-Alipay': 0,
    'Offline-Boost': 0,
    'Offline-favePAY': 0,
    'Offline-TouchnGo-eWallet': 0,
    'Offline-WeChat-Pay': 0,
    'Offline-Credit-Amex': 0,
    'Offline-Bcard-Points': 0,
    'Offline-e-pay': 0,
    'Offline-Maybank-QRPAY': 0,
    'Offline-RAZER-CASH': 0,
    'Offline-RAZER-PAY': 0,
    'Offline-WEBCASH': 0,
    'Offline-Debit-Card': 0,
    'Offline-Credit-VISA': 0,
    'Offline-Credit-MasterCard': 0,
};

export const RAZER_REFUND_MYR = 0.7;

export const RAZER_PAYOUT_MYR = 0.5;

export const RAZER_NOTIFICATION_CHANNEL_PARSED = {
    credit: 'Credit',
    BOOST: 'BOOST',
    WeChatPayMY: 'WeChatPayMY',
    GrabPay: 'GrabPay',
    'MB2U_QRPay-Push': 'MB2U_QRPay-Push',
    'TNG-EWALLET': 'TNG-EWALLET',
    'ShopeePay': 'ShopeePay',
    'RPP_DuitNowQR': 'RPP_DuitNowQR',

    fpx: 'FPX',
    maybank2u: 'FPX_MB2U',
    cimb: 'FPX_CIMBCLICKS',
    rhb: 'FPX_RHB',
    publicbank: 'FPX_PBB',
    hlb: 'FPX_HLB',
    bankislam: 'FPX_BIMB',
    amb: 'FPX_AMB',
    alliancebank: 'FPX_ABMB',
    abb: 'FPX_ABB',
    'affin-epg': 'FPX_ABB',
    muamalat: 'FPX_BMMB',
    bkrm: 'FPX_BKRM',
    bsn: 'FPX_BSN',
    ocbc: 'FPX_OCBC',
    uob: 'FPX_UOB',
    hsbc: 'FPX_HSBC',
    scb: 'FPX_SCB',
    'kuwait-finace': 'FPX_KFH',
};

export const RAZER_MASS_PAYOUT_STATUS = {
    'PAID': '00',
    'FAILURE': '11',
    'PENDING': '22',
    'PROCESSING': '33',
};

export const RAZER_MASS_PAYOUT_STATUS_PARSED = {
    '00': 'PAID',
    '11': 'FAILURE',
    '22': 'PENDING',
    '33': 'PROCESSING',
};

export const BANK_CODE = {
    AIBBMYKL: 'AIBBMYKL',
    ALSRMYK1: 'ALSRMYK1',
    ARBKMYKL: 'ARBKMYKL',
    BIMBMYKL: 'BIMBMYKL',
    BKRMMYK1: 'BKRMMYK1',
    BKRMMYKL: 'BKRMMYKL',
    BMMBMYKL: 'BMMBMYKL',
    BNMAMYKL: 'BNMAMYKL',
    BSNAMYK1: 'BSNAMYK1',
    CIBBMYKL: 'CIBBMYKL',
    CITIMYKL: 'CITIMYKL',
    CTBBMYKL: 'CTBBMYKL',
    HLBBMYKL: 'HLBBMYKL',
    HLIBMYKL: 'HLIBMYKL',
    MBBEMYKL: 'MBBEMYKL',
    MBISMYKL: 'MBISMYKL',
    MFBBMYKL: 'MFBBMYKL',
    OCBCMYKL: 'OCBCMYKL',
    PBBEMYKL: 'PBBEMYKL',
    PHBMMYKL: 'PHBMMYKL',
    PIBEMYK1: 'PIBEMYK1',
    RHBAMYKL: 'RHBAMYKL',
    RHBBMYKL: 'RHBBMYKL',
    SCBLMYKX: 'SCBLMYKX',
    UOVBMYKL: 'UOVBMYKL',
    AGOBMYKL: 'AGOBMYKL',
};

export const BANK_CODE_DROPDOWN_LIST = [
    {
        label: 'AFFIN BANK BHD',
        value: 'PHBMMYKL',
    },
    {
        label: 'AFFIN ISLAMIC BANK BHD',
        value: 'AIBBMYKL',
    },
    {
        label: 'ALLIANCE BANK MALAYSIA BHD',
        value: 'MFBBMYKL',
    },
    {
        label: 'ALLIANCE ISLAMIC BANK BHD',
        value: 'ALSRMYK1',
    },
    {
        label: 'AMBANK BHD',
        value: 'ARBKMYKL',
    },
    {
        label: 'BANK ISLAM BHD',
        value: 'BIMBMYKL',
    },
    {
        label: 'BANK KERJASAMA RAKYAT',
        value: 'BKRMMYK1',
    },
    {
        label: 'BANK KERJASAMA RAKYAT',
        value: 'BKRMMYKL',
    },
    {
        label: 'BANK MUAMALAT (M) BHD',
        value: 'BMMBMYKL',
    },
    {
        label: 'BANK NEGARA MALAYSIA',
        value: 'BNMAMYKL',
    },
    {
        label: 'BANK SIMPANAN NASIONAL',
        value: 'BSNAMYK1',
    },
    {
        label: 'CIMB BANK BHD',
        value: 'CIBBMYKL',
    },
    {
        label: 'CITIBANK BHD',
        value: 'CITIMYKL',
    },
    {
        label: 'CIMB ISLAMIC BANK BHD',
        value: 'CTBBMYKL',
    },
    {
        label: 'CITIBANK BHD',
        value: 'CITIMYKL',
    },
    {
        label: 'HONG LEONG BANK BHD',
        value: 'HLBBMYKL',
    },
    {
        label: 'HONG LEONG ISLAMIC BANK BHD',
        value: 'HLIBMYKL',
    },
    {
        label: 'MALAYAN BANKING BHD',
        value: 'MBBEMYKL',
    },
    {
        label: 'MAYBANK ISLAMIC BHD',
        value: 'MBISMYKL',
    },
    {
        label: 'ALLIANCE BANK MALAYSIA BHD',
        value: 'MFBBMYKL',
    },
    {
        label: 'OCBC BANK (M) BHD',
        value: 'OCBCMYKL',
    },
    {
        label: 'PUBLIC BANK BHD',
        value: 'PBBEMYKL',
    },
    {
        label: 'AFFIN BANK BHD',
        value: 'PHBMMYKL',
    },
    {
        label: 'PUBLIC ISLAMIC BANK BHD',
        value: 'PIBEMYK1',
    },
    {
        label: 'RHB ISLAMIC BANK BERHAD',
        value: 'RHBAMYKL',
    },
    {
        label: 'RHB BANK BHD',
        value: 'RHBBMYKL',
    },
    {
        label: 'RHB ISLAMIC BANK BERHAD',
        value: 'RHBAMYKL',
    },
    {
        label: 'STANDARD CHARTERED BANK BHD',
        value: 'SCBLMYKX',
    },
    {
        label: 'UNITED OVERSEAS BANK (M) BHD',
        value: 'UOVBMYKL',
    },
    {
        label: 'BANK PERTANIAN MALAYSIA BHD (AGROBANK)',
        value: 'AGOBMYKL',
    },
];