import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Platform,
  Switch,
  Modal,
  KeyboardAvoidingView,
  TextInput,
  ActivityIndicator,
  Picker,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from "react-native";
import Colors from "../constant/Colors";
// import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import Ionicons from 'react-native-vector-icons/Ionicons';
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
// import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { FlatList } from "react-native-gesture-handler";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import Styles from "../constant/Styles";
// import * as User from '../util/User';
import AsyncStorage from "@react-native-async-storage/async-storage";
// import CheckBox from 'react-native-check-box';
import moment from "moment";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
// import {isTablet} from 'react-native-device-detection';
import { CommonStore } from "../store/commonStore";
import { OutletStore } from "../store/outletStore";
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from "../store/userStore";
// import Upload from '../assets/svg/Upload';
// import Download from '../assets/svg/Download';
import {
  convertArrayToCSV,
  countWeekdayOccurrencesInMonth,
  generateEmailReport,
  sortReportDataList,
  isMobile,
  listenToPayoutTransactionsChangesMerchant,
  listenToRazerCrawledDataChangesMerchant,
  listenToQRTrackingChanges,
} from "../util/common";
import {
  EMAIL_REPORT_TYPE,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  ORDER_TYPE,
  MERCHANT_DATA_FILTER,
  APP_TYPE,
  ORDER_TYPE_DETAILS,
  OFFLINE_PAYMENT_METHOD_TYPE,
  PAYMENT_CHANNEL_NAME_PARSED,
  PAYMENT_TYPE_DROPDOWN_LIST,
  PAYMENT_TYPE,
  PAYMENT_CHANNEL_NAME,
} from "../constant/common";
// import RNFetchBlob from 'rn-fetch-blob';
// import {useKeyboard} from '../hooks';
// import XLSX from 'xlsx';
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
// import RNPickerSelect from 'react-native-picker-select';
// import AsyncImage from '../components/asyncImage';
// import Feather from 'react-native-vector-icons/Feather';
// import Tooltip from 'react-native-walkthrough-tooltip';

import firebase from "firebase/app";
// import firestore from '@react-native-firebase/firestore';
import { Collections } from "../constant/firebase";

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
import { useLinkTo } from "@react-navigation/native";

import BigNumber from "bignumber.js";

import FusionCharts from "react-fusioncharts";
import FC from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import {
  CHART_DATA,
  CHART_TYPE,
  //FS_LIBRARY_PATH,
  CHART_Y_AXIS_DROPDOWN_LIST,
  CHART_FIELD_COMPARE_DROPDOWN_LIST,
  CHART_FIELD_NAME_DROPDOWN_LIST,
  CHART_FIELD_TYPE,
  CHART_FIELD_COMPARE_DICT,
  CHART_PERIOD,
  CHART_X_AXIS_DROPDOWN_LIST,
  CHART_X_AXIS_TYPE,
} from "../constant/chart";
import {
  filterChartItems,
  getDataForChartDashboardTodaySales,
  getDataForChartReportOnlineQrSales,
  getDataForChartReportProductSales,
  getDataForSalesLineChart,
} from "../util/chart";
import XLSX from "xlsx";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "../constant/styles.css";

FusionCharts.fcRoot(FC, Column2D, FusionTheme);

const { nanoid } = require("nanoid");
// const RNFS = require('react-native-fs');

window.pOutletLastOrderDict = {};
window.pOutletFilterDict = {};

window.pOutletPaletteColorDict = {};

window.boldOutletQrSalesLineDict = {};

window.currToDateTime = Date.now();

const KooDooQRScreen = (props) => {
  const { navigation } = props;

  // const linkTo = useLinkTo();

  // const rootFocusRef = useRef(null);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const keyboardHeight = 0;
  const [visible, setVisible] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [pageReturn, setPageReturn] = useState(1);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);

  // const [qrStartDate, setRev_date] = useState(
  //   moment().subtract(6, "month").startOf("day")
  // );
  // const [qrEndDate, setRev_date1] = useState(
  //   moment().endOf(Date.now()).endOf("day")
  // );

  const userName = UserStore.useState((s) => s.name);

  const [exportEmail, setExportEmail] = useState("");

  const [showDetails, setShowDetails] = useState(false);

  const [exportModalVisibility, setExportModalVisibility] = useState(false);

  const merchantId = UserStore.useState((s) => s.merchantId);
  const isLoading = CommonStore.useState((s) => s.isLoading);
  const [isCsv, setIsCsv] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const allOutletsEmployees = OutletStore.useState(
    (s) => s.allOutletsEmployees
  );

  const [allOutletsEmployeesAction, setAllOutletsEmployeesAction] = useState(
    []
  );

  const [allOutletsEmployeesDetails, setAllOutletsEmployeesDetails] = useState(
    []
  );

  const [tableDataSummary, setTableDataSummary] = useState([]);
  const [tableDataDetails, setTableDataDetails] = useState([]);

  const [toggleCompare, setToggleCompare] = useState(true);

  // const [boldOutletQrSalesLineDict, setBoldOutletQrSalesLineDict] = useState({});
  const [changedBoldTimestamp, setChangedBoldTimestamp] = useState('');

  /////////////////////////////////////////////////////////////////

  // for another table - mass payout

  const [tableDataSummaryMP, setTableDataSummaryMP] = useState([]);

  const [toggleCompareMP, setToggleCompareMP] = useState(true);

  const [perPageMP, setPerPageMP] = useState(100);
  const [pageCountMP, setPageCountMP] = useState(0);
  const [currentPageMP, setCurrentPageMP] = useState(1);
  const [pageReturnMP, setPageReturnMP] = useState(1);

  const [isCalendarOpening, setIsCalendarOpening] = useState(false);

  /////////////////////////////////////////////////////////////////

  const [merchantDataFilterType, setMerchantDataFilterType] = useState(MERCHANT_DATA_FILTER.ACTIVE_24H);
  const [merchantDataAllLength, setMerchantDataAllLength] = useState(0);
  const [merchantData24HLength, setMerchantData24HLength] = useState(0);
  const [merchantData7DLength, setMerchantData7DLength] = useState(0);
  const [merchantData1MLength, setMerchantData1MLength] = useState(0);
  const [merchantData3MLength, setMerchantData3MLength] = useState(0);

  const [outletUserActionDict, setOutletUserActionDict] = useState({});

  /////////////////////////////////////////////////////////////////

  // 2022-10-24 - Add the support of changing date range also will update details list

  const [showDetailsOutletId, setShowDetailsOutletId] = useState('');

  /////////////////////////////////////////////////////////////////

  const allOutlets = MerchantStore.useState(s => s.allOutlets);

  const merchantsOnboarded = CommonStore.useState(s => s.merchantsOnboarded);
  const outletsOnboarded = CommonStore.useState(s => s.outletsOnboarded);
  const employeeClocks = CommonStore.useState(s => s.employeeClocks);
  const userActions = CommonStore.useState(s => s.userActions);

  const userEmail = UserStore.useState((s) => s.email);

  const qrStartDate = CommonStore.useState(s => s.qrStartDate);
  const qrEndDate = CommonStore.useState(s => s.qrEndDate);

  // const settlementTransactions = CommonStore.useState(s => s.settlementTransactions);
  // const massPayoutTransactions = CommonStore.useState(s => s.massPayoutTransactions);

  const accumulator = CommonStore.useState(s => s.accumulator);

  const trackingOutletTransactions = CommonStore.useState(s => s.trackingOutletTransactions);
  const trackingQRTransactions = CommonStore.useState(s => s.trackingQRTransactions);

  // const payoutTransactions = CommonStore.useState(s => s.payoutTransactions);
  // const gmvOrdersRecent = CommonStore.useState(s => s.gmvOrdersRecent); // for those orders that haven't included in razer payout transactions

  //////////////////////////////////////////////

  // 2022-12-10 - Chart related

  const [
    showDateTimePickerFilterLineChart,
    setShowDateTimePickerFilterLineChart,
  ] = useState(false);
  const [
    showDateTimePickerFilterBarChart,
    setShowDateTimePickerFilterBarChart,
  ] = useState(false);

  const [todaySalesChart, setTodaySalesChart] = useState({});

  // const [todaySalesChartPeriod, setTodaySalesChartPeriod] = useState(CHART_PERIOD.TODAY);

  const [salesLineChart, setSalesLineChart] = useState({});
  const [productSalesChart, setProductSalesChart] = useState({});

  const [onlineQrSalesChart, setOnlineQrSalesChart] = useState({});

  const [salesLineChartPeriod, setSalesLineChartPeriod] = useState(
    CHART_PERIOD.NONE
  );
  const [salesBarChartPeriod, setSalesBarChartPeriod] = useState(
    CHART_PERIOD.NONE
  );

  const [dailySalesDetailsList, setDailySalesDetailsList] = useState([]);

  const [selectedItemSummary, setSelectedItemSummary] = useState({});

  const [selectedChartDropdownValueX, setSelectedChartDropdownValueX] =
    useState(
      CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value
    );

  const [chartDropdownValueXList, setChartDropdownValueXList] = useState(
    CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES]
  );

  const [expandBarSelection, setExpandBarSelection] = useState(
    props.expandBarSelection === undefined ? false : props.expandBarSelection
  );
  // const [barFilterTapped, setBarFilterTapped] = useState(props.barFilterTapped === undefined ? 0 : props.barFilterTapped);
  const [expandLineSelection, setExpandLineSelection] = useState(false);
  // const [lineFilterTapped, setLineFilterTapped] = useState(props.lineFilterTapped === undefined ? 0 : props.lineFilterTapped);

  const [
    selectedChartDropdownValueLineChart,
    setSelectedChartDropdownValueLineChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value
  );
  const [
    selectedChartFilterQueriesLineChart,
    setSelectedChartFilterQueriesLineChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[
          CHART_TYPE.DASHBOARD_LINE_CHART_SALES
        ][0].value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesLineChart,
    setAppliedChartFilterQueriesLineChart,
  ] = useState([]);

  const [
    selectedChartDropdownValueBarChart,
    setSelectedChartDropdownValueBarChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value
  );
  const [
    selectedChartFilterQueriesBarChart,
    setSelectedChartFilterQueriesBarChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesBarChart,
    setAppliedChartFilterQueriesBarChart,
  ] = useState([]);

  const [currReportSummarySort, setCurrReportSummarySort] = useState("");
  const [currReportDetailsSort, setCurrReportDetailsSort] = useState("");

  ////////////////////////////////////////////////////////

  // 2022-12-11 - Click event

  const [clickedChartDate, setClickedChartDate] = useState(null);

  ////////////////////////////////////////////////////////

  // 2022-12-11 - Expand event

  const [expandedSummaryRow, setExpandedSummaryRow] = useState({});

  ////////////////////////////////////////////////////////

  // 2023-01-04 - For clicking stacked bar chart (pos/qr offline/qr online profit)

  const [clickedBarChartDate, setClickedBarChartDate] = useState(null);
  const [clickedBarChartDateUnit, setClickedBarChartDateUnit] = useState(null);

  ////////////////////////////////////////////////////////

  // 2023-03-17 - Payment type filter

  const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_TYPE.ALL);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    if (qrStartDate !== qrEndDate) {
      // temp fix for strange bugs for same date

      // listenToPayoutTransactionsChangesMerchant(qrStartDate, qrEndDate);

      typeof global.subscriberListenToQRTrackingChanges === 'function' && global.subscriberListenToQRTrackingChanges();
      global.subscriberListenToQRTrackingChanges = () => { };

      let subscriber = listenToQRTrackingChanges(qrStartDate, qrEndDate);

      global.subscriberListenToQRTrackingChanges = subscriber;

      return () => {
        typeof subscriber === 'function' && subscriber();
      };
    }
  }, [qrStartDate, qrEndDate]);

  ////////////////////////////////////////////////////////

  setInterval(() => {
    var elementList = document.querySelectorAll('[fill="#b1b2b7"]');

    for (var i = 0; i < elementList.length; i++) {
      elementList[i].style.display = 'none';
    }

    var elementLegendList = document.querySelectorAll('[opacity="0.7"]');

    for (var i = 0; i < elementLegendList.length; i++) {
      elementLegendList[i].style['stroke-width'] = '5';
    }
  }, 250);

  useEffect(() => {
    setChartDropdownValueXList(
      CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].filter(
        (xAxisType) => {
          if (salesLineChartPeriod === CHART_PERIOD.THIS_WEEK) {
            if (
              xAxisType.value === CHART_X_AXIS_TYPE.WEEK ||
              xAxisType.value === CHART_X_AXIS_TYPE.MONTH
            ) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.THIS_MONTH) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.MONTH) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.THREE_MONTHS) {
            return true;
          } else if (salesLineChartPeriod === CHART_PERIOD.SIX_MONTHS) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.DAY) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.THIS_YEAR) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.DAY) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.YTD) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.DAY) {
              return false;
            } else {
              return true;
            }
          }
        }
      )
    );
  }, [salesLineChartPeriod]);

  //////////////////////////////////////////////

  useEffect(() => {
    let tableDataSummaryDict = {};

    for (var outletIndex = 0; outletIndex < outletsOnboarded.length; outletIndex++) {
      if (tableDataSummaryDict[outletsOnboarded[outletIndex].uniqueId]) {
        // existed
      }
      else {
        tableDataSummaryDict[outletsOnboarded[outletIndex].uniqueId] = {
          outletName: outletsOnboarded[outletIndex].name,
          outletId: outletsOnboarded[outletIndex].uniqueId,

          qrG: 0,
          qrT: 0,
          qrU: 0,

          qrUScannedPic: 0,
          qrUScannedTotal: 0,

          qrUScannedOnce: 0,

          detailsList: [],

          dtLastPrinted: 'N/A',
          dtLastScanned: 'N/A',

          updatedAt: null,
        };
      }
    }

    for (var i = 0; i < trackingOutletTransactions.length; i++) {
      console.log()
      if (trackingOutletTransactions[i].counter !== undefined) {
        if (trackingOutletTransactions[i].outletId &&
          tableDataSummaryDict[trackingOutletTransactions[i].outletId]) {
          Object.entries(trackingOutletTransactions[i].counter).map(
            ([key, value]) => {
              const parsedDt = moment(key, 'YYYY-MM-DD').startOf('day');

              if (moment(qrStartDate).isSameOrBefore(parsedDt, 'day') &&
                moment(qrEndDate).isSameOrAfter(parsedDt, 'day')) {
                tableDataSummaryDict[trackingOutletTransactions[i].outletId].qrG += value.qrG;
                tableDataSummaryDict[trackingOutletTransactions[i].outletId].qrT += value.qrT;
                tableDataSummaryDict[trackingOutletTransactions[i].outletId].qrU += value.qrU;
                tableDataSummaryDict[trackingOutletTransactions[i].outletId].dtLastPrinted = trackingOutletTransactions[i].dtLastPrinted ? moment(trackingOutletTransactions[i].dtLastPrinted).format('YYYY-MM-DD HH:mm A') : 'N/A';
              }

              // return {
              //   dt: parsedDt,
              //   ...value,
              // };
            },
          );
        }
      }
    }    

    for (var i = 0; i < trackingQRTransactions.length; i++) {
      if (trackingQRTransactions[i].counter !== undefined) {
        if (trackingQRTransactions[i].outletId &&
          tableDataSummaryDict[trackingQRTransactions[i].outletId]) {
          tableDataSummaryDict[trackingQRTransactions[i].outletId].dtLastScanned = trackingQRTransactions[i].dtLastScanned ? moment(trackingQRTransactions[i].dtLastScanned).format('YYYY-MM-DD HH:mm A') : 'N/A';

          var qrUScannedOnce = 0;

          Object.entries(trackingQRTransactions[i].counter).map(
            ([key, value]) => {
              tableDataSummaryDict[trackingQRTransactions[i].outletId].qrUScannedPic += value.u;
              tableDataSummaryDict[trackingQRTransactions[i].outletId].qrUScannedTotal += value.ut;

              qrUScannedOnce++;
            },
          );

          tableDataSummaryDict[trackingQRTransactions[i].outletId].qrUScannedOnce += qrUScannedOnce;
        }
      }
    }    

    const tableDataSummaryTemp = Object.entries(tableDataSummaryDict).map(
      ([key, value]) => ({ ...value }),
    );

    setTableDataSummary(tableDataSummaryTemp);

    setPageCount(Math.ceil(tableDataSummaryTemp.length / perPage));
  }, [
    trackingOutletTransactions,

    trackingQRTransactions,

    outletsOnboarded,

    qrStartDate,
    qrEndDate,
  ]);

  //////////////////////////////////////////////

  // useEffect(() => {
  //   setTableDataSummary(settlementTransactions);

  //   setPageCount(Math.ceil(settlementTransactions.length / perPage));
  // }, [
  //   settlementTransactions,
  // ]);

  // useEffect(() => {
  //   setTableDataSummaryMP(massPayoutTransactions);

  //   setPageCountMP(Math.ceil(massPayoutTransactions.length / perPageMP));
  // }, [
  //   massPayoutTransactions,
  // ]);

  //////////////////////////////////////////////

  useEffect(async () => {
    // if (userEmail === '') {
    //   // linkTo('/login');

    //   window.location.href = '/statistics/login';
    // }

    const emailParsed = await readFromStorage();

    if (emailParsed === '') {
      // linkTo('/login');

      window.location.href = '/statistics/login'; // herks test
    }
  }, [userEmail]);

  const readFromStorage = async () => {
    console.log('\n Reading from asyncStorage \n')

    var emailParsed = '';

    const userStoreDataRaw = await AsyncStorage.getItem('@userStoreV2');
    if (userStoreDataRaw !== null) {
      const userStoreData = JSON.parse(userStoreDataRaw);
      // UserStore.replace(userStoreData);

      if (userStoreData && userStoreData.email) {
        emailParsed = userStoreData.email;
      }
    }

    return emailParsed;
  }

  // no need this
  // useEffect(async () => {
  //   var outletUserActionDictTemp = {};

  //   for (var i = 0; i < outletsOnboarded.length; i++) {
  //     var outletId = outletsOnboarded[i].uniqueId;

  //     var userAction = await retrieveLatestUserAction(outletId);

  //     outletUserActionDictTemp[outletId] = userAction;
  //   }

  //   setOutletUserActionDict(outletUserActionDictTemp);
  // }, [outletsOnboarded]);

  const retrieveLatestUserAction = async (outletId) => {
    const userActionSnapshot = await firebase.firestore()
      .collection(Collections.UserAction)
      .where('outletId', '==', outletId)
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .get();

    var userAction = null;
    if (userActionSnapshot && !userActionSnapshot.empty) {
      userAction = userActionSnapshot.docs[0].data();
    }

    return userAction;
  };

  useEffect(() => {
    if (showDetails && tableDataDetails) {
      setPageReturn(currentPage);
      console.log("currentPage value is");
      console.log(currentPage);
      setCurrentDetailsPage(1);
      setPageCount(Math.ceil(tableDataDetails.length / perPage));
    }
  }, [showDetails, tableDataDetails, perPage]);

  ///////////////////////////////////////////////////////////////////////////////////

  const setState = () => { };

  //   navigation.setOptions({
  //     headerLeft: () => (
  //       <View
  //         style={{
  //           width: Dimensions.get("screen").width * 0.17,
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Image
  //           style={{
  //             width: 124,
  //             height: 26,
  //           }}
  //           resizeMode="contain"
  //           source={require("../assets/image/logo.png")}
  //         />
  //       </View>
  //     ),
  //     headerTitle: () => (
  //       <View
  //         style={[
  //           {
  //             justifyContent: "center",
  //             alignItems: "center",
  //             // bottom: -2,
  //             bottom: switchMerchant ? "2%" : 0,
  //           },
  //           Dimensions.get("screen").width >= 768 && switchMerchant
  //             ? { right: Dimensions.get("screen").width * 0.1 }
  //             : {},
  //           Dimensions.get("screen").width <= 768
  //             ? { right: Dimensions.get("screen").width * 0.12 }
  //             : {},
  //         ]}
  //       >
  //         <Text
  //           style={{
  //             fontSize: switchMerchant ? 20 : 24,
  //             // lineHeight: 25,
  //             textAlign: "center",
  //             fontFamily: "NunitoSans-Bold",
  //             color: Colors.whiteColor,
  //             opacity: 1,
  //           }}
  //         >
  //           KCRM Agreement Report
  //         </Text>
  //       </View>
  //     ),
  //     headerRight: () => (
  //       <View
  //         style={{
  //           flexDirection: "row",
  //           alignItems: "center",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         {outletSelectDropdownView()}
  //         <View
  //           style={{
  //             backgroundColor: "white",
  //             width: 0.5,
  //             height: Dimensions.get("screen").height * 0.025,
  //             opacity: 0.8,
  //             marginHorizontal: 15,
  //             bottom: -1,
  //           }}
  //         ></View>
  //         <TouchableOpacity
  //           onPress={() => navigation.navigate("Setting")}
  //           style={{ flexDirection: "row", alignItems: "center" }}
  //         >
  //           <Text
  //             style={{
  //               fontFamily: "NunitoSans-SemiBold",
  //               fontSize: 16,
  //               color: Colors.secondaryColor,
  //               marginRight: 15,
  //             }}
  //           >
  //             {userName}
  //           </Text>
  //           <View
  //             style={{
  //               marginRight: 30,
  //               width: Dimensions.get("screen").height * 0.05,
  //               height: Dimensions.get("screen").height * 0.05,
  //               borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
  //               alignItems: "center",
  //               justifyContent: "center",
  //               backgroundColor: "white",
  //             }}
  //           >
  //             <Image
  //               style={{
  //                 width: Dimensions.get("screen").height * 0.035,
  //                 height: Dimensions.get("screen").height * 0.035,
  //                 alignSelf: "center",
  //               }}
  //               source={require("../assets/image/profile-pic.jpg")}
  //             />
  //           </View>
  //         </TouchableOpacity>
  //       </View>
  //     ),
  //   });

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };

  const nextDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage + 1 > pageCount
        ? currentDetailsPage
        : currentDetailsPage + 1
    );
  };

  const prevDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1
    );
  };

  const renderItem = ({ item, index }) => {
    return (
      <View
        // onPress={() => {
        //   if (item.massPayoutList && item.massPayoutList.length > 0) {
        //     setShowDetails(true);
        //     setTableDataDetails(item.massPayoutList);

        //     // setShowDetailsOutletId(item.outletId);
        //   }
        //   else {
        //   }
        // }}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          paddingHorizontal: 3,
          paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "2%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "14%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.outletName ? item.outletName : 'N/A'}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.qrU.toFixed(0)}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.qrT.toFixed(0)}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.qrUScannedOnce.toFixed(0)}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.qrUScannedPic.toFixed(0)}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.qrUScannedTotal.toFixed(0)}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.dtLastPrinted}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.dtLastScanned}
          </Text>
        </View>
      </View>
    );
  };

  const renderItemDetails = ({ item, index }) => {
    return (
      <View
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "2%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "14%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.stringDateTime}
          </Text>
          <Text
            style={{
              width: "15%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.bankInfo}
            {/* {item.uniqueId} */}
            {/* {`${item.orderId} ${item.uniqueId}`} */}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.merchantRefId}
          </Text>
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.netAmount.toFixed(2)}`}
          </Text> */}
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.tax.toFixed(2)}`}
          </Text> */}
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.sc.toFixed(2)}`}
          </Text> */}
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.currency}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.amount.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.cost.toFixed(2)}`}
          </Text>
        </View>
      </View>
    );
  };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    // if (!showDetails) {
    //   for (var i = 0; i < tableDataSummary.length; i++) {
    //     var excelRow = {
    //       "Merchant Name": tableDataSummary[i].merchantName,
    //       "QR Orders Online": `RM ${tableDataSummary[i].userAppAndWebDineInOnlineOrdersAmount.toFixed(2)} (${tableDataSummary[i].userAppAndWebDineInOnlineOrdersQty.toFixed(0)})`,
    //       "QR Orders Offline": `RM ${tableDataSummary[i].userAppAndWebDineInOrdersAmount.toFixed(2)} (${tableDataSummary[i].userAppAndWebDineInOrdersQty.toFixed(0)})`,
    //       "POS Orders": `RM ${tableDataSummary[i].merchantAppOrdersAmount.toFixed(2)} (${tableDataSummary[i].merchantAppOrdersQty.toFixed(0)})`,
    //       "Waiter Orders": `RM ${tableDataSummary[i].waiterAppOrdersAmount.toFixed(2)} (${tableDataSummary[i].waiterAppOrdersQty.toFixed(0)})`,
    //       "Takeaway Orders": `RM ${tableDataSummary[i].userAppAndWebTakeawayOrdersAmount.toFixed(2)} (${tableDataSummary[i].userAppAndWebTakeawayOrdersQty.toFixed(0)})`,
    //       "Total Orders": `RM ${tableDataSummary[i].totalOrdersAmount.toFixed(2)} (${tableDataSummary[i].totalOrdersQty.toFixed(0)})`,
    //       "GMV Commission(Payable)": `RM ${tableDataSummary[i].gmvCommissionActual.toFixed(2)}`,
    //       "O. Sales Payout(Actual)": `RM ${tableDataSummary[i].gmvPayoutActual.toFixed(2)}`,
    //       "GMV Commission (Total)": `RM ${tableDataSummary[i].gmvCommission.toFixed(2)}`,
    //       "O. Sales Payout(Expected)": `RM ${tableDataSummary[i].gmvPayout.toFixed(2)}`,
    //       "GMV Fees": `RM ${tableDataSummary[i].gmvFees.toFixed(2)}`,
    //       "Last Order": tableDataSummary[i].lastOrderPlacedDateTime ? moment(tableDataSummary[i].lastOrderPlacedDateTime).format('YYYY-MM-DD hh:mm A') : 'N/A',
    //     };

    //     excelData.push(excelRow);
    //   }
    // } else {
    //   for (var i = 0; i < tableDataDetails.length; i++) {
    //     var excelRow = {
    //       "Date Time": moment(tableDataDetails[i].dateTime).format("DD MMM hh:mm A"),
    //       "Order Id(Joined)": tableDataDetails[i].orderId,
    //       "QR Order": tableDataDetails[i].isQrOrder,
    //       "Total Amount": `RM ${tableDataDetails[i].totalAmount.toFixed(2)}`,
    //       "Razer Charges": `RM ${tableDataDetails[i].razerCharges.toFixed(2)}`,
    //       "KooDoo Charges(Expected)": `RM ${tableDataDetails[i].koodooCharges.toFixed(2)}`,
    //       "Outlet Payout(Expected)": `RM ${tableDataDetails[i].outletPayout.toFixed(2)}`,
    //       "Settlement Date": tableDataDetails[i].settlementDate,
    //     };

    //     excelData.push(excelRow);
    //   }
    // }

    const tableDataSummaryFiltered = tableDataSummary.filter(item => filterItem(item));

    for (var i = 0; i < tableDataSummaryFiltered.length; i++) {
      var excelRow = {
        "Merchant Name": tableDataSummaryFiltered[i].outletName ? tableDataSummaryFiltered[i].outletName : 'N/A',
        "Unique QR Printed Times": tableDataSummaryFiltered[i].qrU.toFixed(0),
        "Takeaway QR Printed Times": tableDataSummaryFiltered[i].qrT.toFixed(0),
        "Unique QR Printed Times (Once)": tableDataSummaryFiltered[i].qrUScannedOnce.toFixed(0),
        "Unique QR Printed Times (PIC)": tableDataSummaryFiltered[i].qrUScannedPic.toFixed(0),
        "Unique QR Printed Times (Total)": tableDataSummaryFiltered[i].qrUScannedTotal.toFixed(0),
        "Last Printed Date/Time": `${tableDataSummaryFiltered[i].dtLastPrinted}`,
        "Last Scanned Date/Time": `${tableDataSummaryFiltered[i].dtLastScanned}`,
      };

      excelData.push(excelRow);
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  const handleExportExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(convertDataToExcelFormat());

    XLSX.utils.book_append_sheet(wb, ws, "koodoo-qr");
    XLSX.writeFile(wb, `koodoo-qr-${moment(qrStartDate).format("YYYY-MM-DD")}-${moment(qrEndDate).format("YYYY-MM-DD")}.xlsx`);
  };

  const nextPageMP = () => {
    setCurrentPageMP(currentPageMP + 1 > pageCountMP ? currentPageMP : currentPageMP + 1);
  };

  const prevPageMP = () => {
    setCurrentPageMP(currentPageMP - 1 < 1 ? currentPageMP : currentPageMP - 1);
  };

  const renderItemMP = ({ item, index }) => {
    return (
      <View
        // onPress={() => {
        //   if (item.massPayoutList && item.massPayoutList.length > 0) {
        //     setShowDetails(true);
        //     setTableDataDetails(item.massPayoutList);

        //     // setShowDetailsOutletId(item.outletId);
        //   }
        //   else {
        //   }
        // }}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          paddingHorizontal: 3,
          paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "2%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "14%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.stringDateTime}
          </Text>

          <Text
            style={{
              width: "15%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.bankInfo}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.massId}
          </Text>

          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.merchantRefId}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.amount.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.cost.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "20%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.action}
          </Text>
        </View>
      </View>
    );
  };

  const emailVariant = () => {
    const excelData = convertDataToExcelFormat();

    var body = {
      // data: CsvData,
      //data: convertArrayToCSV(todaySalesChart.dataSource.data),
      data: JSON.stringify(excelData),
      //data: convertDataToExcelFormat(),
      email: exportEmail,
    };

    ApiClient.POST(API.emailDashboard, body, false).then((result) => {
      if (result !== null) {
        Alert.alert(
          "Success",
          "Email has been sent",
          [{ text: "OK", onPress: () => { } }],
          { cancelable: false }
        );
      }
    });

    setVisible(false);
  };

  const flatListRef = useRef();

  const filterItem = (item) => {
    if (search !== "") {
      if ((item.outletName || '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else if ((item.qrU.toFixed(0) || '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else if ((item.qrT.toFixed(0) || '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else if ((item.dtLastPrinted).toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const filterItemDetails = (item) => {
    if (search !== "") {
      if ((item.bankInfo || '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else if ((item.action || '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else if ((item.merchantRefId || '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    }
    // else {
    //   // check if there is data between the dates
    //   return moment(item.actionDate, "x").isBetween(qrStartDate, qrEndDate);
    // }
    return true;
  };

  // sort func
  const sortingAlphabetically = (a, b, compareName) => {
    if (a[compareName] < b[compareName]) {
      return -1;
    }
    if (a[compareName] > b[compareName]) {
      return 1;
    }
    return 0;
  };
  const sortingAlphabeticallyDesc = (a, b, compareName) => {
    if (a[compareName] < b[compareName]) {
      return 1;
    }
    if (a[compareName] > b[compareName]) {
      return -1;
    }
    return 0;
  };

  // sort by date
  const sortByCreatedDate = (a, b) =>
    moment(a["createdAt"]).valueOf() - moment(b["createdAt"]).valueOf();
  const sortByCreatedDateDesc = (a, b) =>
    moment(b["createdAt"]).valueOf() - moment(a["createdAt"]).valueOf();

  const sortByStartDate = (a, b) =>
    moment(a["createdAt"]).valueOf() - moment(b["createdAt"]).valueOf();
  const sortByStartDateDesc = (a, b) =>
    moment(b["createdAt"]).valueOf() - moment(a["createdAt"]).valueOf();

  const sortByUpdatedAt = (a, b) =>
    moment(a["updatedAt"]).valueOf() - moment(b["updatedAt"]).valueOf();
  const sortByUpdatedAtDesc = (a, b) =>
    moment(b["updatedAt"]).valueOf() - moment(a["updatedAt"]).valueOf();

  const sortByCompName = (a, b) => {
    let compareName = "companyName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompNameDesc = (a, b) => {
    let compareName = "companyName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByCompRegistration = (a, b) => {
    let compareName = "companyRegistrationNo";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompRegistrationDesc = (a, b) => {
    let compareName = "companyRegistrationNo";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByCompAddr = (a, b) => {
    let compareName = "companyAddress";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompAddrDesc = (a, b) => {
    let compareName = "companyAddress";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByDirectorName = (a, b) => {
    let compareName = "directorName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByDirectorNameDesc = (a, b) => {
    let compareName = "directorName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByMerchantEmail = (a, b) => {
    let compareName = "merchantEmail";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantEmailDesc = (a, b) => {
    let compareName = "merchantEmail";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByMerchantName = (a, b) => {
    let compareName = "merchantName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantNameDesc = (a, b) => {
    let compareName = "merchantName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOutletName = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOutletNameDesc = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByOnboarderEmail = (a, b) => {
    let compareName = "onboarderEmail";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOnboarderEmailDesc = (a, b) => {
    let compareName = "onboarderEmail";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByLastActivityDate = (a, b) =>
    moment(a["lastActivity"]).valueOf() - moment(b["lastActivity"]).valueOf();
  const sortByLastActivityDateDesc = (a, b) =>
    moment(b["lastActivity"]).valueOf() - moment(a["lastActivity"]).valueOf();

  //////////////////////////////////////////////////////////////

  const sortByUserAppAndWebDineInOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDineInOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDineInOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDineInOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebDineInOnlineOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDineInOnlineOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDineInOnlineOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDineInOnlineOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByMerchantAppOrdersAmount = (a, b) => {
    let compareName = "merchantAppOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantAppOrdersAmountDesc = (a, b) => {
    let compareName = "merchantAppOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByWaiterAppOrdersAmount = (a, b) => {
    let compareName = "waiterAppOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByWaiterAppOrdersAmountDesc = (a, b) => {
    let compareName = "waiterAppOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebTakeawayOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebTakeawayOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebTakeawayOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebTakeawayOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebDeliveryOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDeliveryOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDeliveryOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDeliveryOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTotalOrdersAmount = (a, b) => {
    let compareName = "totalOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTotalOrdersAmountDesc = (a, b) => {
    let compareName = "totalOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvCommission = (a, b) => {
    let compareName = "gmvCommission";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvCommissionDesc = (a, b) => {
    let compareName = "gmvCommission";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvFees = (a, b) => {
    let compareName = "gmvFees";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvFeesDesc = (a, b) => {
    let compareName = "gmvFees";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvPayout = (a, b) => {
    let compareName = "gmvPayout";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvPayoutDesc = (a, b) => {
    let compareName = "gmvPayout";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvCommissionActual = (a, b) => {
    let compareName = "gmvCommissionActual";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvCommissionActualDesc = (a, b) => {
    let compareName = "gmvCommissionActual";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvPayoutActual = (a, b) => {
    let compareName = "gmvPayoutActual";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvPayoutActualDesc = (a, b) => {
    let compareName = "gmvPayoutActual";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByLastOrderPlacedDateTime = (a, b) => {
    let compareName = "lastOrderPlacedDateTime";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByLastOrderPlacedDateTimeDesc = (a, b) => {
    let compareName = "lastOrderPlacedDateTime";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByDateTime = (a, b) =>
    moment(a["dateTime"]).valueOf() - moment(b["dateTime"]).valueOf();
  const sortByDateTimeDesc = (a, b) =>
    moment(b["dateTime"]).valueOf() - moment(a["dateTime"]).valueOf();

  const sortByMerchantRefId = (a, b) => {
    let compareName = "merchantRefId";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantRefIdDesc = (a, b) => {
    let compareName = "merchantRefId";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByBankInfo = (a, b) => {
    let compareName = "bankInfo";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByBankInfoDesc = (a, b) => {
    let compareName = "bankInfo";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmtTransactionAmount = (a, b) => {
    let compareName = "amtTransactionAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmtTransactionAmountDesc = (a, b) => {
    let compareName = "amtTransactionAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmtTransactionGST = (a, b) => {
    let compareName = "amtTransactionGST";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmtTransactionGSTDesc = (a, b) => {
    let compareName = "amtTransactionGST";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmtDeduct = (a, b) => {
    let compareName = "amtDeduct";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmtDeductDesc = (a, b) => {
    let compareName = "amtDeduct";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmtTokenAmount = (a, b) => {
    let compareName = "amtTokenAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmtTokenAmountDesc = (a, b) => {
    let compareName = "amtTokenAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmtTokenGST = (a, b) => {
    let compareName = "amtTokenGST";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmtTokenGSTDesc = (a, b) => {
    let compareName = "amtTokenGST";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmtMassPayment = (a, b) => {
    let compareName = "amtMassPayment";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmtMassPaymentDesc = (a, b) => {
    let compareName = "amtMassPayment";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmtSettle = (a, b) => {
    let compareName = "amtSettle";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmtSettleDesc = (a, b) => {
    let compareName = "amtSettle";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  // for details

  // const sortByDateTime = (a, b) => {
  //   let compareName = "dateTime";
  //   return sortingAlphabetically(a, b, compareName);
  // };
  // const sortByDateTimeDesc = (a, b) => {
  //   let compareName = "dateTime";
  //   return sortingAlphabeticallyDesc(a, b, compareName);
  // };

  const sortByOrderId = (a, b) => {
    let compareName = "orderId";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOrderIdDesc = (a, b) => {
    let compareName = "orderId";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  // const sortByOrderId = (a, b) => {
  //   let compareName = "orderId";
  //   return sortingAlphabetically(a, b, compareName);
  // };
  // const sortByOrderIdDesc = (a, b) => {
  //   let compareName = "orderId";
  //   return sortingAlphabeticallyDesc(a, b, compareName);
  // };

  const sortByIsQrOrder = (a, b) => {
    let compareName = "isQrOrder";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByIsQrOrderDesc = (a, b) => {
    let compareName = "isQrOrder";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByNetAmount = (a, b) => {
    let compareName = "netAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByNetAmountDesc = (a, b) => {
    let compareName = "netAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTax = (a, b) => {
    let compareName = "tax";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTaxDesc = (a, b) => {
    let compareName = "tax";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortBySc = (a, b) => {
    let compareName = "sc";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByScDesc = (a, b) => {
    let compareName = "sc";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTotalAmount = (a, b) => {
    let compareName = "totalAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTotalAmountDesc = (a, b) => {
    let compareName = "totalAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByRazerCharges = (a, b) => {
    let compareName = "razerCharges";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByRazerChargesDesc = (a, b) => {
    let compareName = "razerCharges";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByKoodooCharges = (a, b) => {
    let compareName = "koodooCharges";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByKoodooChargesDesc = (a, b) => {
    let compareName = "koodooCharges";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOutletPayout = (a, b) => {
    let compareName = "outletPayout";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOutletPayoutDesc = (a, b) => {
    let compareName = "outletPayout";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortBySettlementDate = (a, b) => {
    let compareName = "settlementDate";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortBySettlementDateDesc = (a, b) => {
    let compareName = "settlementDate";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByPaymentMethod = (a, b) => {
    let compareName = "pdChannel";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByPaymentMethodDesc = (a, b) => {
    let compareName = "pdChannel";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByCurrency = (a, b) => {
    let compareName = "currency";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCurrencyDesc = (a, b) => {
    let compareName = "currency";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmount = (a, b) => {
    let compareName = "amount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByAmountDesc = (a, b) => {
    let compareName = "amount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByCost = (a, b) => {
    let compareName = "cost";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCostDesc = (a, b) => {
    let compareName = "cost";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  // mass payout sort

  const sortByMassID = (a, b) => {
    let compareName = "massId";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMassIDDesc = (a, b) => {
    let compareName = "massId";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAction = (a, b) => {
    let compareName = "action";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByActionDesc = (a, b) => {
    let compareName = "action";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByQrU = (a, b) => {
    let compareName = "qrU";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByQrUDesc = (a, b) => {
    let compareName = "qrU";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByQrG = (a, b) => {
    let compareName = "qrG";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByQrGDesc = (a, b) => {
    let compareName = "qrG";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByQrT = (a, b) => {
    let compareName = "qrT";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByQrTDesc = (a, b) => {
    let compareName = "qrT";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByDtLastPrinted = (a, b) => {
    let compareName = "dtLastPrinted";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByDtLastPrintedDesc = (a, b) => {
    let compareName = "dtLastPrinted";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByDtLastScanned = (a, b) => {
    let compareName = "dtLastScanned";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByDtLastScannedDesc = (a, b) => {
    let compareName = "dtLastScanned";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByQrUScannedOnce = (a, b) => {
    let compareName = "qrUScannedOnce";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByQrUScannedOnceDesc = (a, b) => {
    let compareName = "qrUScannedOnce";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByQrUScannedPic = (a, b) => {
    let compareName = "qrUScannedPic";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByQrUScannedPicDesc = (a, b) => {
    let compareName = "qrUScannedPic";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByQrUScannedTotal = (a, b) => {
    let compareName = "qrUScannedTotal";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByQrUScannedTotalDesc = (a, b) => {
    let compareName = "qrUScannedTotal";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  // const sortByDtLastPrinted = (a, b) =>
  //   moment(a["dtLastPrinted"]).valueOf() - moment(b["dtLastPrinted"]).valueOf();
  // const sortByDtLastPrintedDesc = (a, b) =>
  //   moment(b["dtLastPrinted"]).valueOf() - moment(a["dtLastPrinted"]).valueOf();

  //////////////////////////////////////////////////////////////

  return (
    <View
      style={[styles.container, { height: windowHeight, width: windowWidth }]}
    >
      {/* <View
        style={[
          styles.sidebar,
          switchMerchant
            ? {
                // width: '10%'
              }
            : {},
        ]}
      >
        <SideBar
          navigation={props.navigation}
          selectedTab={8}
          expandReport={true}
        />
      </View> */}

      {/* <ScrollView horizontal={false}>
        
      </ScrollView> */}

      <TouchableWithoutFeedback onPress={() => {
        console.log('focus');

        // rootFocusRef && rootFocusRef.current && rootFocusRef.current.focus();

        var rootFocusRef = document.getElementById('rootFocusRef');

        if (rootFocusRef) {
          rootFocusRef.focus();
        }
      }} style={{
        width: '100%',
        height: '100%',
      }}>
        <ScrollView
          id={'rootFocusRef'}
          contentContainerStyle={{
            // flex: 1,

            backgroundColor: Colors.highlightColor,
            paddingVertical: 20,
            paddingBottom: windowHeight * 0.05,
          }}
          horizontal={false}

          showsVerticalScrollIndicator={false}
        >
          {/* <TextInput style={{
            opacity: 0,
            width: 0,
            height: 0,
          }} ref={rootFocusRef}>

          </TextInput> */}

          {/* <ScrollView horizontal={true} nestedScrollEnabled={true}>
          
        </ScrollView> */}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "space-between",
              paddingHorizontal: 18,
              marginTop: 5,
              width: "100%",
            }}
          >
            <Text
              style={{
                fontSize: switchMerchant ? 20 : 26,
                fontFamily: "NunitoSans-Bold",

                ...isMobile() && {
                  fontSize: 16,
                },
              }}
            >
              {`KooDoo QR Tracking`}
            </Text>

            <View
              style={{
                flexDirection: "row",
              }}
            >
              {/* <TouchableOpacity
                style={{
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  //width: 140,
                  paddingHorizontal: 10,
                  height: switchMerchant ? 35 : 40,
                  alignItems: "center",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,
                  marginRight: 10,
                }}
                onPress={() => {
                  setExportModalVisibility(true);
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Icon
                    name="download"
                    size={switchMerchant ? 10 : 20}
                    color={Colors.whiteColor}
                  />
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                    }}
                  >
                    DOWNLOAD
                  </Text>
                </View>
              </TouchableOpacity> */}

              {/* <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 5,
                    paddingHorizontal: 15,
                    height: switchMerchant ? 35 : 39,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    marginTop: 1,
                    paddingTop: 1,

                    marginRight: 15,
                  }}
                  onPress={() => {
                    navigation.navigate('KooDoo Statistics');
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {`Agreements`}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 5,
                    paddingHorizontal: 15,
                    height: switchMerchant ? 35 : 39,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    marginTop: 1,
                    paddingTop: 1,

                    marginRight: 15,
                  }}
                  onPress={() => {
                    navigation.navigate('Merchants - KooDoo Statistics');
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {`Merchants`}
                  </Text>
                </TouchableOpacity> */}

              <View
                style={[
                  {
                    height: switchMerchant ? 35 : 40,
                  },
                ]}
              >

                <View
                  style={{
                    width: switchMerchant ? 200 : 250,
                    height: switchMerchant ? 35 : 40,
                    backgroundColor: "white",
                    borderRadius: 5,
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",

                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                    borderWidth: 1,
                    borderColor: "#E5E5E5",

                    ...isMobile() && {
                      width: 150,

                      marginRight: 5,
                    },
                  }}
                >
                  <Icon
                    name="search"
                    size={switchMerchant ? 13 : 18}
                    color={Colors.primaryColor}
                    style={{ marginLeft: 15 }}
                  />
                  <TextInput
                    editable={!loading}
                    underlineColorAndroid={Colors.whiteColor}
                    style={{
                      width: switchMerchant ? 180 : 220,
                      fontSize: switchMerchant ? 10 : 15,
                      fontFamily: "NunitoSans-Regular",
                      paddingLeft: 5,
                      height: 45,
                    }}
                    placeholderTextColor={Platform.select({
                      ios: "#a9a9a9",
                    })}
                    clearButtonMode="while-editing"
                    placeholder=" Search"
                    onChangeText={(text) => {
                      setSearch(text);
                    }}
                    value={search}
                  />
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "space-between",
              paddingHorizontal: 18,
              marginTop: 5,
              width: "100%",

              marginTop: 15,
            }}
          >
            <View
              style={{
                flexDirection: "column",
              }}
            >
              {/* <Text
                style={{
                  fontSize: switchMerchant ? 20 : 20,
                  fontFamily: "NunitoSans-SemiBold",

                  ...isMobile() && {
                    fontSize: 12,
                  },

                  marginBottom: 5,
                }}
              >
                {`Razer Account Balance: RM ${accumulator.razerMassPayoutBalance !== undefined ? accumulator.razerMassPayoutBalance.toFixed(2) : '-'} (KooDoo Profit + Merchant Funds + Merchant Pending Payouts)`}
              </Text> */}

              <Text
                style={{
                  fontSize: switchMerchant ? 20 : 20,
                  fontFamily: "NunitoSans-SemiBold",

                  ...isMobile() && {
                    fontSize: 12,
                  },

                  marginBottom: 5,

                  color: Colors.primaryColor,

                  display: 'none',
                }}
              >
                {`KooDoo Profit (Pending Withdrawal): RM ${accumulator.koodooPayout !== undefined ? accumulator.koodooPayout.toFixed(2) : '-'}`}
              </Text>

              {/* <Text
                style={{
                  fontSize: switchMerchant ? 20 : 20,
                  fontFamily: "NunitoSans-SemiBold",

                  ...isMobile() && {
                    fontSize: 12,
                  },

                  marginBottom: 5,
                }}
              >
                {`KooDoo Profit (Released): RM ${accumulator.koodooPayoutHistory !== undefined ? accumulator.koodooPayoutHistory.reduce((accum, item) => {
                  return BigNumber(accum).plus(item.amount);
                }, 0).toFixed(2) : '-'}`}
              </Text> */}

              {/* <Text
                style={{
                  fontSize: switchMerchant ? 20 : 20,
                  fontFamily: "NunitoSans-SemiBold",

                  ...isMobile() && {
                    fontSize: 12,
                  },

                  marginBottom: 5,
                }}
              >
                {`KooDoo Profit (Auto Settled): RM ${accumulator.koodooPayoutAmendList !== undefined ?
                  BigNumber(
                    accumulator.koodooPayoutAmendList.reduce((accum, item) => {
                      return BigNumber(accum).plus(item.amount).toNumber();
                    }, 0)
                  )
                    .plus(
                      BigNumber(-accumulator.koodooPayoutDisbursed)
                        .plus(-accumulator.koodooPayoutDisbursed2)
                        .plus(-accumulator.koodooPayoutDisbursed3)
                        .plus(-accumulator.koodooPayoutDisbursed4)
                        .toNumber()
                    )
                    .toFixed(2) : '-'} (Included merchant funds)`}
              </Text> */}
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              //backgroundColor: '#ffffff',
              justifyContent: "space-between",
              //padding: 18,
              marginTop: 20,
              width: "100%",

              ...!isMobile() && {
                paddingLeft: switchMerchant
                  ? 0
                  : windowWidth <= 1823 && windowWidth >= 1820
                    ? "1.5%"
                    : "1%",
                paddingRight: switchMerchant
                  ? 0
                  : windowWidth <= 1823 && windowWidth >= 1820
                    ? "1.5%"
                    : "1%",
              },
            }}
          >
            <TouchableOpacity
              // disabled={true}
              style={[
                {
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  //width: 160,
                  paddingHorizontal: 10,
                  height: switchMerchant ? 35 : 40,
                  alignItems: "center",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  // opacity: !showDetails ? 0 : 100,
                  // opacity: 100,
                  opacity: 0,

                  ...isMobile() && {
                    marginLeft: 30,
                  },
                },
              ]}
              onPress={() => {
                setShowDetails(false);
                setPageCount(
                  Math.ceil(tableDataSummary.length / perPage)
                );
                setCurrentPage(pageReturn);

                setShowDetailsOutletId('');

                console.log("Returning to page");
                console.log(pageReturn);
              }}
              disabled={!showDetails}
            >
              <AntDesign
                name="arrowleft"
                size={switchMerchant ? 10 : 20}
                color={Colors.whiteColor}
                style={{}}
              />
              <Text
                style={{
                  color: Colors.whiteColor,
                  marginLeft: 5,
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: "NunitoSans-Bold",

                  ...isMobile() && {
                    marginLeft: 0,
                  },
                }}
              >
                {isMobile() ? '' : 'SUMMARY'}
              </Text>
            </TouchableOpacity>

            <View style={{
              flexDirection: "row",

              ...isMobile() && {
                flexDirection: 'column',
              },
            }}>
              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  paddingHorizontal: 10,
                  height: 40,
                  alignItems: "center",
                  shadowOpacity: 0,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  marginRight: 10,

                  top: 1,

                  ...isMobile() && {
                    marginRight: 30,
                    marginBottom: 15,
                  },
                }}
                onPress={() => {
                  setExportModalVisibility(true);
                }}
              >
                <View
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Icon
                    name="download"
                    size={20}
                    color={Colors.whiteColor}
                  />
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: 16,
                      fontFamily: "NunitoSans-Bold",
                    }}
                  >
                    DOWNLOAD
                  </Text>
                </View>
              </TouchableOpacity>

              <View
                style={[
                  {
                    paddingHorizontal: 15,
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 10,
                    paddingVertical: 10,
                    justifyContent: "center",
                    backgroundColor: Colors.whiteColor,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,

                    ...isMobile() && {
                      marginRight: 30,
                      // marginBottom: 10,
                    },
                  },
                ]}
              >
                <View
                  style={{ alignSelf: "center", marginRight: 5 }}
                  onPress={() => {
                    setState({
                      pickerMode: "date",
                      showDateTimePicker: true,
                    });
                  }}
                >
                  <GCalendar
                    width={switchMerchant ? 15 : 20}
                    height={switchMerchant ? 15 : 20}
                  />
                </View>

                {/* <TouchableOpacity
                    onPress={() => {
                      setShowDateTimePicker(true);
                      setShowDateTimePicker1(false);
                    }}
                    style={{
                      marginHorizontal: 4,
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                              fontSize: 10,
                              fontFamily: "NunitoSans-Regular",
                            }
                          : { fontFamily: "NunitoSans-Regular" }
                      }
                    >
                      {moment(qrStartDate).format("DD MMM yyyy")}
                    </Text>
                  </TouchableOpacity> */}

                <DatePicker
                  // selected={qrStartDate.toDate()}
                  selected={moment(qrStartDate).toDate()}
                  onCalendarOpen={() => {
                    setIsCalendarOpening(true);
                  }}
                  onCalendarClose={() => {
                    setIsCalendarOpening(false);
                  }}
                  onChange={(date) => {
                    // setRev_date(moment(date).startOf('day'));

                    setClickedBarChartDate(null);
                    setClickedBarChartDateUnit(null);

                    setMerchantDataFilterType(MERCHANT_DATA_FILTER.ALL);

                    CommonStore.update(s => {
                      s.qrStartDate = moment(date).startOf("day").valueOf();
                    });
                  }}
                  maxDate={moment(qrEndDate).toDate()}
                />

                <Text
                  style={
                    switchMerchant
                      ? { fontSize: 10, fontFamily: "NunitoSans-Regular" }
                      : { fontFamily: "NunitoSans-Regular" }
                  }
                >
                  -
                </Text>

                {/* <TouchableOpacity
                    onPress={() => {
                      setShowDateTimePicker(false);
                      setShowDateTimePicker1(true);
                    }}
                    style={{
                      marginHorizontal: 4,
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontSize: 10,
                            fontFamily: "NunitoSans-Regular",
                          }
                          : { fontFamily: "NunitoSans-Regular" }
                      }
                    >
                      {moment(qrEndDate).format("DD MMM yyyy")}
                    </Text>
                  </TouchableOpacity> */}

                <DatePicker
                  // selected={qrEndDate.toDate()}
                  selected={moment(qrEndDate).toDate()}
                  onCalendarOpen={() => {
                    setIsCalendarOpening(true);
                  }}
                  onCalendarClose={() => {
                    setIsCalendarOpening(false);
                  }}
                  onChange={(dateParam) => {
                    // setRev_date1(moment(date).endOf('day'));

                    setClickedBarChartDate(null);
                    setClickedBarChartDateUnit(null);

                    setMerchantDataFilterType(MERCHANT_DATA_FILTER.ALL);

                    var date = moment(dateParam).endOf("day").valueOf();
                    if (moment(date).isSame(window.currToDateTime, 'day')) {
                      date = window.currToDateTime;
                    }

                    CommonStore.update(s => {
                      // s.qrEndDate = moment(date).endOf("day").valueOf();
                      s.qrEndDate = moment(date).valueOf();
                    });
                  }}
                  minDate={moment(qrStartDate).toDate()}
                />
              </View>
            </View>
          </View>

          {/* <ScrollView
            horizontal={true}
            nestedScrollEnabled={true}
            style={{
              width: '100%',
              // display: 'flex',
              // flexDirection: 'row',
              // alignItems: 'center',

              marginTop: 30,
              padding: 20,
              paddingBottom: 0,
              zIndex: -50,

              // backgroundColor: 'red',
            }}
            contentContainerStyle={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',

              paddingBottom: 5,
              paddingRight: 5,
            }}
            showsHorizontalScrollIndicator={false}
          >
            <TouchableOpacity
              style={{
                justifyContent: 'center',
                flexDirection: 'row',
                borderWidth: 1,
                borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.primaryColor : Colors.whiteColor,
                backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.primaryColor : Colors.whiteColor,
                borderRadius: 5,
                paddingHorizontal: 15,
                height: switchMerchant ? 35 : 40,
                alignItems: 'center',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
                zIndex: -1,

                paddingTop: 1,

                marginRight: 15,
              }}
              onPress={() => {
                setClickedBarChartDate(null);
                setClickedBarChartDateUnit(null);

                setMerchantDataFilterType(MERCHANT_DATA_FILTER.ALL);

                CommonStore.update(s => {
                  s.qrStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  // s.qrEndDate = moment().endOf('day').valueOf();
                  s.qrEndDate = moment(window.currToDateTime).valueOf();
                });
              }}>
              <Text
                style={{
                  color: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.whiteColor : Colors.primaryColor,
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: 'NunitoSans-Bold',
                }}>
                {`All (${merchantDataAllLength} in total${merchantDataAllLength > 1 ? 's' : ''})`}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                justifyContent: 'center',
                flexDirection: 'row',
                borderWidth: 1,
                borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.primaryColor : Colors.whiteColor,
                backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.primaryColor : Colors.whiteColor,
                borderRadius: 5,
                paddingHorizontal: 15,
                height: switchMerchant ? 35 : 40,
                alignItems: 'center',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
                zIndex: -1,

                paddingTop: 1,

                marginRight: 15,
              }}
              onPress={() => {
                setClickedBarChartDate(null);
                setClickedBarChartDateUnit(null);

                setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_24H);

                CommonStore.update(s => {
                  s.qrStartDate = moment().startOf('day').valueOf();
                  // s.qrEndDate = moment().endOf('day').valueOf();
                  s.qrEndDate = moment(window.currToDateTime).valueOf();
                });
              }}>
              <Text
                style={{
                  color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.whiteColor : Colors.primaryColor,
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: 'NunitoSans-Bold',
                }}>
                {`Today (${merchantData24HLength} active${merchantData24HLength > 1 ? 's' : ''})`}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                justifyContent: 'center',
                flexDirection: 'row',
                borderWidth: 1,
                borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.primaryColor : Colors.whiteColor,
                backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.primaryColor : Colors.whiteColor,
                borderRadius: 5,
                paddingHorizontal: 15,
                height: switchMerchant ? 35 : 40,
                alignItems: 'center',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
                zIndex: -1,

                paddingTop: 1,

                marginRight: 15,
              }}
              onPress={() => {
                setClickedBarChartDate(null);
                setClickedBarChartDateUnit(null);

                setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_7D);

                CommonStore.update(s => {
                  s.qrStartDate = moment().subtract(7, "day").startOf('day').valueOf();
                  // s.qrEndDate = moment().endOf('day').valueOf();
                  s.qrEndDate = moment(window.currToDateTime).valueOf();
                });
              }}>
              <Text
                style={{
                  color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.whiteColor : Colors.primaryColor,
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: 'NunitoSans-Bold',
                }}>
                {`Last 7d (${merchantData7DLength} active${merchantData7DLength > 1 ? 's' : ''})`}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                justifyContent: 'center',
                flexDirection: 'row',
                borderWidth: 1,
                borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.primaryColor : Colors.whiteColor,
                backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.primaryColor : Colors.whiteColor,
                borderRadius: 5,
                paddingHorizontal: 15,
                height: switchMerchant ? 35 : 40,
                alignItems: 'center',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
                zIndex: -1,

                paddingTop: 1,

                marginRight: 15,
              }}
              onPress={() => {
                setClickedBarChartDate(null);
                setClickedBarChartDateUnit(null);

                setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_1M);

                CommonStore.update(s => {
                  s.qrStartDate = moment().subtract(1, "month").startOf('day').valueOf();
                  // s.qrEndDate = moment().endOf('day').valueOf();
                  s.qrEndDate = moment(window.currToDateTime).valueOf();
                });
              }}>
              <Text
                style={{
                  color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.whiteColor : Colors.primaryColor,
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: 'NunitoSans-Bold',
                }}>
                {`Last 1m (${merchantData1MLength} active${merchantData1MLength > 1 ? 's' : ''})`}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                justifyContent: 'center',
                flexDirection: 'row',
                borderWidth: 1,
                borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.primaryColor : Colors.whiteColor,
                backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.primaryColor : Colors.whiteColor,
                borderRadius: 5,
                paddingHorizontal: 15,
                height: switchMerchant ? 35 : 40,
                alignItems: 'center',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
                zIndex: -1,

                paddingTop: 1,

                marginRight: 15,
              }}
              onPress={() => {
                setClickedBarChartDate(null);
                setClickedBarChartDateUnit(null);

                setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_3M);

                CommonStore.update(s => {
                  s.qrStartDate = moment().subtract(3, "month").startOf('day').valueOf();
                  // s.qrEndDate = moment().endOf('day').valueOf();
                  s.qrEndDate = moment(window.currToDateTime).valueOf();
                });
              }}>
              <Text
                style={{
                  color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.whiteColor : Colors.primaryColor,
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: 'NunitoSans-Bold',
                }}>
                {`Last 3m (${merchantData3MLength} active${merchantData3MLength > 1 ? 's' : ''})`}
              </Text>
            </TouchableOpacity>

            {
              (clickedBarChartDate !== null && clickedBarChartDateUnit !== null)
                ?
                <Text
                  style={{
                    color: Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {
                    clickedBarChartDateUnit === 'hour'
                      ?
                      `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM-DD, hh:mm A')} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                      :
                      ``
                  }

                  {
                    clickedBarChartDateUnit === 'day'
                      ?
                      `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM-DD')} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                      :
                      ``
                  }

                  {
                    clickedBarChartDateUnit === 'week'
                      ?
                      `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM')} W${countWeekdayOccurrencesInMonth(clickedBarChartDate)} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                      :
                      ``
                  }

                  {
                    clickedBarChartDateUnit === 'month'
                      ?
                      `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM')} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                      :
                      ``
                  }
                </Text>
                :
                <></>
            }
          </ScrollView> */}

          <View
            // horizontal={true}
            // nestedScrollEnabled={true}
            style={{
              width: '100%',
              // display: 'flex',
              // flexDirection: 'row',
              // alignItems: 'center',

              // marginTop: 30,
              padding: 20,
              paddingBottom: 0,
              // zIndex: -50,

              zIndex: 10000,

              // backgroundColor: 'red',

              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',

              paddingBottom: 5,
              paddingRight: 5,

              marginTop: 20,
            }}
          >
            {/* <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 8,
                width: 250,
                backgroundColor: "white",
              }}
            >
              <MultiSelect
                clearable={false}
                singleSelect={true}
                defaultValue={selectedPaymentType}
                placeholder={"Select Type"}
                onChange={(value) => {
                  if (value) { // if choose the same option again, value = ''
                    setSelectedPaymentType(value);
                  }
                }}
                options={PAYMENT_TYPE_DROPDOWN_LIST}
                className="msl-varsHEADER"
              />
            </View> */}

            <TouchableOpacity
              style={[
                {
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  //width: 160,
                  paddingHorizontal: 10,
                  height: switchMerchant ? 35 : 40,
                  alignItems: "center",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  opacity: !showDetails ? 0 : 100,
                  // opacity: 100,

                  ...isMobile() && {
                    // marginLeft: 30,
                  },

                  // marginLeft: 15,
                },
              ]}
              onPress={() => {
                setShowDetails(false);
                setPageCount(
                  Math.ceil(tableDataSummary.length / perPage)
                );
                setCurrentPage(pageReturn);

                setShowDetailsOutletId('');

                console.log("Returning to page");
                console.log(pageReturn);
              }}
              disabled={!showDetails}
            >
              <AntDesign
                name="arrowleft"
                size={switchMerchant ? 10 : 20}
                color={Colors.whiteColor}
                style={{}}
              />
              <Text
                style={{
                  color: Colors.whiteColor,
                  marginLeft: 5,
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: "NunitoSans-Bold",

                  ...isMobile() && {
                    marginLeft: 0,
                  },
                }}
              >
                {isMobile() ? '' : 'SUMMARY'}
              </Text>
            </TouchableOpacity>
          </View>

          {/* /////////////////////////////////////////////////////// */}

          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            nestedScrollEnabled={true}
            contentContainerStyle={{
              // minWidth: windowWidth,
              width: "100%",
              // paddingLeft: 0,

              // ...isMobile() && {
              //   flexDirection: 'column',
              // },

              flexDirection: 'column',

              ...isMobile() && {
                width: 1280,
              },
            }}
            style={{
              // minWidth: windowWidth,
              // width: "100%",
              marginTop: 0,
              padding: 20,
              zIndex: -50,
              // backgroundColor: 'red',
            }}

          >
            <View
              style={{
                backgroundColor: Colors.whiteColor,
                width: "100%",
                height: windowHeight * 0.66,
                marginTop: 0,
                // marginHorizontal: 30,
                marginBottom: 10,
                alignSelf: "center",
                borderRadius: 5,
                shadowOpacity: 0,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,

                // ...isMobile() && {
                //   width: 1280,
                // },

                zIndex: -50,
              }}
            >
              {!showDetails ? (
                <View style={{ marginTop: 10, flexDirection: "row" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "2%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      paddingLeft: 10,
                    }}
                  >
                    <View
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"No.\n \n "}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", opacity: 0 }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "14%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByOutletName);
                        } else {
                          temp.sort(sortByOutletNameDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Merchant\nName\n "}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "10%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByQrU);
                        } else {
                          temp.sort(sortByQrUDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Unique QR\nPrinted Times\n "}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "10%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByQrT);
                        } else {
                          temp.sort(sortByQrTDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Takeaway QR\nPrinted Times\n "}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "10%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByQrUScannedOnce);
                        } else {
                          temp.sort(sortByQrUScannedOnceDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Unique QR\nScan Times\n(Once)"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "10%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByQrUScannedPic);
                        } else {
                          temp.sort(sortByQrUScannedPicDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Unique QR\nScan Times\n(PIC)"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "10%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByQrUScannedTotal);
                        } else {
                          temp.sort(sortByQrUScannedTotalDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Unique QR\nScanned Times\n(Total)"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "10%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByDtLastPrinted);
                        } else {
                          temp.sort(sortByDtLastPrintedDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Last Printed\nDate/Time\n "}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "10%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataSummary];

                        if (toggleCompare) {
                          temp.sort(sortByDtLastPrinted);
                        } else {
                          temp.sort(sortByDtLastPrintedDesc);
                        }

                        setTableDataSummary(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Last Scanned\nDate/Time\n "}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                </View>
              ) : (
                // for details page

                <View style={{ marginTop: 10, flexDirection: "row" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "2%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      paddingLeft: 10,
                    }}
                  >
                    <View
                    // onPress={() => {
                    //   let temp = [...tableDataDetails];

                    //   if (toggleCompare) {
                    //     temp.sort(sortByDateTime);
                    //   } else {
                    //     temp.sort(sortByDateTimeDesc);
                    //   }

                    //   setTableDataDetails(temp);

                    //   setToggleCompare(!toggleCompare);
                    // }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"No.\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", opacity: 0 }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "14%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataDetails];

                        if (toggleCompare) {
                          temp.sort(sortByDateTime);
                        } else {
                          temp.sort(sortByDateTimeDesc);
                        }

                        setTableDataDetails(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Request\nDate"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "15%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataDetails];

                        if (toggleCompare) {
                          temp.sort(sortByBankInfo);
                        } else {
                          temp.sort(sortByBankInfoDesc);
                        }

                        setTableDataDetails(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Bank\nInfo"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                  {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByNetAmount);
                          } else {
                            temp.sort(sortByNetAmountDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Net\nAmount"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                  {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByTax);
                          } else {
                            temp.sort(sortByTaxDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Tax\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                  {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortBySc);
                          } else {
                            temp.sort(sortByScDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"SC\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                  <View
                    style={{
                      flexDirection: "row",
                      width: "8%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataDetails];

                        if (toggleCompare) {
                          temp.sort(sortByMerchantRefId);
                        } else {
                          temp.sort(sortByMerchantRefIdDesc);
                        }

                        setTableDataDetails(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Merchant\nRef ID"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "8%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataDetails];

                        if (toggleCompare) {
                          temp.sort(sortByCurrency);
                        } else {
                          temp.sort(sortByCurrencyDesc);
                        }

                        setTableDataDetails(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Currency\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "8%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataDetails];

                        if (toggleCompare) {
                          temp.sort(sortByAmount);
                        } else {
                          temp.sort(sortByAmountDesc);
                        }

                        setTableDataDetails(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Amount\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "8%",
                      borderRightWidth: 1,
                      borderRightColor: "lightgrey",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        let temp = [...tableDataDetails];

                        if (toggleCompare) {
                          temp.sort(sortByCost);
                        } else {
                          temp.sort(sortByCostDesc);
                        }

                        setTableDataDetails(temp);

                        setToggleCompare(!toggleCompare);
                      }}
                    >
                      <View style={{ flexDirection: "row", alignItems: 'center' }}>
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            numberOfLines={3}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Cost\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={Colors.descriptionColor}
                          ></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              )}

              {!showDetails ? (
                <>
                  {tableDataSummary.filter((item) => {
                    return filterItem(item);
                  }).length > 0 ? (
                    <FlatList
                      showsVerticalScrollIndicator={false}
                      ref={flatListRef}
                      data={tableDataSummary
                        .filter((item) => {
                          return filterItem(item);
                        })
                        .slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        )}
                      renderItem={renderItem}
                      keyExtractor={(item, index) => String(index)}
                      style={{ marginTop: 10 }}
                    />
                  ) : (
                    <View
                      style={{
                        height: windowHeight * 0.5,
                      }}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        {/* <Text style={{ color: Colors.descriptionColor }}>
                            Loading...
                          </Text> */}
                      </View>
                    </View>
                  )}
                </>
              ) : (
                <>
                  {tableDataDetails.filter((item) => {
                    return filterItemDetails(item);
                  }).length > 0 ? (
                    <FlatList
                      showsVerticalScrollIndicator={false}
                      ref={flatListRef}
                      data={tableDataDetails
                        .filter((item) => {
                          return filterItemDetails(item);
                        })
                        .slice(
                          (currentDetailsPage - 1) * perPage,
                          currentDetailsPage * perPage
                        )}
                      renderItem={renderItemDetails}
                      keyExtractor={(item, index) => String(index)}
                      style={{ marginTop: 10 }}
                    />
                  ) : (
                    <View
                      style={{
                        height: windowHeight * 0.5,
                      }}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        {/* <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text> */}
                      </View>
                    </View>
                  )}
                </>
              )}
            </View>

            {!showDetails ? (
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                  width: "100%",
                  alignItems: "center",
                  alignSelf: "center",
                  justifyContent: "flex-end",
                  top:
                    Platform.OS == "ios"
                      ? pushPagingToTop && keyboardHeight > 0
                        ? -keyboardHeight * 1
                        : 0
                      : 0,
                  borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                  paddingHorizontal:
                    pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                }}
              >
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                  }}
                >
                  Items Showed
                </Text>
                <View
                  style={{
                    width: Platform.OS === "ios" ? 65 : "13%", //65,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: "center",
                    paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                    //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                    // paddingTop: '-60%',
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    marginRight: "1%",
                  }}
                >
                  {/* <RNPickerSelect
                        useNativeAndroidPickerStyle={false}
                        style={{
                          inputIOS: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            textAlign: "center",
                          },
                          inputAndroid: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            justifyContent: "center",
                            textAlign: "center",
                            height: 40,
                            color: "black",
                          },
                          inputAndroidContainer: { width: "100%" },
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: "none",
                          },
                          chevronDown: {
                            display: "none",
                          },
                          chevronUp: {
                            display: "none",
                          },
                        }}
                        items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: "All",
                          value: !showDetails
                            ? allOutletsEmployeesAction.length
                            : allOutletsEmployeesDetails.length,
                        })}
                        value={perPage}
                        onValueChange={(value) => {
                          setPerPage(value);
                        }}
                      /> */}
                  <Picker
                    selectedValue={perPage}
                    style={{}}
                    onValueChange={(value, text) => {
                      setPerPage(value);
                    }}
                  >
                    {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                      label: "All",
                      value: !showDetails
                        ? tableDataSummary.length
                        : tableDataDetails.length,
                    }).map((value, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          label={value.label}
                          value={value.value}
                        />
                      );
                    })}
                  </Picker>
                </View>

                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                  }}
                >
                  Page
                </Text>
                <View
                  style={{
                    width: switchMerchant ? 65 : 70,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: "center",
                    paddingHorizontal: 22,
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                  }}
                >
                  {console.log("currentPage")}
                  {console.log(currentPage)}

                  <TextInput
                    onChangeText={(text) => {
                      var currentPageTemp =
                        text.length > 0 ? parseInt(text) : 1;

                      setCurrentPage(
                        currentPageTemp > pageCount
                          ? pageCount
                          : currentPageTemp < 1
                            ? 1
                            : currentPageTemp
                      );
                    }}
                    placeholder={
                      pageCount !== 0 ? currentPage.toString() : "0"
                    }
                    placeholderTextColor={Platform.select({
                      ios: "#a9a9a9",
                    })}
                    style={{
                      color: "black",
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Regular",
                      marginTop: Platform.OS === "ios" ? 0 : -15,
                      marginBottom: Platform.OS === "ios" ? 0 : -15,
                      textAlign: "center",
                      width: "100%",
                    }}
                    value={pageCount !== 0 ? currentPage.toString() : "0"}
                    defaultValue={
                      pageCount !== 0 ? currentPage.toString() : "0"
                    }
                    keyboardType={"numeric"}
                    onFocus={() => {
                      setPushPagingToTop(true);
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginLeft: "1%",
                    marginRight: "1%",
                  }}
                >
                  of {pageCount}
                </Text>
                <TouchableOpacity
                  style={{
                    width: switchMerchant ? 30 : 45,
                    height: switchMerchant ? 20 : 28,
                    backgroundColor: Colors.primaryColor,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    prevPage();
                  }}
                >
                  <ArrowLeft color={Colors.whiteColor} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    width: switchMerchant ? 30 : 45,
                    height: switchMerchant ? 20 : 28,
                    backgroundColor: Colors.primaryColor,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    nextPage();
                  }}
                >
                  <ArrowRight color={Colors.whiteColor} />
                </TouchableOpacity>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                  width: '100%',
                  alignItems: "center",
                  alignSelf: "center",
                  justifyContent: "flex-end",
                  top:
                    Platform.OS == "ios"
                      ? pushPagingToTop && keyboardHeight > 0
                        ? -keyboardHeight * 1
                        : 0
                      : 0,
                  borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                  paddingHorizontal:
                    pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                }}
              >
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                  }}
                >
                  Items Showed
                </Text>
                <View
                  style={{
                    width: Platform.OS === "ios" ? 65 : "13%", //65,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: "center",
                    paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                    //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                    // paddingTop: '-60%',
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    marginRight: "1%",
                  }}
                >
                  {/* <RNPickerSelect
                        useNativeAndroidPickerStyle={false}
                        style={{
                          inputIOS: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            textAlign: "center",
                          },
                          inputAndroid: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            justifyContent: "center",
                            textAlign: "center",
                            height: 40,
                            color: "black",
                          },
                          inputAndroidContainer: { width: "100%" },
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: "none",
                          },
                          chevronDown: {
                            display: "none",
                          },
                          chevronUp: {
                            display: "none",
                          },
                        }}
                        items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: "All",
                          value: !showDetails
                            ? allOutletsEmployeesAction.length
                            : allOutletsEmployeesDetails.length,
                        })}
                        value={perPage}
                        onValueChange={(value) => {
                          setPerPage(value);
                        }}
                      /> */}
                  <Picker
                    selectedValue={perPage}
                    style={{}}
                    onValueChange={(value, text) => {
                      setPerPage(value);
                    }}
                  >
                    {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                      label: "All",
                      value: !showDetails
                        ? tableDataSummary.length
                        : tableDataDetails.length,
                    }).map((value, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          label={value.label}
                          value={value.value}
                        />
                      );
                    })}
                  </Picker>
                </View>

                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                  }}
                >
                  Page
                </Text>
                <View
                  style={{
                    width: switchMerchant ? 65 : 70,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: "center",
                    paddingHorizontal: 22,
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                  }}
                >
                  {console.log("currentDetailsPage")}
                  {console.log(currentDetailsPage)}

                  <TextInput
                    onChangeText={(text) => {
                      var currentPageTemp =
                        text.length > 0 ? parseInt(text) : 1;
                      console.log("currentDetailsPage pending");
                      console.log(
                        currentPageTemp > pageCount
                          ? pageCount
                          : currentPageTemp < 1
                            ? 1
                            : currentPageTemp
                      );
                      setCurrentDetailsPage(
                        currentPageTemp > pageCount
                          ? pageCount
                          : currentPageTemp < 1
                            ? 1
                            : currentPageTemp
                      );
                    }}
                    placeholder={
                      pageCount !== 0 ? currentDetailsPage.toString() : "0"
                    }
                    placeholderTextColor={Platform.select({
                      ios: "#a9a9a9",
                    })}
                    style={{
                      color: "black",
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Regular",
                      marginTop: Platform.OS === "ios" ? 0 : -15,
                      marginBottom: Platform.OS === "ios" ? 0 : -15,
                      textAlign: "center",
                      width: "100%",
                    }}
                    value={
                      pageCount !== 0 ? currentDetailsPage.toString() : "0"
                    }
                    defaultValue={
                      pageCount !== 0 ? currentDetailsPage.toString() : "0"
                    }
                    keyboardType={"numeric"}
                    onFocus={() => {
                      setPushPagingToTop(true);
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginLeft: "1%",
                    marginRight: "1%",
                  }}
                >
                  of {pageCount}
                </Text>
                <TouchableOpacity
                  style={{
                    width: switchMerchant ? 30 : 45,
                    height: switchMerchant ? 20 : 28,
                    backgroundColor: Colors.primaryColor,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    prevDetailsPage();
                  }}
                >
                  <ArrowLeft color={Colors.whiteColor} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    width: switchMerchant ? 30 : 45,
                    height: switchMerchant ? 20 : 28,
                    backgroundColor: Colors.primaryColor,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    nextDetailsPage();
                  }}
                >
                  <ArrowRight color={Colors.whiteColor} />
                </TouchableOpacity>
              </View>
            )}
          </ScrollView>

          {/* /////////////////////////////////////////////////////// */}
        </ScrollView>
      </TouchableWithoutFeedback>

      <Modal
        style={{}}
        visible={exportModalVisibility}
        supportedOrientations={["portrait", "landscape"]}
        transparent={true}
        animationType={"fade"}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: Colors.modalBgColor,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              height: Dimensions.get("screen").width * 0.12,
              width: Dimensions.get("screen").width * 0.18,
              backgroundColor: Colors.whiteColor,
              borderRadius: 12,
              padding: Dimensions.get("screen").width * 0.03,
              alignItems: "center",
              justifyContent: "center",

              ...isMobile() && {
                height: 200,
                width: 300,
              },
            }}
          >
            <TouchableOpacity
              disabled={isLoading}
              style={{
                position: "absolute",
                right: Dimensions.get("screen").width * 0.015,
                top: Dimensions.get("screen").width * 0.01,

                elevation: 1000,
                zIndex: 1000,

                ...isMobile() && {
                  right: 20,
                  top: 20,
                },
              }}
              onPress={() => {
                setExportModalVisibility(false);
              }}
            >
              <AntDesign
                name="closecircle"
                size={isMobile() ? 20 : 25}
                color={Colors.fieldtTxtColor}
              />
            </TouchableOpacity>
            <View
              style={{
                alignItems: "center",
                top: "20%",
                position: "absolute",
              }}
            >
              <Text
                style={{
                  fontFamily: "NunitoSans-Bold",
                  textAlign: "center",
                  fontSize: isMobile() ? 16 : 24,
                }}
              >
                Download Report
              </Text>
            </View>
            <View style={{ top: switchMerchant ? "14%" : "10%" }}>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  marginTop: 30,
                }}
              >
                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 100 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    // marginRight: 15,
                  }}
                  onPress={() => {
                    handleExportExcel();
                  }}
                >
                  {isLoading && isExcel ? (
                    <ActivityIndicator
                      size={"small"}
                      color={Colors.whiteColor}
                    />
                  ) : (
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      EXCEL
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      {/* <Modal
        style={{}}
        visible={exportModalVisibility}
        supportedOrientations={["portrait", "landscape"]}
        transparent={true}
        animationType={"fade"}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: Colors.modalBgColor,
            alignItems: "center",
            justifyContent: "center",
            top:
              Platform.OS === "android"
                ? 0
                : keyboardHeight > 0
                  ? -keyboardHeight * 0.45
                  : 0,
          }}
        >
          <View
            style={{
              backgroundColor: Colors.whiteColor,
              borderRadius: 12,
              padding: windowWidth * 0.03,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              disabled={isLoading}
              style={{
                position: "absolute",
                right: windowWidth * 0.02,
                top: windowWidth * 0.02,

                elevation: 1000,
                zIndex: 1000,
              }}
              onPress={() => {
                setExportModalVisibility(false);
              }}
            >
              <AntDesign
                name="closecircle"
                size={switchMerchant ? 15 : 25}
                color={Colors.fieldtTxtColor}
              />
            </TouchableOpacity>
            <View
              style={{
                alignItems: "center",
                top: "20%",
                position: "absolute",
              }}
            >
              <Text
                style={{
                  fontFamily: "NunitoSans-Bold",
                  textAlign: "center",
                  fontSize: switchMerchant ? 16 : 24,
                }}
              >
                Download Report
              </Text>
            </View>
            <View style={{ top: switchMerchant ? "14%" : "10%" }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 20,
                  fontFamily: "NunitoSans-Bold",
                }}
              >
                Email Address:
              </Text>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? 240 : 370,
                  height: switchMerchant ? 35 : 50,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                  paddingLeft: 10,
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholderStyle={{ padding: 5 }}
                placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                placeholder="Enter Your Email"
                onChangeText={(text) => {
                  setExportEmail(text);
                }}
                value={exportEmail}
              />
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 20,
                  fontFamily: "NunitoSans-Bold",
                  marginTop: 15,
                }}
              >
                Send As:
              </Text>

              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 100 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                    marginRight: 15,
                  }}
                  onPress={() => {
                    if (exportEmail.length > 0) {
                      CommonStore.update((s) => {
                        s.isLoading = true;
                      });

                      setIsExcel(true);

                      const excelData = convertDataToExcelFormat();

                      generateEmailReport(
                        EMAIL_REPORT_TYPE.EXCEL,
                        excelData,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report.xlsx",
                        `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                        exportEmail,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report",
                        () => {
                          CommonStore.update((s) => {
                            s.isLoading = false;
                          });

                          setIsExcel(false);

                          Alert.alert(
                            "Success",
                            "Report will be sent to the email address soon"
                          );

                          setExportModalVisibility(false);
                        }
                      );
                    } else {
                      Alert.alert("Info", "Invalid email address");
                    }
                  }}
                >
                  {isLoading && isExcel ? (
                    <ActivityIndicator
                      size={"small"}
                      color={Colors.whiteColor}
                    />
                  ) : (
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      EXCEL
                    </Text>
                  )}
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 100 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => {
                    if (exportEmail.length > 0) {
                      CommonStore.update((s) => {
                        s.isLoading = true;
                      });

                      setIsCsv(true);

                      const csvData = convertArrayToCSV(
                        tableDataSummary
                      );

                      generateEmailReport(
                        EMAIL_REPORT_TYPE.CSV,
                        csvData,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report.csv",
                        `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                        exportEmail,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report",
                        () => {
                          CommonStore.update((s) => {
                            s.isLoading = false;
                          });

                          setIsCsv(false);

                          Alert.alert(
                            "Success",
                            "Report will be sent to the email address soon"
                          );

                          setExportModalVisibility(false);
                        }
                      );
                    } else {
                      Alert.alert("Info", "Invalid email address");
                    }
                  }}
                >
                  {isLoading && isCsv ? (
                    <ActivityIndicator
                      size={"small"}
                      color={Colors.whiteColor}
                    />
                  ) : (
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      CSV
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal> */}

      <DateTimePickerModal
        isVisible={showDateTimePicker}
        mode={"date"}
        onConfirm={(text) => {
          // setRev_date(moment(text).startOf("day"));

          setClickedBarChartDate(null);
          setClickedBarChartDateUnit(null);

          CommonStore.update(s => {
            s.qrStartDate = moment(text).startOf("day").valueOf();
          });

          setShowDateTimePicker(false);

          setSalesLineChartPeriod(CHART_PERIOD.NONE);
        }}
        onCancel={() => {
          setShowDateTimePicker(false);
        }}
        maximumDate={moment(qrStartDate).toDate()}
      />

      <DateTimePickerModal
        isVisible={showDateTimePicker1}
        mode={"date"}
        onConfirm={(dateParam) => {
          // setRev_date1(moment(text).endOf("day"));

          setClickedBarChartDate(null);
          setClickedBarChartDateUnit(null);

          var date = moment(dateParam).endOf("day").valueOf();
          if (moment(date).isSame(window.currToDateTime, 'day')) {
            date = window.currToDateTime;
          }

          CommonStore.update(s => {
            // s.qrEndDate = moment(dateParam).endOf("day").valueOf();
            s.qrEndDate = moment(date).valueOf();
          });

          setShowDateTimePicker1(false);

          setSalesLineChartPeriod(CHART_PERIOD.NONE);
        }}
        onCancel={() => {
          setShowDateTimePicker1(false);
        }}
        minimumDate={moment(qrEndDate).toDate()}
      />

      <Modal
        style={{ flex: 1 }}
        visible={visible}
        transparent={true}
        animationType="slide"
      >
        <KeyboardAvoidingView
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            minHeight: windowHeight,
            top:
              Platform.OS === "ios" && keyboardHeight > 0
                ? -keyboardHeight * 0.5
                : 0,
          }}
        >
          <View style={styles.confirmBox1}>
            <Text
              style={{
                fontSize: 24,
                justifyContent: "center",
                alignSelf: "center",
                marginTop: 40,
                fontFamily: "NunitoSans-Bold",
              }}
            >
              Enter your email
            </Text>
            <View
              style={{
                justifyContent: "center",
                alignSelf: "center",
                alignContent: "center",
                marginTop: 20,
                flexDirection: "row",
                width: "80%",
              }}
            >
              <View style={{ justifyContent: "center", marginHorizontal: 5 }}>
                <Text
                  style={{ color: Colors.descriptionColor, fontSize: 20 }}
                >
                  email:
                </Text>
              </View>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={[styles.textInput8, { paddingLeft: 5 }]}
                placeholder="Enter your email"
                // style={{
                //     // paddingLeft: 1,
                // }}
                //defaultValue={extentionCharges}
                onChangeText={(text) => {
                  // setState({ exportEmail: text });
                  setExportEmail(text);
                }}
                placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                value={exportEmail}
              />
            </View>
            <Text
              style={{
                fontSize: 20,
                fontFamily: "NunitoSans-Bold",
                marginTop: 25,
                justifyContent: "center",
                alignSelf: "center",
                alignContent: "center",
              }}
            >
              Share As:
            </Text>

            {/* Share file using email */}
            <View
              style={{
                justifyContent: "space-between",
                alignSelf: "center",
                marginTop: 10,
                flexDirection: "row",
                width: "80%",
              }}
            >
              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}
              >
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}
                >
                  Excel
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}
              >
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}
                >
                  CSV
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}
              >
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}
                >
                  PDF
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                alignSelf: "center",
                marginTop: 20,
                justifyContent: "center",
                alignItems: "center",
                // width: 260,
                width: windowWidth * 0.2,
                height: 60,
                alignContent: "center",
                flexDirection: "row",
                marginTop: 40,
              }}
            >
              <TouchableOpacity
                onPress={emailVariant}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: 60,
                  borderBottomLeftRadius: 10,
                  borderRightWidth: StyleSheet.hairlineWidth,
                  borderTopWidth: StyleSheet.hairlineWidth,
                }}
              >
                <Text
                  style={{
                    fontSize: 22,
                    color: Colors.primaryColor,
                    fontFamily: "NunitoSans-SemiBold",
                  }}
                >
                  Email
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  // setState({ visible: false });
                  setVisible(false);
                }}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: 60,
                  borderBottomRightRadius: 10,
                  borderTopWidth: StyleSheet.hairlineWidth,
                }}
              >
                <Text
                  style={{
                    fontSize: 22,
                    color: Colors.descriptionColor,
                    fontFamily: "NunitoSans-SemiBold",
                  }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: "#ffffff",
    flexDirection: "row",
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    // padding: 20,
    paddingVertical: 20,
    backgroundColor: Colors.highlightColor,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  confirmBox: {
    // width: '30%',
    // height: '30%',
    // borderRadius: 30,
    // backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.3,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.2,
    width: Dimensions.get("screen").width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.02,
    top: Dimensions.get("screen").width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: Dimensions.get("screen").width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  modalSaveButton1: {
    width: Dimensions.get("screen").width * 0.1,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  confirmBox1: {
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.4,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  submitText: {
    height:
      Platform.OS == "ios"
        ? Dimensions.get("screen").height * 0.06
        : Dimensions.get("screen").height * 0.07,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
});
export default KooDooQRScreen;
