import { Store } from 'pullstate';

export const CommonStore = new Store({
    isLoading: false,

    alertObj: null,

    orderTables: [],
    switchMerchant: false,

    accumulator: {},
    accumulatorRazers: [],

    ///////////////////////////////

    isAuthenticating: false,

    selectedOutletSection: {},

    selectedOutletTable: {},

    /////////////////////////////////

    currOutletTaxes: [],

    // Shared from user app

    outletsTaxDict: {},

    selectedOutletItems: [], // [ outlet_item, ... ]
    selectedOutletItemCategories: [], // [ outlet_item_category, ... ]

    selectedOutletItemCategory: {}, // outlet_item_category
    selectedOutletItem: {}, // outlet_item

    outletsItemAddOnDict: {}, // { outlet_item_id -> outlet_item_addon, ... }
    outletsItemAddOnChoiceDict: {}, // { outlet_item_addon_id -> outlet_item_addon_choice, ... }

    selectedOutletItemAddOn: {}, // { outlet_item_addon_id -> { outlet_item_addon_choice_id = true/false, ... }, ... }
    selectedOutletItemAddOnChoice: {}, // { outlet_item_addon_choice_id -> true/false, ... }

    onUpdatingCartItem: null,

    onUpdatingCurrPendingOrder: null,
    modeAddCart: 'NORMAL',

    cartItems: [], // [ { itemId: outlet_item_id, choices: { outlet_item_addon_choice_id: true/false, ... } }, ... ]
    cartItemChoices: {}, // { outlet_item_id -> { outlet_item_addon_choice_id = true, ... }, ... }

    orderType: 'DELIVERY',

    cartOutletItemsDict: {},
    cartOutletItemAddOnDict: {},
    cartOutletItemAddOnChoiceDict: {},

    cartItemsProcessed: [],

    /////////////////////////////////

    segments: [],
    // segmentsDict: {},

    merchantVouchers: [],
    merchantVouchersDict: {},

    merchantVoucherReportsVoucherIdDict: {},

    outletSupplyItems: [],
    outletSupplyItemsDict: {},
    outletSupplyItemsSkuDict: {},

    supplyItems: [],
    supplyItemsSkuDict: {},
    supplyItemsDict: {},
    suppliers: [],
    suppliersDict: {},

    purchaseOrders: [],
    stockTransfers: [],
    stockTakes: [],

    allOutletsSupplyItems: [],
    allOutletsSupplyItemsDict: {},
    allOutletsSupplyItemsSkuDict: {},

    allOutletsItemAddOn: [],
    allOutletsItemAddOnDict: {},
    allOutletsItemAddOnChoiceDict: {},

    selectedProductEdit: null,
    selectedOutletCategoryEdit: null,
    selectedSupplierEdit: null,
    selectedPurchaseOrderEdit: null,
    selectedStockTransferEdit: null,
    selectedStockTakeEdit: null,
    selectedVoucherEdit: null,
    selectedOutletEmployeeEdit: null,

    selectedPromotionEdit: null,

    selectedCustomerEdit: null,

    selectedPreorderPackageEdit: null,

    selectedPointsRedeemPackageEdit: null,

    selectedSegmentEdit: null,

    selectedBeerDocketEdit: null,

    selectedPurchaseOrderEditFromSupplier: null,

    selectedLoyaltyStampEdit: null,

    selectedLoyaltyCampaignEdit: null,

    userCart: {},

    currPage: 'Dashboard',
    currPageStack: ['Dashboard'],
    pageStack: ['Dashboard'],
    expandTab: 'DASHBOARD',

    timestamp: Date.now(),

    routeParams: {},

    outletSelectDropdownView: () => { },

    selectedOrderToPayUserId: '',
    selectedOrderToPayUserIdVoucherIdRedemptionList: [],
    selectedOrderToPayUserIdVoucherIdValidList: [],

    summaryCheckDict: {},
    summaryCancelledCheckDict: {},
    summaryDeliveredCheckDict: {},

    chatbotModalVisibility: false,
    chatbotMessages: [
        // {
        //     _id: 1,
        //     text: 'Hello developer',
        //     createdAt: new Date(),
        //     user: {
        //         _id: 2,
        //         name: 'React Native',
        //         avatar: 'https://placeimg.com/140/140/any',
        //     },
        // },
        // {
        //     _id: 1,
        //     text: 'Hello developer',
        //     createdAt: new Date(),
        //     user: {
        //         _id: 2,
        //         name: 'React Native',
        //         avatar: 'https://placeimg.com/140/140/any',
        //     },
        // },
    ],


    // Navigation param
    venueSettingPage: '',

    // MainScreen & LoginScreen
    checkLogin: () => { },
    showApp: true,

    merchantsOnboarded: [],
    outletsOnboarded: [],
    employeeClocks: [],
    userActions: [],

    razerOutletBankInfoList: [],
    razerOutletBankInfoMdrList: [],

    gmvOrders: [],   
    gmvStartDate: Date.now(),
    gmvEndDate: Date.now(),

    payoutTransactions: [],
    payoutTransactionsExtend: [],
    ptStartDate: Date.now(),
    ptEndDate: Date.now(),

    outletPayoutTransactions: [],
    outletPayoutTransactionsExtend: [],
    outletOrdersRecent: [],
    aovStartDate: Date.now(),
    aovEndDate: Date.now(),

    gmvOrdersRecent: [],

    // outletFilterDict: {},

    ///////////////////////////////////////////////

    // razer crawled data 

    settlementTransactions: [],
    massPayoutTransactions: [],

    accumulator: {},

    ///////////////////////////////////////////////

    cmpOutletPayoutTransactions: [],
    cmpOutletPayoutTransactionsExtend: [],
    cmpOutletOrdersRecent: [],
    cmpStartDate: Date.now(),
    cmpEndDate: Date.now(),

    ///////////////////////////////////////////////

    trackingOutletTransactions: [],
    qrStartDate: Date.now(),
    qrEndDate: Date.now(),

    trackingQRTransactions: [],

    ///////////////////////////////////////////////

    rptDailySummaryList: [],
    rptDsStartDate: Date.now(),
    rptDsEndDate: Date.now(),

    ///////////////////////////////////////////////
});