import BigNumber from 'bignumber.js';
import moment from 'moment';
import {
    CHART_COLORS,
    CHART_DATA,
    CHART_FIELD_COMPARE_DICT,
    CHART_FIELD_TYPE,
    CHART_PERIOD,
    CHART_TYPE,
    CHART_X_AXIS_TYPE,
    MONTH_TO_CHART_LABEL,
} from '../constant/chart';
import { countWeekdayOccurrencesInMonth, filterByPaymentType, filterByPaymentTypeAndTabOutletId, getAddOnChoicePrice, randomColor, randomColorV2, weekOfMonth } from './common';
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import { ORDER_TYPE_DETAILS, PAYMENT_CHANNEL_NAME, REPORT_DISPLAY_TYPE } from '../constant/common';

export const getDataForChartDashboardTodaySales = (
    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    startDate = new Date(),
    endDate = new Date(),
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        // const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
        //     return moment().isSame(order.completedDate, 'day');
        // });

        for (var i = 0; i < allOutlets.length; i++) {
            var record = {
                label: allOutlets[i].name,
                value: 0,
            };

            if (chartPeriod === CHART_PERIOD.TODAY) {
                const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(
                    (order) => {
                        return moment().isSame(moment(order.completedDate), 'day');
                    },
                );

                for (var x = 0; x < 24; x++) {
                    const currDay = moment().startOf('day').add(x, 'hour');

                    for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                        if (
                            allOutletsUserOrdersDoneToday[j].outletId ===
                            allOutlets[i].uniqueId &&
                            moment(currDay).isSame(
                                moment(allOutletsUserOrdersDoneToday[j].completedDate),
                                'hour',
                            )
                        ) {
                            record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                            // console.log('Got Loop Here1');
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            } else if (chartPeriod === CHART_PERIOD.THIS_WEEK) {
                const startDate = moment().subtract(7, 'day').startOf('day');
                const endDate = moment().endOf('day');

                const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(
                    (order) => {
                        return (
                            moment(startDate).isSameOrBefore(order.completedDate) &&
                            moment(endDate).isAfter(order.completedDate)
                        );
                    },
                );

                for (var x = 0; x < moment(endDate).diff(startDate, 'day'); x++) {
                    const currDay = moment(startDate).add(x, 'day');

                    for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                        if (
                            allOutletsUserOrdersDoneToday[j].outletId ===
                            allOutlets[i].uniqueId &&
                            moment(currDay).isSame(
                                moment(allOutletsUserOrdersDoneToday[j].completedDate),
                                'day',
                            )
                        ) {
                            record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                            // console.log('Got Loop Here1');
                        }
                    }
                }

                // yMaxValue += record.value;
                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            } else if (chartPeriod === CHART_PERIOD.THIS_MONTH) {
                // const startDate = moment().subtract(moment(startDate).daysInMonth(), 'day').startOf('day');
                // const endDate = moment().endOf('day');

                const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(
                    (order) => {
                        return (
                            moment(startDate).isSameOrBefore(order.completedDate) &&
                            moment(endDate).isAfter(order.completedDate)
                        );
                    },
                );

                for (var x = 0; x < moment(startDate).daysInMonth(); x++) {
                    const currDay = moment(startDate).add(x, 'day');

                    for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                        if (
                            allOutletsUserOrdersDoneToday[j].outletId ===
                            allOutlets[i].uniqueId &&
                            moment(currDay).isSame(
                                moment(allOutletsUserOrdersDoneToday[j].completedDate),
                                'day',
                            )
                        ) {
                            record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                            // console.log('Got Loop Here1');
                        }
                    }
                }

                // yMaxValue += record.value;
                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            } else if (chartPeriod === CHART_PERIOD.THREE_MONTHS) {
                const startDate = moment().subtract(2, 'month').startOf('month');
                const endDate = moment().endOf('month');

                const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(
                    (order) => {
                        return (
                            moment(startDate).isSameOrBefore(order.completedDate) &&
                            moment(endDate).isAfter(order.completedDate)
                        );
                    },
                );

                var currWeekCount = 0;
                var prevMonth = moment(startDate);

                for (var x = 0; x < moment(endDate).diff(startDate, 'week'); x++) {
                    const currWeek = moment(startDate).add(x, 'week');

                    if (moment(currWeek).isSame(prevMonth, 'month')) {
                        currWeekCount++;
                    } else {
                        prevMonth = moment(currWeek);

                        currWeekCount = 0;
                        currWeekCount++;
                    }

                    for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                        if (
                            allOutletsUserOrdersDoneToday[j].outletId ===
                            allOutlets[i].uniqueId &&
                            moment(currWeek).isSame(
                                moment(allOutletsUserOrdersDoneToday[j].completedDate),
                                'week',
                            )
                        ) {
                            record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                            // console.log('Got Loop Here1');
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            } else if (chartPeriod === CHART_PERIOD.SIX_MONTHS) {
                const startDate = moment().subtract(5, 'month').startOf('month');
                const endDate = moment().endOf('month');

                const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(
                    (order) => {
                        return (
                            moment(startDate).isSameOrBefore(order.completedDate) &&
                            moment(endDate).isAfter(order.completedDate)
                        );
                    },
                );

                var currWeekCount = 0;
                var prevMonth = moment(startDate);

                for (var x = 0; x < moment(endDate).diff(startDate, 'week'); x++) {
                    const currWeek = moment(startDate).add(x, 'week');

                    if (moment(currWeek).isSame(prevMonth, 'month')) {
                        currWeekCount++;
                    } else {
                        prevMonth = moment(currWeek);

                        currWeekCount = 0;
                        currWeekCount++;
                    }

                    for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                        if (
                            allOutletsUserOrdersDoneToday[j].outletId ===
                            allOutlets[i].uniqueId &&
                            moment(currWeek).isSame(
                                moment(allOutletsUserOrdersDoneToday[j].completedDate),
                                'week',
                            )
                        ) {
                            record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                            // console.log('Got Loop Here1');
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            } else if (chartPeriod === CHART_PERIOD.THIS_YEAR) {
                const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(
                    (order) => {
                        return moment().isSame(moment(order.completedDate), 'year');
                    },
                );

                for (var x = 0; x < 12; x++) {
                    const currMonth = moment().startOf('year').add(x, 'month');

                    for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                        if (
                            allOutletsUserOrdersDoneToday[j].outletId ===
                            allOutlets[i].uniqueId &&
                            moment(currMonth).isSame(
                                moment(allOutletsUserOrdersDoneToday[j].completedDate),
                                'month',
                            )
                        ) {
                            record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                            // console.log('Got Loop Here1');
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            } else if (chartPeriod === CHART_PERIOD.YTD) {
                const startDate = moment().subtract(11, 'month').startOf('month');
                const endDate = moment().endOf('month');

                const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(
                    (order) => {
                        return (
                            moment(startDate).isSameOrBefore(order.completedDate) &&
                            moment(endDate).isAfter(order.completedDate)
                        );
                    },
                );

                for (var x = 0; x < moment(endDate).diff(startDate, 'month'); x++) {
                    const currMonth = moment(startDate).add(x, 'month');

                    for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                        if (
                            allOutletsUserOrdersDoneToday[j].outletId ===
                            allOutlets[i].uniqueId &&
                            moment(currMonth).isSame(
                                moment(allOutletsUserOrdersDoneToday[j].completedDate),
                                'month',
                            )
                        ) {
                            record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                            // console.log('Got Loop Here1');
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            } else if (chartPeriod === CHART_PERIOD.NONE) {
                const allOutletsUserOrdersDoneTodayRange =
                    allOutletsUserOrdersDone.filter((order) => {
                        return (
                            moment(startDate).isSameOrBefore(order.completedDate) &&
                            moment(endDate).isAfter(order.completedDate)
                        );
                    });

                if (
                    moment(endDate).diff(startDate, 'day') <=
                    moment(startDate).daysInMonth()
                ) {
                    endDate = moment(endDate).add(1, 'day');

                    const diffDays = moment(endDate).diff(startDate, 'day');

                    var prevDate = moment(startDate).add(-1, 'month');

                    for (var k = 0; k < diffDays; k++) {
                        const currDate = moment(startDate).add(k, 'day');

                        // category.push({
                        //     //'label': moment(currDate).format('DD MMM'),
                        //     'label': !moment(currDate).isSame(prevDate, 'month') ? moment(currDate).format('DD MMM') : moment(currDate).format('DD')
                        //     //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))

                        // });

                        prevDate = currDate;

                        for (
                            var j = 0;
                            j < allOutletsUserOrdersDoneTodayRange.length;
                            j++
                        ) {
                            if (
                                allOutletsUserOrdersDoneTodayRange[j].outletId ===
                                allOutlets[i].uniqueId &&
                                moment(currDate).isSame(
                                    moment(allOutletsUserOrdersDoneTodayRange[j].completedDate),
                                    'day',
                                )
                            ) {
                                record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                            }
                        }
                    }

                    if (record.value > yMaxValue) {
                        yMaxValue = record.value;
                    }

                    record.value = record.value.toFixed(0);

                    parsedData.push(record);
                } else if (moment(endDate).diff(startDate, 'month') <= 6) {
                    var prevMonth = moment(startDate);

                    const diffWeeks = moment(endDate).diff(startDate, 'week');

                    for (var k = 0; k < diffWeeks; k++) {
                        const currDate = moment(startDate).add(k, 'week');

                        if (moment(currDate).isSame(prevMonth, 'month')) {
                            currWeekCount++;
                        } else {
                            prevMonth = moment(currDate);

                            currWeekCount = 0;
                            currWeekCount++;
                        }

                        // category.push({
                        //     // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                        //     label: moment(currDate).format('MMM') + ' W' + countWeekdayOccurrencesInMonth(currDate),
                        // });

                        for (
                            var j = 0;
                            j < allOutletsUserOrdersDoneTodayRange.length;
                            j++
                        ) {
                            if (
                                allOutletsUserOrdersDoneTodayRange[j].outletId ===
                                allOutlets[i].uniqueId &&
                                moment(currDate).isSame(
                                    moment(allOutletsUserOrdersDoneTodayRange[j].completedDate),
                                    'week',
                                )
                            ) {
                                record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                            }
                        }
                    }

                    if (record.value > yMaxValue) {
                        yMaxValue = record.value;
                    }

                    record.value = record.value.toFixed(0);

                    parsedData.push(record);
                }
                // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
                else {
                    const diffYears = moment(endDate).diff(startDate, 'month');

                    for (var k = 0; k < diffYears; k++) {
                        const currDate = moment(startDate).add(k, 'month');

                        // category.push({
                        //     label: moment(currDate).format('YY\' MMM'),
                        // });

                        for (
                            var j = 0;
                            j < allOutletsUserOrdersDoneTodayRange.length;
                            j++
                        ) {
                            if (
                                allOutletsUserOrdersDoneTodayRange[j].outletId ===
                                allOutlets[i].uniqueId &&
                                moment(currDate).isSame(
                                    moment(allOutletsUserOrdersDoneTodayRange[j].completedDate),
                                    'month',
                                )
                            ) {
                                record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                            }
                        }
                    }

                    if (record.value > yMaxValue) {
                        yMaxValue = record.value;
                    }

                    record.value = record.value.toFixed(0);

                    parsedData.push(record);
                }
            }

            // else if (chartPeriod === CHART_PERIOD.DEFAULT ) {
            //     for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
            //         if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId) {
            //             record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];

            //         }
            //     }

            //     if (record.value > yMaxValue) {
            //         yMaxValue = record.value;
            //     }

            //     record.value = record.value.toFixed(0);

            //     parsedData.push(record);
            // }

            // else {
            //     for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
            //         if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId) {
            //             record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
            //         }
            //     }

            //     if (record.value > yMaxValue) {
            //         yMaxValue = record.value;
            //     }

            //     record.value = record.value.toFixed(0);

            //     parsedData.push(record);
            // }
        }

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].chart,

            data: parsedData,
        };

        // console.log('parsedData');
        // console.log(parsedData);
        // console.log(yMaxValue);

        if (output.type !== 'HLinearGauge') {
            // const yAxisStep = Math.pow(10, Math.min(1, (yMaxValue.toFixed(0).length - 1)));

            // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

            // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
            // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
            // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

            const baseNumberLength = yMaxValue.toFixed(0).length - 1;
            const baseNumber = parseInt(
                '1' +
                Array(baseNumberLength)
                    .fill()
                    .map((num) => 0)
                    .join(''),
            );
            var maxNumber = baseNumber;

            if (yMaxValue > baseNumber * 9) {
                maxNumber = baseNumber * 10;
            } else if (yMaxValue > baseNumber * 8) {
                maxNumber = baseNumber * 9;
            } else if (yMaxValue > baseNumber * 7) {
                maxNumber = baseNumber * 8;
            } else if (yMaxValue > baseNumber * 6) {
                maxNumber = baseNumber * 7;
            } else if (yMaxValue > baseNumber * 5) {
                maxNumber = baseNumber * 6;
            } else if (yMaxValue > baseNumber * 3) {
                maxNumber = baseNumber * 5;
            } else if (yMaxValue > baseNumber * 2) {
                maxNumber = baseNumber * 3;
                // maxNumber = baseNumber * 5;

                // maxNumber = baseNumber * 2;
            } else {
                maxNumber = baseNumber * 2;
                // maxNumber = baseNumber * 5;

                // maxNumber = baseNumber * 2;
            }

            maxNumber = getSuitableYAxisMaxValue(maxNumber);

            maxNumber = Math.max(maxNumber, 10);

            output.dataSource.chart = {
                ...output.dataSource.chart,
                labelDisplay: 'WRAP',
                // labelStep: 2,
                bgColor: '#ffffff',
                canvasBgColor: '#ffffff',
                bgAlpha: 100,

                // showYAxisValues: 0,

                // yaxismaxvalue: yAxisMaxValue * yAxisStep,
                yaxismaxvalue: maxNumber,
                yaxisminvalue: yMinValue,
                adjustDiv: 0,
                // numDivLines: numDivLines,
                numDivLines: 9,

                // yaxisminvalue: 0,
                formatNumberScale: 0,
                decimals: 0,
            };

            // // console.log('/////////////////////////////////////////////////')

            // // console.log({
            //     yMaxValue: yMaxValue,
            //     yMaxValueParsed: yMaxValueParsed,
            //     yaxismaxvalue: yAxisMaxValue * yAxisStep,
            //     yaxisminvalue: yMinValue,
            //     adjustDiv: 0,
            //     numDivLines: numDivLines,
            // });

            // // console.log('/////////////////////////////////////////////////')
        }

        // if (output.type === 'column2D') {
        //     if (type === CHART_TYPE.S_MILK) {
        //         // for (let i = 0; i < output.dataSource.data.length; i++) {
        //         //     if (output.dataSource.data[i].value.length > 0) {
        //         //         yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;

        //         //     }
        //         // }

        //         // const yMaxValueParsed = Math.ceil(yMaxValue / 5);
        //         // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        //         // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        //         // output.dataSource.chart = {
        //         //     ...output.dataSource.chart,
        //         //     adjustDiv: 0,
        //         //     numDivLines: numDivLines,
        //         //     yaxismaxvalue: yAxisMaxValue * 5,
        //         //     yaxisminvalue: yMinValue,
        //         //     formatNumberScale: 0,
        //         // };
        //     }
        //     else if (type === CHART_TYPE.S_SHIT_PEE) {
        //         for (let i = 0; i < output.dataSource.data.length; i++) {
        //             if (output.dataSource.data[i].value.length > 0) {
        //                 yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;
        //             }
        //         }

        //         output.dataSource.chart = {
        //             ...output.dataSource.chart,
        //             adjustDiv: 0,
        //             numDivLines: yMaxValue,
        //             yaxismaxvalue: yMaxValue + 1,
        //             yaxisminvalue: yMinValue,
        //             formatNumberScale: 0,
        //         };
        //     }
        // }

        // console.log('chart output');
        // console.log(output);

        return output;
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};

///////////data for crmChart///////////////////
// export const getDataForCrmBarChart = (allOutlets, allOutletsUserOrdersDone, chartPeriod, yAxisKey) => {

//     var parsedData = [];

//     var yMinValue = 0;
//     var yMaxValue = 0;

//     if (chartPeriod === CHART_PERIOD.TODAY) {
//         const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
//             return moment().isSame(moment(order.completedDate), 'day');
//         });

//         for (var i = 0; i < 24; i++) {
//             const currHour = moment().startOf('day').add(i, 'hour');

//             var record = {
//                 label: i.toString().padStart(2, '0'),
//                 value: 0,
//             };

//             for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
//                 if (moment(currHour).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'hour')) {
//                     record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
//                 }
//             }

//             if (record.value > yMaxValue) {
//                 yMaxValue = record.value;
//             }

//             record.value = record.value.toFixed(0);

//             parsedData.push(record);
//         }

// }
// }

export const getDataForSalesLineChart = (
    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    selectedPaymentType,

    liveGmvData,

    selectedTabOutletId,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');
        // endDate = moment(endDate).endOf('day');

        // console.log('data before chart');

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        var outletNameDict = {};
        // for (var i = 0; i < allOutlets.length; i++) {
        //   outletNameDict['koodooPayoutActual'] = allOutlets[i].name;
        // }

        outletNameDict['koodooPayoutActual'] = 'KooDoo Profit (Actual)';
        outletNameDict['koodooPayoutExpected'] = 'KooDoo Profit (Expected)';

        // var annotations = {
        //     "groups": [{
        //         "items": [{
        //             //Text annotation 1
        //             "type": "text",
        //             "text": 'Hello',
        //             //Define the attributes needed to create a text annotation
        //         }, {
        //             //Text annotation n
        //             "type": "text",
        //             "text": 'Hi',
        //             //Define the attributes needed to create a text annotation
        //         }]
        //     }]
        // };

        var annotations = {
            groups: [
                {
                    items: [],
                },
            ],
        };
        var annotationsItems = [];

        if (chartPeriod === CHART_PERIOD.NONE) {
            // const razerPayoutTransactionsRange =
            //     razerPayoutTransactions.filter((transaction) => {
            //         return (
            //             moment(startDate).isSameOrBefore(transaction.transactionDate) &&
            //             moment(endDate).isAfter(transaction.transactionDate)
            //         );
            //     });

            let razerPayoutTransactionsRange = [];
            for (var i = 0; i < razerPayoutTransactions.length; i++) {
                razerPayoutTransactionsRange = razerPayoutTransactionsRange.concat(razerPayoutTransactions[i].razerPayoutTransactionList);
            }

            razerPayoutTransactionsRange = razerPayoutTransactionsRange.concat(liveGmvData.calculatedGmvUserOrdersFigures);

            // razerPayoutTransactions = filterByPaymentType(razerPayoutTransactionsRange, selectedPaymentType);
            // razerPayoutTransactions = filterByPaymentTypeAndTabOutletId(razerPayoutTransactionsRange, selectedPaymentType, selectedTabOutletId);

            if (selectedTabOutletId !== 'ALL') {
                razerPayoutTransactionsRange = razerPayoutTransactionsRange.filter(summary => {
                    if (summary.outletId === selectedTabOutletId) {
                        return true;
                    }
                    else {
                        return false;
                    }
                });
            }

            // if (selectedTabOutletId !== 'ALL') {
            //     razerPayoutTransactions = razerPayoutTransactions.filter(summary => {
            //         if (summary.outletId === selectedTabOutletId) {
            //             return true;
            //         }
            //         else {
            //             return false;
            //         }
            //     });
            // }

            // if (
            //     moment().isSame(endDate, 'day') &&
            //     moment().isSame(startDate, 'day')
            // ) {
            //     // endDate = moment(endDate).add(1, 'day');

            //     const diffHours = moment(endDate).diff(startDate, 'hour');

            //     var prevDate = moment(startDate).add(-1, 'hour');

            //     datasetDict['koodooPayoutActual'] = {
            //         seriesname: 'GMV Commission (Payable)',
            //         data: Array.from(Array(24)).map(() => {
            //             return {
            //                 value: 0,
            //                 // id: uuidv4(),
            //             };
            //         }),
            //     };

            //     for (var i = 0; i < 24; i++) {
            //         const currDate = moment(startDate).add(i, 'hour');

            //         datasetDict['koodooPayoutActual'].data[
            //             i
            //         ].id = moment(currDate).valueOf();

            //         category.push({
            //             label: i.toString().padStart(2, '0'),

            //             //'label': moment(currDate).format('DD MMM'),

            //             // label: !moment(currDate).isSame(prevDate, 'month')
            //             //     ? moment(currDate).format('DD MMM')
            //             //     : moment(currDate).format('DD'),

            //             //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
            //         });

            //         prevDate = currDate;

            //         for (var j = 0; j < razerPayoutTransactionsRange.length; j++) {
            //             if (
            //                 // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
            //                 moment(currDate).isSame(
            //                     moment(razerPayoutTransactionsRange[j].transactionDate),
            //                     'hour',
            //                 )
            //             ) {
            //                 datasetDict['koodooPayoutActual'].data[
            //                     i
            //                 ].value += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();

            //                 // datasetDict['koodooPayoutActual'].data[
            //                 //     i
            //                 // ].customValue += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();

            //                 // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
            //             }
            //         }

            //         // if (record.value > yMaxValue) {
            //         //     yMaxValue = record.value;
            //         // }

            //         // record.value = record.value.toFixed(0);

            //         // parsedData.push(record);
            //     }
            // }

            // if (
            //     moment().isSame(endDate, 'day') &&
            //     moment().isSame(startDate, 'day')
            // ) {
            //     const diffHours = moment(endDate).diff(startDate, 'hour');

            //     var prevDate = moment(startDate).add(-1, 'hour');

            //     datasetDict['koodooPayoutActual'] = {
            //         seriesname: 'KooDoo Profit (Actual)',
            //         data: Array.from(Array(diffHours)).map(() => {
            //             return {
            //                 value: 0,
            //                 // id: uuidv4(),
            //                 toolText: `RM 0.00`,
            //             };
            //         }),
            //     };

            //     for (var i = 0; i < diffHours; i++) {
            //         const currDate = moment(startDate).add(i, 'hour');

            //         if (moment().isSame(currDate, 'hour')) {
            //             datasetDict['koodooPayoutActual'].data[
            //                 i
            //             ].value += BigNumber(liveGmvData.calculatedGmvActualToday).toNumber();

            //             datasetDict['koodooPayoutActual'].data[
            //                 i
            //             ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
            //                 i
            //             ].value.toFixed(2)}`;
            //         }

            //         datasetDict['koodooPayoutActual'].data[
            //             i
            //         ].id = moment(currDate).valueOf();

            //         category.push({
            //             //'label': moment(currDate).format('DD MMM'),
            //             label: !moment(currDate).isSame(prevDate, 'month')
            //                 ? moment(currDate).format('DD MMM')
            //                 : moment(currDate).format('DD'),
            //             //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
            //         });

            //         prevDate = currDate;

            //         for (var j = 0; j < razerPayoutTransactionsRange.length; j++) {
            //             if (
            //                 // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
            //                 moment(currDate).isSame(
            //                     moment(razerPayoutTransactionsRange[j].transactionDate),
            //                     'day',
            //                 )
            //             ) {
            //                 datasetDict['koodooPayoutActual'].data[
            //                     i
            //                 ].value += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();
            //                 datasetDict['koodooPayoutActual'].data[
            //                     i
            //                 ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
            //                     i
            //                 ].value.toFixed(2)}`;

            //                 // datasetDict['koodooPayoutActual'].data[
            //                 //     i
            //                 // ].customValue += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();

            //                 // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
            //             }
            //         }

            //         // if (record.value > yMaxValue) {
            //         //     yMaxValue = record.value;
            //         // }

            //         // record.value = record.value.toFixed(0);

            //         // parsedData.push(record);
            //     }
            // }
            // else 

            if (
                moment(endDate).diff(startDate, 'day') <=
                moment(startDate).daysInMonth()
            ) {
                endDate = moment(endDate).add(1, 'day');

                const diffDays = moment(endDate).diff(startDate, 'day');

                var prevDate = moment(startDate).add(-1, 'month');

                datasetDict['koodooPayoutActual'] = {
                    seriesname: 'KooDoo Profit (Actual)',
                    data: Array.from(Array(diffDays)).map(() => {
                        return {
                            value: 0,
                            // id: uuidv4(),
                            toolText: `RM 0.00`,
                        };
                    }),
                };

                for (var i = 0; i < diffDays; i++) {
                    const currDate = moment(startDate).add(i, 'day');

                    if (moment().isSame(currDate, 'day')) {
                        datasetDict['koodooPayoutActual'].data[
                            i
                        ].value += BigNumber(liveGmvData.calculatedGmvActualToday).toNumber();

                        datasetDict['koodooPayoutActual'].data[
                            i
                        ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
                            i
                        ].value.toFixed(2)}`;
                    }

                    datasetDict['koodooPayoutActual'].data[
                        i
                    ].id = moment(currDate).valueOf();

                    category.push({
                        //'label': moment(currDate).format('DD MMM'),
                        label: !moment(currDate).isSame(prevDate, 'month')
                            ? moment(currDate).format('DD MMM')
                            : moment(currDate).format('DD'),
                        //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                    });

                    prevDate = currDate;

                    for (var j = 0; j < razerPayoutTransactionsRange.length; j++) {
                        if (
                            // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                            moment(currDate).isSame(
                                moment(razerPayoutTransactionsRange[j].transactionDate),
                                'day',
                            )
                        ) {
                            // datasetDict['koodooPayoutActual'].data[
                            //     i
                            // ].value += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual ? razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual : razerPayoutTransactionsRange[j].koodooProcessingFee).toNumber();
                            datasetDict['koodooPayoutActual'].data[
                                i
                            ].value += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();
                            datasetDict['koodooPayoutActual'].data[
                                i
                            ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
                                i
                            ].value.toFixed(2)}`;

                            // datasetDict['koodooPayoutActual'].data[
                            //     i
                            // ].customValue += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();

                            // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        }
                    }

                    // if (record.value > yMaxValue) {
                    //     yMaxValue = record.value;
                    // }

                    // record.value = record.value.toFixed(0);

                    // parsedData.push(record);
                }
            } else if (moment(endDate).diff(startDate, 'month') <= 6) {
                var currWeekCount = 0;
                var prevMonth = moment(startDate);

                const diffWeeks = moment(endDate).diff(startDate, 'week');

                datasetDict['koodooPayoutActual'] = {
                    seriesname: 'KooDoo Profit (Actual)',
                    data: Array.from(Array(diffWeeks)).map(() => {
                        return {
                            value: 0,
                            // id: uuidv4(),
                            toolText: `RM 0.00`,
                        };
                    }),
                };

                for (var i = 0; i < diffWeeks; i++) {
                    const currDate = moment(startDate).add(i, 'week');

                    if (moment().isSame(currDate, 'day')) {
                        datasetDict['koodooPayoutActual'].data[
                            i
                        ].value += BigNumber(liveGmvData.calculatedGmvActualToday).toNumber();

                        datasetDict['koodooPayoutActual'].data[
                            i
                        ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
                            i
                        ].value.toFixed(2)}`;
                    }

                    datasetDict['koodooPayoutActual'].data[
                        i
                    ].id = moment(currDate).valueOf();

                    if (moment(currDate).isSame(prevMonth, 'month')) {
                        currWeekCount++;
                    } else {
                        prevMonth = moment(currDate);

                        currWeekCount = 0;
                        currWeekCount++;
                    }

                    category.push({
                        // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                        label:
                            moment(currDate).format('MMM') +
                            ' W' +
                            countWeekdayOccurrencesInMonth(currDate),
                    });

                    for (var j = 0; j < razerPayoutTransactionsRange.length; j++) {
                        if (
                            // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                            moment(currDate).isSame(
                                moment(razerPayoutTransactionsRange[j].transactionDate),
                                'week',
                            )
                        ) {
                            // datasetDict['koodooPayoutActual'].data[
                            //     i
                            // ].value += razerPayoutTansactionsRange[j].outletCycleKoodooPayoutsActual ? razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual : razerPayoutTransactionsRange[j].koodooProcessingFee;
                            datasetDict['koodooPayoutActual'].data[
                                i
                            ].value += razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual;
                            datasetDict['koodooPayoutActual'].data[
                                i
                            ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
                                i
                            ].value.toFixed(2)}`;

                            // datasetDict['koodooPayoutActual'].data[
                            //     i
                            // ].customValue += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();

                            // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        }
                    }

                    // if (record.value > yMaxValue) {
                    //     yMaxValue = record.value;
                    // }

                    // record.value = record.value.toFixed(0);

                    // parsedData.push(record);
                }
            }
            // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
            else {
                const diffYears = moment(endDate).diff(startDate, 'month');

                datasetDict['koodooPayoutActual'] = {
                    seriesname: 'KooDoo Profit (Actual)',
                    data: Array.from(Array(diffYears)).map(() => {
                        return {
                            value: 0,
                            // id: uuidv4(),
                            toolText: `RM 0.00`,
                        };
                    }),
                };

                for (var i = 0; i < diffYears; i++) {
                    const currDate = moment(startDate).add(i, 'month');

                    if (moment().isSame(currDate, 'day')) {
                        datasetDict['koodooPayoutActual'].data[
                            i
                        ].value += BigNumber(liveGmvData.calculatedGmvActualToday).toNumber();

                        datasetDict['koodooPayoutActual'].data[
                            i
                        ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
                            i
                        ].value.toFixed(2)}`;
                    }

                    datasetDict['koodooPayoutActual'].data[
                        i
                    ].id = moment(currDate).valueOf();

                    category.push({
                        label: moment(currDate).format("YY' MMM"),
                    });

                    for (var j = 0; j < razerPayoutTransactionsRange.length; j++) {
                        if (
                            // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                            moment(currDate).isSame(
                                moment(razerPayoutTransactionsRange[j].razerPayoutTransactionsRange),
                                'month',
                            )
                        ) {
                            // datasetDict['koodooPayoutActual'].data[
                            //     i
                            // ].value += razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual ? razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual : razerPayoutTransactionsRange[j].koodooProcessingFee;
                            datasetDict['koodooPayoutActual'].data[
                                i
                            ].value += razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual;
                            datasetDict['koodooPayoutActual'].data[
                                i
                            ].toolText = `RM ${datasetDict['koodooPayoutActual'].data[
                                i
                            ].value.toFixed(2)}`;

                            // datasetDict['koodooPayoutActual'].data[
                            //     i
                            // ].customValue += BigNumber(razerPayoutTransactionsRange[j].outletCycleKoodooPayoutsActual).toNumber();

                            // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        }
                    }

                    // if (record.value > yMaxValue) {
                    //     yMaxValue = record.value;
                    // }

                    // record.value = record.value.toFixed(0);

                    // parsedData.push(record);
                }
            }
        }

        // const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
        //     return moment().isSame(moment(order.completedDate), 'year');
        // });

        // for (var i = 0; i < 12; i++) {
        //     var record = {
        //         label: MONTH_TO_CHART_LABEL[i],
        //         value: 0,
        //     };

        //     const currMonth = moment().startOf('year').add(i, 'month');

        //     for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
        //         // if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId) {
        //         //     record.value += allOutletsUserOrdersDoneToday[j].finalPrice;

        //         //     // if (allOutletsUserOrdersDoneToday[j].finalPrice > yMaxValue) {
        //         //     //     yMaxValue += allOutletsUserOrdersDoneToday[j].finalPrice;
        //         //     // }
        //         // }

        //         if (moment(currMonth).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'month')) {
        //             record.value += allOutletsUserOrdersDoneToday[j].finalPrice;
        //         }
        //     }

        //     // yMaxValue += record.value;
        //     if (record.value > yMaxValue) {
        //         yMaxValue = record.value;
        //     }

        //     record.value = record.value.toFixed(0);

        //     parsedData.push(record);
        // }

        var currTotal = 0;

        for (var k = 0; k < datasetDict['koodooPayoutActual'].data.length; k++) {
            // currTotal += datasetDict[outletId].data[k].value;

            currTotal = datasetDict['koodooPayoutActual'].data[k].value;

            if (currTotal > yMaxValue) {
                yMaxValue = currTotal;
            }
        }

        var chartLegend = [];

        const dataset = Object.entries(datasetDict).map(([key, value], index) => {
            const mappedIndex =
                index % CHART_COLORS[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].length; // might got more than 10 preset colors, set to infinite list instead

            chartLegend.push({
                itemName: value.seriesname,
                itemSku: value.seriesname,
                chartColor:
                    CHART_COLORS[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][mappedIndex],
            });

            return {
                ...value,
                color: CHART_COLORS[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][mappedIndex],
            };
        });

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].type;
        output.dataFormat =
            CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,

            annotations: annotations,

            // data: parsedData,
        };

        // // console.log('parsedData');
        // // console.log(parsedData);
        // console.log(yMaxValue);

        if (output.type !== 'HLinearGauge') {
            // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

            // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
            // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
            // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

            const baseNumberLength = yMaxValue.toFixed(0).length - 1;
            const baseNumber = parseInt(
                '1' +
                Array(baseNumberLength)
                    .fill()
                    .map((num) => 0)
                    .join(''),
            );
            var maxNumber = baseNumber;

            if (yMaxValue > baseNumber * 9) {
                maxNumber = baseNumber * 10;
            } else if (yMaxValue > baseNumber * 8) {
                maxNumber = baseNumber * 9;
            } else if (yMaxValue > baseNumber * 7) {
                maxNumber = baseNumber * 8;
            } else if (yMaxValue > baseNumber * 6) {
                maxNumber = baseNumber * 7;
            } else if (yMaxValue > baseNumber * 5) {
                maxNumber = baseNumber * 6;
            } else if (yMaxValue > baseNumber * 3) {
                maxNumber = baseNumber * 5;
            } else if (yMaxValue > baseNumber * 2) {
                maxNumber = baseNumber * 3;
                // maxNumber = baseNumber * 5;

                // maxNumber = baseNumber * 2;
            } else {
                maxNumber = baseNumber * 2;
                // maxNumber = baseNumber * 5;

                // maxNumber = baseNumber * 2;
            }

            maxNumber = getSuitableYAxisMaxValue(maxNumber);

            maxNumber = Math.max(maxNumber, 10);

            output.dataSource.chart = {
                ...output.dataSource.chart,
                labelDisplay: 'WRAP',
                // labelDisplay: 'STAGGER',
                // labelStep: 2,
                bgColor: '#ffffff',
                canvasBgColor: '#ffffff',
                bgAlpha: 100,

                useEllipsesWhenOverflow: 1,

                // showYAxisValues: 0,
                // yaxismaxvalue: yAxisMaxValue * yAxisStep,
                yaxismaxvalue: maxNumber,
                yaxisminvalue: yMinValue,
                adjustDiv: 0,
                // numDivLines: numDivLines,
                numDivLines: 9,

                // yaxisminvalue: 0,
                formatNumberScale: 0,
                decimals: 0,
            };

            // // console.log('/////////////////////////////////////////////////')

            // // console.log({
            //     yMaxValue: yMaxValue,
            //     yMaxValueParsed: yMaxValueParsed,
            //     yaxismaxvalue: yAxisMaxValue * yAxisStep,
            //     yaxisminvalue: yMinValue,
            //     adjustDiv: 0,
            //     numDivLines: numDivLines,
            // });

            // // console.log('/////////////////////////////////////////////////')

            return {
                chartData: output,
                chartLegend: chartLegend,
            };
        }

        // if (output.type === 'column2D') {
        //     if (type === CHART_TYPE.S_MILK) {
        //         // for (let i = 0; i < output.dataSource.data.length; i++) {
        //         //     if (output.dataSource.data[i].value.length > 0) {
        //         //         yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;

        //         //     }
        //         // }

        //         // const yMaxValueParsed = Math.ceil(yMaxValue / 5);
        //         // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        //         // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        //         // output.dataSource.chart = {
        //         //     ...output.dataSource.chart,
        //         //     adjustDiv: 0,
        //         //     numDivLines: numDivLines,
        //         //     yaxismaxvalue: yAxisMaxValue * 5,
        //         //     yaxisminvalue: yMinValue,
        //         //     formatNumberScale: 0,
        //         // };
        //     }
        //     else if (type === CHART_TYPE.S_SHIT_PEE) {
        //         for (let i = 0; i < output.dataSource.data.length; i++) {
        //             if (output.dataSource.data[i].value.length > 0) {
        //                 yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;
        //             }
        //         }

        //         output.dataSource.chart = {
        //             ...output.dataSource.chart,
        //             adjustDiv: 0,
        //             numDivLines: yMaxValue,
        //             yaxismaxvalue: yMaxValue + 1,
        //             yaxisminvalue: yMinValue,
        //             formatNumberScale: 0,
        //         };
        //     }
        // }

        // console.log('sales line chart output');
        // console.log(output);

        return output;
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};

export const getDataForChartReportProductSales = (
    // productSalesTemp,
    // outletItems,
    // allOutlets,
    // startDate,
    // endDate,
    // yAxisKey,
    // xAxisKey,

    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    selectedPaymentType,

    selectedTabOutletId,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        var stackRowList = [
            {
                label: 'POS',
                value: ORDER_TYPE_DETAILS.POS,
            },
            {
                label: 'QR Offline',
                value: ORDER_TYPE_DETAILS.QR_OFFLINE,
            },
            {
                label: 'QR Online',
                value: ORDER_TYPE_DETAILS.QR_ONLINE,
            },
        ];

        let orderDataList = [];
        for (var i = 0; i < razerPayoutTransactions.length; i++) {
            orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList);
        }

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);
        orderDataList = filterByPaymentTypeAndTabOutletId(orderDataList, selectedPaymentType, selectedTabOutletId);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            moment().isSame(endDate, 'day') &&
            moment().isSame(startDate, 'day')
        ) {
            // endDate = moment(endDate).add(1, 'day');

            const diffHours = moment(endDate).diff(startDate, 'hour');

            var prevDate = moment(startDate).add(-1, 'hour');

            for (var i = 0; i < 24; i++) {
                const currDate = moment(startDate).add(i, 'hour');

                category.push({
                    label: i.toString().padStart(2, '0'),

                    //'label': moment(currDate).format('DD MMM'),

                    // label: !moment(currDate).isSame(prevDate, 'month')
                    //     ? moment(currDate).format('DD MMM')
                    //     : moment(currDate).format('DD'),

                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(24)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'hour',
                            ) &&
                            orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        else if (
            moment(endDate).diff(startDate, 'day') <=
            moment(startDate).daysInMonth()
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffDays)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'day',
                            ) &&
                            orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|day`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        } else if (moment(endDate).diff(startDate, 'month') <= 6) {
            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                } else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label:
                        moment(currDate).format('MMM') +
                        ' W' +
                        countWeekdayOccurrencesInMonth(currDate),
                });

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffWeeks)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'week',
                            ) &&
                            orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|week`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        else {
            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format("YY' MMM"),
                });

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffYears)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'month',
                            ) &&
                            orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|month`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        var chartLegend = [];

        const dataset = Object.entries(datasetDict).map(([key, value], index) => {
            chartLegend.push({
                itemName: value.seriesname,
                itemSku: value.seriesname,
                chartColor: CHART_COLORS[CHART_TYPE.REPORT_PRODUCT_SALES][index],
            });

            return {
                ...value,
                color: CHART_COLORS[CHART_TYPE.REPORT_PRODUCT_SALES][index],
            };
        });

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,
        };

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );
        var maxNumber = baseNumber;

        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);

        maxNumber = Math.max(maxNumber, 10);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};

export const getDataForChartReportOnlineQrSales = (
    // productSalesTemp,
    // outletItems,
    // allOutlets,
    // startDate,
    // endDate,
    // yAxisKey,
    // xAxisKey,

    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    tableDataSummary,
    // boldOutletQrSalesLineDict,
    selectedPaymentType,

    selectedTabOutletId,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        // var stackRowList = [
        //     {
        //         label: 'POS',
        //         value: ORDER_TYPE_DETAILS.POS,
        //     },
        //     {
        //         label: 'QR Offline',
        //         value: ORDER_TYPE_DETAILS.QR_OFFLINE,
        //     },
        //     {
        //         label: 'QR Online',
        //         value: ORDER_TYPE_DETAILS.QR_ONLINE,
        //     },
        // ];

        /////////////////////////////////////////////////////

        var sortedTableDataSummary = tableDataSummary.sort((summary1, summary2) => {
            if (summary1.userAppAndWebDineInOnlineOrdersAmount < summary2.userAppAndWebDineInOnlineOrdersAmount) {
                return 1;
            }
            else if (summary1.userAppAndWebDineInOnlineOrdersAmount > summary2.userAppAndWebDineInOnlineOrdersAmount) {
                return -1;
            }
            return 0;
        });

        var stackRowList = sortedTableDataSummary.filter(summary => {
            if (summary.outletId === selectedTabOutletId || selectedTabOutletId === 'ALL') {
                // 2023-08-22 - Show outlet that got orders only

                if (summary.totalOrdersAmount > 0) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }).slice(0, 50).map(summary => ({
            label: summary.outletName,
            value: summary.outletId,

            paletteColor: summary.paletteColor,
            koodooProcessingRate: 0,
        }));

        /////////////////////////////////////////////////////

        let orderDataList = [];
        for (var i = 0; i < razerPayoutTransactions.length; i++) {
            orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList.filter(orderDetails => orderDetails.razerCharges > 0 && orderDetails.koodooCharges > 0));
        }

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);
        orderDataList = filterByPaymentTypeAndTabOutletId(orderDataList, selectedPaymentType, selectedTabOutletId);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            moment().isSame(endDate, 'day') &&
            moment().isSame(startDate, 'day')
        ) {
            // endDate = moment(endDate).add(1, 'day');

            const diffHours = moment(endDate).diff(startDate, 'hour');

            var prevDate = moment(startDate).add(-1, 'hour');

            for (var i = 0; i < 24; i++) {
                const currDate = moment(startDate).add(i, 'hour');

                category.push({
                    label: i.toString().padStart(2, '0'),

                    //'label': moment(currDate).format('DD MMM'),

                    // label: !moment(currDate).isSame(prevDate, 'month')
                    //     ? moment(currDate).format('DD MMM')
                    //     : moment(currDate).format('DD'),

                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(24)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'hour',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            orderDataList[k].outletId === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].koodooCharges;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        }
        else if (
            moment(endDate).diff(startDate, 'day') <=
            moment(startDate).daysInMonth()
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffDays)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'day',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            orderDataList[k].outletId === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].koodooCharges;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|day`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        } else if (moment(endDate).diff(startDate, 'month') <= 6) {
            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                } else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label:
                        moment(currDate).format('MMM') +
                        ' W' +
                        countWeekdayOccurrencesInMonth(currDate),
                });

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffWeeks)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'week',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            orderDataList[k].outletId === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].koodooCharges;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|week`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        }
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        else {
            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format("YY' MMM"),
                });

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffYears)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'month',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            orderDataList[k].outletId === stackRow.value
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].koodooCharges;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|month`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        var chartLegend = [];

        const dataset = Object.entries(datasetDict).map(([key, value], index) => {
            // const colorRandom = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

            // const colorRandom = randomColorV2();

            chartLegend.push({
                // itemName: value.seriesname + `(RM ${value.data.reduce((accum, curr) => accum + parseFloat(curr), 0).toFixed(2)})`,
                itemName: value.seriesname,
                itemSku: value.seriesname,
                // chartColor: CHART_COLORS[CHART_TYPE.REPORT_ONLINE_QR_SALES][index],
                // chartColor: colorRandom,

                // legendItemFontColor: colorRandom,
                // legendBorderColor: colorRandom,
            });

            return {
                ...value,
                // color: CHART_COLORS[CHART_TYPE.REPORT_ONLINE_QR_SALES][index],

                // color: colorRandom,
                // legendColor: colorRandom,

                color: value.paletteColor,
                legendColor: value.paletteColor,

                // seriesname: `${value.seriesname} (RM ${value.totalValue.toFixed(2)}) (${value.totalValueQty.toFixed(0)}) (${value.koodooProcessingRate.toFixed(0)}%)`,
                seriesname: `${value.seriesname} (RM ${value.totalValue.toFixed(2)}) (${value.totalValueQty.toFixed(0)})`,

                // legendItemFontColor: colorRandom,
                // legendBorderColor: colorRandom,
            };
        });

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_ONLINE_QR_SALES].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_ONLINE_QR_SALES].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_ONLINE_QR_SALES].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,
        };

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );
        var maxNumber = baseNumber;

        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);

        maxNumber = Math.max(maxNumber, 10);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};

export const getDataForChartReportExpectedActualProfit = (
    // productSalesTemp,
    // outletItems,
    // allOutlets,
    // startDate,
    // endDate,
    // yAxisKey,
    // xAxisKey,

    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    tableDataSummary,
    // boldOutletQrSalesLineDict,
    selectedPaymentType,

    selectedTabOutletId,

    calculatedGmvActualToday,
    koodooProfitExpectedToday,
    rptDailySummaryList,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        // var stackRowList = [
        //     {
        //         label: 'POS',
        //         value: ORDER_TYPE_DETAILS.POS,
        //     },
        //     {
        //         label: 'QR Offline',
        //         value: ORDER_TYPE_DETAILS.QR_OFFLINE,
        //     },
        //     {
        //         label: 'QR Online',
        //         value: ORDER_TYPE_DETAILS.QR_ONLINE,
        //     },
        // ];

        /////////////////////////////////////////////////////

        // var sortedTableDataSummary = tableDataSummary.sort((summary1, summary2) => {
        //     if (summary1.userAppAndWebDineInOnlineOrdersAmount < summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return 1;
        //     }
        //     else if (summary1.userAppAndWebDineInOnlineOrdersAmount > summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return -1;
        //     }
        //     return 0;
        // });

        // var stackRowList = sortedTableDataSummary.filter(summary => {
        //     if (summary.outletId === selectedTabOutletId || selectedTabOutletId === 'ALL') {
        //         // 2023-08-22 - Show outlet that got orders only

        //         if (summary.totalOrdersAmount > 0) {
        //             return true;
        //         }
        //         else {
        //             return false;
        //         }
        //     }
        //     else {
        //         return false;
        //     }
        // }).slice(0, 50).map(summary => ({
        //     label: summary.outletName,
        //     value: summary.outletId,

        //     paletteColor: summary.paletteColor,
        //     koodooProcessingRate: 0,
        // }));

        const GMV_TERMS = {
            KD_PROFIT_ACTUAL: 'KD_PROFIT_ACTUAL',
            KD_PROFIT_EXPECTED: 'KD_PROFIT_EXPECTED',
        };

        var stackRowList = [
            {
                label: 'KooDoo Profit (Actual)',
                value: GMV_TERMS.KD_PROFIT_ACTUAL,

                // paletteColor: '#EA4335',
                paletteColors: '#4E9F7D',
            },
            {
                label: 'KooDoo Profit (Expected)',
                value: GMV_TERMS.KD_PROFIT_EXPECTED,

                paletteColor: '#4285F4',
            },
        ];

        /////////////////////////////////////////////////////

        let orderDataList = [];
        // for (var i = 0; i < razerPayoutTransactions.length; i++) {
        //     orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList.filter(orderDetails => orderDetails.razerCharges > 0 && orderDetails.koodooCharges > 0));
        // }

        // // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);
        // orderDataList = filterByPaymentTypeAndTabOutletId(orderDataList, selectedPaymentType, selectedTabOutletId);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        orderDataList = rptDailySummaryList;

        //////////////////////////////////////

        // 2023-12-19 - See if needed to include today data or not

        // if (moment().isSame(endDate, 'day')) {


        //     // for (let i = 0; i < tableDataSummary.length; i++) {

        //     // }
        // }

        let todayRow = {
            bqDt: moment().format('YYYY-MM-DD'),

            kpaCalculated: calculatedGmvActualToday,
            kpeCalculated: koodooProfitExpectedToday,

            // [uuidv4()]: {
            //     kpa: calculatedGmvActualToday,
            //     kpe: koodooProfitExpectedToday,

            //     mid: '',
            //     mn: '',
            //     oid: '',
            //     on: '',
            // },
        };

        orderDataList = orderDataList.concat([todayRow]);

        //////////////////////////////////////

        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            moment().isSame(endDate, 'day') &&
            moment().isSame(startDate, 'day')
        ) {
            console.log('getDataForChartReportExpectedActualProfit - same day');

            // endDate = moment(endDate).add(1, 'day');

            const diffHours = moment(endDate).diff(startDate, 'hour');

            var prevDate = moment(startDate).add(-1, 'hour');

            for (var i = 0; i < 24; i++) {
                const currDate = moment(startDate).add(i, 'hour');

                category.push({
                    label: i.toString().padStart(2, '0'),

                    //'label': moment(currDate).format('DD MMM'),

                    // label: !moment(currDate).isSame(prevDate, 'month')
                    //     ? moment(currDate).format('DD MMM')
                    //     : moment(currDate).format('DD'),

                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(24)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            // moment(currDate).isSame(
                            //     moment(orderDataList[k].dateTime),
                            //     'hour',
                            // ) &&
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'hour',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_ACTUAL
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpaCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpaCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            // datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                        else if (
                            // moment(currDate).isSame(
                            //     moment(orderDataList[k].dateTime),
                            //     'hour',
                            // ) &&
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'hour',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_EXPECTED
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpeCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpeCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            // datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        }
        else if (
            moment(endDate).diff(startDate, 'day') <=
            moment(startDate).daysInMonth()
        ) {
            console.log('getDataForChartReportExpectedActualProfit - days in month');

            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (let i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                // console.log('i = ', i);

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                // console.log('startDate');
                // console.log(moment(startDate).format('YYYY-MM-DD'));
                // console.log('currDate');
                // console.log(moment(currDate).format('YYYY-MM-DD'));

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffDays)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        // console.log('compare now')
                        // console.log(moment(currDate).format('YYYY-MM-DD'));
                        // console.log(moment(orderDataList[k].bqDt).format('YYYY-MM-DD'));
                        // console.log(moment(currDate).isSame(
                        //     moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                        //     'day',
                        // ));

                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'day',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_ACTUAL
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpaCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpaCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            // datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                        else if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'day',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_EXPECTED
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpeCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpeCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            // datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|day`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;

                    // console.log(datasetDict);
                    // console.log(stackRow.label);
                    // console.log(i);

                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        } else if (moment(endDate).diff(startDate, 'month') <= 6) {
            console.log('getDataForChartReportExpectedActualProfit - less than 6 months');

            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                } else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label:
                        moment(currDate).format('MMM') +
                        ' W' +
                        countWeekdayOccurrencesInMonth(currDate),
                });

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffWeeks)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            // moment(currDate).isSame(
                            //     moment(orderDataList[k].dateTime),
                            //     'week',
                            // ) &&
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'week',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_ACTUAL
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpaCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpaCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            // datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                        else if (
                            // moment(currDate).isSame(
                            //     moment(orderDataList[k].dateTime),
                            //     'week',
                            // ) &&
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'week',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_EXPECTED
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpeCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpeCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            // datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|week`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        }
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        else {
            console.log('getDataForChartReportExpectedActualProfit - more than 6 months');

            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format("YY' MMM"),
                });

                for (var j = 0; j < stackRowList.length; j++) {
                    var currTotal = 0;

                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffYears)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,

                                    id: stackRow.value,
                                };
                            }),

                            paletteColor: stackRow.paletteColor,
                            totalValue: 0,
                            totalValueQty: 0,
                            koodooProcessingRate: 0,

                            lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            // moment(currDate).isSame(
                            //     moment(orderDataList[k].dateTime),
                            //     'month',
                            // ) &&
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'month',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_ACTUAL
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpaCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpaCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                        else if (
                            // moment(currDate).isSame(
                            //     moment(orderDataList[k].dateTime),
                            //     'month',
                            // ) &&
                            moment(currDate).isSame(
                                moment(orderDataList[k].bqDt, 'YYYY-MM-DD'),
                                'month',
                            ) &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                            stackRow.value === GMV_TERMS.KD_PROFIT_EXPECTED
                        ) {
                            datasetDict[stackRow.label].data[i].value += orderDataList[k].kpeCalculated;
                            datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

                            currTotal += orderDataList[k].kpeCalculated;

                            datasetDict[stackRow.label].totalValueQty += 1;

                            datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
                        }
                    }

                    // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|month`;

                    // currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);

                    datasetDict[stackRow.label].totalValue += currTotal;

                    if (currTotal > yMaxValue) {
                        yMaxValue = currTotal;
                    }
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        var chartLegend = [];

        const dataset = Object.entries(datasetDict).map(([key, value], index) => {
            // const colorRandom = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

            // const colorRandom = randomColorV2();

            chartLegend.push({
                // itemName: value.seriesname + `(RM ${value.data.reduce((accum, curr) => accum + parseFloat(curr), 0).toFixed(2)})`,
                itemName: value.seriesname,
                itemSku: value.seriesname,
                // chartColor: CHART_COLORS[CHART_TYPE.REPORT_ONLINE_QR_SALES][index],
                // chartColor: colorRandom,

                // legendItemFontColor: colorRandom,
                // legendBorderColor: colorRandom,
            });

            return {
                ...value,
                // color: CHART_COLORS[CHART_TYPE.REPORT_ONLINE_QR_SALES][index],

                // color: colorRandom,
                // legendColor: colorRandom,

                color: value.paletteColor,
                legendColor: value.paletteColor,

                // seriesname: `${value.seriesname} (RM ${value.totalValue.toFixed(2)}) (${value.totalValueQty.toFixed(0)}) (${value.koodooProcessingRate.toFixed(0)}%)`,
                // seriesname: `${value.seriesname} (RM ${value.totalValue.toFixed(2)}) (${value.totalValueQty.toFixed(0)})`,
                seriesname: `${value.seriesname} (RM ${value.totalValue.toFixed(2)})`,

                // legendItemFontColor: colorRandom,
                // legendBorderColor: colorRandom,
            };
        });

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_EXPECTED_ACTUAL_PROFIT].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_EXPECTED_ACTUAL_PROFIT].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_EXPECTED_ACTUAL_PROFIT].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,
        };

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );
        var maxNumber = baseNumber;

        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);

        maxNumber = Math.max(maxNumber, 10);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        console.error(ex);
        // return false;
    }
};

export const getDataForChartReportAovSales = (
    // productSalesTemp,
    // outletItems,
    // allOutlets,
    // startDate,
    // endDate,
    // yAxisKey,
    // xAxisKey,

    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    tableDataSummary,
    // boldOutletQrSalesLineDict,
    selectedPaymentType,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var yMinValueLineChart = 0;
        var yMaxValueLineChart = 0;

        var category = [];
        var datasetDict = {};

        // var stackRowList = [
        //     {
        //         label: 'POS',
        //         value: ORDER_TYPE_DETAILS.POS,
        //     },
        //     {
        //         label: 'QR Offline',
        //         value: ORDER_TYPE_DETAILS.QR_OFFLINE,
        //     },
        //     {
        //         label: 'QR Online',
        //         value: ORDER_TYPE_DETAILS.QR_ONLINE,
        //     },
        // ];

        /////////////////////////////////////////////////////

        // var sortedTableDataSummary = tableDataSummary.sort((summary1, summary2) => {
        //     if (summary1.userAppAndWebDineInOnlineOrdersAmount < summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return 1;
        //     }
        //     else if (summary1.userAppAndWebDineInOnlineOrdersAmount > summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return -1;
        //     }
        //     return 0;
        // });

        // var stackRowList = sortedTableDataSummary.slice(0, 50).map(summary => ({
        //     label: summary.outletName,
        //     value: summary.outletId,

        //     paletteColor: summary.paletteColor,
        //     koodooProcessingRate: 0,
        // }));

        /////////////////////////////////////////////////////

        var groupBarChart = {
            seriesName: 'Additional Revenue (RM)',
            data: [],
        };

        var groupLineChart = {
            seriesName: 'Additional Revenue (%)',
            parentYAxis: "S",
            renderAs: "line",
            showValues: "0",
            data: [],
        };

        var groupOrders = {
            data: [],
        };

        /////////////////////////////////////////////////////

        let orderDataList = razerPayoutTransactions;

        // let orderDataList = [];
        // for (var i = 0; i < razerPayoutTransactions.length; i++) {
        //     orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList.filter(orderDetails => orderDetails.razerCharges > 0 && orderDetails.koodooCharges > 0));
        // }

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        // if (
        //     // moment(endDate).diff(startDate, 'day') <=
        //     // moment(startDate).daysInMonth()
        //     moment().isSame(endDate, 'day') &&
        //     moment().isSame(startDate, 'day')
        // ) {
        //     // endDate = moment(endDate).add(1, 'day');

        //     const diffHours = moment(endDate).diff(startDate, 'hour');

        //     var prevDate = moment(startDate).add(-1, 'hour');

        //     for (var i = 0; i < 24; i++) {
        //         const currDate = moment(startDate).add(i, 'hour');

        //         category.push({
        //             label: i.toString().padStart(2, '0'),

        //             //'label': moment(currDate).format('DD MMM'),

        //             // label: !moment(currDate).isSame(prevDate, 'month')
        //             //     ? moment(currDate).format('DD MMM')
        //             //     : moment(currDate).format('DD'),

        //             //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
        //         });

        //         prevDate = currDate;

        //         for (var j = 0; j < stackRowList.length; j++) {
        //             var currTotal = 0;

        //             const stackRow = stackRowList[j];

        //             if (datasetDict[stackRow.label]) {
        //                 // do nothing for now
        //             } else {
        //                 datasetDict[stackRow.label] = {
        //                     seriesname: stackRow.label,
        //                     data: Array.from(Array(24)).map(() => {
        //                         return {
        //                             value: 0,
        //                             toolText: `${stackRow.label}: RM 0.00`,

        //                             id: stackRow.value,
        //                         };
        //                     }),

        //                     paletteColor: stackRow.paletteColor,
        //                     totalValue: 0,
        //                     totalValueQty: 0,
        //                     koodooProcessingRate: 0,

        //                     lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
        //                 };
        //             }

        //             for (var k = 0; k < orderDataList.length; k++) {
        //                 if (
        //                     moment(currDate).isSame(
        //                         moment(orderDataList[k].dateTime),
        //                         'hour',
        //                     ) &&
        //                     // orderDataList[k].orderTypeDetails === stackRow.value
        //                     orderDataList[k].outletId === stackRow.value
        //                 ) {
        //                     datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
        //                     datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

        //                     currTotal += orderDataList[k].koodooCharges;

        //                     datasetDict[stackRow.label].totalValueQty += 1;

        //                     datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
        //                 }
        //             }

        //             // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

        //             // currTotal += datasetDict[stackRow.label].data[i].value;
        //             datasetDict[stackRow.label].data[i].value =
        //                 datasetDict[stackRow.label].data[i].value.toFixed(0);

        //             datasetDict[stackRow.label].totalValue += currTotal;

        //             if (currTotal > yMaxValue) {
        //                 yMaxValue = currTotal;
        //             }
        //         }
        //     }
        // }
        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            true
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            groupBarChart = {
                ...groupBarChart,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `RM 0.00`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupLineChart = {
                ...groupLineChart,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `0.0 %`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupOrders = {
                ...groupOrders,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `0.0 %`,
                        posOrderNum: 0,
                        posOrderSales: 0,
                        qrOrderNum: 0,
                        qrOrderSales: 0,
                    };
                }),
            };

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;
                var currTotalLineChart = 0;

                for (var k = 0; k < orderDataList.length; k++) {
                    if (
                        moment(currDate).isSame(
                            moment(orderDataList[k].dateTime),
                            'day',
                        )
                    ) {
                        if (moment(currDate).isSame(moment(), 'day')) {
                            console.log(orderDataList[k]);
                            console.log('today!');
                        }

                        if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                            groupOrders.data[i].posOrderNum += 1;
                            groupOrders.data[i].posOrderSales = BigNumber(groupOrders.data[i].posOrderSales).plus(orderDataList[k].totalAmount).toNumber();
                        }
                        else {
                            groupOrders.data[i].qrOrderNum += 1;
                            groupOrders.data[i].qrOrderSales = BigNumber(groupOrders.data[i].qrOrderSales).plus(orderDataList[k].totalAmount).toNumber();
                        }

                        var aovPos = 0;
                        var aovQr = 0;
                        var aovDiff = 0;
                        var additionalRevenueAmount = 0;
                        var additionalRevenuePercentage = 0;

                        if (groupOrders.data[i].posOrderNum > 0) {
                            aovPos = BigNumber(groupOrders.data[i].posOrderSales).dividedBy(groupOrders.data[i].posOrderNum).toNumber();
                        }

                        if (groupOrders.data[i].qrOrderNum > 0) {
                            aovQr = BigNumber(groupOrders.data[i].qrOrderSales).dividedBy(groupOrders.data[i].qrOrderNum).toNumber();
                        }

                        aovDiff = BigNumber(aovQr).minus(aovPos).toNumber();

                        additionalRevenueAmount = BigNumber(aovDiff < 0 ? 0 : aovDiff).multipliedBy(groupOrders.data[i].qrOrderNum).toNumber();
                        if (additionalRevenueAmount > 0) {
                            additionalRevenuePercentage = BigNumber(additionalRevenueAmount).dividedBy(
                                BigNumber(groupOrders.data[i].posOrderSales).plus(groupOrders.data[i].qrOrderSales)
                            ).multipliedBy(100).toNumber();
                        }

                        groupBarChart.data[i].value = additionalRevenueAmount;
                        groupBarChart.data[i].toolText = `RM ${additionalRevenueAmount.toFixed(2)}`;

                        groupLineChart.data[i].value = additionalRevenuePercentage;
                        groupLineChart.data[i].toolText = `${additionalRevenuePercentage.toFixed(1)}%`;

                        ////////////////////////////////////////////////////////////////////////////////

                        // currTotal = BigNumber(currTotal).plus(additionalRevenueAmount).toNumber();
                        // currTotalLineChart = BigNumber(currTotalLineChart).plus(additionalRevenuePercentage).toNumber();

                        currTotal = additionalRevenueAmount; // use the accumulated value
                        currTotalLineChart = additionalRevenuePercentage; // use the accumulated value
                    }
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }

                if (currTotalLineChart > yMaxValueLineChart) {
                    yMaxValueLineChart = currTotalLineChart;
                }
            }
        }
        // else if (moment(endDate).diff(startDate, 'month') <= 6) {
        //     var currWeekCount = 0;
        //     var prevMonth = moment(startDate);

        //     const diffWeeks = moment(endDate).diff(startDate, 'week');

        //     for (var i = 0; i < diffWeeks; i++) {
        //         const currDate = moment(startDate).add(i, 'week');

        //         if (moment(currDate).isSame(prevMonth, 'month')) {
        //             currWeekCount++;
        //         } else {
        //             prevMonth = moment(currDate);

        //             currWeekCount = 0;
        //             currWeekCount++;
        //         }

        //         category.push({
        //             // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
        //             label:
        //                 moment(currDate).format('MMM') +
        //                 ' W' +
        //                 countWeekdayOccurrencesInMonth(currDate),
        //         });

        //         for (var j = 0; j < stackRowList.length; j++) {
        //             var currTotal = 0;

        //             const stackRow = stackRowList[j];

        //             if (datasetDict[stackRow.label]) {
        //                 // do nothing for now
        //             } else {
        //                 datasetDict[stackRow.label] = {
        //                     seriesname: stackRow.label,
        //                     data: Array.from(Array(diffWeeks)).map(() => {
        //                         return {
        //                             value: 0,
        //                             toolText: `${stackRow.label}: RM 0.00`,

        //                             id: stackRow.value,
        //                         };
        //                     }),

        //                     paletteColor: stackRow.paletteColor,
        //                     totalValue: 0,
        //                     totalValueQty: 0,
        //                     koodooProcessingRate: 0,

        //                     lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
        //                 };
        //             }

        //             for (var k = 0; k < orderDataList.length; k++) {
        //                 if (
        //                     moment(currDate).isSame(
        //                         moment(orderDataList[k].dateTime),
        //                         'week',
        //                     ) &&
        //                     // orderDataList[k].orderTypeDetails === stackRow.value
        //                     orderDataList[k].outletId === stackRow.value
        //                 ) {
        //                     datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
        //                     datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

        //                     currTotal += orderDataList[k].koodooCharges;

        //                     datasetDict[stackRow.label].totalValueQty += 1;

        //                     datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
        //                 }
        //             }

        //             // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|week`;

        //             // currTotal += datasetDict[stackRow.label].data[i].value;
        //             datasetDict[stackRow.label].data[i].value =
        //                 datasetDict[stackRow.label].data[i].value.toFixed(0);

        //             datasetDict[stackRow.label].totalValue += currTotal;

        //             if (currTotal > yMaxValue) {
        //                 yMaxValue = currTotal;
        //             }
        //         }
        //     }
        // }        
        // else {
        //     const diffYears = moment(endDate).diff(startDate, 'month');

        //     for (var i = 0; i < diffYears; i++) {
        //         const currDate = moment(startDate).add(i, 'month');

        //         category.push({
        //             label: moment(currDate).format("YY' MMM"),
        //         });

        //         for (var j = 0; j < stackRowList.length; j++) {
        //             var currTotal = 0;

        //             const stackRow = stackRowList[j];

        //             if (datasetDict[stackRow.label]) {
        //                 // do nothing for now
        //             } else {
        //                 datasetDict[stackRow.label] = {
        //                     seriesname: stackRow.label,
        //                     data: Array.from(Array(diffYears)).map(() => {
        //                         return {
        //                             value: 0,
        //                             toolText: `${stackRow.label}: RM 0.00`,

        //                             id: stackRow.value,
        //                         };
        //                     }),

        //                     paletteColor: stackRow.paletteColor,
        //                     totalValue: 0,
        //                     totalValueQty: 0,
        //                     koodooProcessingRate: 0,

        //                     lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
        //                 };
        //             }

        //             for (var k = 0; k < orderDataList.length; k++) {
        //                 if (
        //                     moment(currDate).isSame(
        //                         moment(orderDataList[k].dateTime),
        //                         'month',
        //                     ) &&
        //                     // orderDataList[k].orderTypeDetails === stackRow.value
        //                     orderDataList[k].outletId === stackRow.value
        //                 ) {
        //                     datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
        //                     datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

        //                     currTotal += orderDataList[k].koodooCharges;

        //                     datasetDict[stackRow.label].totalValueQty += 1;

        //                     datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
        //                 }
        //             }

        //             // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|month`;

        //             // currTotal += datasetDict[stackRow.label].data[i].value;
        //             datasetDict[stackRow.label].data[i].value =
        //                 datasetDict[stackRow.label].data[i].value.toFixed(0);

        //             datasetDict[stackRow.label].totalValue += currTotal;

        //             if (currTotal > yMaxValue) {
        //                 yMaxValue = currTotal;
        //             }
        //         }
        //     }
        // }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        var chartLegend = [];

        const dataset = [
            groupBarChart,
            groupLineChart,
        ];

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_AOV_SALES].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_AOV_SALES].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_AOV_SALES].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,
        };

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        var baseNumberLength = yMaxValue.toFixed(0).length - 1;
        var baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );

        var maxNumber = baseNumber;
        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);
        maxNumber = Math.max(maxNumber, 10);

        ////////////////////////////////////////////////////

        baseNumberLength = yMaxValueLineChart.toFixed(0).length - 1;
        baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );

        var maxNumberLineChart = baseNumber;
        if (yMaxValueLineChart > baseNumber * 5) {
            maxNumberLineChart = baseNumber * 10;
        } else if (yMaxValueLineChart > baseNumber * 3) {
            maxNumberLineChart = baseNumber * 5;
        } else if (yMaxValueLineChart > baseNumber * 2) {
            maxNumberLineChart = baseNumber * 3;
        } else {
            maxNumberLineChart = baseNumber * 2;
        }

        maxNumberLineChart = getSuitableYAxisMaxValue(maxNumberLineChart);
        maxNumberLineChart = Math.max(maxNumberLineChart, 10);

        ////////////////////////////////////////////////////

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,

            sYAxisMaxValue: maxNumberLineChart,
            sYAxisMinValue: yMinValueLineChart,

            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};

export const getDataForChartReportCmpSales = (
    // productSalesTemp,
    // outletItems,
    // allOutlets,
    // startDate,
    // endDate,
    // yAxisKey,
    // xAxisKey,

    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    tableDataSummary,
    // boldOutletQrSalesLineDict,
    selectedPaymentType,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var yMinValueLineChart = 0;
        var yMaxValueLineChart = 0;

        var category = [];
        var datasetDict = {};

        // var stackRowList = [
        //     {
        //         label: 'POS',
        //         value: ORDER_TYPE_DETAILS.POS,
        //     },
        //     {
        //         label: 'QR Offline',
        //         value: ORDER_TYPE_DETAILS.QR_OFFLINE,
        //     },
        //     {
        //         label: 'QR Online',
        //         value: ORDER_TYPE_DETAILS.QR_ONLINE,
        //     },
        // ];

        /////////////////////////////////////////////////////

        // var sortedTableDataSummary = tableDataSummary.sort((summary1, summary2) => {
        //     if (summary1.userAppAndWebDineInOnlineOrdersAmount < summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return 1;
        //     }
        //     else if (summary1.userAppAndWebDineInOnlineOrdersAmount > summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return -1;
        //     }
        //     return 0;
        // });

        // var stackRowList = sortedTableDataSummary.slice(0, 50).map(summary => ({
        //     label: summary.outletName,
        //     value: summary.outletId,

        //     paletteColor: summary.paletteColor,
        //     koodooProcessingRate: 0,
        // }));

        /////////////////////////////////////////////////////

        var groupLineChartTotalSales = {
            seriesName: 'Total Sales (RM)',
            renderAs: "line",
            showValues: "0",
            data: [],
        };
        var groupLineChartGmvProfit = {
            seriesName: 'GMV Profit (RM)',
            parentYAxis: "S",
            renderAs: "line",
            showValues: "0",
            // placeValuesInside: "1",
            data: [],
        };

        var groupLineChartQrPct = {
            seriesName: 'QR vs. Total Orders (%)',
            // parentYAxis: "S",
            renderAs: "line",
            showValues: "0",
            data: [],
        };

        var groupOrders = {
            data: [],
        };

        /////////////////////////////////////////////////////

        let orderDataList = razerPayoutTransactions;

        // let orderDataList = [];
        // for (var i = 0; i < razerPayoutTransactions.length; i++) {
        //     orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList.filter(orderDetails => orderDetails.razerCharges > 0 && orderDetails.koodooCharges > 0));
        // }

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        // if (
        //     // moment(endDate).diff(startDate, 'day') <=
        //     // moment(startDate).daysInMonth()
        //     moment().isSame(endDate, 'day') &&
        //     moment().isSame(startDate, 'day')
        // ) {
        //     // endDate = moment(endDate).add(1, 'day');

        //     const diffHours = moment(endDate).diff(startDate, 'hour');

        //     var prevDate = moment(startDate).add(-1, 'hour');

        //     for (var i = 0; i < 24; i++) {
        //         const currDate = moment(startDate).add(i, 'hour');

        //         category.push({
        //             label: i.toString().padStart(2, '0'),

        //             //'label': moment(currDate).format('DD MMM'),

        //             // label: !moment(currDate).isSame(prevDate, 'month')
        //             //     ? moment(currDate).format('DD MMM')
        //             //     : moment(currDate).format('DD'),

        //             //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
        //         });

        //         prevDate = currDate;

        //         for (var j = 0; j < stackRowList.length; j++) {
        //             var currTotal = 0;

        //             const stackRow = stackRowList[j];

        //             if (datasetDict[stackRow.label]) {
        //                 // do nothing for now
        //             } else {
        //                 datasetDict[stackRow.label] = {
        //                     seriesname: stackRow.label,
        //                     data: Array.from(Array(24)).map(() => {
        //                         return {
        //                             value: 0,
        //                             toolText: `${stackRow.label}: RM 0.00`,

        //                             id: stackRow.value,
        //                         };
        //                     }),

        //                     paletteColor: stackRow.paletteColor,
        //                     totalValue: 0,
        //                     totalValueQty: 0,
        //                     koodooProcessingRate: 0,

        //                     lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
        //                 };
        //             }

        //             for (var k = 0; k < orderDataList.length; k++) {
        //                 if (
        //                     moment(currDate).isSame(
        //                         moment(orderDataList[k].dateTime),
        //                         'hour',
        //                     ) &&
        //                     // orderDataList[k].orderTypeDetails === stackRow.value
        //                     orderDataList[k].outletId === stackRow.value
        //                 ) {
        //                     datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
        //                     datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

        //                     currTotal += orderDataList[k].koodooCharges;

        //                     datasetDict[stackRow.label].totalValueQty += 1;

        //                     datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
        //                 }
        //             }

        //             // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

        //             // currTotal += datasetDict[stackRow.label].data[i].value;
        //             datasetDict[stackRow.label].data[i].value =
        //                 datasetDict[stackRow.label].data[i].value.toFixed(0);

        //             datasetDict[stackRow.label].totalValue += currTotal;

        //             if (currTotal > yMaxValue) {
        //                 yMaxValue = currTotal;
        //             }
        //         }
        //     }
        // }
        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            true
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            groupLineChartTotalSales = {
                ...groupLineChartTotalSales,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `RM 0.00`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupLineChartGmvProfit = {
                ...groupLineChartGmvProfit,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `RM 0.00`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupLineChartQrPct = {
                ...groupLineChartQrPct,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `0.0 %`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupOrders = {
                ...groupOrders,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `0.0 %`,

                        posOrderNum: 0,
                        posOrderSales: 0,
                        qrOrderNum: 0,
                        qrOrderSales: 0,
                        koodooCharges: 0,
                    };
                }),
            };

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;
                var currTotalLineChart = 0;

                for (var k = 0; k < orderDataList.length; k++) {
                    if (
                        moment(currDate).isSame(
                            moment(orderDataList[k].dateTime),
                            'day',
                        )
                    ) {
                        if (moment(currDate).isSame(moment(), 'day')) {
                            console.log(orderDataList[k]);
                            console.log('today!');
                        }

                        if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                            groupOrders.data[i].posOrderNum += 1;
                            groupOrders.data[i].posOrderSales = BigNumber(groupOrders.data[i].posOrderSales).plus(orderDataList[k].totalAmount).toNumber();
                        }
                        else {
                            groupOrders.data[i].qrOrderNum += 1;
                            groupOrders.data[i].qrOrderSales = BigNumber(groupOrders.data[i].qrOrderSales).plus(orderDataList[k].totalAmount).toNumber();
                        }

                        groupOrders.data[i].koodooCharges = BigNumber(groupOrders.data[i].koodooCharges).plus(orderDataList[k].koodooCharges).toNumber();

                        /////////////////////////////////////////////////////////

                        groupLineChartTotalSales.data[i].value = BigNumber(groupOrders.data[i].posOrderSales).plus(groupOrders.data[i].qrOrderSales).toNumber();
                        groupLineChartGmvProfit.data[i].value = groupOrders.data[i].koodooCharges;
                        groupLineChartQrPct.data[i].value = BigNumber(groupOrders.data[i].qrOrderNum).dividedBy(
                            BigNumber(groupOrders.data[i].posOrderNum).plus(groupOrders.data[i].qrOrderNum)
                        ).multipliedBy(100).toNumber();

                        /////////////////////////////////////////////////////////

                        groupLineChartTotalSales.data[i].toolText = `RM ${groupLineChartTotalSales.data[i].value.toFixed(2)}`;
                        groupLineChartGmvProfit.data[i].toolText = `RM ${groupLineChartGmvProfit.data[i].value.toFixed(2)}`;
                        groupLineChartQrPct.data[i].toolText = `${groupLineChartQrPct.data[i].value.toFixed(1)}%`;

                        ////////////////////////////////////////////////////////////////////////////////

                        // currTotal = BigNumber(currTotal).plus(additionalRevenueAmount).toNumber();
                        // currTotalLineChart = BigNumber(currTotalLineChart).plus(additionalRevenuePercentage).toNumber();

                        currTotal = groupLineChartTotalSales.data[i].value; // use the accumulated value

                        // currTotalLineChart = groupLineChartQrPct.data[i].value; // use the accumulated value
                        currTotalLineChart = groupLineChartGmvProfit.data[i].value; // use the accumulated value
                    }
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }

                if (currTotalLineChart > yMaxValueLineChart) {
                    yMaxValueLineChart = currTotalLineChart;
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        ////////////////////////////////////////////////////

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        var baseNumberLength = yMaxValue.toFixed(0).length - 1;
        var baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );

        var maxNumber = baseNumber;
        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);
        maxNumber = Math.max(maxNumber, 10);

        ////////////////////////////////////////////////////

        // 2022-05-23 - Map values from percentage to amount (as the left y-axis still using amounts)

        for (var i = 0; i < groupLineChartQrPct.data.length; i++) {
            groupLineChartQrPct.data[i].value = BigNumber(groupLineChartQrPct.data[i].value).dividedBy(100).multipliedBy(maxNumber);
        }

        ////////////////////////////////////////////////////

        // 2022-05-22 - Generate annotation based on left y-axis

        // var annotationLeftYAxis = {
        //     groups: [
        //         {
        //             items: [
        //                 {
        //                     id: 'high-annotation',
        //                     type: 'text',
        //                     text: 'High',
        //                     align: 'right',
        //                     y: '$yaxis.label.10000',
        //                     x: '$canvasEndX - 5',
        //                     bgColor: '#f2726f',
        //                     borderColor: '#f2726f',
        //                     wrap: '1',
        //                     visible: '1'
        //                 },
        //             ]
        //         }
        //     ],
        // };

        ////////////////////////////////////////////////////

        baseNumberLength = yMaxValueLineChart.toFixed(0).length - 1;
        baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );

        var maxNumberLineChart = baseNumber;
        if (yMaxValueLineChart > baseNumber * 5) {
            maxNumberLineChart = baseNumber * 10;
        } else if (yMaxValueLineChart > baseNumber * 3) {
            maxNumberLineChart = baseNumber * 5;
        } else if (yMaxValueLineChart > baseNumber * 2) {
            maxNumberLineChart = baseNumber * 3;
        } else {
            maxNumberLineChart = baseNumber * 2;
        }

        maxNumberLineChart = getSuitableYAxisMaxValue(maxNumberLineChart);
        maxNumberLineChart = Math.max(maxNumberLineChart, 10);

        ////////////////////////////////////////////////////

        var chartLegend = [];

        const dataset = [
            groupLineChartTotalSales,
            groupLineChartQrPct,
            groupLineChartGmvProfit,
        ];

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_CMP_AMT_PCT_SALES].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_CMP_AMT_PCT_SALES].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_CMP_AMT_PCT_SALES].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,

            // annotations: annotationLeftYAxis,
        };

        ///////////////////////////////////////////////////////////

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,

            sYAxisMaxValue: maxNumberLineChart,
            sYAxisMinValue: yMinValueLineChart,

            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};

export const filterChartItems = (item, appliedChartFilterQueries) => {
    var isValid = false;

    if (appliedChartFilterQueries.length > 0) {
        var matchedFilterNum = 0;

        for (var i = 0; i < appliedChartFilterQueries.length; i++) {
            const appliedChartFilterQuery = appliedChartFilterQueries[i];

            if (appliedChartFilterQuery.fieldNameType === CHART_FIELD_TYPE.STRING) {
                // proceed with string type

                if (appliedChartFilterQuery.fieldSpecial === 'cartItems') {
                    // cartItems field

                    if (
                        appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS
                    ) {
                        var isMatched = false;

                        for (
                            var i = 0;
                            i < item[appliedChartFilterQuery.fieldSpecial].length;
                            i++
                        ) {
                            if (
                                item[appliedChartFilterQuery.fieldSpecial][i][
                                appliedChartFilterQuery.fieldNameKey
                                ] === appliedChartFilterQuery.fieldDataValue
                            ) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.LIKE
                    ) {
                        try {
                            var isMatched = false;

                            for (
                                var i = 0;
                                i < item[appliedChartFilterQuery.fieldSpecial].length;
                                i++
                            ) {
                                if (
                                    appliedChartFilterQuery.fieldDataValue
                                        .toLowerCase()
                                        .includes(
                                            item[appliedChartFilterQuery.fieldSpecial][i][
                                                appliedChartFilterQuery.fieldNameKey
                                            ].toLowerCase(),
                                        ) ||
                                    item[appliedChartFilterQuery.fieldSpecial][i][
                                        appliedChartFilterQuery.fieldNameKey
                                    ]
                                        .toLowerCase()
                                        .includes(
                                            appliedChartFilterQuery.fieldDataValue.toLowerCase(),
                                        )
                                ) {
                                    matchedFilterNum++;
                                    isMatched = true;
                                    break;
                                }
                            }

                            if (isMatched) {
                                continue;
                            }
                        } catch (ex) {
                            console.error(ex);
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.NOT
                    ) {
                        var isMatched = false;

                        for (
                            var i = 0;
                            i < item[appliedChartFilterQuery.fieldSpecial].length;
                            i++
                        ) {
                            if (
                                item[appliedChartFilterQuery.fieldSpecial][i][
                                appliedChartFilterQuery.fieldNameKey
                                ] !== appliedChartFilterQuery.fieldDataValue
                            ) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.MORE_THAN
                    ) {
                        var isMatched = false;

                        for (
                            var i = 0;
                            i < item[appliedChartFilterQuery.fieldSpecial].length;
                            i++
                        ) {
                            if (
                                item[appliedChartFilterQuery.fieldSpecial][i][
                                appliedChartFilterQuery.fieldNameKey
                                ] > appliedChartFilterQuery.fieldDataValue
                            ) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN
                    ) {
                        var isMatched = false;

                        for (
                            var i = 0;
                            i < item[appliedChartFilterQuery.fieldSpecial].length;
                            i++
                        ) {
                            if (
                                item[appliedChartFilterQuery.fieldSpecial][i][
                                appliedChartFilterQuery.fieldNameKey
                                ] >= appliedChartFilterQuery.fieldDataValue
                            ) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.LESS_THAN
                    ) {
                        var isMatched = false;

                        for (
                            var i = 0;
                            i < item[appliedChartFilterQuery.fieldSpecial].length;
                            i++
                        ) {
                            if (
                                item[appliedChartFilterQuery.fieldSpecial][i][
                                appliedChartFilterQuery.fieldNameKey
                                ] < appliedChartFilterQuery.fieldDataValue
                            ) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN
                    ) {
                        var isMatched = false;

                        for (
                            var i = 0;
                            i < item[appliedChartFilterQuery.fieldSpecial].length;
                            i++
                        ) {
                            if (
                                item[appliedChartFilterQuery.fieldSpecial][i][
                                appliedChartFilterQuery.fieldNameKey
                                ] <= appliedChartFilterQuery.fieldDataValue
                            ) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    }
                } else {
                    // ordinary string field

                    if (
                        appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS
                    ) {
                        if (
                            item[appliedChartFilterQuery.fieldNameKey] ===
                            appliedChartFilterQuery.fieldDataValue
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.LIKE
                    ) {
                        try {
                            if (
                                (item[appliedChartFilterQuery.fieldNameKey].length > 0 &&
                                    appliedChartFilterQuery.fieldDataValue
                                        .toLowerCase()
                                        .includes(
                                            item[appliedChartFilterQuery.fieldNameKey].toLowerCase(),
                                        )) ||
                                item[appliedChartFilterQuery.fieldNameKey]
                                    .toLowerCase()
                                    .includes(
                                        appliedChartFilterQuery.fieldDataValue.toLowerCase(),
                                    )
                            ) {
                                matchedFilterNum++;
                                continue;
                            }
                        } catch (ex) {
                            console.error(ex);
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.NOT
                    ) {
                        if (
                            item[appliedChartFilterQuery.fieldNameKey] !==
                            appliedChartFilterQuery.fieldDataValue
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.MORE_THAN
                    ) {
                        if (
                            item[appliedChartFilterQuery.fieldNameKey] >
                            appliedChartFilterQuery.fieldDataValue
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN
                    ) {
                        if (
                            item[appliedChartFilterQuery.fieldNameKey] >=
                            appliedChartFilterQuery.fieldDataValue
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.LESS_THAN
                    ) {
                        if (
                            item[appliedChartFilterQuery.fieldNameKey] <
                            appliedChartFilterQuery.fieldDataValue
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    } else if (
                        appliedChartFilterQuery.fieldCompare ===
                        CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN
                    ) {
                        if (
                            item[appliedChartFilterQuery.fieldNameKey] <=
                            appliedChartFilterQuery.fieldDataValue
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                }
            } else if (
                appliedChartFilterQuery.fieldNameType === CHART_FIELD_TYPE.NUMBER
            ) {
                // proceed with string type

                if (
                    appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS
                ) {
                    if (
                        item[appliedChartFilterQuery.fieldNameKey] ===
                        appliedChartFilterQuery.fieldDataValue
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LIKE
                ) {
                    try {
                        if (
                            item[appliedChartFilterQuery.fieldNameKey] ===
                            appliedChartFilterQuery.fieldDataValue
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.NOT
                ) {
                    if (
                        item[appliedChartFilterQuery.fieldNameKey] !==
                        appliedChartFilterQuery.fieldDataValue
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.MORE_THAN
                ) {
                    if (
                        item[appliedChartFilterQuery.fieldNameKey] >
                        appliedChartFilterQuery.fieldDataValue
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN
                ) {
                    if (
                        item[appliedChartFilterQuery.fieldNameKey] >=
                        appliedChartFilterQuery.fieldDataValue
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.LESS_THAN
                ) {
                    if (
                        item[appliedChartFilterQuery.fieldNameKey] <
                        appliedChartFilterQuery.fieldDataValue
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN
                ) {
                    if (
                        item[appliedChartFilterQuery.fieldNameKey] <=
                        appliedChartFilterQuery.fieldDataValue
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                }
            } else if (
                appliedChartFilterQuery.fieldNameType === CHART_FIELD_TYPE.DATETIME
            ) {
                // proceed with string type

                if (
                    appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS
                ) {
                    if (
                        moment(item[appliedChartFilterQuery.fieldNameKey]).isSame(
                            appliedChartFilterQuery.fieldDataValue,
                            'day',
                        )
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LIKE
                ) {
                    try {
                        if (
                            moment(item[appliedChartFilterQuery.fieldNameKey]).isSame(
                                appliedChartFilterQuery.fieldDataValue,
                                'day',
                            )
                        ) {
                            matchedFilterNum++;
                            continue;
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.NOT
                ) {
                    if (
                        !moment(item[appliedChartFilterQuery.fieldNameKey]).isSame(
                            appliedChartFilterQuery.fieldDataValue,
                            'day',
                        )
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.MORE_THAN
                ) {
                    if (
                        moment(item[appliedChartFilterQuery.fieldNameKey]).isAfter(
                            appliedChartFilterQuery.fieldDataValue,
                            'day',
                        )
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN
                ) {
                    if (
                        moment(item[appliedChartFilterQuery.fieldNameKey]).isSameOrAfter(
                            appliedChartFilterQuery.fieldDataValue,
                            'day',
                        )
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.LESS_THAN
                ) {
                    if (
                        moment(item[appliedChartFilterQuery.fieldNameKey]).isBefore(
                            appliedChartFilterQuery.fieldDataValue,
                            'day',
                        )
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                } else if (
                    appliedChartFilterQuery.fieldCompare ===
                    CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN
                ) {
                    if (
                        moment(item[appliedChartFilterQuery.fieldNameKey]).isSameOrBefore(
                            appliedChartFilterQuery.fieldDataValue,
                            'day',
                        )
                    ) {
                        matchedFilterNum++;
                        continue;
                    }
                }
            }
        }

        if (matchedFilterNum === appliedChartFilterQueries.length) {
            isValid = true;
        }
    } else {
        isValid = true;
    }

    return isValid;
};

export const getDataForReservationGuestStats = (
    userReservations,
    crmUsers,
    // chartPeriod,
    // yAxisKey,
    startDate = new Date(),
    endDate = new Date(),
) => {
    try {
        // console.log('data before chart');

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        const userReservationsFiltered = userReservations.filter(
            (reservation) => {
                return moment(reservation.createdAt).isSameOrAfter(moment(startDate)) &&
                    moment(reservation.createdAt).isBefore(moment(endDate));
            },
        );

        const newGuestsNum = userReservationsFiltered.filter(reservation => {
            if (reservation.userId) {
                // check whether is new user or previous user

                // flow: when reservation made, if new user, will create a new crmUser and its createdAt will be later than the reservation's createdAt

                var findResult = crmUsers.find(crmUser => {
                    if (crmUser.userId === reservation.userId ||
                        crmUser.number === reservation.userPhone ||
                        crmUser.userEmail === reservation.userEmail) {
                        if (moment(crmUser.createdAt).isAfter(reservation.createdAt)) {
                            // means is new

                            return true;
                        }
                        else {
                            // means is old

                            return false;
                        }
                    }
                    else {
                        // not match

                        return false;
                    }
                });

                if (findResult) {
                    // means is the new user

                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                // if no userId, assume is new

                return true;
            }
        }).length;

        parsedData = [
            {
                'label': 'New guests',
                'value': newGuestsNum.toFixed(0),
            },
            {
                'label': 'Repeat guests',
                'value': (userReservationsFiltered.length - newGuestsNum).toFixed(0),
            },
        ];

        ///////////////////////////////////////////////////////////////

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.RESERVATION_GUEST_STATS].type;
        output.dataFormat =
            CHART_DATA[CHART_TYPE.RESERVATION_GUEST_STATS].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.RESERVATION_GUEST_STATS].chart,

            data: parsedData,
        };

        // // console.log('parsedData');
        // // console.log(parsedData);
        // // console.log(yMaxValue);

        // if (output.type !== 'HLinearGauge') {
        //   const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        //   const baseNumber = parseInt(
        //     '1' +
        //     Array(baseNumberLength)
        //       .fill()
        //       .map((num) => 0)
        //       .join(''),
        //   );
        //   var maxNumber = baseNumber;

        //   if (yMaxValue > baseNumber * 5) {
        //     maxNumber = baseNumber * 10;
        //   } else if (yMaxValue > baseNumber * 3) {
        //     maxNumber = baseNumber * 5;
        //   } else if (yMaxValue > baseNumber * 2) {
        //     maxNumber = baseNumber * 3;
        //   } else {
        //     maxNumber = baseNumber * 2;
        //   }

        //   maxNumber = Math.max(maxNumber, 100);

        //   output.dataSource.chart = {
        //     ...output.dataSource.chart,
        //     labelDisplay: 'WRAP',
        //     // labelStep: 2,
        //     bgColor: '#ffffff',
        //     canvasBgColor: '#ffffff',
        //     bgAlpha: 100,

        //     useEllipsesWhenOverflow: 1,

        //     // showYAxisValues: 0,
        //     // yaxismaxvalue: yAxisMaxValue * yAxisStep,
        //     yaxismaxvalue: maxNumber,
        //     yaxisminvalue: yMinValue,
        //     adjustDiv: 0,
        //     // numDivLines: numDivLines,
        //     numDivLines: 9,

        //     // yaxisminvalue: 0,
        //     formatNumberScale: 0,
        //     decimals: 0,
        //   };

        //   return {
        //     chartData: output,
        //     chartLegend: chartLegend,
        //   };
        // }

        // console.log('sales line chart output');
        // console.log(output);

        return output;
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};

export const getDataForChartReportRevisitNum = (
    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    selectedPaymentType,

    selectedTabOutletId,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        var stackRowList = [
            {
                label: 'POS',
                value: ORDER_TYPE_DETAILS.POS,
            },
            {
                label: 'QR',
                value: ORDER_TYPE_DETAILS.QR,
            },
            // {
            //     label: 'QR Online',
            //     value: ORDER_TYPE_DETAILS.QR_ONLINE,
            // },
        ];

        // let orderDataList = [];
        // for (var i = 0; i < razerPayoutTransactions.length; i++) {
        //     orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList);
        // }
        let orderDataList = razerPayoutTransactions;

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);
        // orderDataList = filterByPaymentTypeAndTabOutletId(orderDataList, selectedPaymentType, selectedTabOutletId);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            moment().isSame(endDate, 'day') &&
            moment().isSame(startDate, 'day')
        ) {
            // endDate = moment(endDate).add(1, 'day');

            const diffHours = moment(endDate).diff(startDate, 'hour');

            var prevDate = moment(startDate).add(-1, 'hour');

            for (var i = 0; i < 24; i++) {
                const currDate = moment(startDate).add(i, 'hour');

                category.push({
                    label: i.toString().padStart(2, '0'),

                    //'label': moment(currDate).format('DD MMM'),

                    // label: !moment(currDate).isSame(prevDate, 'month')
                    //     ? moment(currDate).format('DD MMM')
                    //     : moment(currDate).format('DD'),

                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(24)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: 0 new customer(s)`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'hour',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === stackRow.value && !orderDataList[k].repeatCust && orderDataList[k].userPhone) {
                                datasetDict[stackRow.label].data[i].value += 1;
                                datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: ${datasetDict[stackRow.label].data[i].value} new customer(s)`;
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        else if (
            moment(endDate).diff(startDate, 'day') <=
            moment(startDate).daysInMonth()
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffDays)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: 0 new customer(s)`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'day',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === stackRow.value && !orderDataList[k].repeatCust && orderDataList[k].userPhone) {
                                datasetDict[stackRow.label].data[i].value += 1;
                                datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: ${datasetDict[stackRow.label].data[i].value} new customer(s)`;
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|day`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        } else if (moment(endDate).diff(startDate, 'month') <= 6) {
            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                } else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label:
                        moment(currDate).format('MMM') +
                        ' W' +
                        countWeekdayOccurrencesInMonth(currDate),
                });

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffWeeks)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: 0 new customer(s)`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'week',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === stackRow.value && !orderDataList[k].repeatCust && orderDataList[k].userPhone) {
                                datasetDict[stackRow.label].data[i].value += 1;
                                datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: ${datasetDict[stackRow.label].data[i].value} new customer(s)`;
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|week`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        else {
            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format("YY' MMM"),
                });

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffYears)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: 0 new customer(s)`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'month',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === stackRow.value && !orderDataList[k].repeatCust && orderDataList[k].userPhone) {
                                datasetDict[stackRow.label].data[i].value += 1;
                                datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: ${datasetDict[stackRow.label].data[i].value} new customer(s)`;
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|month`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        var chartLegend = [];

        const dataset = Object.entries(datasetDict).map(([key, value], index) => {
            chartLegend.push({
                itemName: value.seriesname,
                itemSku: value.seriesname,
                chartColor: CHART_COLORS[CHART_TYPE.REPORT_REPEAT_CUST][index],
            });

            return {
                ...value,
                color: CHART_COLORS[CHART_TYPE.REPORT_REPEAT_CUST][index],
            };
        });

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_REPEAT_CUST].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_REPEAT_CUST].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_REPEAT_CUST].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,
        };

        // output.annotations = {
        //     "groups": [{
        //         "showBelow": "0",
        //         "items": [{
        //             "type": "text",
        //             "text": "12",
        //             "x": "$dataset.0.set.1.ENDX-5",
        //             "y": "$dataset.0.set.1.CENTERY-10",
        //             "align": "RIGHT",
        //             "fontColor": "#FFFFFF"
        //         }]
        //     }]
        // };

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );
        var maxNumber = baseNumber;

        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);

        maxNumber = Math.max(maxNumber, 10);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        console.log('revisit num exception');
        console.log(ex);

        return false;
    }
};

export const getDataForChartReportUpsellingSales = (
    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    selectedPaymentType,

    selectedTabOutletId,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        var stackRowList = [
            {
                label: 'POS Only',
                value: ORDER_TYPE_DETAILS.POS_ONLY,
            },
            {
                label: 'POS Upselling',
                value: ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY,
            },
            {
                label: 'QR Only',
                value: ORDER_TYPE_DETAILS.QR_ONLY,
            },
            {
                label: 'QR Upselling',
                value: ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY,
            },

            // {
            //     label: 'QR Online',
            //     value: ORDER_TYPE_DETAILS.QR_ONLINE,
            // },
        ];

        // let orderDataList = [];
        // for (var i = 0; i < razerPayoutTransactions.length; i++) {
        //     orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList);
        // }
        let orderDataList = razerPayoutTransactions;

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);
        // orderDataList = filterByPaymentTypeAndTabOutletId(orderDataList, selectedPaymentType, selectedTabOutletId);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            moment().isSame(endDate, 'day') &&
            moment().isSame(startDate, 'day')
        ) {
            // endDate = moment(endDate).add(1, 'day');

            const diffHours = moment(endDate).diff(startDate, 'hour');

            var prevDate = moment(startDate).add(-1, 'hour');

            for (var i = 0; i < 24; i++) {
                const currDate = moment(startDate).add(i, 'hour');

                category.push({
                    label: i.toString().padStart(2, '0'),

                    //'label': moment(currDate).format('DD MMM'),

                    // label: !moment(currDate).isSame(prevDate, 'month')
                    //     ? moment(currDate).format('DD MMM')
                    //     : moment(currDate).format('DD'),

                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(24)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'hour',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.POS &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    // is promotional orders

                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.QR &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId
                                        // ||
                                        // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                        // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                    ) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    const variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? 0 : BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                    else if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? orderDataList[k].totalAmount : variantsAddonsUpsellingPrice).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(2);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        else if (
            moment(endDate).diff(startDate, 'day') <=
            moment(startDate).daysInMonth()
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffDays)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'day',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.POS &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    // is promotional orders

                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.QR &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId
                                        // ||
                                        // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                        // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                    ) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    const variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? 0 : BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                    else if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? orderDataList[k].totalAmount : variantsAddonsUpsellingPrice).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|day`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        } else if (moment(endDate).diff(startDate, 'month') <= 6) {
            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                } else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label:
                        moment(currDate).format('MMM') +
                        ' W' +
                        countWeekdayOccurrencesInMonth(currDate),
                });

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffWeeks)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'week',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.POS &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    // is promotional orders

                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.QR &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId
                                        // ||
                                        // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                        // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                    ) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    const variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? 0 : BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                    else if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? orderDataList[k].totalAmount : variantsAddonsUpsellingPrice).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|week`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        else {
            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format("YY' MMM"),
                });

                var currTotal = 0;

                for (var j = 0; j < stackRowList.length; j++) {
                    const stackRow = stackRowList[j];

                    if (datasetDict[stackRow.label]) {
                        // do nothing for now
                    } else {
                        datasetDict[stackRow.label] = {
                            seriesname: stackRow.label,
                            data: Array.from(Array(diffYears)).map(() => {
                                return {
                                    value: 0,
                                    toolText: `${stackRow.label}: RM 0.00`,
                                };
                            }),
                        };
                    }

                    for (var k = 0; k < orderDataList.length; k++) {
                        if (
                            moment(currDate).isSame(
                                moment(orderDataList[k].dateTime),
                                'month',
                            )
                            // &&
                            // orderDataList[k].orderTypeDetails === stackRow.value
                        ) {
                            let orderGroup = '';
                            if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                                orderGroup = ORDER_TYPE_DETAILS.POS;
                            }
                            else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                                orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                                orderGroup = ORDER_TYPE_DETAILS.QR;
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.POS &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    // is promotional orders

                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.POS_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }

                            if (orderGroup === ORDER_TYPE_DETAILS.QR &&
                                (
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY
                                    ||
                                    stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY
                                )
                            ) {
                                if (orderDataList[k].cartItems.find(findCartItem => {
                                    if (findCartItem.pi ||
                                        findCartItem.promotionId ||
                                        findCartItem.vi ||
                                        findCartItem.voucherId ||
                                        findCartItem.upsellId ||
                                        findCartItem.upsellingCampaignId
                                        // ||
                                        // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                        // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                    ) {
                                        return true;
                                    }
                                }) !== undefined) {
                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus(orderDataList[k].totalAmount).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                                else {
                                    const variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (stackRow.value === ORDER_TYPE_DETAILS.QR_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? 0 : BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                    else if (stackRow.value === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY) {
                                        datasetDict[stackRow.label].data[i].value = BigNumber(datasetDict[stackRow.label].data[i].value).plus((orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) ? orderDataList[k].totalAmount : variantsAddonsUpsellingPrice).toNumber();
                                        datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;
                                    }
                                }
                            }
                        }
                    }

                    datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|month`;

                    currTotal += datasetDict[stackRow.label].data[i].value;
                    datasetDict[stackRow.label].data[i].value =
                        datasetDict[stackRow.label].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        var chartLegend = [];

        const dataset = Object.entries(datasetDict).map(([key, value], index) => {
            chartLegend.push({
                itemName: value.seriesname,
                itemSku: value.seriesname,
                chartColor: CHART_COLORS[CHART_TYPE.REPORT_UPSELLING][index],
            });

            return {
                ...value,
                color: CHART_COLORS[CHART_TYPE.REPORT_UPSELLING][index],
            };
        });

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_UPSELLING].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_UPSELLING].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_UPSELLING].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,
        };

        // output.annotations = {
        //     "groups": [{
        //         "showBelow": "0",
        //         "items": [{
        //             "type": "text",
        //             "text": "12",
        //             "x": "$dataset.0.set.1.ENDX-5",
        //             "y": "$dataset.0.set.1.CENTERY-10",
        //             "align": "RIGHT",
        //             "fontColor": "#FFFFFF"
        //         }]
        //     }]
        // };

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );
        var maxNumber = baseNumber;

        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);

        maxNumber = Math.max(maxNumber, 10);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        console.log('revisit num exception');
        console.log(ex);

        return false;
    }
};

export const getDataForChartReportAOVUpsellingSales = (
    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    selectedPaymentType,

    selectedTabOutletId,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var category = [];
        var datasetDict = {};

        // var stackRowList = [
        //     {
        //         label: 'POS',
        //         value: ORDER_TYPE_DETAILS.POS,
        //     },
        //     {
        //         label: 'QR Only',
        //         value: ORDER_TYPE_DETAILS.QR_ONLY,
        //     },
        //     {
        //         label: 'QR Upselling',
        //         value: ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY,
        //     },

        //     // {
        //     //     label: 'QR Online',
        //     //     value: ORDER_TYPE_DETAILS.QR_ONLINE,
        //     // },
        // ];

        var stackRowList = [
            {
                label: 'POS AOV',
                value: ORDER_TYPE_DETAILS.POS_ONLY,
            },
            {
                label: 'POS Upselling AOV',
                value: ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY,
            },
            {
                label: 'QR AOV',
                value: ORDER_TYPE_DETAILS.QR_ONLY,
            },
            {
                label: 'QR Upselling AOV',
                value: ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY,
            },
        ];

        //   var appTypeToValueDict = {
        //     [APP_TYPE.MERCHANT]: '-POS',
        //     [APP_TYPE.WAITER]: '-POS',

        //     [APP_TYPE.WEB_ORDER]: '-QR',
        //     [APP_TYPE.USER]: '-QR',

        //     [undefined]: '-POS',
        //   };

        // let orderDataList = [];
        // for (var i = 0; i < razerPayoutTransactions.length; i++) {
        //     orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList);
        // }

        let orderDataList = razerPayoutTransactions;

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);
        // orderDataList = filterByPaymentTypeAndTabOutletId(orderDataList, selectedPaymentType, selectedTabOutletId);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            moment().isSame(endDate, 'day') &&
            moment().isSame(startDate, 'day')
        ) {
            // endDate = moment(endDate).add(1, 'day');

            const diffHours = moment(endDate).diff(startDate, 'hour');

            var prevDate = moment(startDate).add(-1, 'hour');

            for (var i = 0; i < stackRowList.length; i++) {
                datasetDict[stackRowList[i].value] = {
                    seriesname: stackRowList[i].label,
                    data: Array.from(Array(diffHours)).map(() => {
                        return {
                            value: 0,
                            name: stackRowList[i].label,
                            toolText: `${stackRowList[i].label}: RM 0.00`,
                            totalOrders: 0,
                            totalSales: 0,
                        };
                    }),
                };
            }

            for (var i = 0; i < 24; i++) {
                const currDate = moment(startDate).add(i, 'hour');

                category.push({
                    label: i.toString().padStart(2, '0'),

                    //'label': moment(currDate).format('DD MMM'),

                    // label: !moment(currDate).isSame(prevDate, 'month')
                    //     ? moment(currDate).format('DD MMM')
                    //     : moment(currDate).format('DD'),

                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var k = 0; k < orderDataList.length; k++) {
                    if (
                        moment(currDate).isSame(
                            moment(orderDataList[k].dateTime),
                            'hour',
                        )
                        // &&
                        // orderDataList[k].orderTypeDetails === stackRow.value
                    ) {
                        let variantsAddonsUpsellingPrice = 0;

                        let orderGroup = '';
                        if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                            orderGroup = ORDER_TYPE_DETAILS.POS_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId) {
                                    return true;
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY;
                            }
                        }
                        else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                            orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                            orderGroup = ORDER_TYPE_DETAILS.QR_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId
                                    // ||
                                    // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    return true;
                                }
                                else if (
                                    (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (variantsAddonsUpsellingPrice > 0) {
                                        return true;
                                    }
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY;
                            }
                        }

                        if (orderGroup === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY &&
                            variantsAddonsUpsellingPrice > 0) {
                            if ((orderDataList[k].totalAmount - variantsAddonsUpsellingPrice) <= 0) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 0;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 1;
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 1;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 0;
                            }

                            if (orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    orderDataList[k].totalAmount
                                ).toNumber();
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()
                                ).toNumber();

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    variantsAddonsUpsellingPrice
                                ).toNumber();
                            }

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value.toFixed(2)}`;

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value.toFixed(2)}`;
                        }
                        else {
                            datasetDict[orderGroup].data[
                                i
                            ].totalOrders += 1;

                            datasetDict[orderGroup].data[
                                i
                            ].totalSales = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).plus(orderDataList[k].totalAmount).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].value = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).dividedBy(datasetDict[orderGroup].data[
                                i
                            ].totalOrders).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].toolText = `${datasetDict[orderGroup].data[
                                i
                            ].name}: RM ${datasetDict[orderGroup].data[
                                i
                            ].value.toFixed(2)}`;
                        }

                        // currTotal += datasetDict[orderGroup].data[i].value;
                        // datasetDict[orderGroup].data[i].value =
                        //     datasetDict[orderGroup].data[i].value.toFixed(0);
                    }
                }
            }
        }
        else if (
            moment(endDate).diff(startDate, 'day') <=
            moment(startDate).daysInMonth()
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (var i = 0; i < stackRowList.length; i++) {
                datasetDict[stackRowList[i].value] = {
                    seriesname: stackRowList[i].label,
                    data: Array.from(Array(diffDays)).map(() => {
                        return {
                            value: 0,
                            name: stackRowList[i].label,
                            toolText: `${stackRowList[i].label}: RM 0.00`,
                            totalOrders: 0,
                            totalSales: 0,
                        };
                    }),
                };
            }

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;

                for (var k = 0; k < orderDataList.length; k++) {
                    if (
                        moment(currDate).isSame(
                            moment(orderDataList[k].dateTime),
                            'day',
                        )
                        // &&
                        // orderDataList[k].orderTypeDetails === stackRow.value
                    ) {
                        let variantsAddonsUpsellingPrice = 0;

                        let orderGroup = '';
                        if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                            orderGroup = ORDER_TYPE_DETAILS.POS_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId) {
                                    return true;
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY;
                            }
                        }
                        else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                            orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                            orderGroup = ORDER_TYPE_DETAILS.QR_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId
                                    // ||
                                    // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    return true;
                                }
                                else if (
                                    (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (variantsAddonsUpsellingPrice > 0) {
                                        return true;
                                    }
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY;
                            }
                        }

                        if (orderGroup === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY &&
                            variantsAddonsUpsellingPrice > 0) {
                            if ((orderDataList[k].totalAmount - variantsAddonsUpsellingPrice) <= 0) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 0;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 1;
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 1;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 0;
                            }

                            if (orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    orderDataList[k].totalAmount
                                ).toNumber();
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()
                                ).toNumber();

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    variantsAddonsUpsellingPrice
                                ).toNumber();
                            }

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value.toFixed(2)}`;

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value.toFixed(2)}`;
                        }
                        else {
                            datasetDict[orderGroup].data[
                                i
                            ].totalOrders += 1;

                            datasetDict[orderGroup].data[
                                i
                            ].totalSales = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).plus(orderDataList[k].totalAmount).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].value = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).dividedBy(datasetDict[orderGroup].data[
                                i
                            ].totalOrders).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].toolText = `${datasetDict[orderGroup].data[
                                i
                            ].name}: RM ${datasetDict[orderGroup].data[
                                i
                            ].value.toFixed(2)}`;
                        }

                        // if (orderDataList[k].orderId === '#0023' ||
                        //     orderDataList[k].orderId === '#0025') {
                        //     console.log(orderDataList[k].orderId);
                        //     console.log('stop');
                        // }

                        // currTotal += datasetDict[orderGroup].data[i].value;
                        // datasetDict[orderGroup].data[i].value =
                        //     datasetDict[orderGroup].data[i].value.toFixed(0);
                    }
                }

                // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|day`;

                // if (currTotal > yMaxValue) {
                //     yMaxValue = currTotal;
                // }
            }
        } else if (moment(endDate).diff(startDate, 'month') <= 6) {
            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < stackRowList.length; i++) {
                datasetDict[stackRowList[i].value] = {
                    seriesname: stackRowList[i].label,
                    data: Array.from(Array(diffWeeks)).map(() => {
                        return {
                            value: 0,
                            name: stackRowList[i].label,
                            toolText: `${stackRowList[i].label}: RM 0.00`,
                            totalOrders: 0,
                            totalSales: 0,
                        };
                    }),
                };
            }

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                } else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label:
                        moment(currDate).format('MMM') +
                        ' W' +
                        countWeekdayOccurrencesInMonth(currDate),
                });

                var currTotal = 0;

                for (var k = 0; k < orderDataList.length; k++) {
                    if (
                        moment(currDate).isSame(
                            moment(orderDataList[k].dateTime),
                            'week',
                        )
                        // &&
                        // orderDataList[k].orderTypeDetails === stackRow.value
                    ) {
                        let variantsAddonsUpsellingPrice = 0;

                        let orderGroup = '';
                        if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                            orderGroup = ORDER_TYPE_DETAILS.POS_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId) {
                                    return true;
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY;
                            }
                        }
                        else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                            orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                            orderGroup = ORDER_TYPE_DETAILS.QR_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId
                                    // ||
                                    // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    return true;
                                }
                                else if (
                                    (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (variantsAddonsUpsellingPrice > 0) {
                                        return true;
                                    }
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY;
                            }
                        }

                        if (orderGroup === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY &&
                            variantsAddonsUpsellingPrice > 0) {
                            if ((orderDataList[k].totalAmount - variantsAddonsUpsellingPrice) <= 0) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 0;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 1;
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 1;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 0;
                            }

                            if (orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    orderDataList[k].totalAmount
                                ).toNumber();
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()
                                ).toNumber();

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    variantsAddonsUpsellingPrice
                                ).toNumber();
                            }

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value.toFixed(2)}`;

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value.toFixed(2)}`;
                        }
                        else {
                            datasetDict[orderGroup].data[
                                i
                            ].totalOrders += 1;

                            datasetDict[orderGroup].data[
                                i
                            ].totalSales = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).plus(orderDataList[k].totalAmount).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].value = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).dividedBy(datasetDict[orderGroup].data[
                                i
                            ].totalOrders).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].toolText = `${datasetDict[orderGroup].data[
                                i
                            ].name}: RM ${datasetDict[orderGroup].data[
                                i
                            ].value.toFixed(2)}`;
                        }

                        // currTotal += datasetDict[orderGroup].data[i].value;
                        // datasetDict[orderGroup].data[i].value =
                        //     datasetDict[orderGroup].data[i].value.toFixed(0);
                    }
                }
            }
        }
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        else {
            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < stackRowList.length; i++) {
                datasetDict[stackRowList[i].value] = {
                    seriesname: stackRowList[i].label,
                    data: Array.from(Array(diffYears)).map(() => {
                        return {
                            value: 0,
                            name: stackRowList[i].label,
                            toolText: `${stackRowList[i].label}: RM 0.00`,
                            totalOrders: 0,
                            totalSales: 0,
                        };
                    }),
                };
            }

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format("YY' MMM"),
                });

                var currTotal = 0;

                for (var k = 0; k < orderDataList.length; k++) {
                    if (
                        moment(currDate).isSame(
                            moment(orderDataList[k].dateTime),
                            'month',
                        )
                        // &&
                        // orderDataList[k].orderTypeDetails === stackRow.value
                    ) {
                        let variantsAddonsUpsellingPrice = 0;

                        let orderGroup = '';
                        if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.POS) {
                            orderGroup = ORDER_TYPE_DETAILS.POS_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId) {
                                    return true;
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.POS_UPSELLING_ONLY;
                            }
                        }
                        else if (orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_OFFLINE ||
                            orderDataList[k].orderTypeDetails === ORDER_TYPE_DETAILS.QR_ONLINE) {
                            orderGroup = ORDER_TYPE_DETAILS.QR_ONLY;

                            if (orderDataList[k].cartItems.find(findCartItem => {
                                if (findCartItem.pi ||
                                    findCartItem.promotionId ||
                                    findCartItem.vi ||
                                    findCartItem.voucherId ||
                                    findCartItem.upsellId ||
                                    findCartItem.upsellingCampaignId
                                    // ||
                                    // (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    // (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    return true;
                                }
                                else if (
                                    (findCartItem.addons && findCartItem.addons.length > 0) ||
                                    (findCartItem.addOns && findCartItem.addOns.length > 0)
                                ) {
                                    variantsAddonsUpsellingPrice =
                                        orderDataList[k].totalAmount > 0
                                            ?
                                            orderDataList[k].cartItems.reduce((accum, cartItem) => {
                                                return accum + (
                                                    cartItem.upsellId
                                                        ?
                                                        cartItem.price
                                                        :
                                                        (
                                                            (cartItem.addons ? cartItem.addons : cartItem.addOns).reduce((accumAddOns, addOnsItem) => {
                                                                return accumAddOns + getAddOnChoicePrice(addOnsItem, cartItem);
                                                            }, 0)
                                                        )
                                                );
                                            }, 0)
                                            :
                                            0
                                        ;

                                    if (variantsAddonsUpsellingPrice > 0) {
                                        return true;
                                    }
                                }
                            }) !== undefined) {
                                // is promotional orders

                                orderGroup = ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY;
                            }
                        }

                        if (orderGroup === ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY &&
                            variantsAddonsUpsellingPrice > 0) {
                            if ((orderDataList[k].totalAmount - variantsAddonsUpsellingPrice) <= 0) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 0;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 1;
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders += 1;

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders += 0;
                            }

                            if (orderDataList[k].totalAmount < variantsAddonsUpsellingPrice) {
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    orderDataList[k].totalAmount
                                ).toNumber();
                            }
                            else {
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    BigNumber(orderDataList[k].totalAmount).minus(variantsAddonsUpsellingPrice).toNumber()
                                ).toNumber();

                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalSales).plus(
                                    variantsAddonsUpsellingPrice
                                ).toNumber();
                            }

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value = BigNumber(datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].totalSales).dividedBy(
                                datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                    i
                                ].totalOrders
                                    <= 0.5
                                    ?
                                    1
                                    :
                                    datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                        i
                                    ].totalOrders
                            ).toNumber();

                            datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_ONLY].data[
                                i
                            ].value.toFixed(2)}`;

                            datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].toolText = `${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].name}: RM ${datasetDict[ORDER_TYPE_DETAILS.QR_UPSELLING_ONLY].data[
                                i
                            ].value.toFixed(2)}`;
                        }
                        else {
                            datasetDict[orderGroup].data[
                                i
                            ].totalOrders += 1;

                            datasetDict[orderGroup].data[
                                i
                            ].totalSales = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).plus(orderDataList[k].totalAmount).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].value = BigNumber(datasetDict[orderGroup].data[
                                i
                            ].totalSales).dividedBy(datasetDict[orderGroup].data[
                                i
                            ].totalOrders).toNumber();

                            datasetDict[orderGroup].data[
                                i
                            ].toolText = `${datasetDict[orderGroup].data[
                                i
                            ].name}: RM ${datasetDict[orderGroup].data[
                                i
                            ].value.toFixed(2)}`;
                        }

                        // currTotal += datasetDict[orderGroup].data[i].value;
                        // datasetDict[orderGroup].data[i].value =
                        //     datasetDict[orderGroup].data[i].value.toFixed(0);
                    }
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        for (var j = 0; j < stackRowList.length; j++) {
            var stackRowValue = stackRowList[j].value;

            var currTotal = 0;

            for (var k = 0; k < datasetDict[stackRowValue].data.length; k++) {
                // currTotal += datasetDict[outletId].data[k].value;

                currTotal = datasetDict[stackRowValue].data[k].value;

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }

            // if (currTotal > yMaxValue) {
            //     yMaxValue = currTotal;
            // }
        }

        var chartLegend = [];

        const dataset = Object.entries(datasetDict).slice(0, 2).map(([key, value], index) => {
            const mappedIndex =
                index % CHART_COLORS[CHART_TYPE.REPORT_AOV_UPSELLING].length; // might got more than 10 preset colors, set to infinite list instead

            chartLegend.push({
                itemName: value.seriesname,
                itemSku: value.seriesname,
                chartColor:
                    CHART_COLORS[CHART_TYPE.REPORT_AOV_UPSELLING][mappedIndex],
            });

            return {
                ...value,
                color: CHART_COLORS[CHART_TYPE.REPORT_AOV_UPSELLING][mappedIndex],
            };
        });

        const dataset2 = Object.entries(datasetDict).slice(2, 4).map(([key, value], index) => {
            const mappedIndex =
                (index + 2) % CHART_COLORS[CHART_TYPE.REPORT_AOV_UPSELLING].length; // might got more than 10 preset colors, set to infinite list instead

            chartLegend.push({
                itemName: value.seriesname,
                itemSku: value.seriesname,
                chartColor:
                    CHART_COLORS[CHART_TYPE.REPORT_AOV_UPSELLING][mappedIndex],
            });

            return {
                ...value,
                color: CHART_COLORS[CHART_TYPE.REPORT_AOV_UPSELLING][mappedIndex],
            };
        });

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_AOV_UPSELLING].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_AOV_UPSELLING].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_AOV_UPSELLING].chart,

            categories: [
                {
                    category: category,
                },
            ],
            // dataset: dataset,
            dataset: [
                {
                    dataset,
                },
                {
                    dataset: dataset2,
                },
            ],
        };

        // output.annotations = {
        //     "groups": [{
        //         "showBelow": "0",
        //         "items": [{
        //             "type": "text",
        //             "text": "12",
        //             "x": "$dataset.0.set.1.ENDX-5",
        //             "y": "$dataset.0.set.1.CENTERY-10",
        //             "align": "RIGHT",
        //             "fontColor": "#FFFFFF"
        //         }]
        //     }]
        // };

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );
        var maxNumber = baseNumber;

        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);

        maxNumber = Math.max(maxNumber, 10);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        console.log('revisit num exception');
        console.log(ex);

        return false;
    }
};

export const getSuitableYAxisMaxValue = (maxValueParam) => {
    var maxValue = maxValueParam;

    if (maxValue <= 10) {
        maxValue = 10;
    }
    else if (maxValue <= 20) {
        maxValue = 20;
    }
    else if (maxValue <= 40) {
        maxValue = 40;
    }
    else if (maxValue <= 50) {
        maxValue = 50;
    }
    else if (maxValue <= 100) {
        maxValue = 100;
    }
    else if (maxValue <= 150) {
        maxValue = 150;
    }
    else if (maxValue <= 200) {
        maxValue = 200;
    }
    else if (maxValue <= 300) {
        maxValue = 300;
    }
    else if (maxValue <= 400) {
        maxValue = 400;
    }
    else if (maxValue <= 500) {
        maxValue = 500;
    }
    else if (maxValue <= 750) {
        maxValue = 750;
    }
    else if (maxValue <= 1000) {
        maxValue = 1000;
    }
    else if (maxValue <= 2000) {
        maxValue = 2000;
    }
    else if (maxValue <= 4000) {
        maxValue = 4000;
    }
    else if (maxValue <= 4000) {
        maxValue = 4000;
    }
    else if (maxValue <= 5000) {
        maxValue = 5000;
    }
    else if (maxValue <= 6000) {
        maxValue = 6000;
    }
    else if (maxValue <= 7000) {
        maxValue = 7000;
    }
    else if (maxValue <= 8000) {
        maxValue = 8000;
    }
    else if (maxValue <= 9000) {
        maxValue = 9000;
    }
    else if (maxValue <= 10000) {
        maxValue = 10000;
    }
    else {
        // do nothing
    }

    return maxValue;
};

export const getDataForChartReportMdr = (
    // productSalesTemp,
    // outletItems,
    // allOutlets,
    // startDate,
    // endDate,
    // yAxisKey,
    // xAxisKey,

    allOutlets,
    allOutletsUserOrdersDone,
    chartPeriod,
    yAxisKey,
    xAxisKey,
    startDate = new Date(),
    endDate = new Date(),

    razerPayoutTransactions,
    gmvOrdersRecent,
    dataPlotClickCallback,
    tableDataSummary,
    // boldOutletQrSalesLineDict,
    selectedPaymentType,
) => {
    try {
        // endDate = moment(endDate).add(1, 'day');

        // console.log('data before chart');

        // // endDate = moment(endDate).add(1, 'day');

        // const productSales = productSalesTemp
        //     .slice(0)
        //     .sort((a, b) => b.totalSales - a.totalSales)
        //     .slice(0, 10);

        var parsedData = [];

        var yMinValue = 0;
        var yMaxValue = 0;

        var yMinValueLineChart = 0;
        var yMaxValueLineChart = 0;

        var category = [];
        var datasetDict = {};

        // var stackRowList = [
        //     {
        //         label: 'POS',
        //         value: ORDER_TYPE_DETAILS.POS,
        //     },
        //     {
        //         label: 'QR Offline',
        //         value: ORDER_TYPE_DETAILS.QR_OFFLINE,
        //     },
        //     {
        //         label: 'QR Online',
        //         value: ORDER_TYPE_DETAILS.QR_ONLINE,
        //     },
        // ];

        /////////////////////////////////////////////////////

        // var sortedTableDataSummary = tableDataSummary.sort((summary1, summary2) => {
        //     if (summary1.userAppAndWebDineInOnlineOrdersAmount < summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return 1;
        //     }
        //     else if (summary1.userAppAndWebDineInOnlineOrdersAmount > summary2.userAppAndWebDineInOnlineOrdersAmount) {
        //         return -1;
        //     }
        //     return 0;
        // });

        // var stackRowList = sortedTableDataSummary.slice(0, 50).map(summary => ({
        //     label: summary.outletName,
        //     value: summary.outletId,

        //     paletteColor: summary.paletteColor,
        //     koodooProcessingRate: 0,
        // }));

        /////////////////////////////////////////////////////

        var groupLineChartOnlineSales = {
            seriesName: 'Online Sales',
            // renderAs: "line",
            showValues: "0",
            data: [],
        };
        var groupLineChartTotalMdr = {
            seriesName: 'Total MDR',
            renderAs: "area2d",
            showValues: "0",
            data: [],
        };
        var groupLineChartEarnedMdrFpx = {
            seriesName: 'Earned MDR (FPX)',
            parentYAxis: "S",
            renderAs: "line",
            showValues: "0",
            // placeValuesInside: "1",
            data: [],
        };

        var groupLineChartEarnedMdrCreditDebit = {
            seriesName: 'Earned MDR (Credit/Debit)',
            parentYAxis: "S",
            renderAs: "line",
            showValues: "0",
            data: [],
        };

        var groupLineChartEarnedMdrEwallet = {
            seriesName: 'Earned MDR (e-Wallet)',
            parentYAxis: "S",
            renderAs: "line",
            showValues: "0",
            data: [],
        };

        var groupOrders = {
            data: [],
        };

        /////////////////////////////////////////////////////

        let orderDataList = razerPayoutTransactions;

        // let orderDataList = [];
        // for (var i = 0; i < razerPayoutTransactions.length; i++) {
        //     orderDataList = orderDataList.concat(razerPayoutTransactions[i].detailsList.filter(orderDetails => orderDetails.razerCharges > 0 && orderDetails.koodooCharges > 0));
        // }

        // orderDataList = filterByPaymentType(orderDataList, selectedPaymentType);

        // orderDataList = orderDataList.concat(allOutletsUserOrdersDone);

        // if (
        //     // moment(endDate).diff(startDate, 'day') <=
        //     // moment(startDate).daysInMonth()
        //     moment().isSame(endDate, 'day') &&
        //     moment().isSame(startDate, 'day')
        // ) {
        //     // endDate = moment(endDate).add(1, 'day');

        //     const diffHours = moment(endDate).diff(startDate, 'hour');

        //     var prevDate = moment(startDate).add(-1, 'hour');

        //     for (var i = 0; i < 24; i++) {
        //         const currDate = moment(startDate).add(i, 'hour');

        //         category.push({
        //             label: i.toString().padStart(2, '0'),

        //             //'label': moment(currDate).format('DD MMM'),

        //             // label: !moment(currDate).isSame(prevDate, 'month')
        //             //     ? moment(currDate).format('DD MMM')
        //             //     : moment(currDate).format('DD'),

        //             //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
        //         });

        //         prevDate = currDate;

        //         for (var j = 0; j < stackRowList.length; j++) {
        //             var currTotal = 0;

        //             const stackRow = stackRowList[j];

        //             if (datasetDict[stackRow.label]) {
        //                 // do nothing for now
        //             } else {
        //                 datasetDict[stackRow.label] = {
        //                     seriesname: stackRow.label,
        //                     data: Array.from(Array(24)).map(() => {
        //                         return {
        //                             value: 0,
        //                             toolText: `${stackRow.label}: RM 0.00`,

        //                             id: stackRow.value,
        //                         };
        //                     }),

        //                     paletteColor: stackRow.paletteColor,
        //                     totalValue: 0,
        //                     totalValueQty: 0,
        //                     koodooProcessingRate: 0,

        //                     lineThickness: window.boldOutletQrSalesLineDict[stackRow.value] ? 5 : 2,
        //                 };
        //             }

        //             for (var k = 0; k < orderDataList.length; k++) {
        //                 if (
        //                     moment(currDate).isSame(
        //                         moment(orderDataList[k].dateTime),
        //                         'hour',
        //                     ) &&
        //                     // orderDataList[k].orderTypeDetails === stackRow.value
        //                     orderDataList[k].outletId === stackRow.value
        //                 ) {
        //                     datasetDict[stackRow.label].data[i].value += orderDataList[k].koodooCharges;
        //                     datasetDict[stackRow.label].data[i].toolText = `${stackRow.label}: RM ${datasetDict[stackRow.label].data[i].value.toFixed(2)}`;

        //                     currTotal += orderDataList[k].koodooCharges;

        //                     datasetDict[stackRow.label].totalValueQty += 1;

        //                     datasetDict[stackRow.label].koodooProcessingRate = orderDataList[k].koodooProcessingRate;
        //                 }
        //             }

        //             // datasetDict[stackRow.label].data[i].id = `${moment(currDate).valueOf()}|hour`;

        //             // currTotal += datasetDict[stackRow.label].data[i].value;
        //             datasetDict[stackRow.label].data[i].value =
        //                 datasetDict[stackRow.label].data[i].value.toFixed(0);

        //             datasetDict[stackRow.label].totalValue += currTotal;

        //             if (currTotal > yMaxValue) {
        //                 yMaxValue = currTotal;
        //             }
        //         }
        //     }
        // }
        if (
            // moment(endDate).diff(startDate, 'day') <=
            // moment(startDate).daysInMonth()
            true
        ) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            groupLineChartOnlineSales = {
                ...groupLineChartOnlineSales,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `RM 0.00`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupLineChartTotalMdr = {
                ...groupLineChartTotalMdr,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `RM 0.00`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupLineChartEarnedMdrFpx = {
                ...groupLineChartEarnedMdrFpx,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `RM 0.00`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupLineChartEarnedMdrCreditDebit = {
                ...groupLineChartEarnedMdrCreditDebit,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `0.0 %`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupLineChartEarnedMdrEwallet = {
                ...groupLineChartEarnedMdrEwallet,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `0.0 %`,
                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                    };
                }),
            };

            groupOrders = {
                ...groupOrders,
                data: Array.from(Array(diffDays)).map(() => {
                    return {
                        value: 0,
                        toolText: `0.0 %`,

                        // posOrderNum: 0,
                        // posOrderSales: 0,
                        // qrOrderNum: 0,
                        // qrOrderSales: 0,
                        // koodooCharges: 0,

                        onlineSales: 0,
                        totalMdr: 0,
                        earnedMdrFpx: 0,
                        earnedMdrCreditDebit: 0,
                        earnedMdrEwallet: 0,
                    };
                }),
            };

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    label: !moment(currDate).isSame(prevDate, 'month')
                        ? moment(currDate).format('DD MMM')
                        : moment(currDate).format('DD'),
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                var currTotal = 0;
                var currTotalLineChart = 0;

                for (var k = 0; k < orderDataList.length; k++) {
                    if (
                        moment(currDate).isSame(
                            moment(orderDataList[k].dateTime),
                            'day',
                        )
                    ) {
                        if (moment(currDate).isSame(moment(), 'day')) {
                            console.log(orderDataList[k]);
                            console.log('today!');
                        }

                        if (
                            (
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME.ShopeePay ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME.GrabPay ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME.BOOST ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
                            )
                        ) {
                            groupOrders.data[i].earnedMdrEwallet = BigNumber(groupOrders.data[i].earnedMdrEwallet).plus(orderDataList[k].cfe).toNumber();
                            groupOrders.data[i].totalMdr = BigNumber(groupOrders.data[i].totalMdr).plus(orderDataList[k].razerCharges).toNumber();
                            groupOrders.data[i].onlineSales = BigNumber(groupOrders.data[i].onlineSales).plus(orderDataList[k].totalAmount).toNumber();

                        }
                        else if (
                            (
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['MB2u'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_CIMBCLICKS'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['PBeBank'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_HLB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_AMB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['AMB-W2W'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_ABMB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['ALB-ONL'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_ABB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_BMMB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_BKRM'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_BSN'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_OCBC'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_UOB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_HSBC'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_SCB'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_KFH'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['FPX_AGROBANK']
                            )
                        ) {
                            groupOrders.data[i].earnedMdrFpx = BigNumber(groupOrders.data[i].earnedMdrFpx).plus(orderDataList[k].cfe).toNumber();
                            groupOrders.data[i].totalMdr = BigNumber(groupOrders.data[i].totalMdr).plus(orderDataList[k].razerCharges).toNumber();
                            groupOrders.data[i].onlineSales = BigNumber(groupOrders.data[i].onlineSales).plus(orderDataList[k].totalAmount).toNumber();
                        }
                        else if (
                            (
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['credit'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['Credit'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
                                orderDataList[k].pdChannel === PAYMENT_CHANNEL_NAME['CIL_Visa']
                            )
                        ) {
                            groupOrders.data[i].earnedMdrCreditDebit = BigNumber(groupOrders.data[i].earnedMdrCreditDebit).plus(orderDataList[k].cfe).toNumber();
                            groupOrders.data[i].totalMdr = BigNumber(groupOrders.data[i].totalMdr).plus(orderDataList[k].razerCharges).toNumber();
                            groupOrders.data[i].onlineSales = BigNumber(groupOrders.data[i].onlineSales).plus(orderDataList[k].totalAmount).toNumber();
                        }

                        /////////////////////////////////////////////////////////

                        groupLineChartEarnedMdrEwallet.data[i].value = groupOrders.data[i].earnedMdrEwallet;
                        groupLineChartEarnedMdrFpx.data[i].value = groupOrders.data[i].earnedMdrFpx;
                        groupLineChartEarnedMdrCreditDebit.data[i].value = groupOrders.data[i].earnedMdrCreditDebit;
                        groupLineChartTotalMdr.data[i].value = groupOrders.data[i].totalMdr;
                        groupLineChartOnlineSales.data[i].value = groupOrders.data[i].onlineSales;
                        // groupLineChartQrPct.data[i].value = BigNumber(groupOrders.data[i].qrOrderNum).dividedBy(
                        //     BigNumber(groupOrders.data[i].posOrderNum).plus(groupOrders.data[i].qrOrderNum)
                        // ).multipliedBy(100).toNumber();

                        /////////////////////////////////////////////////////////

                        groupLineChartEarnedMdrEwallet.data[i].toolText = `RM ${groupLineChartEarnedMdrEwallet.data[i].value.toFixed(2)} e-Wallet`;
                        groupLineChartEarnedMdrFpx.data[i].toolText = `RM ${groupLineChartEarnedMdrFpx.data[i].value.toFixed(2)} FPX`;
                        groupLineChartEarnedMdrCreditDebit.data[i].toolText = `RM ${groupLineChartEarnedMdrCreditDebit.data[i].value.toFixed(1)} Credit/Debit`;
                        groupLineChartTotalMdr.data[i].toolText = `RM ${groupLineChartTotalMdr.data[i].value.toFixed(1)} Total MDR`;
                        groupLineChartOnlineSales.data[i].toolText = `RM ${groupLineChartOnlineSales.data[i].value.toFixed(1)} Online Sales`;

                        ////////////////////////////////////////////////////////////////////////////////

                        // currTotal = BigNumber(currTotal).plus(additionalRevenueAmount).toNumber();
                        // currTotalLineChart = BigNumber(currTotalLineChart).plus(additionalRevenuePercentage).toNumber();

                        currTotal = groupLineChartOnlineSales.data[i].value; // use the accumulated value

                        // currTotalLineChart = groupLineChartQrPct.data[i].value; // use the accumulated value
                        // currTotalLineChart = groupLineChartGmvProfit.data[i].value; // use the accumulated value
                        currTotalLineChart = Math.max(groupLineChartEarnedMdrFpx.data[i].value, groupLineChartEarnedMdrCreditDebit.data[i].value, groupLineChartEarnedMdrEwallet.data[i].value); // use the accumulated value
                    }
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }

                if (currTotalLineChart > yMaxValueLineChart) {
                    yMaxValueLineChart = currTotalLineChart;
                }
            }
        }

        // console.log('category');
        // console.log(category);
        // console.log('datasetDict');
        // console.log(datasetDict);

        ////////////////////////////////////////////////////

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        var baseNumberLength = yMaxValue.toFixed(0).length - 1;
        var baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );

        var maxNumber = baseNumber;
        if (yMaxValue > baseNumber * 9) {
            maxNumber = baseNumber * 10;
        } else if (yMaxValue > baseNumber * 8) {
            maxNumber = baseNumber * 9;
        } else if (yMaxValue > baseNumber * 7) {
            maxNumber = baseNumber * 8;
        } else if (yMaxValue > baseNumber * 6) {
            maxNumber = baseNumber * 7;
        } else if (yMaxValue > baseNumber * 5) {
            maxNumber = baseNumber * 6;
        } else if (yMaxValue > baseNumber * 3) {
            maxNumber = baseNumber * 5;
        } else if (yMaxValue > baseNumber * 2) {
            maxNumber = baseNumber * 3;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        } else {
            maxNumber = baseNumber * 2;
            // maxNumber = baseNumber * 5;

            // maxNumber = baseNumber * 2;
        }

        maxNumber = getSuitableYAxisMaxValue(maxNumber);
        maxNumber = Math.max(maxNumber, 10);

        ////////////////////////////////////////////////////

        // 2022-05-23 - Map values from percentage to amount (as the left y-axis still using amounts)

        // for (var i = 0; i < groupLineChartQrPct.data.length; i++) {
        //     groupLineChartQrPct.data[i].value = BigNumber(groupLineChartQrPct.data[i].value).dividedBy(100).multipliedBy(maxNumber);
        // }

        // for (var i = 0; i < groupLineChartTotalMdr.data.length; i++) {
        //     groupLineChartTotalMdr.data[i].value = BigNumber(groupLineChartTotalMdr.data[i].value).dividedBy(100).multipliedBy(maxNumber);
        // }

        ////////////////////////////////////////////////////

        // 2022-05-22 - Generate annotation based on left y-axis

        // var annotationLeftYAxis = {
        //     groups: [
        //         {
        //             items: [
        //                 {
        //                     id: 'high-annotation',
        //                     type: 'text',
        //                     text: 'High',
        //                     align: 'right',
        //                     y: '$yaxis.label.10000',
        //                     x: '$canvasEndX - 5',
        //                     bgColor: '#f2726f',
        //                     borderColor: '#f2726f',
        //                     wrap: '1',
        //                     visible: '1'
        //                 },
        //             ]
        //         }
        //     ],
        // };

        ////////////////////////////////////////////////////

        baseNumberLength = yMaxValueLineChart.toFixed(0).length - 1;
        baseNumber = parseInt(
            '1' +
            Array(baseNumberLength)
                .fill()
                .map((num) => 0)
                .join(''),
        );

        var maxNumberLineChart = baseNumber;
        if (yMaxValueLineChart > baseNumber * 5) {
            maxNumberLineChart = baseNumber * 10;
        } else if (yMaxValueLineChart > baseNumber * 3) {
            maxNumberLineChart = baseNumber * 5;
        } else if (yMaxValueLineChart > baseNumber * 2) {
            maxNumberLineChart = baseNumber * 3;
        } else {
            maxNumberLineChart = baseNumber * 2;
        }

        maxNumberLineChart = getSuitableYAxisMaxValue(maxNumberLineChart);
        maxNumberLineChart = Math.max(maxNumberLineChart, 10);

        ////////////////////////////////////////////////////

        var chartLegend = [];

        const dataset = [
            groupLineChartOnlineSales,
            groupLineChartTotalMdr,
            groupLineChartEarnedMdrFpx,
            groupLineChartEarnedMdrCreditDebit,
            groupLineChartEarnedMdrEwallet,
        ];

        var output = {};

        output.type = CHART_DATA[CHART_TYPE.REPORT_MDR].type;
        output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_MDR].dataFormat;
        output.dataSource = {
            chart: CHART_DATA[CHART_TYPE.REPORT_MDR].chart,

            categories: [
                {
                    category: category,
                },
            ],
            dataset: dataset,

            // annotations: annotationLeftYAxis,
        };

        ///////////////////////////////////////////////////////////

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,

            sYAxisMaxValue: maxNumberLineChart,
            sYAxisMinValue: yMinValueLineChart,

            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('chart output');
        // console.log(output);

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    } catch (ex) {
        // console.log(ex);
        return false;
    }
};
