import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Dimensions, useWindowDimensions } from "react-native";
import firebase from 'firebase/app';
import AppNavigator from "./navigation/AppNavigator";
import FontAwesomeTTF from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import SimpleLineIconsTTF from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';
import EntypoTTF from 'react-native-vector-icons/Fonts/Entypo.ttf';
import IoniconsTTF from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import FeatherTTF from 'react-native-vector-icons/Fonts/Feather.ttf';
import AntDesignTTF from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import FontistoTTF from 'react-native-vector-icons/Fonts/Fontisto.ttf';
import NunitoSansBoldTTF from './assets/fonts/NunitoSans-Bold.ttf';
import NunitoSansRegularTTF from './assets/fonts/NunitoSans-Regular.ttf';
import NunitoSansSemiBoldTTF from './assets/fonts/NunitoSans-SemiBold.ttf';
import { CommonStore } from "../src/store/commonStore";
import AwesomeAlert from 'react-native-awesome-alerts';
import {
  // listenToUserChanges,
  // listenToSelectedOutletChanges,
  // listenToSelectedOutletItemChanges,
  listenToCommonChangesMerchant,
  // listenToSelectedOutletTagChanges,
  // listenToSearchOutletTextChanges,
  // listenToSearchOutletMerchantIdChanges,
  // listenToSelectedOutletTableIdChanges,
  requestNotificationsPermission,
  getOutletById,
  isMobile,
  listenToOrderChangesMerchant,
  listenToPayoutTransactionsChangesMerchant,
} from "./util/common";
import Ionicons from 'react-native-vector-icons/Ionicons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Draggable from 'react-native-draggable';
import Colors from '../src/constant/Colors';
import { useNavigation } from '@react-navigation/native';
import { DataStore } from '../src/store/dataStore';
import { UserStore } from '../src/store/userStore';
// import messaging from '@react-native-firebase/messaging';
import { Collections } from '../src/constant/firebase';
import ApiClient from '../src/util/ApiClient';
import API from '../src/constant/API';
import { parseMessages } from '../src/util/notifications';
import { NotificationStore } from '../src/store/notificationStore';
import { PROMOTION_TYPE, PROMOTION_TYPE_VARIATION } from '../src/constant/promotions';
import { prefix } from "./constant/env";
import Styles from "./constant/Styles";
import moment from "moment";
import * as Sentry from '@sentry/browser';

// Sentry.init({
//   dsn: 'https://97d407600cff4c26b771ca7d5c389ecb@o4505254714081280.ingest.sentry.io/4505254719193088',
// });

// Add a global error handler to catch and report errors
// window.onerror = function (message, source, lineno, colno, error) {
//   Sentry.captureException(error);
// };

// Look at public/index.html!

const firebaseConfig = require('./config/firebase-web.json');

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use this one
}

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

// firebase.firestore().enablePersistence();

global.outletItemDict = {};

global.subscriberListenToUserChangesMerchant = () => { };

const App = () => {
  const {
    height: windowHeight,
    width: windowWidth,
  } = useWindowDimensions();

  const closeIt = () => {
    AsyncStorage.setItem('refreshed', '1');

    // return "Any string value here forces a dialog box to \n" + "appear before closing the window.";
  }

  // set the refreshed string before refresh
  window.onbeforeunload = closeIt;

  // when loading up check if refresh string is 1 to read from storage
  document.addEventListener('readystatechange', async (event) => {
    console.log('page is readystatechange');

    const reload = await AsyncStorage.getItem('refreshed');

    if (reload === '1') {
      await readFromStorage();

    } else {
      // CommonStore.subscribe(
      //   (s) => s,
      //   async (commonStore) => {
      //     await AsyncStorage.setItem('@commonStore', JSON.stringify(commonStore));
      //   },
      // );

      // DataStore.subscribe(
      //   (s) => s,
      //   async (dataStore) => {
      //     await AsyncStorage.setItem('@dataStore', JSON.stringify(dataStore));
      //   },
      // );

      UserStore.subscribe(
        (s) => s,
        async (userStore) => {
          await AsyncStorage.setItem('@userStoreV2', JSON.stringify(userStore));
        },
      );
    }

    await AsyncStorage.setItem('refreshed', '0');
  });

  const readFromStorage = async () => {
    console.log('\n Reading from asyncStorage \n')

    // const commonStoreDataRaw = await AsyncStorage.getItem('@commonStore');
    // if (commonStoreDataRaw !== null) {
    //   const commonStoreData = JSON.parse(commonStoreDataRaw);
    //   console.log(commonStoreData, typeof commonStoreData);

    //   CommonStore.replace(commonStoreData);
    // }

    const userStoreDataRaw = await AsyncStorage.getItem('@userStoreV2');
    if (userStoreDataRaw !== null) {
      const userStoreData = JSON.parse(userStoreDataRaw);
      UserStore.replace(userStoreData);
    }

    // const dataStoreDataRaw = await AsyncStorage.getItem('@dataStore');
    // if (dataStoreDataRaw !== null) {
    //   const dataStoreData = JSON.parse(dataStoreDataRaw);
    //   DataStore.replace(dataStoreData);
    // }
  }

  const [cartIcon, setCartIcon] = useState(false);

  const alertObj = CommonStore.useState(s => s.alertObj);

  const firebaseUid = UserStore.useState(s => s.firebaseUid);

  const lat = CommonStore.useState(s => s.lat);
  const lng = CommonStore.useState(s => s.lng);

  const selectedOutlet = CommonStore.useState(s => s.selectedOutlet);
  const selectedOutletItem = CommonStore.useState(s => s.selectedOutletItem);

  const selectedOutletTag = CommonStore.useState(s => s.selectedOutletTag);

  const searchOutletText = CommonStore.useState(s => s.searchOutletText);
  const searchOutletMerchantId = CommonStore.useState(s => s.searchOutletMerchantId);

  const cartItems = CommonStore.useState(s => s.cartItems);

  const debugText = CommonStore.useState(s => s.debugText);

  const selectedOutletTableId = CommonStore.useState(s => s.selectedOutletTableId);

  const navigationObj = DataStore.useState(s => s.navigationObj);

  // const beerDockets = CommonStore.useState(s => s.beerDockets);
  // const selectedUserBeerDocket = CommonStore.useState(s => s.selectedUserBeerDocket);
  // const beerDocketsRedemptions = CommonStore.useState(s => s.beerDocketsRedemptions);
  // const beerDocketsRedemptionsBDDict = CommonStore.useState(s => s.beerDocketsRedemptionsBDDict);

  const linkToFunc = DataStore.useState(s => s.linkToFunc);

  const currPage = CommonStore.useState(s => s.currPage);

  const nPromotionNotificationManual = NotificationStore.useState(s => s.nPromotionNotificationManual);
  const nPromotionNotificationAuto = NotificationStore.useState(s => s.nPromotionNotificationAuto);
  const nUserOrderCourierAction = NotificationStore.useState(s => s.nUserOrderCourierAction);

  const userGroups = UserStore.useState(s => s.userGroups);
  const email = UserStore.useState(s => s.email);

  // const selectedOutletPromotions = CommonStore.useState(s => s.selectedOutletPromotions);
  // const availablePromotions = CommonStore.useState(s => s.availablePromotions);

  const selectedOutletPointsRedeemPackages = CommonStore.useState(s => s.selectedOutletPointsRedeemPackages);
  // const availablePointsRedeemPackages = CommonStore.useState(s => s.availablePointsRedeemPackages);

  const selectedOutletCRMTagsDict = CommonStore.useState(s => s.selectedOutletCRMTagsDict);
  const selectedOutletCRMUser = CommonStore.useState(s => s.selectedOutletCRMUser);

  const loadAsyncStorage = async () => {
    // await AsyncStorage.removeItem(`${firebaseUid}.cartItems`);

    const cartItemsRaw = await AsyncStorage.getItem(`${firebaseUid}.cartItems`);
    const cartOutletId = await AsyncStorage.getItem(`${firebaseUid}.cartOutletId`);

    if (cartItemsRaw) {
      DataStore.update(s => {
        s.cartItems = [
          // ...s.cartItems,
          ...JSON.parse(cartItemsRaw),
        ];
      });
    }

    if (cartOutletId) {
      CommonStore.update(s => {
        s.cartOutletId = cartOutletId;
      });
    }
  };

  useEffect(() => {
    // screen.orientation.lock('landscape');

    // AsyncStorage.getItem('refreshed')

    // readFromStorage();

    // CommonStore.subscribe(
    //   (s) => s,
    //   async (commonStore) => {
    //     await AsyncStorage.setItem('@commonStore', JSON.stringify(commonStore));
    //   },
    // );

    // DataStore.subscribe(
    //   (s) => s,
    //   async (dataStore) => {
    //     await AsyncStorage.setItem('@dataStore', JSON.stringify(dataStore));
    //   },
    // );

    // UserStore.subscribe(
    //   (s) => s,
    //   async (userStore) => {
    //     await AsyncStorage.setItem('@userStoreV2', JSON.stringify(userStore));
    //   },
    // );

    console.log('isMobile');
    console.log(isMobile());
  }, []);

  // useEffect(() => {
  //   if (firebaseUid !== '') {
  //     listenToUserChanges(firebaseUid);

  //     // loadAsyncStorage();
  //   }
  // }, [firebaseUid]);

  // useEffect(() => {
  //   if (selectedOutlet !== null
  //     // && email
  //   ) {
  //     listenToSelectedOutletChanges(selectedOutlet, email);
  //   }
  // }, [selectedOutlet, email]);

  // useEffect(() => {
  //   if (selectedOutletItem !== null &&
  //     selectedOutletItem !== undefined &&
  //     selectedOutletItem.uniqueId) {
  //     listenToSelectedOutletItemChanges(selectedOutletItem);
  //   }
  // }, [selectedOutletItem]);

  useEffect(() => {
    listenToCommonChangesMerchant();

    CommonStore.update(s => {
      s.gmvStartDate = moment().startOf('day').valueOf();
      s.gmvEndDate = moment().endOf("day").valueOf();
    });

    CommonStore.update(s => {
      // s.ptStartDate = moment().add(-2, 'day').startOf('day').valueOf();
      s.ptStartDate = moment().startOf('day').valueOf();
      s.ptEndDate = moment().endOf("day").valueOf();
    });

    CommonStore.update(s => {
      // s.ptStartDate = moment().add(-2, 'day').startOf('day').valueOf();
      s.aovStartDate = moment().startOf('month').startOf('day').valueOf();
      s.aovEndDate = moment().endOf('month').endOf("day").valueOf();
    });

    CommonStore.update(s => {
      // s.ptStartDate = moment().add(-2, 'day').startOf('day').valueOf();
      s.cmpStartDate = moment().startOf('month').startOf('day').valueOf();
      s.cmpEndDate = moment().endOf('month').endOf("day").valueOf();

      // s.rptDsStartDate = moment().startOf('month').startOf('day').valueOf();
      // s.rptDsEndDate = moment().endOf('month').endOf("day").valueOf();

      s.rptDsStartDate = moment().subtract(30, 'day').startOf('day').valueOf();
      s.rptDsEndDate = moment().endOf('day').valueOf();
    });

    CommonStore.update(s => {
      // s.ptStartDate = moment().add(-2, 'day').startOf('day').valueOf();
      s.qrStartDate = moment().startOf('month').startOf('day').valueOf();
      s.qrEndDate = moment().endOf('month').endOf("day").valueOf();
    });
  }, []);

  // const gmvStartDate = CommonStore.useState(s => s.gmvStartDate);
  // const gmvEndDate = CommonStore.useState(s => s.gmvEndDate);

  // useEffect(() => {
  //   if (gmvStartDate !== gmvEndDate) {
  //     // temp fix for strange bugs for same date

  //     listenToOrderChangesMerchant(gmvStartDate, gmvEndDate);
  //   }
  // }, [gmvStartDate, gmvEndDate]);

  const ptStartDate = CommonStore.useState(s => s.ptStartDate);
  const ptEndDate = CommonStore.useState(s => s.ptEndDate);

  // useEffect(() => {
  //   if (ptStartDate !== ptEndDate) {
  //     // temp fix for strange bugs for same date

  //     // listenToPayoutTransactionsChangesMerchant(ptStartDate, ptEndDate);

  //     typeof global.subscriberListenToPayoutTransactionsChangesMerchant === 'function' && global.subscriberListenToPayoutTransactionsChangesMerchant();
  //     global.subscriberListenToPayoutTransactionsChangesMerchant = () => { };

  //     let subscriber = listenToPayoutTransactionsChangesMerchant(ptStartDate, ptEndDate);

  //     global.subscriberListenToPayoutTransactionsChangesMerchant = subscriber;

  //     return () => {
  //       typeof subscriber === 'function' && subscriber();
  //     };
  //   }
  // }, [ptStartDate, ptEndDate]);

  /////////////////

  // useEffect(() => {
  //   if (moment(gmvStartDate).diff(gmvEndDate, 'month') >= 12) {
  //     // update largest period


  //   }
  // }, [gmvOrders]);

  // useEffect(() => {
  //   if (selectedOutletTag !== null &&
  //     selectedOutletTag !== undefined &&
  //     selectedOutletTag.uniqueId) {
  //     listenToSelectedOutletTagChanges(selectedOutletTag);
  //   }
  // }, [selectedOutletTag]);

  // useEffect(() => {
  //   if (searchOutletText && searchOutletText.length > 0) {
  //     listenToSearchOutletTextChanges(searchOutletText);
  //   }
  // }, [searchOutletText]);

  // useEffect(() => {
  //   if (searchOutletMerchantId && searchOutletMerchantId.length > 0) {
  //     listenToSearchOutletMerchantIdChanges(searchOutletMerchantId);
  //   }
  // }, [searchOutletMerchantId]);

  // useEffect(() => {
  //   if (selectedOutletTableId && selectedOutletTableId.length > 0 && selectedOutlet && selectedOutlet.uniqueId && firebaseUid.length > 0) {
  //     listenToSelectedOutletTableIdChanges(firebaseUid, selectedOutletTableId, selectedOutlet.uniqueId);
  //   }
  // }, [firebaseUid, selectedOutletTableId, selectedOutlet]);

  // useEffect(() => {
  //   // combine merchant's beer docket with user's redeemed beer docket record, to merge user's action changes (redeemed mug, extended days, etc)

  //   var userBeerDocketsTemp = [];
  //   var selectedUserBeerDocketTemp = {};

  //   for (var i = 0; i < beerDockets.length; i++) {
  //     var record = {
  //       ...beerDockets[i],
  //       beerDocketId: beerDockets[i].uniqueId,
  //     };

  //     if (beerDocketsRedemptionsBDDict[record.beerDocketId]) {
  //       record = {
  //         ...record,
  //         ...beerDocketsRedemptionsBDDict[record.uniqueId], // extend the docket default data with user's own data
  //         userBeerDocketId: beerDocketsRedemptionsBDDict[record.uniqueId].uniqueId,
  //       };
  //     }

  //     userBeerDocketsTemp.push(record);

  //     if (record.beerDocketId === selectedUserBeerDocket.beerDocketId) {
  //       selectedUserBeerDocketTemp = record;
  //     }
  //   };

  //   console.log('changed userBeerDockets!');
  //   console.log(userBeerDocketsTemp);

  //   CommonStore.update(s => {
  //     s.userBeerDockets = userBeerDocketsTemp;

  //     if (selectedUserBeerDocket && selectedUserBeerDocket.beerDocketId) {
  //       s.selectedUserBeerDocket = selectedUserBeerDocketTemp;
  //     }
  //   });
  // }, [beerDockets, beerDocketsRedemptionsBDDict]);

  /////////////////////////////////////////////////////  

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      setCartIcon(true);
    }
    else {
      setCartIcon(false);
    }
  }, [cartItems]);

  useEffect(() => {
    var promotionOutletId = '';

    if (nPromotionNotificationManual && nPromotionNotificationManual.type) {
      // if (nUserOrder.orderType === ORDER_TYPE.DINEIN) {
      //     navigation.navigate('Order');
      // }
      // else {
      //     navigation.navigate('Takeaway');
      // }

      promotionOutletId = nPromotionNotificationManual.outletId;
    }
    else if (nPromotionNotificationAuto && nPromotionNotificationAuto.type) {
      promotionOutletId = nPromotionNotificationAuto.outletId;
    }

    if (promotionOutletId) {
      redirectToPromotionOutlet(promotionOutletId);
    }
  }, [nPromotionNotificationManual, nPromotionNotificationAuto]);

  const redirectToPromotionOutlet = async (outletId) => {
    const outletSnapshot = await firebase.firestore()
      .collection(Collections.Outlet)
      .where('uniqueId', '==', outletId)
      .limit(1)
      .get();

    var outlet = null;
    if (!outletSnapshot.empty) {
      outlet = outletSnapshot.docs[0].data();
    }

    CommonStore.update(s => {
      s.selectedOutlet = outlet;
    }, () => {
      navigationObj.navigate('Outlet', { outletData: outlet });
    });
  };

  useEffect(() => {
    if (nUserOrderCourierAction && nUserOrderCourierAction.type) {
      redirectToOrderHistoryDetails(nUserOrderCourierAction.orderId);
    }
  }, [nUserOrderCourierAction]);

  useEffect(() => {
    firebase.auth().signInAnonymously()
      .then((result) => {
        const firebaseUid = result.user.uid;

        ApiClient.GET(API.getTokenKWeb + firebaseUid).then(async (result) => {
          console.log('getTokenKWeb');
          console.log(result);

          if (result && result.token) {
            await AsyncStorage.setItem('accessToken', result.token);
            await AsyncStorage.setItem('refreshToken', result.refreshToken);

            UserStore.update(s => {
              s.firebaseUid = result.userId;
              s.userId = result.userId;
              s.role = result.role;
              s.refreshToken = result.refreshToken;
              s.token = result.token;
            });
          }
          else {
            CommonStore.update(s => {
              s.alertObj = {
                title: 'Error',
                message: 'Unauthorized access',
              };

              // s.isAuthenticating = false;
            });
          }
        });
      });
  }, []);

  const redirectToOrderHistoryDetails = async (orderId) => {
    const userOrderSnapshot = await firebase.firestore()
      .collection(Collections.UserOrder)
      .where('uniqueId', '==', orderId)
      .limit(1)
      .get();

    var userOrder = null;
    if (!userOrderSnapshot.empty) {
      userOrder = userOrderSnapshot.docs[0].data();
    }

    CommonStore.update(s => {
      s.selectedUserOrder = userOrder;
    });

    navigationObj.navigate("OrderHistoryDetail", { orderId: userOrder.uniqueId });
  };

  ////////////////////////////////////////////////////////

  const updateTokenFcm = async () => {
    const tokenFcm = await AsyncStorage.getItem('tokenFcm');

    if (tokenFcm) {
      const body = {
        tokenFcm: tokenFcm,
        userId: firebaseUid,
      };

      ApiClient.POST(API.updateTokenFcm, body).then((result) => {
        console.log('updated token fcm');
      });
    }
  };

  /////////////////////////////////////////////////////////

  // useEffect(() => {
  //   var availablePromotionsTemp = [];
  //   var availablePointsRedeemPackagesTemp = [];

  //   for (var i = 0; i < selectedOutletPromotions.length; i++) {
  //     var isValid = false;

  //     if (userGroups.includes(selectedOutletPromotions[i].targetUserGroup)) {
  //       isValid = true;
  //     }

  //     if (selectedOutletCRMTagsDict[selectedOutletPromotions[i].targetUserGroup]) {
  //       const currCrmUserTag = selectedOutletCRMTagsDict[selectedOutletPromotions[i].targetUserGroup];

  //       if (currCrmUserTag.emailList.includes(email)) {
  //         // means got

  //         isValid = true;
  //       }
  //     }

  //     if (isValid) {
  //       availablePromotionsTemp.push(selectedOutletPromotions[i]);
  //     }
  //   }

  //   for (var i = 0; i < selectedOutletPointsRedeemPackages.length; i++) {
  //     var isValid = false;

  //     if (userGroups.includes(selectedOutletPointsRedeemPackages[i].targetUserGroup)) {
  //       isValid = true;
  //     }

  //     if (selectedOutletCRMTagsDict[selectedOutletPointsRedeemPackages[i].targetUserGroup]) {
  //       const currCrmUserTag = selectedOutletCRMTagsDict[selectedOutletPointsRedeemPackages[i].targetUserGroup];

  //       if (currCrmUserTag.emailList.includes(email)) {
  //         // means got

  //         isValid = true;
  //       }
  //     }

  //     //////////////////////////////////

  //     if (selectedOutletCRMUser && selectedOutletCRMUser.pointsRedeemPackageDisableDict) {
  //       if (selectedOutletCRMUser.pointsRedeemPackageDisableDict[selectedOutletPointsRedeemPackages[i].uniqueId]) {
  //         isValid = false;
  //       }
  //     }

  //     //////////////////////////////////

  //     if (isValid) {
  //       availablePointsRedeemPackagesTemp.push(selectedOutletPointsRedeemPackages[i]);
  //     }
  //   }

  //   CommonStore.update(s => {
  //     s.availablePromotions = availablePromotionsTemp;
  //     s.availablePointsRedeemPackages = availablePointsRedeemPackagesTemp;
  //   });
  // }, [
  //   selectedOutletPromotions,
  //   selectedOutletPointsRedeemPackages,
  //   userGroups,
  //   email,

  //   selectedOutletCRMTagsDict,
  //   selectedOutletCRMUser,
  // ]);

  // useEffect(() => {
  //   var overrideItemPriceSkuDictTemp = {};
  //   var amountOffItemSkuDictTemp = {};
  //   var percentageOffItemSkuDictTemp = {};

  //   var overrideCategoryPriceNameDictTemp = {};
  //   var amountOffCategoryNameDictTemp = {};
  //   var percentageOffCategoryNameDictTemp = {};

  //   for (var i = 0; i < availablePromotions.length; i++) {
  //     if (availablePromotions[i].promotionType === PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE) {
  //       for (var j = 0; j < availablePromotions[i].criteriaList.length; j++) {
  //         const criteria = availablePromotions[i].criteriaList[j];

  //         if (criteria.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
  //           for (var k = 0; k < criteria.variationItemsSku.length; k++) {
  //             overrideItemPriceSkuDictTemp[criteria.variationItemsSku[k]] = criteria.priceBeforeTax;
  //           }
  //         }
  //         else if (criteria.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
  //           for (var k = 0; k < criteria.variationItemsSku.length; k++) {
  //             overrideCategoryPriceNameDictTemp[criteria.variationItemsSku[k]] = criteria.priceBeforeTax;
  //           }
  //         }
  //       }
  //     }
  //     else if (availablePromotions[i].promotionType === PROMOTION_TYPE.TAKE_AMOUNT_OFF) {
  //       for (var j = 0; j < availablePromotions[i].criteriaList.length; j++) {
  //         const criteria = availablePromotions[i].criteriaList[j];

  //         if (criteria.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
  //           for (var k = 0; k < criteria.variationItemsSku.length; k++) {
  //             amountOffItemSkuDictTemp[criteria.variationItemsSku[k]] = {
  //               amountOff: criteria.amountOff,
  //               maxQuantity: criteria.maxQuantity,
  //               minQuantity: criteria.minQuantity,

  //               quantityMin: criteria.quantityMin,
  //               quantityMax: criteria.quantityMax,
  //               priceMin: criteria.priceMin,
  //               priceMax: criteria.priceMax,
  //             };
  //           }
  //         }
  //         else if (criteria.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
  //           for (var k = 0; k < criteria.variationItemsSku.length; k++) {
  //             amountOffCategoryNameDictTemp[criteria.variationItemsSku[k]] = {
  //               amountOff: criteria.amountOff,
  //               maxQuantity: criteria.maxQuantity,
  //               minQuantity: criteria.minQuantity,

  //               quantityMin: criteria.quantityMin,
  //               quantityMax: criteria.quantityMax,
  //               priceMin: criteria.priceMin,
  //               priceMax: criteria.priceMax,
  //             };
  //           }
  //         }
  //       }
  //     }
  //     else if (availablePromotions[i].promotionType === PROMOTION_TYPE.TAKE_PERCENTAGE_OFF) {
  //       for (var j = 0; j < availablePromotions[i].criteriaList.length; j++) {
  //         const criteria = availablePromotions[i].criteriaList[j];

  //         if (criteria.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
  //           for (var k = 0; k < criteria.variationItemsSku.length; k++) {
  //             percentageOffItemSkuDictTemp[criteria.variationItemsSku[k]] = {
  //               percentageOff: criteria.percentageOff,
  //               maxQuantity: criteria.maxQuantity,
  //               minQuantity: criteria.minQuantity,

  //               quantityMin: criteria.quantityMin,
  //               quantityMax: criteria.quantityMax,
  //               priceMin: criteria.priceMin,
  //               priceMax: criteria.priceMax,
  //             };
  //           }
  //         }
  //         else if (criteria.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
  //           for (var k = 0; k < criteria.variationItemsSku.length; k++) {
  //             percentageOffCategoryNameDictTemp[criteria.variationItemsSku[k]] = {
  //               percentageOff: criteria.percentageOff,
  //               maxQuantity: criteria.maxQuantity,
  //               minQuantity: criteria.minQuantity,

  //               quantityMin: criteria.quantityMin,
  //               quantityMax: criteria.quantityMax,
  //               priceMin: criteria.priceMin,
  //               priceMax: criteria.priceMax,
  //             };
  //           }
  //         }
  //       }
  //     }
  //   }

  //   CommonStore.update(s => {
  //     s.overrideItemPriceSkuDict = overrideItemPriceSkuDictTemp;
  //     s.amountOffItemSkuDict = amountOffItemSkuDictTemp;
  //     s.percentageOffItemSkuDict = percentageOffItemSkuDictTemp;

  //     s.overrideCategoryPriceNameDict = overrideCategoryPriceNameDictTemp;
  //     s.amountOffCategoryNameDict = amountOffCategoryNameDictTemp;
  //     s.percentageOffCategoryNameDict = percentageOffCategoryNameDictTemp;
  //   });
  // }, [availablePromotions]);

  // useEffect(() => {
  //   var pointsRedeemItemSkuDict = {};
  //   var pointsRedeemCategoryNameDict = {};

  //   for (var i = 0; i < availablePointsRedeemPackages.length; i++) {
  //     const pointsRedeemPackage = availablePointsRedeemPackages[i];

  //     if (pointsRedeemPackage.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
  //       for (var k = 0; k < pointsRedeemPackage.variationItemsSku.length; k++) {
  //         pointsRedeemItemSkuDict[pointsRedeemPackage.variationItemsSku[k]] = {
  //           packageId: pointsRedeemPackage.uniqueId,
  //           limitRedemptionPerUser: pointsRedeemPackage.limitRedemptionPerUser,
  //           conversionCurrency: pointsRedeemPackage.conversionCurrency,
  //           conversionCurrencyTo: pointsRedeemPackage.conversionCurrencyTo,
  //           conversionPointsFrom: pointsRedeemPackage.conversionPointsFrom,
  //         };
  //       }
  //     }
  //     else if (pointsRedeemPackage.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
  //       for (var k = 0; k < pointsRedeemPackage.variationItemsSku.length; k++) {
  //         pointsRedeemCategoryNameDict[pointsRedeemPackage.variationItemsSku[k]] = {
  //           packageId: pointsRedeemPackage.uniqueId,
  //           limitRedemptionPerUser: pointsRedeemPackage.limitRedemptionPerUser,
  //           conversionCurrency: pointsRedeemPackage.conversionCurrency,
  //           conversionCurrencyTo: pointsRedeemPackage.conversionCurrencyTo,
  //           conversionPointsFrom: pointsRedeemPackage.conversionPointsFrom,
  //         };
  //       }
  //     }
  //   }

  //   CommonStore.update(s => {
  //     s.pointsRedeemItemSkuDict = pointsRedeemItemSkuDict;
  //     s.pointsRedeemCategoryNameDict = pointsRedeemCategoryNameDict;
  //   });
  // }, [availablePointsRedeemPackages]);

  /////////////////////////////////////////////////////////

  const onCartClicked = () => {
    if (cartItems.length > 0) {
      navigationObj && navigationObj.navigate("Cart", { test: null, outletData: selectedOutlet });
    } else {
      alert("Info: No item in your cart at the moment")
    }
  };

  return (
    <View style={{
      // width: isMobile() ? Dimensions.get('window').width : Dimensions.get('window').width,
      // height: Dimensions.get('window').height,
      width: windowWidth,
      height: windowHeight,
      alignSelf: 'center',
      justifyContent: 'center'
    }}>
      <style type="text/css">{`
        @font-face {
          font-family: 'FontAwesome';
          src: url(${FontAwesomeTTF}) format('truetype');
        }

        @font-face {
          font-family: 'SimpleLineIcons';
          src: url(${SimpleLineIconsTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Entypo';
          src: url(${EntypoTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Ionicons';
          src: url(${IoniconsTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Feather';
          src: url(${FeatherTTF}) format('truetype');
        }

        @font-face {
          font-family: 'AntDesign';
          src: url(${AntDesignTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Fontisto';
          src: url(${FontistoTTF}) format('truetype');
        }

        @font-face {
          font-family: 'NunitoSans-Bold';
          src: url(${NunitoSansBoldTTF}) format('truetype');
        }

        @font-face {
          font-family: 'NunitoSans-SemiBold';
          src: url(${NunitoSansSemiBoldTTF}) format('truetype');
        }

        @font-face {
          font-family: 'NunitoSans-Regular';
          src: url(${NunitoSansRegularTTF}) format('truetype');
        }
      `}</style>

      {/* <AwesomeAlert
        show={alertObj !== null}
        showProgress={false}
        title={alertObj ? alertObj.title : ''}
        message={alertObj ? alertObj.message : ''}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        // cancelText="No, cancel"
        confirmText="OK"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          // this.hideAlert();
          // setShowAlertLogin(false)
          CommonStore.update(s => {
            s.alertObj = null;
          });
        }}
        onConfirmPressed={() => {
          // this.hideAlert();
          // setShowAlertLogin(false)
          CommonStore.update(s => {
            s.alertObj = null;
          });
        }}
        alertContainerStyle={{
          zIndex: 100,
        }}
      /> */}

      <AppNavigator />

    </View>
  );
};

const styles = StyleSheet.create({
  cartCount: {
    position: 'absolute',
    top: -12,
    right: -10,
    backgroundColor: Colors.primaryColor,
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default App;
