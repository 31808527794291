import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Platform,
  Switch,
  Modal,
  KeyboardAvoidingView,
  TextInput,
  ActivityIndicator,
  Picker,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from "react-native";
import Colors from "../constant/Colors";
import { run, sort, stringify, mapAsync } from 'js-coroutines';
// import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
// import Ionicons from 'react-native-vector-icons/Ionicons';
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
// import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { FlatList } from "react-native-gesture-handler";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import Styles from "../constant/Styles";
// import * as User from '../util/User';
import AsyncStorage from "@react-native-async-storage/async-storage";
// import CheckBox from 'react-native-check-box';
import moment from "moment";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
// import {isTablet} from 'react-native-device-detection';
import { CommonStore } from "../store/commonStore";
import { OutletStore } from "../store/outletStore";
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from "../store/userStore";
// import Upload from '../assets/svg/Upload';
// import Download from '../assets/svg/Download';
import {
  convertArrayToCSV,
  countWeekdayOccurrencesInMonth,
  generateEmailReport,
  sortReportDataList,
  isMobile,
  listenToPayoutTransactionsChangesMerchant,
  listenToOrderChangesMerchant,
  listenToPayoutTransactionsChangesForSpecificOutlet,
  listenToPayoutTransactionsChangesForSpecificOutletCmp,
  listenToRPTDailySummary,
  readFromStorage,
  simplifyToSingleDigitRatio,
} from "../util/common";
import {
  EMAIL_REPORT_TYPE,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  ORDER_TYPE,
  MERCHANT_DATA_FILTER,
  APP_TYPE,
  ORDER_TYPE_DETAILS,
  OFFLINE_PAYMENT_METHOD_TYPE,
  PAYMENT_CHANNEL_NAME_PARSED,
  PAYMENT_TYPE_DROPDOWN_LIST,
  PAYMENT_TYPE,
  PAYMENT_CHANNEL_NAME,
  AOV_DATE_RANGE,
  PAYOUT_TABS,
  ONLINE_PAYMENT_METHOD_TYPE,
} from "../constant/common";
// import RNFetchBlob from 'rn-fetch-blob';
// import {useKeyboard} from '../hooks';
// import XLSX from 'xlsx';
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
// import RNPickerSelect from 'react-native-picker-select';
// import AsyncImage from '../components/asyncImage';
// import Feather from 'react-native-vector-icons/Feather';
// import Tooltip from 'react-native-walkthrough-tooltip';

import firebase from "firebase/app";
// import firestore from '@react-native-firebase/firestore';
import { Collections } from "../constant/firebase";

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
import { useLinkTo } from "@react-navigation/native";

import BigNumber from "bignumber.js";

import FusionCharts from "react-fusioncharts";
import FC from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import {
  CHART_DATA,
  CHART_TYPE,
  //FS_LIBRARY_PATH,
  CHART_Y_AXIS_DROPDOWN_LIST,
  CHART_FIELD_COMPARE_DROPDOWN_LIST,
  CHART_FIELD_NAME_DROPDOWN_LIST,
  CHART_FIELD_TYPE,
  CHART_FIELD_COMPARE_DICT,
  CHART_PERIOD,
  CHART_X_AXIS_DROPDOWN_LIST,
  CHART_X_AXIS_TYPE,
} from "../constant/chart";
import {
  filterChartItems,
  getDataForChartDashboardTodaySales,
  getDataForChartReportAovSales,
  getDataForChartReportCmpSales,
  getDataForChartReportOnlineQrSales,
  getDataForChartReportProductSales,
  getDataForChartReportRevisitNum,
  getDataForChartReportUpsellingSales,
  getDataForChartReportAOVUpsellingSales,
  getDataForSalesLineChart,
  getDataForChartReportExpectedActualProfit,
  getDataForChartReportMdr,
} from "../util/chart";
import XLSX from "xlsx";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "../constant/styles.css";
import { BANK_CODE_DROPDOWN_LIST, RAZER_CHANNEL_COMMISIONS_MYR_MIN, RAZER_CHANNEL_COMMISIONS_MYR_RATE, RAZER_MASS_PAYOUT_STATUS_PARSED } from "../constant/razer";

FusionCharts.fcRoot(FC, Column2D, FusionTheme);

const { nanoid } = require("nanoid");
// const RNFS = require('react-native-fs');

window.pOutletLastOrderDict = {};
window.pOutletFilterDict = {};

window.pOutletPaletteColorDict = {};

window.boldOutletQrSalesLineDict = {};

window.currToDateTime = Date.now();

const KooDooPayoutScreen = (props) => {
  const { navigation } = props;

  // Use the debugger to output the debugger messages to JavaScript console.
  // FusionCharts && FusionCharts["debugger"] && FusionCharts["debugger"].enable(true, function (message) {
  //   console.log('debugger');
  //   console.log(message);
  // });

  // const linkTo = useLinkTo();

  // const rootFocusRef = useRef(null);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const keyboardHeight = 0;
  const [visible, setVisible] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [pageReturn, setPageReturn] = useState(1);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);

  // const [ptStartDate, setRev_date] = useState(
  //   moment().subtract(6, "month").startOf("day")
  // );
  // const [ptEndDate, setRev_date1] = useState(
  //   moment().endOf(Date.now()).endOf("day")
  // );

  const userName = UserStore.useState((s) => s.name);

  const [exportEmail, setExportEmail] = useState("");

  const [showDetails, setShowDetails] = useState(false);

  const [exportModalVisibility, setExportModalVisibility] = useState(false);

  const merchantId = UserStore.useState((s) => s.merchantId);
  const isLoading = CommonStore.useState((s) => s.isLoading);
  const [isCsv, setIsCsv] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const allOutletsEmployees = OutletStore.useState(
    (s) => s.allOutletsEmployees
  );

  const [allOutletsEmployeesAction, setAllOutletsEmployeesAction] = useState(
    []
  );

  const [allOutletsEmployeesDetails, setAllOutletsEmployeesDetails] = useState(
    []
  );

  const [tableDataSummary, setTableDataSummary] = useState([]);
  const [tableDataDetails, setTableDataDetails] = useState([]);

  const [toggleCompare, setToggleCompare] = useState(true);

  // const [boldOutletQrSalesLineDict, setBoldOutletQrSalesLineDict] = useState({});
  const [changedBoldTimestamp, setChangedBoldTimestamp] = useState('');

  /////////////////////////////////////////////////////////////////

  const [merchantDataFilterType, setMerchantDataFilterType] = useState(MERCHANT_DATA_FILTER.ACTIVE_24H);
  const [merchantDataAllLength, setMerchantDataAllLength] = useState(0);
  const [merchantData24HLength, setMerchantData24HLength] = useState(0);
  const [merchantData7DLength, setMerchantData7DLength] = useState(0);
  const [merchantData1MLength, setMerchantData1MLength] = useState(0);
  const [merchantData3MLength, setMerchantData3MLength] = useState(0);

  const [outletUserActionDict, setOutletUserActionDict] = useState({});

  /////////////////////////////////////////////////////////////////

  const [selectedPayoutTabs, setSelectedPayoutTabs] = useState(PAYOUT_TABS.DAILY_ACTIVE_MERCHANTS);

  /////////////////////////////////////////////////////////////////

  // 2022-10-24 - Add the support of changing date range also will update details list

  const [showDetailsOutletId, setShowDetailsOutletId] = useState('');

  /////////////////////////////////////////////////////////////////

  const allOutlets = MerchantStore.useState(s => s.allOutlets);

  // const merchantsOnboarded = CommonStore.useState(s => s.merchantsOnboarded);
  const outletsOnboarded = CommonStore.useState(s => s.outletsOnboarded);
  const employeeClocks = CommonStore.useState(s => s.employeeClocks);
  const userActions = CommonStore.useState(s => s.userActions);

  const razerOutletBankInfoList = CommonStore.useState(s => s.razerOutletBankInfoList);
  const razerOutletBankInfoMdrList = CommonStore.useState(s => s.razerOutletBankInfoMdrList);

  const userEmail = UserStore.useState((s) => s.email);

  const ptStartDate = CommonStore.useState(s => s.ptStartDate);
  const ptEndDate = CommonStore.useState(s => s.ptEndDate);
  const payoutTransactions = CommonStore.useState(s => s.payoutTransactions);
  const gmvOrdersRecent = CommonStore.useState(s => s.gmvOrdersRecent); // for those orders that haven't included in razer payout transactions

  const payoutTransactionsExtend = CommonStore.useState(s => s.payoutTransactionsExtend);

  //////////////////////////////////////////////

  // 2022-12-10 - Chart related

  const [
    showDateTimePickerFilterLineChart,
    setShowDateTimePickerFilterLineChart,
  ] = useState(false);
  const [
    showDateTimePickerFilterBarChart,
    setShowDateTimePickerFilterBarChart,
  ] = useState(false);

  const [todaySalesChart, setTodaySalesChart] = useState({});

  // const [todaySalesChartPeriod, setTodaySalesChartPeriod] = useState(CHART_PERIOD.TODAY);

  const [salesLineChart, setSalesLineChart] = useState({});
  const [productSalesChart, setProductSalesChart] = useState({});

  const [expectedActualProfitChart, setExpectedActualProfitChart] = useState({});

  const [onlineQrSalesChart, setOnlineQrSalesChart] = useState({});

  const [aovSalesChart, setAovSalesChart] = useState({});

  const [cmpSalesChart, setCmpSalesChart] = useState({});

  const [repeatCustNumChart, setRepeatCustNumChart] = useState({});

  const [upsellingSalesChart, setUpsellingSalesChart] = useState({});

  const [aovUpsellingSalesChart, setAovUpsellingSalesChart] = useState({});

  const [mdrChart, setMdrChart] = useState({});

  /////////////////////////////////////////////////////////////////////////


  const [salesLineChartPeriod, setSalesLineChartPeriod] = useState(
    CHART_PERIOD.NONE
  );
  const [salesBarChartPeriod, setSalesBarChartPeriod] = useState(
    CHART_PERIOD.NONE
  );

  const [dailySalesDetailsList, setDailySalesDetailsList] = useState([]);

  const [selectedItemSummary, setSelectedItemSummary] = useState({});

  const [selectedChartDropdownValueX, setSelectedChartDropdownValueX] =
    useState(
      CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value
    );

  const [chartDropdownValueXList, setChartDropdownValueXList] = useState(
    CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES]
  );

  const [expandBarSelection, setExpandBarSelection] = useState(
    props.expandBarSelection === undefined ? false : props.expandBarSelection
  );
  // const [barFilterTapped, setBarFilterTapped] = useState(props.barFilterTapped === undefined ? 0 : props.barFilterTapped);
  const [expandLineSelection, setExpandLineSelection] = useState(false);
  // const [lineFilterTapped, setLineFilterTapped] = useState(props.lineFilterTapped === undefined ? 0 : props.lineFilterTapped);

  const [
    selectedChartDropdownValueLineChart,
    setSelectedChartDropdownValueLineChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value
  );
  const [
    selectedChartFilterQueriesLineChart,
    setSelectedChartFilterQueriesLineChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[
          CHART_TYPE.DASHBOARD_LINE_CHART_SALES
        ][0].value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesLineChart,
    setAppliedChartFilterQueriesLineChart,
  ] = useState([]);

  const [
    selectedChartDropdownValueBarChart,
    setSelectedChartDropdownValueBarChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value
  );
  const [
    selectedChartFilterQueriesBarChart,
    setSelectedChartFilterQueriesBarChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesBarChart,
    setAppliedChartFilterQueriesBarChart,
  ] = useState([]);

  const [currReportSummarySort, setCurrReportSummarySort] = useState("");
  const [currReportDetailsSort, setCurrReportDetailsSort] = useState("");

  ////////////////////////////////////////////////////////

  // 2022-12-11 - Click event

  const [clickedChartDate, setClickedChartDate] = useState(null);

  ////////////////////////////////////////////////////////

  // 2022-12-11 - Expand event

  const [expandedSummaryRow, setExpandedSummaryRow] = useState({});

  const [currExpandedRow, setCurrExpandedRow] = useState({});

  ////////////////////////////////////////////////////////

  // 2023-01-04 - For clicking stacked bar chart (pos/qr offline/qr online profit)

  const [clickedBarChartDate, setClickedBarChartDate] = useState(null);
  const [clickedBarChartDateUnit, setClickedBarChartDateUnit] = useState(null);

  ////////////////////////////////////////////////////////

  // 2023-03-17 - Payment type filter

  const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_TYPE.ALL);

  ////////////////////////////////////////////////////////

  // 2023-04-28 - To calculate for the gmv actual

  const [calculatedGmvUserOrdersFigures, setCalculatedGmvUserOrdersFigures] = useState(0);
  const [calculatedGmvActualToday, setCalculatedGmvActualToday] = useState(0);
  const [calculatedGmvActual, setCalculatedGmvActual] = useState(0);
  const [calculatedMdrEarned, setCalculatedMdrEarned] = useState(0);
  const [calculatedActiveMerchants, setCalculatedActiveMerchants] = useState(0);

  const [calculatedTodayGMVActualOutletIdDict, setCalculatedTodayGMVActualOutletIdDict] = useState({});
  const [calculatedTodayMdrEarnedOutletIdDict, setCalculatedTodayMdrEarnedOutletIdDict] = useState({});

  const [koodooProfitExpectedToday, setKooDooProfitExpectedToday] = useState(0);

  ////////////////////////////////////////////////////////

  // 2023-05-08 - To calculate for the aov

  const [outletDropdownList, setOutletDropdownList] = useState([]);

  // default = jungleaf-oasis
  const [selectedAovOutletId, setSelectedAovOutletId] = useState('ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea');

  const [monthYearDropdownList, setMonthYearDropdownList] = useState([]);

  const [selectedMonthYear, setSelectedMonthYear] = useState(moment().startOf('month').startOf('day').valueOf().toString());

  const [selectedAovDateRange, setSelectedAovDateRange] = useState(AOV_DATE_RANGE.PAST_1_MONTH);

  const [outletPayoutTransactionsProcessed, setOutletPayoutTransactionsProcessed] = useState([]);

  const aovStartDate = CommonStore.useState(s => s.aovStartDate);
  const aovEndDate = CommonStore.useState(s => s.aovEndDate);

  const outletPayoutTransactions = CommonStore.useState(s => s.outletPayoutTransactions);
  const outletOrdersRecent = CommonStore.useState(s => s.outletOrdersRecent);

  const outletPayoutTransactionsExtend = CommonStore.useState(s => s.outletPayoutTransactionsExtend);

  ////////////////////////////////////////////////////////

  // 2023-05-22 - To calculate for the comparison

  // default = jungleaf-oasis
  const [selectedCmpOutletId, setSelectedCmpOutletId] = useState('ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea');

  // const [selectedTabOutletId, setSelectedTabOutletId] = useState('ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea');
  const [selectedTabOutletId, setSelectedTabOutletId] = useState('ALL');
  const [tabOutletDropdownList, setTabOutletDropdownList] = useState([]);

  const [cmpOutletPayoutTransactionsProcessed, setCmpOutletPayoutTransactionsProcessed] = useState([]);

  const cmpStartDate = CommonStore.useState(s => s.cmpStartDate);
  const cmpEndDate = CommonStore.useState(s => s.cmpEndDate);

  const cmpOutletPayoutTransactions = CommonStore.useState(s => s.cmpOutletPayoutTransactions);
  const cmpOutletPayoutTransactionsExtend = CommonStore.useState(s => s.cmpOutletPayoutTransactionsExtend);
  const cmpOutletOrdersRecent = CommonStore.useState(s => s.cmpOutletOrdersRecent);

  const [cmpMultiSelectZIndex, setCmpMultiSelectZIndex] = useState(9999);

  const [gmMultiSelectZIndex, setGmMultiSelectZIndex] = useState(99);

  ////////////////////////////////////////////////////////

  const rptDailySummaryList = CommonStore.useState(s => s.rptDailySummaryList);
  const rptDsStartDate = CommonStore.useState(s => s.rptDsStartDate);
  const rptDsEndDate = CommonStore.useState(s => s.rptDsEndDate);

  ////////////////////////////////////////////////////////

  // 2024-03-19 - best sellers related

  const [showDetailsBs, setShowDetailsBs] = useState(false);

  const [toggleCompareBs, setToggleCompareBs] = useState(true);

  const [tableDataSummaryItems, setTableDataSummaryItems] = useState([]);
  const [tableDataSummaryAddons, setTableDataSummaryAddons] = useState([]);
  const [tabBestSeller, setTabBestSeller] = useState('ITEMS');

  const [tableDataSummaryBs, setTableDataSummaryBs] = useState([]);
  const [tableDataDetailsBs, setTableDataDetailsBs] = useState([]);

  const [expandedSummaryRowBs, setExpandedSummaryRowBs] = useState({});

  /////////////////////////////////////////////////////////////////////

  const [toggleCompareAo, setToggleCompareAo] = useState(true);

  const [tableDataSummaryAo, setTableDataSummaryAo] = useState([]);

  /////////////////////////////////////////////////////////////////////

  const [outletItems, setOutletItems] = useState([]);
  const [outletItemsDt, setOutletItemsDt] = useState(Date.now());
  const [outletItemsLength, setOutletItemsLength] = useState(0);
  const [outletIdPrev, setOutletIdPrev] = useState('');

  ////////////////////////////////////////////////////////

  // 2024-09-29 - auto login

  useEffect(() => {
    setTimeout(async () => {
      const userEmailRaw = await AsyncStorage.getItem('userEmail');

      if (typeof userEmailRaw === 'string' && userEmailRaw.includes('@')) {
        UserStore.update(s => {
          s.email = userEmailRaw;
        });
      }
    }, 100);
  }, []);

  ////////////////////////////////////////////////////////

  // 2024-04-05 - Addons view support

  useEffect(() => {
    if (selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS) {
      setExpandedSummaryRowBs({});

      if (tabBestSeller === 'ITEMS') {
        setTableDataSummaryBs(tableDataSummaryItems);
        setPageCount(tableDataSummaryItems.length / perPage);
      }
      else if (tabBestSeller === 'ADDONS') {
        setTableDataSummaryBs(tableDataSummaryAddons);
        setPageCount(tableDataSummaryAddons.length / perPage);
      }
    }
    else {
      setExpandedSummaryRowBs({});
      setTableDataSummaryBs([]);
    }
  }, [
    tableDataSummaryItems,
    tableDataSummaryAddons,

    tabBestSeller,
    selectedPayoutTabs,
  ]);

  ////////////////////////////////////////////////////////

  // 2024-08-11 - AR overview

  // useEffect(() => {
  //   if (selectedPayoutTabs === PAYOUT_TABS.AR_OVERVIEW) {
  //     // setExpandedSummaryRowBs({});

  //     if (tabBestSeller === 'ITEMS') {
  //       setTableDataSummaryBs(tableDataSummaryItems);
  //       setPageCount(tableDataSummaryItems.length / perPage);
  //     }
  //     else if (tabBestSeller === 'ADDONS') {
  //       setTableDataSummaryBs(tableDataSummaryAddons);
  //       setPageCount(tableDataSummaryAddons.length / perPage);
  //     }
  //   }
  //   else {
  //     setExpandedSummaryRowBs({});
  //     setTableDataSummaryBs([]);
  //   }
  // }, [
  //   tableDataSummaryItems,
  //   tableDataSummaryAddons,

  //   tabBestSeller,
  //   selectedPayoutTabs,
  // ]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    setOutletDropdownList(outletsOnboarded.map(outlet => ({
      label: outlet.name,
      value: outlet.uniqueId,
    })));

    const dataOutletNames = tableDataSummary.filter(data => data.totalOrdersAmount > 0).map(data => data.outletName);

    let tabOutletDropdownListTemp = [{
      label: 'All',
      value: 'ALL',
    }].concat(outletsOnboarded
      .filter(outlet => {
        if (dataOutletNames.includes(outlet.name)) {
          return true;
        }
        else {
          return false;
        }
      })
      .map(outlet => ({
        label: outlet.name,
        value: outlet.uniqueId,
      })));

    if (tabOutletDropdownListTemp.find(tabOutlet => tabOutlet.value === selectedTabOutletId)) {
      // found, remain the selected outlet id first
    }
    else {
      // not found

      setSelectedTabOutletId('ALL');
    }

    setTabOutletDropdownList(tabOutletDropdownListTemp);
  }, [outletsOnboarded, tableDataSummary]);

  useEffect(() => {
    var monthYearDropdownListTemp = [];

    var startDateTime = 1667232000000;
    var endDateTime = moment().add(1, 'month').startOf('month').startOf('day').valueOf();

    const diffMonths = moment(endDateTime).diff(startDateTime, 'month');

    for (var i = 0; i < diffMonths; i++) {
      var currDateTime = moment(startDateTime).add(i, 'month').startOf('month').startOf('day').valueOf();

      monthYearDropdownListTemp.push({
        label: moment(currDateTime).format('YYYY MMM'),
        value: currDateTime.toString(),
      });
    }

    monthYearDropdownListTemp.reverse();

    setMonthYearDropdownList(monthYearDropdownListTemp);
    setSelectedMonthYear(monthYearDropdownListTemp[0].value);
  }, []);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    let outletPayoutTransactionsProcessedTemp = [];

    for (let i = 0; i < outletPayoutTransactions.length; i++) {
      for (let j = 0; j < outletPayoutTransactions[i].userOrdersFigures.length; j++) {
        if (outletPayoutTransactions[i].userOrdersFigures[j].combinedOrderList && outletPayoutTransactions[i].userOrdersFigures[j].combinedOrderList.length > 0) {
          // means this order already merged with other orders         
        }
        else {
          let userOrderFigure = outletPayoutTransactions[i].userOrdersFigures[j];

          //////////////////////////////////////////////////

          // let toProceedPaymentType = false;
          // let channel = (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel;

          // if (selectedPaymentType === PAYMENT_TYPE.ALL) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.E_WALLET &&
          //   (
          //     channel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
          //     channel === PAYMENT_CHANNEL_NAME.ShopeePay ||
          //     channel === PAYMENT_CHANNEL_NAME.GrabPay ||
          //     channel === PAYMENT_CHANNEL_NAME.BOOST ||
          //     channel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.FPX &&
          //   (
          //     channel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
          //     channel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
          //     channel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
          //     channel === PAYMENT_CHANNEL_NAME['ALB-Paymex'] ||
          //     channel === PAYMENT_CHANNEL_NAME['MB2u'] ||
          //     channel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
          //     channel === PAYMENT_CHANNEL_NAME['Cash-epay'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_HLB']
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.CREDIT_DEBIT &&
          //   (
          //     channel === PAYMENT_CHANNEL_NAME['credit'] ||
          //     channel === PAYMENT_CHANNEL_NAME['Credit'] ||
          //     channel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
          //     channel === PAYMENT_CHANNEL_NAME['CIL_Visa']
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.OFFLINE &&
          //   (
          //     channel.startsWith('Offline')
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else {
          //   toProceedPaymentType = false;
          // }

          // if (!toProceedPaymentType) {
          //   continue;
          // }

          //////////////////////////////////////////////////

          // push to details list

          let toAddObj = {
            dateTime: moment(userOrderFigure.createdAt || outletPayoutTransactions[i].createdAt).format('YYYY/MM/DD HH:mm'),
            orderId: (userOrderFigure.orderIdHuman && userOrderFigure.orderType) ? `#${userOrderFigure.orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${userOrderFigure.orderIdHuman}` : 'N/A',
            uniqueId: userOrderFigure.orderId,

            // uniqueId: payoutTransactionsFiltered[i].uniqueId,
            isQrOrder: (userOrderFigure.appType !== undefined ? (userOrderFigure.appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No') : 'N/A'),
            netAmount: BigNumber(0).dp(2).toNumber(),
            tax: BigNumber(0).dp(2).toNumber(),
            sc: BigNumber(0).dp(2).toNumber(),
            totalAmount: BigNumber(userOrderFigure.userOrderPriceBeforeCommission).dp(2).toNumber(),
            razerCharges: BigNumber(userOrderFigure.commissionFeeFinal).dp(2).toNumber(),
            koodooCharges: BigNumber(userOrderFigure.koodooProcessingFee).dp(2).toNumber(),
            outletPayout: BigNumber(userOrderFigure.commissionFeeFinal > 0 ? userOrderFigure.userOrderPriceAfterCommissionAndFee : 0).dp(2).toNumber(),
            settlementDate: moment(outletPayoutTransactions[i].createdAt).format('YYYY/MM/DD'),

            ...userOrderFigure.kpfOd && {
              kpfOd: BigNumber(userOrderFigure.kpfOd).dp(2).toNumber(),
            },
            ...userOrderFigure.cfOd && {
              cfOd: BigNumber(userOrderFigure.cfOd).dp(2).toNumber(),
            },

            // ...(outletPayoutTransactions[i].rsc !== undefined) && {
            //   rsc: outletPayoutTransactions[i].rsc,
            // },

            cfe: BigNumber(userOrderFigure.cfe ? userOrderFigure.cfe : 0).dp(2).toNumber(),

            isSettled: true,

            // gmvRate: gmvRate,

            orderTypeDetails: (userOrderFigure.appType === APP_TYPE.WEB_ORDER ?
              ((userOrderFigure.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
              :
              ORDER_TYPE_DETAILS.POS),

            outletId: outletPayoutTransactions[i].outletId,
            // on: outletPayoutTransactions[i].outletName,

            koodooProcessingRate: userOrderFigure.koodooProcessingRate || 0,

            pdChannel: (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

            cartItemsLength: userOrderFigure.cartItems.length,
          };

          outletPayoutTransactionsProcessedTemp.push(toAddObj);

          //////////////////////////////////////////////////////////////////////
        }
      }
    }

    for (let i = 0; i < outletPayoutTransactionsExtend.length; i++) {
      for (let j = 0; j < outletPayoutTransactionsExtend[i].userOrdersFigures.length; j++) {
        if (outletPayoutTransactionsExtend[i].userOrdersFigures[j].combinedOrderList && outletPayoutTransactionsExtend[i].userOrdersFigures[j].combinedOrderList.length > 0) {
          // means this order already merged with other orders         
        }
        else {
          let userOrderFigure = outletPayoutTransactionsExtend[i].userOrdersFigures[j];

          //////////////////////////////////////////////////

          // let toProceedPaymentType = false;
          // let channel = (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel;

          // if (selectedPaymentType === PAYMENT_TYPE.ALL) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.E_WALLET &&
          //   (
          //     channel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
          //     channel === PAYMENT_CHANNEL_NAME.ShopeePay ||
          //     channel === PAYMENT_CHANNEL_NAME.GrabPay ||
          //     channel === PAYMENT_CHANNEL_NAME.BOOST ||
          //     channel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.FPX &&
          //   (
          //     channel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
          //     channel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
          //     channel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
          //     channel === PAYMENT_CHANNEL_NAME['ALB-Paymex'] ||
          //     channel === PAYMENT_CHANNEL_NAME['MB2u'] ||
          //     channel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
          //     channel === PAYMENT_CHANNEL_NAME['Cash-epay'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
          //     channel === PAYMENT_CHANNEL_NAME['FPX_HLB']
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.CREDIT_DEBIT &&
          //   (
          //     channel === PAYMENT_CHANNEL_NAME['credit'] ||
          //     channel === PAYMENT_CHANNEL_NAME['Credit'] ||
          //     channel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
          //     channel === PAYMENT_CHANNEL_NAME['CIL_Visa']
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else if (
          //   selectedPaymentType === PAYMENT_TYPE.OFFLINE &&
          //   (
          //     channel.startsWith('Offline')
          //   )
          // ) {
          //   toProceedPaymentType = true;
          // }
          // else {
          //   toProceedPaymentType = false;
          // }

          // if (!toProceedPaymentType) {
          //   continue;
          // }

          //////////////////////////////////////////////////

          // push to details list

          let toAddObj = {
            dateTime: moment(userOrderFigure.createdAt || outletPayoutTransactionsExtend[i].createdAt).format('YYYY/MM/DD HH:mm'),
            orderId: (userOrderFigure.orderIdHuman && userOrderFigure.orderType) ? `#${userOrderFigure.orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${userOrderFigure.orderIdHuman}` : 'N/A',
            uniqueId: userOrderFigure.orderId,

            // uniqueId: payoutTransactionsFiltered[i].uniqueId,
            isQrOrder: (userOrderFigure.appType !== undefined ? (userOrderFigure.appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No') : 'N/A'),
            netAmount: BigNumber(0).dp(2).toNumber(),
            tax: BigNumber(0).dp(2).toNumber(),
            sc: BigNumber(0).dp(2).toNumber(),
            totalAmount: BigNumber(userOrderFigure.userOrderPriceBeforeCommission).dp(2).toNumber(),
            razerCharges: BigNumber(userOrderFigure.commissionFeeFinal).dp(2).toNumber(),
            koodooCharges: BigNumber(userOrderFigure.koodooProcessingFee).dp(2).toNumber(),
            outletPayout: BigNumber(userOrderFigure.commissionFeeFinal > 0 ? userOrderFigure.userOrderPriceAfterCommissionAndFee : 0).dp(2).toNumber(),
            settlementDate: moment(outletPayoutTransactionsExtend[i].createdAt).format('YYYY/MM/DD'),

            ...userOrderFigure.kpfOd && {
              kpfOd: BigNumber(userOrderFigure.kpfOd).dp(2).toNumber(),
            },
            ...userOrderFigure.cfOd && {
              cfOd: BigNumber(userOrderFigure.cfOd).dp(2).toNumber(),
            },

            // ...(outletPayoutTransactionsExtend[i].rsc !== undefined) && {
            //   rsc: outletPayoutTransactionsExtend[i].rsc,
            // },

            cfe: BigNumber(userOrderFigure.cfe ? userOrderFigure.cfe : 0).dp(2).toNumber(),

            isSettled: true,

            // gmvRate: gmvRate,

            orderTypeDetails: (userOrderFigure.appType === APP_TYPE.WEB_ORDER ?
              ((userOrderFigure.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
              :
              ORDER_TYPE_DETAILS.POS),

            outletId: outletPayoutTransactionsExtend[i].outletId,
            // on: outletPayoutTransactionsExtend[i].outletName,

            koodooProcessingRate: userOrderFigure.koodooProcessingRate || 0,

            pdChannel: (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

            cartItemsLength: userOrderFigure.cartItems.length,
          };

          outletPayoutTransactionsProcessedTemp.push(toAddObj);

          //////////////////////////////////////////////////////////////////////
        }
      }
    }

    for (let i = 0; i < outletOrdersRecent.length; i++) {
      if (
        !outletPayoutTransactionsProcessedTemp.find(order => order.uniqueId === outletOrdersRecent[i].uniqueId)
      ) {
        outletPayoutTransactionsProcessedTemp.push({
          dateTime: moment(outletOrdersRecent[i].createdAt).format('YYYY/MM/DD HH:mm'),
          orderId: `#${outletOrdersRecent[i].orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${outletOrdersRecent[i].orderId}`,
          uniqueId: outletOrdersRecent[i].uniqueId,
          isQrOrder: outletOrdersRecent[i].appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No',
          netAmount: BigNumber(outletOrdersRecent[i].finalPrice).minus(outletOrdersRecent[i].tax).minus(outletOrdersRecent[i].sc).dp(2).toNumber(),
          tax: BigNumber(outletOrdersRecent[i].tax).dp(2).toNumber(),
          sc: BigNumber(outletOrdersRecent[i].sc).dp(2).toNumber(),
          totalAmount: BigNumber(outletOrdersRecent[i].finalPrice).dp(2).toNumber(),
          razerCharges: BigNumber((outletOrdersRecent[i].settlementDetails && outletOrdersRecent[i].settlementDetails.commissionFeeFinal) ? outletOrdersRecent[i].settlementDetails.commissionFeeFinal : 0).dp(2).toNumber(),
          koodooCharges: BigNumber((outletOrdersRecent[i].settlementDetails && outletOrdersRecent[i].settlementDetails.koodooProcessingFee) ? outletOrdersRecent[i].settlementDetails.koodooProcessingFee : 0).dp(2).toNumber(),
          outletPayout: BigNumber(
            (outletOrdersRecent[i].paymentDetails &&
              (outletOrdersRecent[i].paymentDetails.txn_ID !== undefined ||
                outletOrdersRecent[i].paymentDetails.txnId !== undefined)
              &&
              (outletOrdersRecent[i].settlementDetails && outletOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
            )
              ?
              outletOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee
              :
              0)
            .dp(2).toNumber(),
          settlementDate: outletOrdersRecent[i].settlementDate ? moment(outletOrdersRecent[i].settlementDate).format('YYYY/MM/DD') : 'N/A',

          ...outletOrdersRecent[i].settlementDetails && outletOrdersRecent[i].settlementDetails.kpfOd && {
            kpfOd: BigNumber(outletOrdersRecent[i].settlementDetails.kpfOd).dp(2).toNumber(),
          },
          ...outletOrdersRecent[i].settlementDetails && outletOrdersRecent[i].settlementDetails.cfOd && {
            cfOd: BigNumber(outletOrdersRecent[i].settlementDetails.cfOd).dp(2).toNumber(),
          },

          cfe: BigNumber((outletOrdersRecent[i].settlementDetails && outletOrdersRecent[i].settlementDetails.cfe) ? outletOrdersRecent[i].settlementDetails.cfe : 0).dp(2).toNumber(),

          isSettled: outletOrdersRecent[i].settlementDate ? true : false,

          orderTypeDetails: (outletOrdersRecent[i].appType === APP_TYPE.WEB_ORDER ?
            ((outletOrdersRecent[i].settlementDetails && outletOrdersRecent[i].settlementDetails.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
            :
            ORDER_TYPE_DETAILS.POS),

          outletId: outletOrdersRecent[i].outletId,
          // on: outletOrdersRecent[i].outletName,

          koodooProcessingRate: outletOrdersRecent[i].koodooProcessingRate || 0,

          pdChannel: (outletOrdersRecent[i].paymentDetails && outletOrdersRecent[i].paymentDetails.channel) ? outletOrdersRecent[i].paymentDetails.channel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

          cartItemsLength: outletOrdersRecent[i].cartItems.length,
        });
      }
    }

    setOutletPayoutTransactionsProcessed(outletPayoutTransactionsProcessedTemp);
  }, [
    outletOrdersRecent,
    outletPayoutTransactions,

    outletPayoutTransactionsExtend,
  ]);

  ////////////////////////////////////////////////////////

  // 2023-05-22 - Cmp sales data processing

  useEffect(() => {
    let outletPayoutTransactionsProcessedTemp = [];

    for (let i = 0; i < cmpOutletPayoutTransactions.length; i++) {
      for (let j = 0; j < cmpOutletPayoutTransactions[i].userOrdersFigures.length; j++) {
        if (cmpOutletPayoutTransactions[i].userOrdersFigures[j].combinedOrderList && cmpOutletPayoutTransactions[i].userOrdersFigures[j].combinedOrderList.length > 0) {
          // means this order already merged with other orders         
        }
        else {
          let userOrderFigure = cmpOutletPayoutTransactions[i].userOrdersFigures[j];

          //////////////////////////////////////////////////

          // push to details list

          let toAddObj = {
            dateTime: moment(userOrderFigure.createdAt || cmpOutletPayoutTransactions[i].createdAt).format('YYYY/MM/DD HH:mm'),
            orderId: (userOrderFigure.orderIdHuman && userOrderFigure.orderType) ? `#${userOrderFigure.orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${userOrderFigure.orderIdHuman}` : 'N/A',
            uniqueId: userOrderFigure.orderId,

            // uniqueId: payoutTransactionsFiltered[i].uniqueId,
            isQrOrder: (userOrderFigure.appType !== undefined ? (userOrderFigure.appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No') : 'N/A'),
            netAmount: BigNumber(0).dp(2).toNumber(),
            tax: BigNumber(0).dp(2).toNumber(),
            sc: BigNumber(0).dp(2).toNumber(),
            totalAmount: BigNumber(userOrderFigure.userOrderPriceBeforeCommission).dp(2).toNumber(),
            razerCharges: BigNumber(userOrderFigure.commissionFeeFinal).dp(2).toNumber(),
            koodooCharges: BigNumber(userOrderFigure.koodooProcessingFee).dp(2).toNumber(),
            outletPayout: BigNumber(userOrderFigure.commissionFeeFinal > 0 ? userOrderFigure.userOrderPriceAfterCommissionAndFee : 0).dp(2).toNumber(),
            settlementDate: moment(cmpOutletPayoutTransactions[i].createdAt).format('YYYY/MM/DD'),

            ...userOrderFigure.kpfOd && {
              kpfOd: BigNumber(userOrderFigure.kpfOd).dp(2).toNumber(),
            },
            ...userOrderFigure.cfOd && {
              cfOd: BigNumber(userOrderFigure.cfOd).dp(2).toNumber(),
            },

            cfe: BigNumber(userOrderFigure.cfe ? userOrderFigure.cfe : 0).dp(2).toNumber(),

            isSettled: true,

            // gmvRate: gmvRate,

            orderTypeDetails: (userOrderFigure.appType === APP_TYPE.WEB_ORDER ?
              ((userOrderFigure.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
              :
              ORDER_TYPE_DETAILS.POS),

            outletId: cmpOutletPayoutTransactions[i].outletId,
            // on: cmpOutletPayoutTransactions[i].outletName,

            koodooProcessingRate: userOrderFigure.koodooProcessingRate || 0,

            pdChannel: (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

            repeatCust: userOrderFigure.repeatCust ? userOrderFigure.repeatCust : false,
            userPhone: userOrderFigure.userPhone ? userOrderFigure.userPhone : '',

            cartItems: userOrderFigure.cartItems,

            cartItemsLength: userOrderFigure.cartItems.length,
          };

          outletPayoutTransactionsProcessedTemp.push(toAddObj);

          //////////////////////////////////////////////////////////////////////
        }
      }
    }

    for (let i = 0; i < cmpOutletPayoutTransactionsExtend.length; i++) {
      for (let j = 0; j < cmpOutletPayoutTransactionsExtend[i].userOrdersFigures.length; j++) {
        if (cmpOutletPayoutTransactionsExtend[i].userOrdersFigures[j].combinedOrderList && cmpOutletPayoutTransactionsExtend[i].userOrdersFigures[j].combinedOrderList.length > 0) {
          // means this order already merged with other orders         
        }
        else {
          let userOrderFigure = cmpOutletPayoutTransactionsExtend[i].userOrdersFigures[j];

          //////////////////////////////////////////////////

          // push to details list

          let toAddObj = {
            dateTime: moment(userOrderFigure.createdAt || cmpOutletPayoutTransactionsExtend[i].createdAt).format('YYYY/MM/DD HH:mm'),
            orderId: (userOrderFigure.orderIdHuman && userOrderFigure.orderType) ? `#${userOrderFigure.orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${userOrderFigure.orderIdHuman}` : 'N/A',
            uniqueId: userOrderFigure.orderId,

            // uniqueId: payoutTransactionsFiltered[i].uniqueId,
            isQrOrder: (userOrderFigure.appType !== undefined ? (userOrderFigure.appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No') : 'N/A'),
            netAmount: BigNumber(0).dp(2).toNumber(),
            tax: BigNumber(0).dp(2).toNumber(),
            sc: BigNumber(0).dp(2).toNumber(),
            totalAmount: BigNumber(userOrderFigure.userOrderPriceBeforeCommission).dp(2).toNumber(),
            razerCharges: BigNumber(userOrderFigure.commissionFeeFinal).dp(2).toNumber(),
            koodooCharges: BigNumber(userOrderFigure.koodooProcessingFee).dp(2).toNumber(),
            outletPayout: BigNumber(userOrderFigure.commissionFeeFinal > 0 ? userOrderFigure.userOrderPriceAfterCommissionAndFee : 0).dp(2).toNumber(),
            settlementDate: moment(cmpOutletPayoutTransactionsExtend[i].createdAt).format('YYYY/MM/DD'),

            ...userOrderFigure.kpfOd && {
              kpfOd: BigNumber(userOrderFigure.kpfOd).dp(2).toNumber(),
            },
            ...userOrderFigure.cfOd && {
              cfOd: BigNumber(userOrderFigure.cfOd).dp(2).toNumber(),
            },

            cfe: BigNumber(userOrderFigure.cfe ? userOrderFigure.cfe : 0).dp(2).toNumber(),

            isSettled: true,

            // gmvRate: gmvRate,

            orderTypeDetails: (userOrderFigure.appType === APP_TYPE.WEB_ORDER ?
              ((userOrderFigure.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
              :
              ORDER_TYPE_DETAILS.POS),

            outletId: cmpOutletPayoutTransactionsExtend[i].outletId,
            // on: cmpOutletPayoutTransactionsExtend[i].outletName,

            koodooProcessingRate: userOrderFigure.koodooProcessingRate || 0,

            pdChannel: (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

            repeatCust: userOrderFigure.repeatCust ? userOrderFigure.repeatCust : false,
            userPhone: userOrderFigure.userPhone ? userOrderFigure.userPhone : '',

            cartItems: userOrderFigure.cartItems,

            cartItemsLength: userOrderFigure.cartItems.length,
          };

          outletPayoutTransactionsProcessedTemp.push(toAddObj);

          //////////////////////////////////////////////////////////////////////
        }
      }
    }

    for (let i = 0; i < cmpOutletOrdersRecent.length; i++) {
      if (
        !outletPayoutTransactionsProcessedTemp.find(order => order.uniqueId === cmpOutletOrdersRecent[i].uniqueId)
      ) {
        outletPayoutTransactionsProcessedTemp.push({
          dateTime: moment(cmpOutletOrdersRecent[i].createdAt).format('YYYY/MM/DD HH:mm'),
          orderId: `#${cmpOutletOrdersRecent[i].orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${cmpOutletOrdersRecent[i].orderId}`,
          uniqueId: cmpOutletOrdersRecent[i].uniqueId,
          isQrOrder: cmpOutletOrdersRecent[i].appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No',
          netAmount: BigNumber(cmpOutletOrdersRecent[i].finalPrice).minus(cmpOutletOrdersRecent[i].tax).minus(cmpOutletOrdersRecent[i].sc).dp(2).toNumber(),
          tax: BigNumber(cmpOutletOrdersRecent[i].tax).dp(2).toNumber(),
          sc: BigNumber(cmpOutletOrdersRecent[i].sc).dp(2).toNumber(),
          totalAmount: BigNumber(cmpOutletOrdersRecent[i].finalPrice).dp(2).toNumber(),
          razerCharges: BigNumber((cmpOutletOrdersRecent[i].settlementDetails && cmpOutletOrdersRecent[i].settlementDetails.commissionFeeFinal) ? cmpOutletOrdersRecent[i].settlementDetails.commissionFeeFinal : 0).dp(2).toNumber(),
          koodooCharges: BigNumber((cmpOutletOrdersRecent[i].settlementDetails && cmpOutletOrdersRecent[i].settlementDetails.koodooProcessingFee) ? cmpOutletOrdersRecent[i].settlementDetails.koodooProcessingFee : 0).dp(2).toNumber(),
          outletPayout: BigNumber(
            (cmpOutletOrdersRecent[i].paymentDetails &&
              (cmpOutletOrdersRecent[i].paymentDetails.txn_ID !== undefined ||
                cmpOutletOrdersRecent[i].paymentDetails.txnId !== undefined)
              &&
              (cmpOutletOrdersRecent[i].settlementDetails && cmpOutletOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
            )
              ?
              cmpOutletOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee
              :
              0)
            .dp(2).toNumber(),
          settlementDate: cmpOutletOrdersRecent[i].settlementDate ? moment(cmpOutletOrdersRecent[i].settlementDate).format('YYYY/MM/DD') : 'N/A',

          ...cmpOutletOrdersRecent[i].settlementDetails && cmpOutletOrdersRecent[i].settlementDetails.kpfOd && {
            kpfOd: BigNumber(cmpOutletOrdersRecent[i].settlementDetails.kpfOd).dp(2).toNumber(),
          },
          ...cmpOutletOrdersRecent[i].settlementDetails && cmpOutletOrdersRecent[i].settlementDetails.cfOd && {
            cfOd: BigNumber(cmpOutletOrdersRecent[i].settlementDetails.cfOd).dp(2).toNumber(),
          },

          cfe: BigNumber((cmpOutletOrdersRecent[i].settlementDetails && cmpOutletOrdersRecent[i].settlementDetails.cfe) ? cmpOutletOrdersRecent[i].settlementDetails.cfe : 0).dp(2).toNumber(),

          isSettled: cmpOutletOrdersRecent[i].settlementDate ? true : false,

          orderTypeDetails: (cmpOutletOrdersRecent[i].appType === APP_TYPE.WEB_ORDER ?
            ((cmpOutletOrdersRecent[i].settlementDetails && cmpOutletOrdersRecent[i].settlementDetails.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
            :
            ORDER_TYPE_DETAILS.POS),

          outletId: cmpOutletOrdersRecent[i].outletId,
          // on: cmpOutletOrdersRecent[i].outletName,

          koodooProcessingRate: cmpOutletOrdersRecent[i].koodooProcessingRate || 0,

          pdChannel: (cmpOutletOrdersRecent[i].paymentDetails && cmpOutletOrdersRecent[i].paymentDetails.channel) ? cmpOutletOrdersRecent[i].paymentDetails.channel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

          repeatCust: cmpOutletOrdersRecent[i].repeatCust ? cmpOutletOrdersRecent[i].repeatCust : false,
          userPhone: cmpOutletOrdersRecent[i].userPhone ? cmpOutletOrdersRecent[i].userPhone : '',

          cartItems: cmpOutletOrdersRecent[i].cartItems,

          cartItemsLength: cmpOutletOrdersRecent[i].cartItems.length,
        });
      }
    }

    setCmpOutletPayoutTransactionsProcessed(outletPayoutTransactionsProcessedTemp);
  }, [
    cmpOutletOrdersRecent,
    cmpOutletPayoutTransactions,

    cmpOutletPayoutTransactionsExtend,
  ]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    var calculatedGmvUserOrdersFiguresTemp = [];
    var calculatedGmvActualTemp = BigNumber(0);
    var calculatedGmvActualTodayTemp = BigNumber(0);
    var calculatedMdrEarnedTemp = BigNumber(0);
    var calculatedMdrEarnedTodayTemp = BigNumber(0);
    var calculatedActiveMerchantsList = [];

    var calculatedTodayGMVActualOutletIdDictTemp = {};
    var calculatedTodayMdrEarnedOutletIdDictTemp = {};

    for (var i = 0; i < tableDataSummary.length; i++) {
      // console.log('test');
      // console.log(tableDataSummary[i].gmvCommissionActual);
      // console.log(BigNumber(calculatedGmvActualTemp).toNumber());

      calculatedGmvActualTemp = BigNumber(calculatedGmvActualTemp).plus(tableDataSummary[i].gmvCommissionActual);
      calculatedMdrEarnedTemp = BigNumber(calculatedMdrEarnedTemp).plus(tableDataSummary[i].mdrEarned ? tableDataSummary[i].mdrEarned : 0);

      if (tableDataSummary[i].totalOrdersQty > 0 && tableDataSummary[i].gmvCommissionActual > 0) {
        if (!calculatedActiveMerchantsList.includes(tableDataSummary[i].outletName)) {
          calculatedActiveMerchantsList.push(tableDataSummary[i].outletName);
        }
      }
    }

    if (
      (moment().isSame(ptStartDate, 'day') || moment().isSame(ptEndDate, 'day'))
      &&
      moment().isSameOrAfter(moment().set({
        hour: 10,
        minute: 30,
      }))
    ) {
      // valid

      const razerOutletBankInfoListFiltered = razerOutletBankInfoList.filter((item) => {
        return filterItem(item);
      });

      for (var i = 0; i < razerOutletBankInfoListFiltered.length; i++) {
        // console.log('test 2');
        // console.log(razerOutletBankInfoListFiltered[i].liveTodayKooDooPayoutsActual);
        // console.log(BigNumber(calculatedGmvActualTemp).toNumber());

        // no need first
        // calculatedGmvUserOrdersFiguresTemp = calculatedGmvUserOrdersFiguresTemp.concat(razerOutletBankInfoListFiltered[i].userOrdersFigures ? razerOutletBankInfoListFiltered[i].userOrdersFigures.map(order => {
        //   return {
        //     ...order,
        //     transactionDate: order.createdAt,
        //   };
        // }) : []);

        if (selectedTabOutletId === razerOutletBankInfoListFiltered[i].outletId || selectedTabOutletId === 'ALL') {
          calculatedGmvActualTemp = BigNumber(calculatedGmvActualTemp).plus(razerOutletBankInfoListFiltered[i].liveTodayKooDooPayoutsActual ? razerOutletBankInfoListFiltered[i].liveTodayKooDooPayoutsActual : 0);
          calculatedGmvActualTodayTemp = BigNumber(calculatedGmvActualTodayTemp).plus(razerOutletBankInfoListFiltered[i].liveTodayKooDooPayoutsActual ? razerOutletBankInfoListFiltered[i].liveTodayKooDooPayoutsActual : 0);

          calculatedMdrEarnedTemp = BigNumber(calculatedMdrEarnedTemp).plus(razerOutletBankInfoListFiltered[i].liveTodayKooDooMDREarned ? razerOutletBankInfoListFiltered[i].liveTodayKooDooMDREarned : 0);
        }

        // if (razerOutletBankInfoListFiltered[i].outletId === '6cfa14e1-5873-4044-b13c-d91424d1d7c4') {
        //   console.log('stop');
        // }

        calculatedTodayGMVActualOutletIdDictTemp[razerOutletBankInfoListFiltered[i].outletId] = razerOutletBankInfoListFiltered[i].liveTodayKooDooPayoutsActual;
        calculatedTodayMdrEarnedOutletIdDictTemp[razerOutletBankInfoListFiltered[i].outletId] = razerOutletBankInfoListFiltered[i].liveTodayKooDooMDREarned ? razerOutletBankInfoListFiltered[i].liveTodayKooDooMDREarned : 0;

        if (razerOutletBankInfoListFiltered[i].liveTodayActive && razerOutletBankInfoListFiltered[i].liveTodayKooDooPayoutsActual > 0) {
          if (!calculatedActiveMerchantsList.includes(razerOutletBankInfoListFiltered[i].outletName)) {
            calculatedActiveMerchantsList.push(razerOutletBankInfoListFiltered[i].outletName);
          }
        }
      }

      /////////////////////////////////////////////////////////

      const razerOutletBankInfoMdrListFiltered = razerOutletBankInfoMdrList.filter((item) => {
        let duplicated = false;
        for (let filterIndex = 0; filterIndex < razerOutletBankInfoListFiltered.length; filterIndex++) {
          if (razerOutletBankInfoListFiltered[filterIndex].outletId === item.outletId) {
            duplicated = true;
            break;
          }
        }
        if (duplicated) {
          return false;
        }
        else {
          return filterItem(item);
        }
      });

      for (var i = 0; i < razerOutletBankInfoMdrListFiltered.length; i++) {
        // console.log('test 2');
        // console.log(razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooPayoutsActual);
        // console.log(BigNumber(calculatedGmvActualTemp).toNumber());

        // no need first
        // calculatedGmvUserOrdersFiguresTemp = calculatedGmvUserOrdersFiguresTemp.concat(razerOutletBankInfoMdrListFiltered[i].userOrdersFigures ? razerOutletBankInfoMdrListFiltered[i].userOrdersFigures.map(order => {
        //   return {
        //     ...order,
        //     transactionDate: order.createdAt,
        //   };
        // }) : []);

        if (selectedTabOutletId === razerOutletBankInfoMdrListFiltered[i].outletId || selectedTabOutletId === 'ALL') {
          calculatedGmvActualTemp = BigNumber(calculatedGmvActualTemp).plus(razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooPayoutsActual ? razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooPayoutsActual : 0);
          calculatedGmvActualTodayTemp = BigNumber(calculatedGmvActualTodayTemp).plus(razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooPayoutsActual ? razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooPayoutsActual : 0);

          calculatedMdrEarnedTemp = BigNumber(calculatedMdrEarnedTemp).plus(razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooMDREarned ? razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooMDREarned : 0);
        }

        // if (razerOutletBankInfoMdrListFiltered[i].outletId === '6cfa14e1-5873-4044-b13c-d91424d1d7c4') {
        //   console.log('stop');
        // }

        calculatedTodayGMVActualOutletIdDictTemp[razerOutletBankInfoMdrListFiltered[i].outletId] = razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooPayoutsActual;
        calculatedTodayMdrEarnedOutletIdDictTemp[razerOutletBankInfoMdrListFiltered[i].outletId] = razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooMDREarned ? razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooMDREarned : 0;

        if (razerOutletBankInfoMdrListFiltered[i].liveTodayActive && razerOutletBankInfoMdrListFiltered[i].liveTodayKooDooPayoutsActual > 0) {
          if (!calculatedActiveMerchantsList.includes(razerOutletBankInfoMdrListFiltered[i].outletName)) {
            calculatedActiveMerchantsList.push(razerOutletBankInfoMdrListFiltered[i].outletName);
          }
        }
      }
    }

    setCalculatedGmvUserOrdersFigures(calculatedGmvUserOrdersFiguresTemp);

    setCalculatedGmvActual(BigNumber(calculatedGmvActualTemp).toNumber());
    setCalculatedGmvActualToday(BigNumber(calculatedGmvActualTodayTemp).toNumber());
    setCalculatedMdrEarned(BigNumber(calculatedMdrEarnedTemp).toNumber());
    setCalculatedActiveMerchants(calculatedActiveMerchantsList.length);

    setCalculatedTodayGMVActualOutletIdDict(calculatedTodayGMVActualOutletIdDictTemp);
    setCalculatedTodayMdrEarnedOutletIdDict(calculatedTodayMdrEarnedOutletIdDictTemp);
  }, [
    // tableDataSummary.length, // to fix for the weird bugs of causing unable to sort (but will cause koodoo profit qr online chart not rendered)
    tableDataSummary,

    razerOutletBankInfoList,
    razerOutletBankInfoMdrList,

    ptStartDate,
    ptEndDate,

    selectedTabOutletId,
  ]);

  ////////////////////////////////////////////////////////

  const gmvStartDate = CommonStore.useState(s => s.gmvStartDate);
  const gmvEndDate = CommonStore.useState(s => s.gmvEndDate);

  // 2023-04-28 - No need first
  // useEffect(() => {
  //   if (gmvStartDate !== gmvEndDate) {
  //     // temp fix for strange bugs for same date

  //     listenToOrderChangesMerchant(gmvStartDate, gmvEndDate);
  //   }
  // }, [gmvStartDate, gmvEndDate]);

  useEffect(() => {
    if (ptStartDate !== ptEndDate) {
      // temp fix for strange bugs for same date

      // listenToPayoutTransactionsChangesMerchant(ptStartDate, ptEndDate);

      typeof global.subscriberListenToPayoutTransactionsChangesMerchant === 'function' && global.subscriberListenToPayoutTransactionsChangesMerchant();
      global.subscriberListenToPayoutTransactionsChangesMerchant = () => { };

      let subscriber = listenToPayoutTransactionsChangesMerchant(ptStartDate, ptEndDate);

      global.subscriberListenToPayoutTransactionsChangesMerchant = subscriber;

      return () => {
        typeof subscriber === 'function' && subscriber();
      };
    }
  }, [ptStartDate, ptEndDate]);

  useEffect(() => {
    if (aovStartDate !== aovEndDate && selectedAovOutletId) {
      // temp fix for strange bugs for same date

      // listenToPayoutTransactionsChangesMerchant(ptStartDate, ptEndDate);

      typeof global.subscriberListenToPayoutTransactionsChangesForSpecificOutlet === 'function' && global.subscriberListenToPayoutTransactionsChangesForSpecificOutlet();
      global.subscriberListenToPayoutTransactionsChangesForSpecificOutlet = () => { };

      let subscriber = listenToPayoutTransactionsChangesForSpecificOutlet(aovStartDate, aovEndDate, selectedAovOutletId);

      global.subscriberListenToPayoutTransactionsChangesForSpecificOutlet = subscriber;

      return () => {
        typeof subscriber === 'function' && subscriber();
      };
    }
  }, [aovStartDate, aovEndDate, selectedAovOutletId]);

  useEffect(() => {
    if (cmpStartDate !== cmpEndDate && selectedCmpOutletId) {
      // temp fix for strange bugs for same date

      // listenToPayoutTransactionsChangesMerchant(ptStartDate, ptEndDate);

      typeof global.subscriberListenToPayoutTransactionsChangesForSpecificOutletCmp === 'function' && global.subscriberListenToPayoutTransactionsChangesForSpecificOutletCmp();
      global.subscriberListenToPayoutTransactionsChangesForSpecificOutletCmp = () => { };

      let subscriber = listenToPayoutTransactionsChangesForSpecificOutletCmp(cmpStartDate, cmpEndDate, selectedCmpOutletId);

      global.subscriberListenToPayoutTransactionsChangesForSpecificOutletCmp = subscriber;

      return () => {
        typeof subscriber === 'function' && subscriber();
      };
    }
  }, [cmpStartDate, cmpEndDate, selectedCmpOutletId]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    if (rptDsStartDate !== rptDsEndDate) {
      // temp fix for strange bugs for same date

      // listenToPayoutTransactionsChangesMerchant(ptStartDate, ptEndDate);

      typeof global.subscriberListenToRPTDailySummary === 'function' && global.subscriberListenToRPTDailySummary();
      global.subscriberListenToRPTDailySummary = () => { };

      let subscriber = listenToRPTDailySummary(rptDsStartDate, rptDsEndDate);

      global.subscriberListenToRPTDailySummary = subscriber;

      return () => {
        typeof subscriber === 'function' && subscriber();
      };
    }
  }, [rptDsStartDate, rptDsEndDate]);

  ////////////////////////////////////////////////////////

  setInterval(() => {
    var divTabButtons = document.getElementById('tabButtons');

    if (divTabButtons) {
      var hasHorizontalScrollbar = divTabButtons.scrollWidth > divTabButtons.clientWidth;
      // var hasVerticalScrollbar = divTabButtons.scrollHeight > divTabButtons.clientHeight;

      // console.log(hasHorizontalScrollbar);
      // console.log(hasVerticalScrollbar);

      if (hasHorizontalScrollbar) {
        divTabButtons.children[0].style['margin-top'] = '20px';
      }
      else {
        divTabButtons.children[0].style['margin-top'] = '0px';
      }
    }

    var elementList = document.querySelectorAll('[fill="#b1b2b7"]');

    for (var i = 0; i < elementList.length; i++) {
      elementList[i].style.display = 'none';
    }

    var elementLegendList = document.querySelectorAll('[opacity="0.7"]');

    for (var i = 0; i < elementLegendList.length; i++) {
      elementLegendList[i].style['stroke-width'] = '5';
    }

    var elementLegendTextList = document.querySelectorAll('[style="font-family: Verdana, sans; cursor: pointer; font-size: 16px; text-anchor: start; font-weight: normal;"]')

    for (var i = 0; i < elementLegendTextList.length; i++) {
      elementLegendTextList[i].style['transform'] = 'translateY(1px)';
    }

    ///////////////////////////////////////////////

    // 2023-05-22 - For comparison chart (add percentage to y-axis)

    try {
      var cmpSalesChartSvg = document.querySelectorAll('[data-testid="idCmpSalesChart"] > div > span > svg > g');

      // console.log('cmpSalesChartSvg')
      // console.log(cmpSalesChartSvg);

      var cmpSalesChartSvgGroup1 = cmpSalesChartSvg[0];

      // console.log('cmpSalesChartSvgGroup1');
      // console.log(cmpSalesChartSvgGroup1);

      var cmpSalesChartSvgGroup2 = cmpSalesChartSvgGroup1.children[2];

      // console.log('cmpSalesChartSvgGroup2');
      // console.log(cmpSalesChartSvgGroup2);

      var cmpSalesChartSvgGroup3 = cmpSalesChartSvgGroup2.children[4];

      // console.log('cmpSalesChartSvgGroup3');
      // console.log(cmpSalesChartSvgGroup3);

      var cmpSalesChartSvgGroup4 = cmpSalesChartSvgGroup3.children[0];

      // console.log('cmpSalesChartSvgGroup4');
      // console.log(cmpSalesChartSvgGroup4);

      var yAxisLabelsTextElements = Array.from(cmpSalesChartSvgGroup4.children);

      // Sort the text elements based on the "y" attribute
      yAxisLabelsTextElements.sort((b, a) => {
        const yA = parseInt(a.getAttribute('y'));
        const yB = parseInt(b.getAttribute('y'));
        return yA - yB;
      });

      // Detach the text elements from their current positions
      // cmpSalesChartSvgGroup4.children.forEach((textElement) => {
      //   textElement.remove();
      // });

      for (var i = 0; i < cmpSalesChartSvgGroup4.children.length; i++) {
        cmpSalesChartSvgGroup4.children[i].remove();
      }

      // Append the sorted text elements back to the <g> element
      yAxisLabelsTextElements.forEach((textElement) => {
        cmpSalesChartSvgGroup4.appendChild(textElement);
      });

      const percentageRegex = /\((\d+%)\)/;

      for (var i = 0; i < cmpSalesChartSvgGroup4.children.length; i++) {
        if (!cmpSalesChartSvgGroup4.children[i].textContent.includes('%')) {
          cmpSalesChartSvgGroup4.children[i].dataset.originalTextContent = cmpSalesChartSvgGroup4.children[i].textContent;

          cmpSalesChartSvgGroup4.children[i].textContent = `${cmpSalesChartSvgGroup4.children[i].dataset.originalTextContent}\n(${i * 10}%)`;

          // console.log('before cmpSalesChartSvgGroup4.children[i].outerHTML');
          // console.log(cmpSalesChartSvgGroup4.children[i].outerHTML);

          // var newOuterHTML = cmpSalesChartSvgGroup4.children[i].outerHTML;
          // var percentageMatch = newOuterHTML.match(percentageRegex);
          // var percentageText = percentageMatch[0];

          // newOuterHTML = newOuterHTML.replace(percentageText, `<tspan x="0" dy="1em">${percentageText}</tspan>`);

          // cmpSalesChartSvgGroup4.children[i].outerHTML = newOuterHTML;

          // console.log('after cmpSalesChartSvgGroup4.children[i].outerHTML');
          // console.log(cmpSalesChartSvgGroup4.children[i].outerHTML);
        }
        else {
          cmpSalesChartSvgGroup4.children[i].textContent = `${cmpSalesChartSvgGroup4.children[i].dataset.originalTextContent}\n(${i * 10}%)`;
        }
      }

      ////////////////////////////////////////////////////////////////

      var cmpSalesChartSvgLeftYAxis1 = cmpSalesChartSvgGroup2.children[3];

      cmpSalesChartSvgLeftYAxis1.style['transform'] = 'translateX(-65px)';

      ////////////////////////////////////////////////////////////////
    }
    catch (ex) {
      // console.error(ex);
    }

    ///////////////////////////////////////////////

    const spanElement = document.getElementById('chartReportRepeatCust');

    if (spanElement) {
      // Get all <g> elements with specific attributes
      const gElements = spanElement.querySelectorAll('g[font-size="16px"][font-weight="normal"][font-style="normal"]');

      // Initialize an array to store filtered <text> elements
      // const filteredTextElements = [];

      // Loop through each <g> element
      gElements.forEach(gElement => {
        // Get all <text> elements within the current <g> element
        const textElements = gElement.querySelectorAll('text');

        // Loop through each <text> element
        textElements.forEach(textElement => {
          // Check if the element meets all the specified conditions
          if (
            textElement.getAttribute('opacity') === '1' &&
            textElement.getAttribute('fill-opacity') === '1' &&
            // textElement.getAttribute('fill') === '#ffffff' &&
            textElement.getAttribute('stroke') === 'none' &&
            textElement.getAttribute('text-anchor') === 'middle' &&
            textElement.getAttribute('style') === 'text-anchor: middle;' &&
            textElement.textContent.trim() === '0'
          ) {
            // Add the element to the filtered array
            // filteredTextElements.push(textElement);

            textElement.style['display'] = 'none';
          }
          else if (
            textElement.getAttribute('opacity') === '1' &&
            textElement.getAttribute('fill-opacity') === '1' &&
            // textElement.getAttribute('fill') === '#ffffff' &&
            textElement.getAttribute('stroke') === 'none' &&
            textElement.getAttribute('text-anchor') === 'middle' &&
            textElement.getAttribute('style') === 'text-anchor: middle;' &&
            textElement.textContent.trim() !== '0'
            &&
            !textElement.dataset.isModified
          ) {
            // Add the element to the filtered array
            // filteredTextElements.push(textElement);

            // console.log('element y');
            // console.log(textElement.getAttribute('y'));
            // console.log(textElement.textContent);
            // console.log(parseFloat(textElement.getAttribute('y')) / 4);

            // textElement.style['transform'] = `translateY(-${parseFloat(textElement.getAttribute('y')) / 4}px)`;
            // // textElement.style['fill'] = '#000000';
            // textElement.setAttribute('fill', '#000000');

            // textElement.dataset.isModified = true;
          }
        });
      });

      // console.log('filteredTextElements');
      // console.log(filteredTextElements);
    }


  }, 250);

  useEffect(() => {
    setChartDropdownValueXList(
      CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].filter(
        (xAxisType) => {
          if (salesLineChartPeriod === CHART_PERIOD.THIS_WEEK) {
            if (
              xAxisType.value === CHART_X_AXIS_TYPE.WEEK ||
              xAxisType.value === CHART_X_AXIS_TYPE.MONTH
            ) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.THIS_MONTH) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.MONTH) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.THREE_MONTHS) {
            return true;
          } else if (salesLineChartPeriod === CHART_PERIOD.SIX_MONTHS) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.DAY) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.THIS_YEAR) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.DAY) {
              return false;
            } else {
              return true;
            }
          } else if (salesLineChartPeriod === CHART_PERIOD.YTD) {
            if (xAxisType.value === CHART_X_AXIS_TYPE.DAY) {
              return false;
            } else {
              return true;
            }
          }
        }
      )
    );
  }, [salesLineChartPeriod]);

  useEffect(() => {
    // const allOutletsUserOrdersDoneFilteredBarChart =
    //   gmvOrdersRecent.filter((item) =>
    //     filterChartItems(item, appliedChartFilterQueriesBarChart)
    //   );
    // const allOutletsUserOrdersDoneFilteredLineChart =
    //   gmvOrdersRecent.map((item) => {
    //     // filterChartItems(item, appliedChartFilterQueriesLineChart)

    //     // return {
    //     //   ...item,

    //     //   orderTypeDetails: (item.appType === APP_TYPE.WEB_ORDER ?
    //     //     ((gmvOrdersRecent[i].commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
    //     //     :
    //     //     ORDER_TYPE_DETAILS.POS),
    //     // };

    //     return item;
    //   });

    const allOutletsUserOrdersDoneFilteredLineChart =
      selectedPaymentType === PAYMENT_TYPE.ALL ? gmvOrdersRecent : gmvOrdersRecent.filter((order) => {
        let channel = (order.paymentDetails && order.paymentDetails.channel) ? order.paymentDetails.channel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel;

        if (order.outletId === 'ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea') {
          console.log('break');
        }

        if (
          selectedPaymentType === PAYMENT_TYPE.E_WALLET &&
          (
            channel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
            channel === PAYMENT_CHANNEL_NAME.ShopeePay ||
            channel === PAYMENT_CHANNEL_NAME.GrabPay ||
            channel === PAYMENT_CHANNEL_NAME.BOOST ||
            channel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.FPX &&
          (
            channel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
            channel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
            channel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
            channel === PAYMENT_CHANNEL_NAME['ALB-Paymex'] ||
            channel === PAYMENT_CHANNEL_NAME['MB2u'] ||
            channel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
            channel === PAYMENT_CHANNEL_NAME['Cash-epay'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_HLB']
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.CREDIT_DEBIT &&
          (
            channel === PAYMENT_CHANNEL_NAME['credit'] ||
            channel === PAYMENT_CHANNEL_NAME['Credit'] ||
            channel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
            channel === PAYMENT_CHANNEL_NAME['CIL_Visa']
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.OFFLINE &&
          (
            channel.startsWith('Offline')
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.ONLINE &&
          (
            !channel.startsWith('Offline')
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.OFFLINE_WO_CASH &&
          (
            channel.startsWith('Offline') &&
            !channel.startsWith('Offline-Cash')
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.ALL_WO_CASH &&
          (
            !channel.startsWith('Offline-Cash')
          )
        ) {
          return true;
        }
        else {
          return false;
        }
      });

    // setExpandLineSelection(false);

    // const result = getDataForSalesLineChart(
    //   allOutlets,
    //   allOutletsUserOrdersDoneFilteredLineChart,
    //   salesLineChartPeriod,
    //   selectedChartDropdownValueLineChart,
    //   selectedChartDropdownValueX,
    //   ptStartDate,
    //   ptEndDate,

    //   allOutletsEmployeesAction,
    //   // gmvOrdersRecent,
    //   allOutletsUserOrdersDoneFilteredLineChart,
    //   dataPlotClickCallback,
    //   selectedPaymentType,

    //   {
    //     calculatedGmvActualToday: calculatedGmvActualToday,
    //     calculatedGmvUserOrdersFigures: calculatedGmvUserOrdersFigures,
    //   }
    // );

    // if (result) {
    //   setSalesLineChart(result.chartData);
    //   setExpandLineSelection(false);
    // } else {
    //   setSalesLineChart({});
    //   setExpandLineSelection(false);
    // }

    //////////////////////////////////////////////////////////

    if (selectedPayoutTabs === PAYOUT_TABS.KOODOO_EXPECTED_ACTUAL_PROFIT) {
      const resultExpectedActualProfit = getDataForChartReportExpectedActualProfit(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        selectedChartDropdownValueX,
        rptDsStartDate,
        rptDsEndDate,

        allOutletsEmployeesAction,
        // gmvOrdersRecent,
        allOutletsUserOrdersDoneFilteredLineChart,
        dataPlotClickCallback,

        tableDataSummary,
        // boldOutletQrSalesLineDict,
        // changedBoldTimestamp,
        selectedPaymentType,

        selectedTabOutletId,

        calculatedGmvActualToday,
        koodooProfitExpectedToday,
        rptDailySummaryList,
      );

      if (resultExpectedActualProfit) {
        setExpectedActualProfitChart(resultExpectedActualProfit.chartData);
        // setExpandLineSelection(false);
      } else {
        setExpectedActualProfitChart({});
        // setExpandLineSelection(false);
      }
    }

    // console.log('resultExpectedActualProfit.chartData');
    // console.log(resultExpectedActualProfit.chartData);

    //////////////////////////////////////////////////////////

    // 2023-01-04 - Break down chart for pos sales, qr offline sales, qr online sales

    // const resultProduct = getDataForChartReportProductSales(
    //   allOutlets,
    //   allOutletsUserOrdersDoneFilteredLineChart,
    //   salesLineChartPeriod,
    //   selectedChartDropdownValueLineChart,
    //   selectedChartDropdownValueX,
    //   ptStartDate,
    //   ptEndDate,

    //   allOutletsEmployeesAction,
    //   // gmvOrdersRecent,
    //   allOutletsUserOrdersDoneFilteredLineChart,
    //   dataPlotClickCallback,
    //   selectedPaymentType,

    //   selectedTabOutletId,
    // );

    // if (resultProduct) {
    //   setProductSalesChart(resultProduct.chartData);
    //   // setExpandLineSelection(false);
    // } else {
    //   setProductSalesChart({});
    //   // setExpandLineSelection(false);
    // }

    //////////////////////////////////////////////////////////

    // 2023-02-12 - For online QR sales

    // const resultOnlineQrSales = getDataForChartReportOnlineQrSales(
    //   allOutlets,
    //   allOutletsUserOrdersDoneFilteredLineChart,
    //   salesLineChartPeriod,
    //   selectedChartDropdownValueLineChart,
    //   selectedChartDropdownValueX,
    //   ptStartDate,
    //   ptEndDate,

    //   allOutletsEmployeesAction,
    //   // gmvOrdersRecent,
    //   allOutletsUserOrdersDoneFilteredLineChart,
    //   dataPlotClickCallback,

    //   tableDataSummary,
    //   // boldOutletQrSalesLineDict,
    //   // changedBoldTimestamp,
    //   selectedPaymentType,

    //   selectedTabOutletId,
    // );

    // if (resultOnlineQrSales) {
    //   setOnlineQrSalesChart(resultOnlineQrSales.chartData);
    //   // setExpandLineSelection(false);
    // } else {
    //   setOnlineQrSalesChart({});
    //   // setExpandLineSelection(false);
    // }

    //////////////////////////////////////////////////////////

    // 2023-05-08 - For AOV sales

    if (selectedPayoutTabs === PAYOUT_TABS.AOV) {
      const resultAovSales = getDataForChartReportAovSales(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        selectedChartDropdownValueX,
        aovStartDate,
        aovEndDate,

        // allOutletsEmployeesAction,
        outletPayoutTransactionsProcessed,
        // gmvOrdersRecent,
        allOutletsUserOrdersDoneFilteredLineChart,
        dataPlotClickCallback,

        tableDataSummary,
        // boldOutletQrSalesLineDict,
        // changedBoldTimestamp,
        selectedPaymentType,
      );

      if (resultAovSales) {
        setAovSalesChart(resultAovSales.chartData);
        // setExpandLineSelection(false);
      } else {
        setAovSalesChart({});
        // setExpandLineSelection(false);
      }
    }

    //////////////////////////////////////////////////////////

    // 2023-05-22 - For Comparison sales

    if (selectedPayoutTabs === PAYOUT_TABS.COMPARISON) {
      const resultCmpSales = getDataForChartReportCmpSales(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        selectedChartDropdownValueX,
        cmpStartDate,
        cmpEndDate,

        // allOutletsEmployeesAction,
        cmpOutletPayoutTransactionsProcessed,
        // gmvOrdersRecent,
        allOutletsUserOrdersDoneFilteredLineChart,
        dataPlotClickCallback,

        tableDataSummary,
        // boldOutletQrSalesLineDict,
        // changedBoldTimestamp,
        selectedPaymentType,
      );

      if (resultCmpSales) {
        setCmpSalesChart(resultCmpSales.chartData);
        // setExpandLineSelection(false);
      } else {
        setCmpSalesChart({});
        // setExpandLineSelection(false);
      }
    }

    //////////////////////////////////////////////////////////

    // 2023-05-22 - For mdr sales

    if (selectedPayoutTabs === PAYOUT_TABS.MDR) {
      const resultMdr = getDataForChartReportMdr(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        selectedChartDropdownValueX,
        cmpStartDate,
        cmpEndDate,

        // allOutletsEmployeesAction,
        cmpOutletPayoutTransactionsProcessed,
        // gmvOrdersRecent,
        allOutletsUserOrdersDoneFilteredLineChart,
        dataPlotClickCallback,

        tableDataSummary,
        // boldOutletQrSalesLineDict,
        // changedBoldTimestamp,
        selectedPaymentType,
      );

      if (resultMdr) {
        setMdrChart(resultMdr.chartData);
        // setExpandLineSelection(false);
      } else {
        setMdrChart({});
        // setExpandLineSelection(false);
      }
    }

    //////////////////////////////////////////////////////////

    // 2023-08-24 - For New Cust

    if (selectedPayoutTabs === PAYOUT_TABS.NEW_CUSTOMER) {
      const resultRepeatCustNum = getDataForChartReportRevisitNum(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        selectedChartDropdownValueX,
        cmpStartDate,
        cmpEndDate,

        // allOutletsEmployeesAction,
        cmpOutletPayoutTransactionsProcessed,
        // gmvOrdersRecent,
        allOutletsUserOrdersDoneFilteredLineChart,
        dataPlotClickCallback,

        tableDataSummary,
        // boldOutletQrSalesLineDict,
        // changedBoldTimestamp,
        selectedPaymentType,
      );

      if (resultRepeatCustNum) {
        setRepeatCustNumChart(resultRepeatCustNum.chartData);
        // setExpandLineSelection(false);
      } else {
        setRepeatCustNumChart({});
        // setExpandLineSelection(false);
      }
    }

    //////////////////////////////////////////////////////////

    // 2023-08-24 - For New Cust

    if (selectedPayoutTabs === PAYOUT_TABS.UPSELLING) {
      const resultUpsellingSales = getDataForChartReportUpsellingSales(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        selectedChartDropdownValueX,
        cmpStartDate,
        cmpEndDate,

        // allOutletsEmployeesAction,
        cmpOutletPayoutTransactionsProcessed,
        // gmvOrdersRecent,
        allOutletsUserOrdersDoneFilteredLineChart,
        dataPlotClickCallback,

        tableDataSummary,
        // boldOutletQrSalesLineDict,
        // changedBoldTimestamp,
        selectedPaymentType,
      );

      if (resultUpsellingSales) {
        setUpsellingSalesChart(resultUpsellingSales.chartData);
        // setExpandLineSelection(false);
      } else {
        setUpsellingSalesChart({});
        // setExpandLineSelection(false);
      }
    }

    //////////////////////////////////////////////////////////

    // 2023-08-24 - For New Cust

    if (selectedPayoutTabs === PAYOUT_TABS.AOV_UPSELLING) {
      const resultAovUpsellingSales = getDataForChartReportAOVUpsellingSales(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        selectedChartDropdownValueX,
        cmpStartDate,
        cmpEndDate,

        // allOutletsEmployeesAction,
        cmpOutletPayoutTransactionsProcessed,
        // gmvOrdersRecent,
        allOutletsUserOrdersDoneFilteredLineChart,
        dataPlotClickCallback,

        tableDataSummary,
        // boldOutletQrSalesLineDict,
        // changedBoldTimestamp,
        selectedPaymentType,
      );

      if (resultAovUpsellingSales) {
        setAovUpsellingSalesChart(resultAovUpsellingSales.chartData);
        // setExpandLineSelection(false);
      } else {
        setAovUpsellingSalesChart({});
        // setExpandLineSelection(false);
      }
    }

    //////////////////////////////////////////////////////////

    // if (allOutlets.length > 0 && allOutlets[0].merchantId === merchantId) {
    //   const result = getDataForSalesLineChart(
    //     allOutlets,
    //     allOutletsUserOrdersDoneFilteredLineChart,
    //     salesLineChartPeriod,
    //     selectedChartDropdownValueLineChart,
    //     selectedChartDropdownValueX,
    //     ptStartDate,
    //     ptEndDate
    //   );

    //   if (result) {
    //     setSalesLineChart(result.chartData);
    //     setExpandLineSelection(false);
    //   } else {
    //     setSalesLineChart({});
    //     setExpandLineSelection(false);
    //   }
    // } else {
    //   setSalesLineChart({});
    //   setExpandLineSelection(false);
    // }
  }, [
    // allOutlets,
    // allOutletsUserOrdersDone,
    gmvOrdersRecent,

    salesLineChartPeriod,
    // salesBarChartPeriod,
    // selectedChartDropdownValueBarChart,
    selectedChartDropdownValueLineChart,
    // appliedChartFilterQueriesBarChart,
    appliedChartFilterQueriesLineChart,
    ptStartDate,
    ptEndDate,

    selectedChartDropdownValueX,

    merchantId,

    allOutletsEmployeesAction,

    selectedPaymentType,

    // tableDataSummary,
    // boldOutletQrSalesLineDict,
    // changedBoldTimestamp,

    // calculatedGmvActualToday,
    // calculatedGmvUserOrdersFigures,

    aovStartDate,
    aovEndDate,
    outletPayoutTransactionsProcessed,

    cmpStartDate,
    cmpEndDate,
    cmpOutletPayoutTransactionsProcessed,

    selectedTabOutletId,

    calculatedGmvActualToday,
    koodooProfitExpectedToday,
    rptDailySummaryList,

    selectedPayoutTabs,
  ]);

  //////////////////////////////////////////////////////////

  // 2023-05-11 - Separated useEffect to address chart and table issue

  useEffect(() => {
    const allOutletsUserOrdersDoneFilteredLineChart =
      selectedPaymentType === PAYMENT_TYPE.ALL ? gmvOrdersRecent : gmvOrdersRecent.filter((order) => {
        let channel = (order.paymentDetails && order.paymentDetails.channel) ? order.paymentDetails.channel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel;

        if (order.outletId === 'ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea') {
          console.log('break');
        }

        if (
          selectedPaymentType === PAYMENT_TYPE.E_WALLET &&
          (
            channel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
            channel === PAYMENT_CHANNEL_NAME.ShopeePay ||
            channel === PAYMENT_CHANNEL_NAME.GrabPay ||
            channel === PAYMENT_CHANNEL_NAME.BOOST ||
            channel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.FPX &&
          (
            channel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
            channel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
            channel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
            channel === PAYMENT_CHANNEL_NAME['ALB-Paymex'] ||
            channel === PAYMENT_CHANNEL_NAME['MB2u'] ||
            channel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
            channel === PAYMENT_CHANNEL_NAME['Cash-epay'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_HLB']
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.CREDIT_DEBIT &&
          (
            channel === PAYMENT_CHANNEL_NAME['credit'] ||
            channel === PAYMENT_CHANNEL_NAME['Credit'] ||
            channel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
            channel === PAYMENT_CHANNEL_NAME['CIL_Visa']
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.OFFLINE &&
          (
            channel.startsWith('Offline')
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.ONLINE &&
          (
            !channel.startsWith('Offline')
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.OFFLINE_WO_CASH &&
          (
            channel.startsWith('Offline') &&
            !channel.startsWith('Offline-Cash')
          )
        ) {
          return true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.ALL_WO_CASH &&
          (
            !channel.startsWith('Offline-Cash')
          )
        ) {
          return true;
        }
        else {
          return false;
        }
      });

    setExpandLineSelection(false);

    const result = getDataForSalesLineChart(
      allOutlets,
      allOutletsUserOrdersDoneFilteredLineChart,
      salesLineChartPeriod,
      selectedChartDropdownValueLineChart,
      selectedChartDropdownValueX,
      ptStartDate,
      ptEndDate,

      allOutletsEmployeesAction,
      // gmvOrdersRecent,
      allOutletsUserOrdersDoneFilteredLineChart,
      dataPlotClickCallback,
      selectedPaymentType,

      {
        calculatedGmvActualToday: calculatedGmvActualToday,
        calculatedGmvUserOrdersFigures: calculatedGmvUserOrdersFigures,
      },

      selectedTabOutletId,
    );

    if (result) {
      setSalesLineChart(result.chartData);
      setExpandLineSelection(false);
    } else {
      setSalesLineChart({});
      setExpandLineSelection(false);
    }

    //////////////////////////////////////////////////////////
  }, [
    // allOutlets,
    // allOutletsUserOrdersDone,
    gmvOrdersRecent,

    salesLineChartPeriod,
    // salesBarChartPeriod,
    // selectedChartDropdownValueBarChart,
    selectedChartDropdownValueLineChart,
    // appliedChartFilterQueriesBarChart,
    appliedChartFilterQueriesLineChart,
    ptStartDate,
    ptEndDate,

    selectedChartDropdownValueX,

    merchantId,

    allOutletsEmployeesAction,

    selectedPaymentType,

    // tableDataSummary,
    // boldOutletQrSalesLineDict,
    // changedBoldTimestamp,

    calculatedGmvActualToday,
    calculatedGmvUserOrdersFigures,

    // aovStartDate,
    // aovEndDate,
    // outletPayoutTransactionsProcessed,

    selectedTabOutletId,
  ]);

  //////////////////////////////////////////////////////////

  // useEffect(() => {
  //   //////////////////////////////////////////////////////////

  //   const allOutletsUserOrdersDoneFilteredLineChart =
  //     gmvOrdersRecent.map((item) => {
  //       // filterChartItems(item, appliedChartFilterQueriesLineChart)

  //       // return {
  //       //   ...item,

  //       //   orderTypeDetails: (item.appType === APP_TYPE.WEB_ORDER ?
  //       //     ((gmvOrdersRecent[i].commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
  //       //     :
  //       //     ORDER_TYPE_DETAILS.POS),
  //       // };

  //       return item;
  //     });

  //   // 2023-02-12 - For online QR sales

  //   const resultOnlineQrSales = getDataForChartReportOnlineQrSales(
  //     allOutlets,
  //     allOutletsUserOrdersDoneFilteredLineChart,
  //     salesLineChartPeriod,
  //     selectedChartDropdownValueLineChart,
  //     selectedChartDropdownValueX,
  //     ptStartDate,
  //     ptEndDate,

  //     allOutletsEmployeesAction,
  //     gmvOrdersRecent,
  //     dataPlotClickCallback,

  //     tableDataSummary,
  //     // boldOutletQrSalesLineDict,
  //     changedBoldTimestamp,
  //   );

  //   if (resultOnlineQrSales) {
  //     setOnlineQrSalesChart(resultOnlineQrSales.chartData);
  //     // setExpandLineSelection(false);
  //   } else {
  //     setOnlineQrSalesChart({});
  //     // setExpandLineSelection(false);
  //   }

  //   //////////////////////////////////////////////////////////
  // }, [
  //   // allOutlets,
  //   // allOutletsUserOrdersDone,
  //   gmvOrdersRecent,

  //   salesLineChartPeriod,
  //   // salesBarChartPeriod,
  //   // selectedChartDropdownValueBarChart,
  //   selectedChartDropdownValueLineChart,
  //   // appliedChartFilterQueriesBarChart,
  //   appliedChartFilterQueriesLineChart,
  //   ptStartDate,
  //   ptEndDate,

  //   selectedChartDropdownValueX,

  //   merchantId,

  //   allOutletsEmployeesAction,

  //   // tableDataSummary,

  //   // boldOutletQrSalesLineDict,
  //   // changedBoldTimestamp,
  // ]);

  const dataPlotClickCallback = (event, data) => {
    console.log(event);
    console.log(data);

    console.log('call!');
  };

  //////////////////////////////////////////////

  // 2022-10-18 - To get the active/online status for each outlet

  // useEffect(async () => {
  //   var startDate24H = moment().startOf('day').valueOf();
  //   var endDate24H = moment().endOf('day').valueOf();

  //   var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
  //   var endDate7D = moment().endOf('day').valueOf();

  //   var startDate1M = moment().subtract(1, "month").startOf('day').valueOf();
  //   var endDate1M = moment().endOf('day').valueOf();

  //   var startDate3M = moment().subtract(3, "month").startOf('day').valueOf();
  //   var endDate3M = moment().endOf('day').valueOf();

  //   for (var i = 0; i < outletsOnboarded.length; i++) {
  //     const outlet = outletsOnboarded[i];

  //     if (!window.pOutletFilterDict[outlet.uniqueId]) {
  //       window.pOutletFilterDict[outlet.uniqueId] = {
  //         is24H: false,
  //         is7D: false,
  //         is1M: false,
  //         is3M: false,
  //       };
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder24HSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate24H)
  //       .where('createdAt', '<', endDate24H)
  //       .limit(1)
  //       .get();

  //     if (userOrder24HSnapshot && !userOrder24HSnapshot.empty) {
  //       window.pOutletFilterDict[outlet.uniqueId]['is24H'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder7DSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate7D)
  //       .where('createdAt', '<', endDate7D)
  //       .limit(1)
  //       .get();

  //     if (userOrder7DSnapshot && !userOrder7DSnapshot.empty) {
  //       window.pOutletFilterDict[outlet.uniqueId]['is7D'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder1MSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate1M)
  //       .where('createdAt', '<', endDate1M)
  //       .limit(1)
  //       .get();

  //     if (userOrder1MSnapshot && !userOrder1MSnapshot.empty) {
  //       window.pOutletFilterDict[outlet.uniqueId]['is1M'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     const userOrder3MSnapshot = await firebase.firestore()
  //       .collection(Collections.UserOrder)
  //       .where('outletId', '==', outlet.uniqueId)
  //       .where('createdAt', '>=', startDate3M)
  //       .where('createdAt', '<', endDate3M)
  //       .limit(1)
  //       .get();

  //     if (userOrder3MSnapshot && !userOrder3MSnapshot.empty) {
  //       window.pOutletFilterDict[outlet.uniqueId]['is3M'] = true;
  //     }

  //     /////////////////////////////////////////////////////////////

  //     // actual update the status (outlet active number)

  //     const outletLastOrderArray = Object.entries(window.pOutletFilterDict).map(
  //       ([key, value]) => ({ key: key, value: value }),
  //     );

  //     setMerchantDataAllLength(outletsOnboarded.length);

  //     // var startDate24H = moment().subtract(1, "day").startOf('day').valueOf();
  //     // var endDate24H = moment().subtract(1, "day").endOf('day').valueOf();
  //     setMerchantData24HLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate24H).isSameOrBefore(temp.value) &&
  //       //   moment(endDate24H).isAfter(temp.value)) {
  //       if (temp.value['is24H']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     // var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
  //     // var endDate7D = moment().subtract(1, "day").endOf('day').valueOf();
  //     setMerchantData7DLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate7D).isSameOrBefore(temp.value) &&
  //       //   moment(endDate7D).isAfter(temp.value)) {
  //       if (temp.value['is7D']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     // var startDate1M = moment().subtract(1, "month").startOf('month').startOf('day').valueOf();
  //     // var endDate1M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
  //     setMerchantData1MLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate1M).isSameOrBefore(temp.value) &&
  //       //   moment(endDate1M).isAfter(temp.value)) {
  //       if (temp.value['is1M']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     // var startDate3M = moment().subtract(3, "month").startOf('month').startOf('day').valueOf();
  //     // var endDate3M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
  //     setMerchantData3MLength(outletLastOrderArray.filter(temp => {
  //       // if (moment(startDate3M).isSameOrBefore(temp.value) &&
  //       //   moment(endDate3M).isAfter(temp.value)) {
  //       if (temp.value['is3M']) {
  //         return true;
  //       }
  //       else {
  //         return false;
  //       }
  //     }).length);

  //     /////////////////////////////////////////////////////////////
  //   }

  //   /////////////////////////////////////////////////////////////

  //   // CommonStore.update(s => {
  //   //   s.outletFilterDict = window.pOutletFilterDict;
  //   // });

  //   /////////////////////////////////////////////////////////////
  // }, [outletsOnboarded]);

  // const outletFilterDict = CommonStore.useState(s => s.outletFilterDict);

  // useEffect(() => {
  //   window.pOutletFilterDict = outletFilterDict;
  // }, [outletFilterDict]);

  /////////////////////////////

  useEffect(async () => {
    // if (userEmail === '') {
    //   // linkTo('/login');

    //   window.location.href = '/statistics/login';
    // }

    setTimeout(async () => {
      const emailParsed = await readFromStorage();

      if (emailParsed === '') {

      }
      else {
        UserStore.update(s => {
          s.email = emailParsed;
        });
      }
    }, 5000);
  }, []);

  useEffect(async () => {
    // if (userEmail === '') {
    //   // linkTo('/login');

    //   window.location.href = '/statistics/login';
    // }

    const emailParsed = await readFromStorage();

    if (emailParsed === '') {
      // linkTo('/login');

      window.location.href = '/statistics/login'; // herks test
    }
  }, [userEmail]);

  // const readFromStorage = async () => {
  //   console.log('\n Reading from asyncStorage \n')

  //   var emailParsed = '';

  //   const userStoreDataRaw = await AsyncStorage.getItem('@userStoreV2');
  //   if (userStoreDataRaw !== null) {
  //     const userStoreData = JSON.parse(userStoreDataRaw);
  //     // UserStore.replace(userStoreData);

  //     if (userStoreData && userStoreData.email) {
  //       emailParsed = userStoreData.email;
  //     }
  //   }

  //   return emailParsed;
  // }

  // no need this
  // useEffect(async () => {
  //   var outletUserActionDictTemp = {};

  //   for (var i = 0; i < outletsOnboarded.length; i++) {
  //     var outletId = outletsOnboarded[i].uniqueId;

  //     var userAction = await retrieveLatestUserAction(outletId);

  //     outletUserActionDictTemp[outletId] = userAction;
  //   }

  //   setOutletUserActionDict(outletUserActionDictTemp);
  // }, [outletsOnboarded]);

  const retrieveLatestUserAction = async (outletId) => {
    const userActionSnapshot = await firebase.firestore()
      .collection(Collections.UserAction)
      .where('outletId', '==', outletId)
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .get();

    var userAction = null;
    if (userActionSnapshot && !userActionSnapshot.empty) {
      userAction = userActionSnapshot.docs[0].data();
    }

    return userAction;
  };

  useEffect(() => {
    if (showDetails && tableDataDetails) {
      setPageReturn(currentPage);
      console.log("currentPage value is");
      console.log(currentPage);
      setCurrentDetailsPage(1);
      setPageCount(Math.ceil(tableDataDetails.length / perPage));
    }
  }, [showDetails, tableDataDetails, perPage]);

  useEffect(async () => {
    const outletFilterDictRaw = await AsyncStorage.getItem('outletFilterDict');

    if (outletFilterDictRaw) {
      const outletFilterDictParsed = JSON.parse(outletFilterDictRaw);

      if (typeof outletFilterDictParsed === 'object') {
        window.pOutletFilterDict = outletFilterDictParsed;
      }
    }
  }, []);

  useEffect(async () => {
    // const userActionSnapshot = await firebase
    //   .firestore()
    //   .collection(Collections.KCRMAgreement)
    //   .orderBy("email", "asc")
    //   .get();

    // const userAction = userActionSnapshot.docs.map((doc) => doc.data());

    // group the records by the email and store the details in detailsList

    let koodooProfitExpectedTodayTemp = BigNumber(0);

    /////////////////////////////////////////////////////

    var ytdStartDate = moment().add(-1, 'day').startOf('day').valueOf();
    // var todayEndDate = moment().endOf('day').valueOf();
    var todayEndDate = moment(window.currToDateTime).valueOf();

    // if (ptStartDate >= ytdStartDate) {
    //   ytdStartDate = ptStartDate;
    // }

    if (moment().isSameOrAfter(moment().set('hour', 6))) {
      ytdStartDate = moment().startOf('day').valueOf();
    }

    if (ptEndDate < todayEndDate) {
      todayEndDate = ptEndDate;
    }

    /////////////////////////////////////////////////////

    var startDate24H = moment().startOf('day').valueOf();
    // var endDate24H = moment().endOf('day').valueOf();
    var endDate24H = moment(window.currToDateTime).valueOf();

    var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
    // var endDate7D = moment().endOf('day').valueOf();
    var endDate7D = moment(window.currToDateTime).valueOf();

    var startDate1M = moment().subtract(1, "month").startOf('day').valueOf();
    // var endDate1M = moment().endOf('day').valueOf();
    var endDate1M = moment(window.currToDateTime).valueOf();

    var startDate3M = moment().subtract(3, "month").startOf('day').valueOf();
    // var endDate3M = moment().endOf('day').valueOf();
    var endDate3M = moment(window.currToDateTime).valueOf();

    var tempDataBefore = outletsOnboarded.map(outlet => {
      var lastActivity = outletUserActionDict[outlet.uniqueId];
      if (lastActivity) {
        lastActivity = lastActivity.updatedAt;
      }

      if (!window.pOutletFilterDict[outlet.uniqueId]) {
        window.pOutletFilterDict[outlet.uniqueId] = {
          is24H: false,
          is7D: false,
          is1M: false,
          is3M: false,
        };
      }
      else {
        window.pOutletFilterDict[outlet.uniqueId]['is24H'] = false;
      }

      // var recentPayoutTransactionDictTemp = {};
      // if (moment(ytdStartDate).isSame(moment().add(-1, 'day').startOf('day'), 'day') &&
      //   moment(ytdStartDate).isBetween(ptStartDate, ptEndDate, null, '[]')) {
      //   recentPayoutTransactionDictTemp[ytdStartDate] = {
      //     outletCycleKoodooPayoutsExpected: 0,
      //     outletCycleRazerPayouts: 0,
      //     outletCycleMerchantPayoutsExpected: 0,
      //     outletCycleKoodooPayoutsActual: undefined,
      //     outletCycleMerchantPayoutsActual: undefined,

      //     outletId: '',
      //     outletCycleFunds: undefined,
      //     outletCycleMerchantOverdueAmounts: undefined,
      //     outletCycleMerchantPendingAmounts: undefined,

      //     stockUpAmount: undefined,

      //     outletCycleMerchantPendingRefundOrdersAmount: undefined,
      //     outletCycleMerchantRefundOrdersAmount: undefined,

      //     payoutFee: undefined,

      //     prevStockUpAmount: undefined,
      //     prevOverdueAmount: undefined,
      //     prevPendingAmount: undefined,

      //     transactionDate: undefined,
      //     settlementDate: moment(ytdStartDate).add(1, 'day').set('hour', 6).set('minute', 0).set('second', 0).valueOf(),

      //     trackDate: ytdStartDate,

      //     outletCycleKoodooPayoutsOnlineOnlyExpected: 0,
      //   };
      // }

      // if (
      //   // moment(todayEndDate).isSame(moment().endOf('day'), 'day') &&
      //   moment(todayEndDate).isSame(moment(window.currToDateTime), 'day') &&
      //   moment(todayEndDate).isBetween(ptStartDate, ptEndDate, null, '[]')) {
      //   recentPayoutTransactionDictTemp[todayEndDate] = {
      //     outletCycleKoodooPayoutsExpected: 0,
      //     outletCycleRazerPayouts: 0,
      //     outletCycleMerchantPayoutsExpected: 0,
      //     outletCycleKoodooPayoutsActual: undefined,
      //     outletCycleMerchantPayoutsActual: undefined,

      //     outletId: '',
      //     outletCycleFunds: undefined,
      //     outletCycleMerchantOverdueAmounts: undefined,
      //     outletCycleMerchantPendingAmounts: undefined,

      //     stockUpAmount: undefined,

      //     outletCycleMerchantPendingRefundOrdersAmount: undefined,
      //     outletCycleMerchantRefundOrdersAmount: undefined,

      //     payoutFee: undefined,

      //     prevStockUpAmount: undefined,
      //     prevOverdueAmount: undefined,
      //     prevPendingAmount: undefined,

      //     transactionDate: undefined,
      //     settlementDate: moment(todayEndDate).add(1, 'day').set('hour', 6).set('minute', 0).set('second', 0).valueOf(),

      //     trackDate: todayEndDate,

      //     outletCycleKoodooPayoutsOnlineOnlyExpected: 0,
      //   };
      // }

      var colorRandom = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      if (window.pOutletPaletteColorDict[outlet.uniqueId]) {
        colorRandom = window.pOutletPaletteColorDict[outlet.uniqueId];
      }
      else {
        window.pOutletPaletteColorDict[outlet.uniqueId] = colorRandom;
      }

      return {
        paletteColor: colorRandom,

        merchantName: outlet.name,
        outletName: outlet.name,

        createdAt: outlet.createdAt,
        onboarderEmail: outlet.onboarderEmail || '-',
        // lastActivity: merchant.updatedAt > merchant.updatedAt ? merchant.updatedAt : outlet.updatedAt,
        lastActivity: lastActivity ? lastActivity : outlet.createdAt,

        merchantId: outlet.merchantId,
        outletId: outlet.uniqueId,

        //////////////////////////////////////////////

        // new info

        userAppAndWebDineInOrdersAmount: 0,
        userAppAndWebDineInOrdersQty: 0,

        userAppAndWebDineInOnlineOrdersAmount: 0,
        userAppAndWebDineInOnlineOrdersQty: 0,

        merchantAppOrdersAmount: 0,
        merchantAppOrdersQty: 0,

        merchantAppOnlineOrdersAmount: 0,
        merchantAppOnlineOrdersQty: 0,

        merchantAppWoCashAmount: 0,
        merchantAppWoCashOrdersQty: 0,

        waiterAppOrdersAmount: 0,
        waiterAppOrdersQty: 0,

        userAppAndWebTakeawayOrdersAmount: 0,
        userAppAndWebTakeawayOrdersQty: 0,

        userAppAndWebDeliveryOrdersAmount: 0,
        userAppAndWebDeliveryOrdersQty: 0,

        totalOrdersAmount: 0,
        totalOrdersQty: 0,

        gmvCommission: BigNumber(0),
        gmvPayout: BigNumber(0),
        gmvFees: BigNumber(0),
        mdrEarned: BigNumber(0),

        gmvCommissionActual: BigNumber(0),
        gmvPayoutActual: BigNumber(0),

        // recentPayoutTransactionDict: recentPayoutTransactionDictTemp,
        razerPayoutTransactionList: [],

        lastOrderPlacedDateTime: null,

        //////////////////////////////////////////////

        // details info

        detailsList: [],

        //////////////////////////////////////////////
      };
    });

    var outletDataDict = Object.assign({}, ...tempDataBefore.map(data => ({
      [data.outletId]: data,
    })));

    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    await run(function* () {
      const payoutTransactionsFiltered = payoutTransactions.concat(payoutTransactionsExtend);

      console.log('loop');
      console.log(payoutTransactionsFiltered.length);

      for (let i = 0; i < payoutTransactionsFiltered.length; i++) {
        if (outletDataDict[payoutTransactionsFiltered[i].outletId]) {
          // var gmvRate = 0;
          // if (payoutTransactionsFiltered[i].settlementDetails) {
          //   if (payoutTransactionsFiltered[i].settlementDetails && payoutTransactionsFiltered[i].settlementDetails.processingRateTotalSalesActive) {
          //     gmvRate = payoutTransactionsFiltered[i].settlementDetails.processingRateTotalSales;
          //   }
          //   else if (payoutTransactionsFiltered[i].settlementDetails && payoutTransactionsFiltered[i].settlementDetails.processingRateRazerActive) {
          //     gmvRate = payoutTransactionsFiltered[i].settlementDetails.processingRateRazer;
          //   }
          // }

          if (i % 10 === 0) yield;

          for (let j = 0; j < payoutTransactionsFiltered[i].userOrdersFigures.length; j++) {
            // if (payoutTransactionsFiltered[i].userOrdersFigures[j].orderId === 'fe940886-1624-4498-8d9d-0ab5bb9128cb') {
            //   console.log('break')  ;
            // }
            // if (payoutTransactionsFiltered[i].clientEmail === 'kafemildura@gmail.com') {
            //   console.log('break')  ;
            // }

            if (payoutTransactionsFiltered[i].userOrdersFigures[j].combinedOrderList && payoutTransactionsFiltered[i].userOrdersFigures[j].combinedOrderList.length > 0) {
              // means this order already merged with other orders         
            }
            else {
              let userOrderFigure = payoutTransactionsFiltered[i].userOrdersFigures[j];

              //////////////////////////////////////////////////

              let toProceedPaymentType = false;
              let channel = (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel;

              if (payoutTransactionsFiltered[i].outletId === 'ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea') {
                console.log('break');
              }

              if (selectedPaymentType === PAYMENT_TYPE.ALL) {
                toProceedPaymentType = true;
              }
              else if (
                selectedPaymentType === PAYMENT_TYPE.E_WALLET &&
                (
                  channel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
                  channel === PAYMENT_CHANNEL_NAME.ShopeePay ||
                  channel === PAYMENT_CHANNEL_NAME.GrabPay ||
                  channel === PAYMENT_CHANNEL_NAME.BOOST ||
                  channel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
                )
              ) {
                toProceedPaymentType = true;
              }
              else if (
                selectedPaymentType === PAYMENT_TYPE.FPX &&
                (
                  channel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
                  channel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
                  channel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
                  channel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
                  channel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
                  channel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
                  channel === PAYMENT_CHANNEL_NAME['ALB-Paymex'] ||
                  channel === PAYMENT_CHANNEL_NAME['MB2u'] ||
                  channel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
                  channel === PAYMENT_CHANNEL_NAME['Cash-epay'] ||
                  channel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
                  channel === PAYMENT_CHANNEL_NAME['FPX_HLB']
                )
              ) {
                toProceedPaymentType = true;
              }
              else if (
                selectedPaymentType === PAYMENT_TYPE.CREDIT_DEBIT &&
                (
                  channel === PAYMENT_CHANNEL_NAME['credit'] ||
                  channel === PAYMENT_CHANNEL_NAME['Credit'] ||
                  channel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
                  channel === PAYMENT_CHANNEL_NAME['CIL_Visa']
                )
              ) {
                toProceedPaymentType = true;
              }
              else if (
                selectedPaymentType === PAYMENT_TYPE.OFFLINE &&
                (
                  channel.startsWith('Offline')
                )
              ) {
                toProceedPaymentType = true;
              }
              else if (
                selectedPaymentType === PAYMENT_TYPE.ONLINE &&
                (
                  !channel.startsWith('Offline')
                )
              ) {
                toProceedPaymentType = true;
              }
              else if (
                selectedPaymentType === PAYMENT_TYPE.OFFLINE_WO_CASH &&
                (
                  channel.startsWith('Offline') &&
                  !channel.startsWith('Offline-Cash')
                )
              ) {
                toProceedPaymentType = true;
              }
              else if (
                selectedPaymentType === PAYMENT_TYPE.ALL_WO_CASH &&
                (
                  !channel.startsWith('Offline-Cash')
                )
              ) {
                toProceedPaymentType = true;
              }
              else {
                toProceedPaymentType = false;
              }

              if (!toProceedPaymentType) {
                continue;
              }

              //////////////////////////////////////////////////

              // push to details list

              let toAddObj = {
                dateTime: moment(userOrderFigure.createdAt || payoutTransactionsFiltered[i].createdAt).format('YYYY/MM/DD HH:mm'),
                orderId: (userOrderFigure.orderIdHuman && userOrderFigure.orderType) ? `#${userOrderFigure.orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${userOrderFigure.orderIdHuman}` : 'N/A',
                uniqueId: userOrderFigure.orderId,

                // uniqueId: payoutTransactionsFiltered[i].uniqueId,
                isQrOrder: (userOrderFigure.appType !== undefined ? (userOrderFigure.appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No') : 'N/A'),
                netAmount: BigNumber(0).dp(2).toNumber(),
                tax: BigNumber(0).dp(2).toNumber(),
                sc: BigNumber(0).dp(2).toNumber(),
                totalAmount: BigNumber(userOrderFigure.userOrderPriceBeforeCommission).dp(2).toNumber(),
                razerCharges: BigNumber(userOrderFigure.commissionFeeFinal).dp(2).toNumber(),
                koodooCharges: BigNumber(userOrderFigure.koodooProcessingFee).dp(2).toNumber(),
                outletPayout: BigNumber(userOrderFigure.commissionFeeFinal > 0 ? userOrderFigure.userOrderPriceAfterCommissionAndFee : 0).dp(2).toNumber(),
                settlementDate: moment(payoutTransactionsFiltered[i].createdAt).format('YYYY/MM/DD'),

                ...userOrderFigure.kpfOd && {
                  kpfOd: BigNumber(userOrderFigure.kpfOd).dp(2).toNumber(),
                },
                ...userOrderFigure.cfOd && {
                  cfOd: BigNumber(userOrderFigure.cfOd).dp(2).toNumber(),
                },

                cfe: BigNumber(userOrderFigure.cfe ? userOrderFigure.cfe : 0).dp(2).toNumber(),

                isSettled: true,

                // gmvRate: gmvRate,

                orderTypeDetails: (userOrderFigure.appType === APP_TYPE.WEB_ORDER ?
                  ((userOrderFigure.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
                  :
                  ORDER_TYPE_DETAILS.POS),

                outletId: payoutTransactionsFiltered[i].outletId,
                // on: payoutTransactionsFiltered[i].outletName,

                koodooProcessingRate: userOrderFigure.koodooProcessingRate || 0,

                pdChannel: (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

                cartItemsLength: userOrderFigure.cartItems.length,
              };

              // let foundIndex = outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.findIndex(order => order.uniqueId === toAddObj.uniqueId);

              // if (foundIndex >= 0) {
              //   outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList[foundIndex] = toAddObj;
              // }
              // else {
              //   outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.push(toAddObj);
              // }           

              // if (!outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.find(order => order.uniqueId === toAddObj.uniqueId)) {
              //   outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.push(toAddObj);
              // }            

              outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.push(toAddObj);

              //////////////////////////////////////////////////////////////////////

              // 2025-01-09 - count pos w/o cash orders

              if (userOrderFigure.pdChannel !== OFFLINE_PAYMENT_METHOD_TYPE.CASH &&
                userOrderFigure.pdChannel &&
                (
                  userOrderFigure.pdChannel.startsWith('Offline')
                  ||
                  RAZER_CHANNEL_COMMISIONS_MYR_RATE[userOrderFigure.pdChannel] !== undefined
                )
                &&
                !userOrderFigure.pdChannel.startsWith('Offline-Cash')
              ) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppWoCashAmount += userOrderFigure.userOrderPriceBeforeCommission;
                outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppWoCashOrdersQty += 1;
              }

              //////////////////////////////////////////////////////////////////////

              if (userOrderFigure.pdChannel === ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOnlineOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
                outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOnlineOrdersQty += 1;

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                checkOutletFilterDict({
                  uniqueId: payoutTransactionsFiltered[i].outletId,
                }, userOrderFigure.createdAt, {
                  startDate24H,
                  endDate24H,
                  startDate7D,
                  endDate7D,
                  startDate1M,
                  endDate1M,
                  startDate3M,
                  endDate3M,
                });

                continue;
              }

              if ((userOrderFigure.appType === APP_TYPE.MERCHANT ||
                userOrderFigure.appType === undefined ||
                userOrderFigure.appType === APP_TYPE.UNKNOWN) &&
                !userOrderFigure.isOnlineOrdering) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
                outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersQty += 1;

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                checkOutletFilterDict({
                  uniqueId: payoutTransactionsFiltered[i].outletId,
                }, userOrderFigure.createdAt, {
                  startDate24H,
                  endDate24H,
                  startDate7D,
                  endDate7D,
                  startDate1M,
                  endDate1M,
                  startDate3M,
                  endDate3M,
                });

                continue;
              }

              if (userOrderFigure.appType === APP_TYPE.USER ||
                userOrderFigure.appType === APP_TYPE.WEB_ORDER) {
                if (userOrderFigure.orderType === ORDER_TYPE.DINEIN) {
                  if (userOrderFigure.commissionFeeFinal > 0) {
                    // is online paid

                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
                  }
                  else {
                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;
                  }

                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                  checkOutletFilterDict({
                    uniqueId: payoutTransactionsFiltered[i].outletId,
                  }, userOrderFigure.createdAt, {
                    startDate24H,
                    endDate24H,
                    startDate7D,
                    endDate7D,
                    startDate1M,
                    endDate1M,
                    startDate3M,
                    endDate3M,
                  });

                  continue;
                }
                else if (userOrderFigure.orderType === ORDER_TYPE.PICKUP) {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                  checkOutletFilterDict({
                    uniqueId: payoutTransactionsFiltered[i].outletId,
                  }, userOrderFigure.createdAt, {
                    startDate24H,
                    endDate24H,
                    startDate7D,
                    endDate7D,
                    startDate1M,
                    endDate1M,
                    startDate3M,
                    endDate3M,
                  });

                  continue;
                }
                else if (userOrderFigure.orderType === ORDER_TYPE.DELIVERY) {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                  checkOutletFilterDict({
                    uniqueId: payoutTransactionsFiltered[i].outletId,
                  }, userOrderFigure.createdAt, {
                    startDate24H,
                    endDate24H,
                    startDate7D,
                    endDate7D,
                    startDate1M,
                    endDate1M,
                    startDate3M,
                    endDate3M,
                  });

                  continue;
                }
              }

              ////////////////////////////////////////////////////////////

              // to compatible with previous web orders

              if (
                // payoutTransactionsFiltered[i].appType === undefined &&
                userOrderFigure.isOnlineOrdering) {
                if (userOrderFigure.orderType === ORDER_TYPE.DINEIN) {
                  if (userOrderFigure.commissionFeeFinal > 0) {
                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
                  }
                  else {
                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                    outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;
                  }

                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                  checkOutletFilterDict({
                    uniqueId: payoutTransactionsFiltered[i].outletId,
                  }, userOrderFigure.createdAt, {
                    startDate24H,
                    endDate24H,
                    startDate7D,
                    endDate7D,
                    startDate1M,
                    endDate1M,
                    startDate3M,
                    endDate3M,
                  });

                  continue;
                }
                else if (userOrderFigure.orderType === ORDER_TYPE.PICKUP) {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                  checkOutletFilterDict({
                    uniqueId: payoutTransactionsFiltered[i].outletId,
                  }, userOrderFigure.createdAt, {
                    startDate24H,
                    endDate24H,
                    startDate7D,
                    endDate7D,
                    startDate1M,
                    endDate1M,
                    startDate3M,
                    endDate3M,
                  });

                  continue;
                }
                else if (userOrderFigure.orderType === ORDER_TYPE.DELIVERY) {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                    outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                  checkOutletFilterDict({
                    uniqueId: payoutTransactionsFiltered[i].outletId,
                  }, userOrderFigure.createdAt, {
                    startDate24H,
                    endDate24H,
                    startDate7D,
                    endDate7D,
                    startDate1M,
                    endDate1M,
                    startDate3M,
                    endDate3M,
                  });

                  continue;
                }
              }

              ////////////////////////////////////////////////////////////

              if (userOrderFigure.appType === APP_TYPE.WAITER) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersQty += 1;

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                checkOutletFilterDict({
                  uniqueId: payoutTransactionsFiltered[i].outletId,
                }, userOrderFigure.createdAt, {
                  startDate24H,
                  endDate24H,
                  startDate7D,
                  endDate7D,
                  startDate1M,
                  endDate1M,
                  startDate3M,
                  endDate3M,
                });

                continue;
              }
            }
          }

          if (payoutTransactionsFiltered[i].linkedRazerPayoutTransactionId) {
            // means is extended razer payout transaction, no need do anything
          }
          else {
            if (payoutTransactionsFiltered[i].outletCycleKoodooMDREarned > 0) {
              console.log(payoutTransactionsFiltered[i]);
              console.log('stop');
            }

            outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommission = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommission).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleKoodooPayoutsExpected)).toNumber();
            outletDataDict[payoutTransactionsFiltered[i].outletId].gmvFees = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvFees).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleRazerPayouts)).toNumber();
            outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayout = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayout).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleMerchantPayoutsExpected)).toNumber();
            outletDataDict[payoutTransactionsFiltered[i].outletId].mdrEarned = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].mdrEarned).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleKoodooMDREarned ? payoutTransactionsFiltered[i].outletCycleKoodooMDREarned : 0)).toNumber();

            outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommissionActual = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommissionActual).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleKoodooPayoutsActual)).toNumber();
            outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayoutActual = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayoutActual).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleMerchantPayoutsActual)).toNumber();

            outletDataDict[payoutTransactionsFiltered[i].outletId].razerPayoutTransactionList.push({
              active: payoutTransactionsFiltered[i].userOrdersFigures.length > 0 ? true : false,
              bankCode: payoutTransactionsFiltered[i].bankCode,
              bankAccountNumber: payoutTransactionsFiltered[i].bankAccountNumber,
              mid: payoutTransactionsFiltered[i].mid,
              tid: payoutTransactionsFiltered[i].tid,

              outletCycleKoodooPayoutsExpected: payoutTransactionsFiltered[i].outletCycleKoodooPayoutsExpected,
              outletCycleRazerPayouts: payoutTransactionsFiltered[i].outletCycleRazerPayouts,
              outletCycleMerchantPayoutsExpected: payoutTransactionsFiltered[i].outletCycleMerchantPayoutsExpected,
              outletCycleKoodooPayoutsActual: payoutTransactionsFiltered[i].outletCycleKoodooPayoutsActual,
              outletCycleMerchantPayoutsActual: payoutTransactionsFiltered[i].outletCycleMerchantPayoutsActual,
              outletCycleKoodooMDREarned: payoutTransactionsFiltered[i].outletCycleKoodooMDREarned ? payoutTransactionsFiltered[i].outletCycleKoodooMDREarned : 0,

              outletCycleMerchantOverdueAmountsMdr: payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsRhb ? payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsMdr : 0,
              outletCycleMerchantOverdueAmountsGmv: payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsRhb ? payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsGmv : 0,

              outletId: payoutTransactionsFiltered[i].outletId,
              outletCycleFunds: payoutTransactionsFiltered[i].outletCycleFunds,
              outletCycleMerchantOverdueAmounts: payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmounts,
              outletCycleMerchantPendingAmounts: payoutTransactionsFiltered[i].outletCycleMerchantPendingAmounts,

              stockUpAmount: payoutTransactionsFiltered[i].stockUpAmount,

              outletCycleMerchantPendingRefundOrdersAmount: payoutTransactionsFiltered[i].outletCycleMerchantPendingRefundOrdersAmount ? payoutTransactionsFiltered[i].outletCycleMerchantPendingRefundOrdersAmount : 0,
              outletCycleMerchantRefundOrdersAmount: payoutTransactionsFiltered[i].outletCycleMerchantRefundOrdersAmount ? payoutTransactionsFiltered[i].outletCycleMerchantRefundOrdersAmount : 0,

              payoutFee: payoutTransactionsFiltered[i].payoutFee,

              prevStockUpAmount: payoutTransactionsFiltered[i].prevStockUpAmount ? payoutTransactionsFiltered[i].prevStockUpAmount : 0,
              prevOverdueAmount: payoutTransactionsFiltered[i].prevOverdueAmount ? payoutTransactionsFiltered[i].prevOverdueAmount : 0,
              prevPendingAmount: payoutTransactionsFiltered[i].prevPendingAmount ? payoutTransactionsFiltered[i].prevPendingAmount : 0,

              transactionDate: payoutTransactionsFiltered[i].transactionDate,
              settlementDate: payoutTransactionsFiltered[i].createdAt,

              uniqueId: payoutTransactionsFiltered[i].uniqueId,

              ...(payoutTransactionsFiltered[i].rsc !== undefined) && {
                rsc: payoutTransactionsFiltered[i].rsc,
              },
            });

            //////////////////////////////////////////////////////////////////////

            // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersAmount = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount +
            //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersAmount +
            //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount;
            // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersQty = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty +
            //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersQty +
            //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty;
          }
        }
      }

      ////////////////////////////////////////////////////////////

      // for gmvOrdersRecent

      console.log('loop');
      console.log(gmvOrdersRecent.length);

      for (let i = 0; i < gmvOrdersRecent.length; i++) {
        if (
          outletDataDict[gmvOrdersRecent[i].outletId] &&
          !outletDataDict[gmvOrdersRecent[i].outletId].detailsList.find(order => order.uniqueId === gmvOrdersRecent[i].uniqueId)
        ) {

          outletDataDict[gmvOrdersRecent[i].outletId].detailsList.push({
            dateTime: moment(gmvOrdersRecent[i].createdAt).format('YYYY/MM/DD HH:mm'),
            orderId: `#${gmvOrdersRecent[i].orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${gmvOrdersRecent[i].orderId}`,
            uniqueId: gmvOrdersRecent[i].uniqueId,
            isQrOrder: gmvOrdersRecent[i].appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No',
            netAmount: BigNumber(gmvOrdersRecent[i].finalPrice).minus(gmvOrdersRecent[i].tax).minus(gmvOrdersRecent[i].sc).dp(2).toNumber(),
            tax: BigNumber(gmvOrdersRecent[i].tax).dp(2).toNumber(),
            sc: BigNumber(gmvOrdersRecent[i].sc).dp(2).toNumber(),
            totalAmount: BigNumber(gmvOrdersRecent[i].finalPrice).dp(2).toNumber(),
            razerCharges: BigNumber((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecent[i].settlementDetails.commissionFeeFinal : 0).dp(2).toNumber(),
            koodooCharges: BigNumber((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecent[i].settlementDetails.koodooProcessingFee : 0).dp(2).toNumber(),
            outletPayout: BigNumber(
              (gmvOrdersRecent[i].paymentDetails &&
                (gmvOrdersRecent[i].paymentDetails.txn_ID !== undefined ||
                  gmvOrdersRecent[i].paymentDetails.txnId !== undefined)
                &&
                (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
              )
                ?
                gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee
                :
                0)
              .dp(2).toNumber(),
            settlementDate: gmvOrdersRecent[i].settlementDate ? moment(gmvOrdersRecent[i].settlementDate).format('YYYY/MM/DD') : 'N/A',

            ...gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.kpfOd && {
              kpfOd: BigNumber(gmvOrdersRecent[i].settlementDetails.kpfOd).dp(2).toNumber(),
            },
            ...gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.cfOd && {
              cfOd: BigNumber(gmvOrdersRecent[i].settlementDetails.cfOd).dp(2).toNumber(),
            },

            cfe: BigNumber((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.cfe) ? gmvOrdersRecent[i].settlementDetails.cfe : 0).dp(2).toNumber(),

            isSettled: gmvOrdersRecent[i].settlementDate ? true : false,

            orderTypeDetails: (gmvOrdersRecent[i].appType === APP_TYPE.WEB_ORDER ?
              ((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
              :
              ORDER_TYPE_DETAILS.POS),

            outletId: gmvOrdersRecent[i].outletId,
            // on: gmvOrdersRecent[i].outletName,

            koodooProcessingRate: gmvOrdersRecent[i].koodooProcessingRate || 0,

            pdChannel: (gmvOrdersRecent[i].paymentDetails && gmvOrdersRecent[i].paymentDetails.channel) ? gmvOrdersRecent[i].paymentDetails.channel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

            cartItemsLength: gmvOrdersRecent[i].cartItems.length,
          });

          outletDataDict[gmvOrdersRecent[i].outletId].gmvCommission = BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].gmvCommission).plus(BigNumber((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecent[i].settlementDetails.koodooProcessingFee : 0)).toNumber();
          outletDataDict[gmvOrdersRecent[i].outletId].gmvFees = BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].gmvFees).plus(BigNumber((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecent[i].settlementDetails.commissionFeeFinal : 0)).toNumber();
          outletDataDict[gmvOrdersRecent[i].outletId].gmvPayout = BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].gmvPayout).plus(BigNumber(
            (gmvOrdersRecent[i].paymentDetails &&
              (gmvOrdersRecent[i].paymentDetails.txn_ID !== undefined ||
                gmvOrdersRecent[i].paymentDetails.txnId !== undefined)
              &&
              (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
            )
              ?
              gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee
              :
              0)).toNumber();

          /////////////////////////////////////////////////////////////////////////////

          // let orderFinalPrice = BigNumber(!isNaN(currUserOrder.finalPrice) ? currUserOrder.finalPrice : 0);
          // if (currUserOrder.isRefundOrder) {
          //   orderFinalPrice = BigNumber(currUserOrder.finalPriceBackup); // use the original amount to calculate commission
          // }

          // let commissionRateVendor = BigNumber(0);

          // try {
          //   commissionRateVendor = BigNumber(RAZER_CHANNEL_COMMISIONS_MYR_RATE[gmvOrdersRecent[i].paymentDetails.channel] ? RAZER_CHANNEL_COMMISIONS_MYR_RATE[gmvOrdersRecent[i].paymentDetails.channel] : 0);
          // }
          // catch (ex) {
          //   console.error(ex);
          // }

          // let commissionRate = BigNumber(RAZER_CHANNEL_COMMISIONS_MYR_RATE[gmvOrdersRecent[i].paymentDetails.channel] ? RAZER_CHANNEL_COMMISIONS_MYR_RATE[gmvOrdersRecent[i].paymentDetails.channel] : 0);

          //////////////////////////////////////////////////////////////////////////////

          // 2023-03-17 - For custom razer charges and online-paid orders

          // if (currRazerOutletBankInfo.razerChargesRateCustom > 0 && BigNumber(commissionRate).toNumber() > 0) {
          //   commissionRate = BigNumber(currRazerOutletBankInfo.razerChargesRateCustom);
          // }

          // let commissionFeeMin = BigNumber(RAZER_CHANNEL_COMMISIONS_MYR_MIN[currUserOrder.paymentDetails.channel] ? RAZER_CHANNEL_COMMISIONS_MYR_MIN[currUserOrder.paymentDetails.channel] : 0);
          // commissionFee = BigNumber(orderFinalPrice).multipliedBy(commissionRate).dividedBy(100);
          // let commissionFeeFinal = BigNumber(Math.max(commissionFee.toNumber(), commissionFeeMin.toNumber()));
          // if (orderFinalPrice > 0) {
          //   commissionRateFinal = commissionFeeFinal.dividedBy(orderFinalPrice).multipliedBy(100);
          // }

          // //////////////////////////////////////////////////////////////////////////////

          // try {
          //   if (currUserOrder.isRefundOrder && currUserOrder.isRefundOnline) {
          //     // mdr rate will refunded also, can skip
          //   }
          //   else {
          //     let commissionFeeVendor = BigNumber(orderFinalPrice).multipliedBy(commissionRateVendor).dividedBy(100);
          //     let commissionFeeFinalVendor = BigNumber(Math.max(commissionFeeVendor.toNumber(), commissionFeeMin.toNumber()));
          //     let commissionRateFinalVendor = BigNumber(commissionRateVendor);
          //     if (orderFinalPrice > 0) {
          //       commissionRateFinalVendor = commissionFeeFinalVendor.dividedBy(orderFinalPrice).multipliedBy(100);
          //     }

          //     let commissionRateDiff = BigNumber(commissionRate).minus(commissionRateFinalVendor);

          //     console.log('===================================');
          //     console.log('===================================');
          //     console.log('commissionRateDiff');
          //     console.log(BigNumber(commissionRateDiff).toNumber());
          //     console.log(BigNumber(commissionRate).toNumber());
          //     console.log(BigNumber(commissionRateFinalVendor).toNumber());
          //     console.log('===================================');
          //     console.log('===================================');

          //     if (BigNumber(commissionRateDiff).isGreaterThan(0)) {
          //       commissionFeeEarned = BigNumber(orderFinalPrice).multipliedBy(commissionRateDiff).dividedBy(100);

          //       outletCycleKoodooMDREarned = outletCycleKoodooMDREarned.plus(commissionFeeEarned);
          //       totalCycleKoodooMDREarned = totalCycleKoodooMDREarned.plus(commissionFeeEarned);
          //     }
          //   }
          // }
          // catch (ex) {
          //   console.error(ex);
          // }

          // /////////////////////////////////////////////////////////////////////////////

          // outletDataDict[gmvOrdersRecent[i].outletId].mdrEarned = BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].gmvFees).plus(BigNumber((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecent[i].settlementDetails.commissionFeeFinal : 0)).toNumber();

          koodooProfitExpectedTodayTemp = BigNumber(koodooProfitExpectedTodayTemp).plus(BigNumber((gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecent[i].settlementDetails.koodooProcessingFee : 0));

          // outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommissionActual = BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].gmvCommissionActual).plus(BigNumber(userOrderFigure.outletCycleKoodooPayoutsActual)).toNumber();
          // outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayoutActual = BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].gmvPayoutActual).plus(BigNumber(userOrderFigure.outletCycleMerchantPayoutsActual)).toNumber();

          //////////////////////////////////////////////////////////////////////

          //////////////////////////////////////////////////////////////////////

          // 2022-12-11 - To show under expanded summary

          // var orderStartDate = moment(gmvOrdersRecent[i].createdAt).startOf('day').valueOf();
          // var orderEndDate = moment(gmvOrdersRecent[i].createdAt).endOf('day').valueOf();

          // if (moment(orderEndDate).isSame(window.currToDateTime, 'day')) {
          //   orderEndDate = window.currToDateTime;
          // }

          // if (outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate]) {
          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsExpected =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsExpected).plus(
          //       (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecent[i].settlementDetails.koodooProcessingFee : 0).toNumber();

          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleRazerPayouts =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleRazerPayouts).plus(
          //       (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecent[i].settlementDetails.commissionFeeFinal : 0).toNumber();

          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleMerchantPayoutsExpected =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleMerchantPayoutsExpected).plus(
          //       (gmvOrdersRecent[i].paymentDetails &&
          //         (gmvOrdersRecent[i].paymentDetails.txn_ID !== undefined ||
          //           gmvOrdersRecent[i].paymentDetails.txnId !== undefined)
          //         &&
          //         (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
          //       )
          //         ?
          //         gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee
          //         :
          //         0).toNumber();

          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsOnlineOnlyExpected =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsOnlineOnlyExpected).plus(
          //       (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal > 0) ? gmvOrdersRecent[i].settlementDetails.koodooProcessingFee : 0).toNumber();
          // }

          // if (outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate]) {
          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsExpected =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsExpected).plus(
          //       (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecent[i].settlementDetails.koodooProcessingFee : 0).toNumber();

          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleRazerPayouts =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleRazerPayouts).plus(
          //       (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecent[i].settlementDetails.commissionFeeFinal : 0).toNumber();

          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleMerchantPayoutsExpected =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleMerchantPayoutsExpected).plus(
          //       (gmvOrdersRecent[i].paymentDetails &&
          //         (gmvOrdersRecent[i].paymentDetails.txn_ID !== undefined ||
          //           gmvOrdersRecent[i].paymentDetails.txnId !== undefined)
          //         &&
          //         (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
          //       )
          //         ?
          //         gmvOrdersRecent[i].settlementDetails.userOrderPriceAfterCommissionAndFee
          //         :
          //         0).toNumber();

          //   outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsOnlineOnlyExpected =
          //     BigNumber(outletDataDict[gmvOrdersRecent[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsOnlineOnlyExpected).plus(
          //       (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal > 0) ? gmvOrdersRecent[i].settlementDetails.koodooProcessingFee : 0).toNumber();
          // }

          //       //////////////////////////////////////////////////////////////////////

          //////////////////////////////////////////////////////////////////////

          // 2025-01-09 - count pos w/o cash orders

          if (gmvOrdersRecent[i].paymentDetails && gmvOrdersRecent[i].paymentDetails.channel !== OFFLINE_PAYMENT_METHOD_TYPE.CASH &&
            gmvOrdersRecent[i].paymentDetails.channel &&
            (
              gmvOrdersRecent[i].paymentDetails.channel.startsWith('Offline')
              ||
              RAZER_CHANNEL_COMMISIONS_MYR_RATE[gmvOrdersRecent[i].paymentDetails.channel] !== undefined
            )
            &&
            !gmvOrdersRecent[i].paymentDetails.channel.startsWith('Offline-Cash')
          ) {
            outletDataDict[gmvOrdersRecent[i].outletId].merchantAppWoCashAmount += gmvOrdersRecent[i].finalPrice;
            outletDataDict[gmvOrdersRecent[i].outletId].merchantAppWoCashOrdersQty += 1;
          }

          //////////////////////////////////////////////////////////////////////

          if (gmvOrdersRecent[i].paymentDetails &&
            gmvOrdersRecent[i].paymentDetails.channel === ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX) {
            outletDataDict[gmvOrdersRecent[i].outletId].merchantAppOnlineOrdersAmount += gmvOrdersRecent[i].finalPrice;
            // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
            outletDataDict[gmvOrdersRecent[i].outletId].merchantAppOnlineOrdersQty += 1;

            outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersRecent[i].outletId,
            }, gmvOrdersRecent[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }

          if ((gmvOrdersRecent[i].appType === APP_TYPE.MERCHANT ||
            gmvOrdersRecent[i].appType === undefined ||
            gmvOrdersRecent[i].appType === APP_TYPE.UNKNOWN) &&
            !gmvOrdersRecent[i].isOnlineOrdering) {
            outletDataDict[gmvOrdersRecent[i].outletId].merchantAppOrdersAmount += gmvOrdersRecent[i].finalPrice;
            // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
            outletDataDict[gmvOrdersRecent[i].outletId].merchantAppOrdersQty += 1;

            outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersRecent[i].outletId,
            }, gmvOrdersRecent[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }

          if (gmvOrdersRecent[i].appType === APP_TYPE.USER ||
            gmvOrdersRecent[i].appType === APP_TYPE.WEB_ORDER) {
            if (gmvOrdersRecent[i].orderType === ORDER_TYPE.DINEIN) {
              if (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal > 0) {
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOnlineOrdersAmount += gmvOrdersRecent[i].finalPrice;
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
              }
              else {
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOrdersAmount += gmvOrdersRecent[i].finalPrice;
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOrdersQty += 1;
              }

              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

              checkOutletFilterDict({
                uniqueId: gmvOrdersRecent[i].outletId,
              }, gmvOrdersRecent[i].createdAt, {
                startDate24H,
                endDate24H,
                startDate7D,
                endDate7D,
                startDate1M,
                endDate1M,
                startDate3M,
                endDate3M,
              });

              continue;
            }
            else if (gmvOrdersRecent[i].orderType === ORDER_TYPE.PICKUP) {
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebTakeawayOrdersAmount += gmvOrdersRecent[i].finalPrice;
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

              checkOutletFilterDict({
                uniqueId: gmvOrdersRecent[i].outletId,
              }, gmvOrdersRecent[i].createdAt, {
                startDate24H,
                endDate24H,
                startDate7D,
                endDate7D,
                startDate1M,
                endDate1M,
                startDate3M,
                endDate3M,
              });

              continue;
            }
            else if (gmvOrdersRecent[i].orderType === ORDER_TYPE.DELIVERY) {
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDeliveryOrdersAmount += gmvOrdersRecent[i].finalPrice;
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

              checkOutletFilterDict({
                uniqueId: gmvOrdersRecent[i].outletId,
              }, gmvOrdersRecent[i].createdAt, {
                startDate24H,
                endDate24H,
                startDate7D,
                endDate7D,
                startDate1M,
                endDate1M,
                startDate3M,
                endDate3M,
              });

              continue;
            }
          }

          ////////////////////////////////////////////////////////////

          // to compatible with previous web orders

          if (gmvOrdersRecent[i].appType === undefined &&
            gmvOrdersRecent[i].isOnlineOrdering) {
            if (gmvOrdersRecent[i].orderType === ORDER_TYPE.DINEIN) {
              if (gmvOrdersRecent[i].settlementDetails && gmvOrdersRecent[i].settlementDetails.commissionFeeFinal > 0) {
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOnlineOrdersAmount += gmvOrdersRecent[i].finalPrice;
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
              }
              else {
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOrdersAmount += gmvOrdersRecent[i].finalPrice;
                outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDineInOrdersQty += 1;
              }

              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

              checkOutletFilterDict({
                uniqueId: gmvOrdersRecent[i].outletId,
              }, gmvOrdersRecent[i].createdAt, {
                startDate24H,
                endDate24H,
                startDate7D,
                endDate7D,
                startDate1M,
                endDate1M,
                startDate3M,
                endDate3M,
              });

              continue;
            }
            else if (gmvOrdersRecent[i].orderType === ORDER_TYPE.PICKUP) {
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebTakeawayOrdersAmount += gmvOrdersRecent[i].finalPrice;
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

              checkOutletFilterDict({
                uniqueId: gmvOrdersRecent[i].outletId,
              }, gmvOrdersRecent[i].createdAt, {
                startDate24H,
                endDate24H,
                startDate7D,
                endDate7D,
                startDate1M,
                endDate1M,
                startDate3M,
                endDate3M,
              });

              continue;
            }
            else if (gmvOrdersRecent[i].orderType === gmvOrdersRecent.DELIVERY) {
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDeliveryOrdersAmount += gmvOrdersRecent[i].finalPrice;
              outletDataDict[gmvOrdersRecent[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
                outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

              checkOutletFilterDict({
                uniqueId: gmvOrdersRecent[i].outletId,
              }, gmvOrdersRecent[i].createdAt, {
                startDate24H,
                endDate24H,
                startDate7D,
                endDate7D,
                startDate1M,
                endDate1M,
                startDate3M,
                endDate3M,
              });

              continue;
            }
          }

          ////////////////////////////////////////////////////////////

          if (gmvOrdersRecent[i].appType === APP_TYPE.WAITER) {
            outletDataDict[gmvOrdersRecent[i].outletId].waiterAppOrdersAmount += gmvOrdersRecent[i].finalPrice;
            outletDataDict[gmvOrdersRecent[i].outletId].waiterAppOrdersQty += 1;

            outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecent[i].createdAt >
              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecent[i].createdAt :
              outletDataDict[gmvOrdersRecent[i].outletId].lastOrderPlacedDateTime;

            checkOutletFilterDict({
              uniqueId: gmvOrdersRecent[i].outletId,
            }, gmvOrdersRecent[i].createdAt, {
              startDate24H,
              endDate24H,
              startDate7D,
              endDate7D,
              startDate1M,
              endDate1M,
              startDate3M,
              endDate3M,
            });

            continue;
          }

          console.log('orders not categorized');
          console.log(gmvOrdersRecent[i]);

          // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersAmount = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount +
          //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersAmount +
          //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount;
          // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersQty = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty +
          //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersQty +
          //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty;
        }
      }

      console.log('outletDataDict');
      console.log(outletDataDict);
    });

    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////

    // update details automatically

    if (outletDataDict[showDetailsOutletId] && outletDataDict[showDetailsOutletId].detailsList !== undefined) {
      // var detailsListTemp = outletDataDict[showDetailsOutletId].detailsList;
      // detailsListTemp.sort(sortByDateTime);
      // setAllOutletsEmployeesDetails(detailsListTemp);
      // setToggleCompare(!toggleCompare);

      // setTableDataDetails(outletDataDict[showDetailsOutletId].detailsList);
      setAllOutletsEmployeesDetails(outletDataDict[showDetailsOutletId].detailsList);
    }

    ////////////////////////////////////////////////////////////

    // var tempData = Object.entries(outletDataDict).map(([key, value]) => {
    var tempData = await mapAsync(Object.entries(outletDataDict), ([key, value]) => {
      // console.log('total');
      // console.log(value.userAppAndWebDineInOrdersAmount);
      // console.log(value.merchantAppOrdersAmount);
      // console.log(value.waiterAppOrdersAmount);
      // console.log(value.userAppAndWebTakeawayOrdersAmount);
      // console.log(value.userAppAndWebDeliveryOrdersAmount);

      return {
        ...value,
        totalOrdersAmount: value.userAppAndWebDineInOrdersAmount + value.userAppAndWebDineInOnlineOrdersAmount + value.merchantAppOrdersAmount + value.merchantAppOnlineOrdersAmount + value.waiterAppOrdersAmount +
          value.userAppAndWebTakeawayOrdersAmount + value.userAppAndWebDeliveryOrdersAmount,
        totalOrdersQty: value.userAppAndWebDineInOrdersQty + value.userAppAndWebDineInOnlineOrdersQty + value.merchantAppOrdersQty + value.merchantAppOnlineOrdersQty + value.waiterAppOrdersQty +
          value.userAppAndWebTakeawayOrdersQty + value.userAppAndWebDeliveryOrdersQty,

        // recentPayoutTransactionList: Object.entries(value.recentPayoutTransactionDict).map(([key, value]) => ({ ...value })),
      };
    });

    setKooDooProfitExpectedToday(BigNumber(koodooProfitExpectedTodayTemp).toNumber());

    // delete outletDataDict;
    outletDataDict = {};

    console.log('tempData');
    console.log(tempData);

    const outletLastOrderArray = Object.entries(window.pOutletFilterDict).map(
      ([key, value]) => ({ key: key, value: value }),
    );

    setMerchantDataAllLength(tempData.length);

    // var startDate24H = moment().subtract(1, "day").startOf('day').valueOf();
    // var endDate24H = moment().subtract(1, "day").endOf('day').valueOf();
    setMerchantData24HLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate24H).isSameOrBefore(temp.value) &&
      //   moment(endDate24H).isAfter(temp.value)) {
      if (temp.value['is24H']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
    // var endDate7D = moment().subtract(1, "day").endOf('day').valueOf();
    setMerchantData7DLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate7D).isSameOrBefore(temp.value) &&
      //   moment(endDate7D).isAfter(temp.value)) {
      if (temp.value['is7D']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // var startDate1M = moment().subtract(1, "month").startOf('month').startOf('day').valueOf();
    // var endDate1M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
    setMerchantData1MLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate1M).isSameOrBefore(temp.value) &&
      //   moment(endDate1M).isAfter(temp.value)) {
      if (temp.value['is1M']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // var startDate3M = moment().subtract(3, "month").startOf('month').startOf('day').valueOf();
    // var endDate3M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
    setMerchantData3MLength(outletLastOrderArray.filter(temp => {
      // if (moment(startDate3M).isSameOrBefore(temp.value) &&
      //   moment(endDate3M).isAfter(temp.value)) {
      if (temp.value['is3M']) {
        return true;
      }
      else {
        return false;
      }
    }).length);

    // CommonStore.update(s => {
    //   s.outletFilterDict = window.pOutletFilterDict;
    // });

    // setMerchantData3MLength(tempData.filter(temp => {
    //   // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 3) {
    //   if (moment(startDate3M).isSameOrBefore(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId]) &&
    //     moment(endDate3M).isAfter(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId])) {
    //     if (temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId]) {
    //       return true;
    //     }
    //     else {
    //       return false;
    //     }
    //   }
    //   else {
    //     return false;
    //   }
    // }).length);

    if (merchantDataFilterType === MERCHANT_DATA_FILTER.ALL) {
      tempData = tempData;
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'hour') <= 24) {
        if (moment(startDate24H).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate24H).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'day') <= 7) {
        if (moment(startDate7D).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate7D).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 1) {
        if (moment(startDate1M).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate1M).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }
    else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M) {
      tempData = tempData.filter(temp => {
        // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 3) {
        if (moment(startDate3M).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
          moment(endDate3M).isAfter(temp.lastOrderPlacedDateTime)) {
          if (temp.lastOrderPlacedDateTime) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
    }

    //////////////////////////////////////////////////////////////////

    // sort by default

    // tempData.sort(sortByTotalOrdersAmountDesc);
    await run(function* () {
      yield* sort(tempData, sortByTotalOrdersAmountDesc);
    });

    //////////////////////////////////////////////////////////////////

    const tempKCRMarray = tempData;

    console.log("detailsList", tempKCRMarray);

    setAllOutletsEmployeesAction(tempKCRMarray);
    // setTableDataSummary(tempKCRMarray);

    setPageCount(Math.ceil(tempKCRMarray.length / perPage));

    //////////////////////////////////////////////////////////////////

    // save data

    await AsyncStorage.setItem('outletFilterDict', JSON.stringify(window.pOutletFilterDict));

    //////////////////////////////////////////////////////////////////
  }, [
    // merchantsOnboarded, 
    outletsOnboarded,
    ptStartDate, ptEndDate, merchantDataFilterType,
    outletUserActionDict,
    payoutTransactions,
    payoutTransactionsExtend,

    gmvOrdersRecent,

    selectedPaymentType,
  ]);

  ///////////////////////////////////////////////////////////////////////////////

  // 2022-12-11 - another useeffect to mimic the data flow, but influenced by chart date selection

  useEffect(async () => {
    ///////////////////////////////////////////////////////////////////////

    // for gmv orders recent

    var ytdStartDate = moment().add(-1, 'day').startOf('day').valueOf();
    // var todayEndDate = moment().endOf('day').valueOf();
    var todayEndDate = moment(window.currToDateTime).valueOf();

    // if (ptStartDate >= ytdStartDate) {
    //   ytdStartDate = ptStartDate;
    // }

    if (moment().isSameOrAfter(moment().set('hour', 6))) {
      ytdStartDate = moment().startOf('day').valueOf();
    }

    if (ptEndDate < todayEndDate) {
      todayEndDate = ptEndDate;
    }

    ///////////////////////////////////////////////////////////////////////

    var startDate24H = moment().startOf('day').valueOf();
    // var endDate24H = moment().endOf('day').valueOf();
    var endDate24H = moment(window.currToDateTime).valueOf();

    var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
    // var endDate7D = moment().endOf('day').valueOf();
    var endDate7D = moment(window.currToDateTime).valueOf();

    var startDate1M = moment().subtract(1, "month").startOf('day').valueOf();
    // var endDate1M = moment().endOf('day').valueOf();
    var endDate1M = moment(window.currToDateTime).valueOf();

    var startDate3M = moment().subtract(3, "month").startOf('day').valueOf();
    // var endDate3M = moment().endOf('day').valueOf();
    var endDate3M = moment(window.currToDateTime).valueOf();

    var tempDataBefore = outletsOnboarded.map(outlet => {
      var lastActivity = outletUserActionDict[outlet.uniqueId];
      if (lastActivity) {
        lastActivity = lastActivity.updatedAt;
      }

      // if (!window.pOutletFilterDict[outlet.uniqueId]) {
      //   window.pOutletFilterDict[outlet.uniqueId] = {
      //     is24H: false,
      //     is7D: false,
      //     is1M: false,
      //     is3M: false,
      //   };
      // }
      // else {
      //   window.pOutletFilterDict[outlet.uniqueId]['is24H'] = false;
      // }      

      var recentPayoutTransactionDictTemp = {};
      if (moment(ytdStartDate).isSame(moment().add(-1, 'day').startOf('day'), 'day') &&
        moment(ytdStartDate).isBetween(ptStartDate, ptEndDate, null, '[]')) {
        recentPayoutTransactionDictTemp[ytdStartDate] = {
          outletCycleKoodooPayoutsExpected: 0,
          outletCycleRazerPayouts: 0,
          outletCycleMerchantPayoutsExpected: 0,
          outletCycleKoodooPayoutsActual: undefined,
          outletCycleMerchantPayoutsActual: undefined,
          outletCycleKoodooMDREarned: undefined,

          outletCycleMerchantOverdueAmountsMdr: 0,
          outletCycleMerchantOverdueAmountsGmv: 0,

          // mdrEarned: 0,

          outletId: '',
          outletCycleFunds: undefined,
          outletCycleMerchantOverdueAmounts: undefined,
          outletCycleMerchantPendingAmounts: undefined,

          stockUpAmount: undefined,

          outletCycleMerchantPendingRefundOrdersAmount: undefined,
          outletCycleMerchantRefundOrdersAmount: undefined,

          payoutFee: undefined,

          prevStockUpAmount: undefined,
          prevOverdueAmount: undefined,
          prevPendingAmount: undefined,

          transactionDate: undefined,
          settlementDate: moment(ytdStartDate).add(1, 'day').set('hour', 6).set('minute', 0).set('second', 0).valueOf(),

          trackDate: ytdStartDate,

          outletCycleKoodooPayoutsOnlineOnlyExpected: 0,
        };
      }

      if (
        // moment(todayEndDate).isSame(moment().endOf('day'), 'day') &&
        moment(todayEndDate).isSame(moment(window.currToDateTime), 'day') &&
        moment(todayEndDate).isBetween(ptStartDate, ptEndDate, null, '[]')) {
        recentPayoutTransactionDictTemp[todayEndDate] = {
          outletCycleKoodooPayoutsExpected: 0,
          outletCycleRazerPayouts: 0,
          outletCycleMerchantPayoutsExpected: 0,
          outletCycleKoodooPayoutsActual: undefined,
          outletCycleMerchantPayoutsActual: undefined,
          outletCycleKoodooMDREarned: undefined,

          outletCycleMerchantOverdueAmountsMdr: 0,
          outletCycleMerchantOverdueAmountsGmv: 0,

          // mdrEarned: 0,

          outletId: '',
          outletCycleFunds: undefined,
          outletCycleMerchantOverdueAmounts: undefined,
          outletCycleMerchantPendingAmounts: undefined,

          stockUpAmount: undefined,

          outletCycleMerchantPendingRefundOrdersAmount: undefined,
          outletCycleMerchantRefundOrdersAmount: undefined,

          payoutFee: undefined,

          prevStockUpAmount: undefined,
          prevOverdueAmount: undefined,
          prevPendingAmount: undefined,

          transactionDate: undefined,
          settlementDate: moment(todayEndDate).add(1, 'day').set('hour', 6).set('minute', 0).set('second', 0).valueOf(),

          trackDate: todayEndDate,

          outletCycleKoodooPayoutsOnlineOnlyExpected: 0,
        };
      }

      var colorRandom = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      if (window.pOutletPaletteColorDict[outlet.uniqueId]) {
        colorRandom = window.pOutletPaletteColorDict[outlet.uniqueId];
      }
      else {
        window.pOutletPaletteColorDict[outlet.uniqueId] = colorRandom;
      }

      return {
        paletteColor: colorRandom,

        merchantName: outlet.name,
        outletName: outlet.name,


        createdAt: outlet.createdAt,
        onboarderEmail: outlet.onboarderEmail || '-',
        // lastActivity: merchant.updatedAt > merchant.updatedAt ? merchant.updatedAt : outlet.updatedAt,
        lastActivity: lastActivity ? lastActivity : outlet.createdAt,

        merchantId: outlet.merchantId,
        outletId: outlet.uniqueId,

        //////////////////////////////////////////////

        // new info

        userAppAndWebDineInOrdersAmount: 0,
        userAppAndWebDineInOrdersQty: 0,

        userAppAndWebDineInOnlineOrdersAmount: 0,
        userAppAndWebDineInOnlineOrdersQty: 0,

        merchantAppOrdersAmount: 0,
        merchantAppOrdersQty: 0,

        merchantAppOnlineOrdersAmount: 0,
        merchantAppOnlineOrdersQty: 0,

        merchantAppWoCashAmount: 0,
        merchantAppWoCashOrdersQty: 0,

        waiterAppOrdersAmount: 0,
        waiterAppOrdersQty: 0,

        userAppAndWebTakeawayOrdersAmount: 0,
        userAppAndWebTakeawayOrdersQty: 0,

        userAppAndWebDeliveryOrdersAmount: 0,
        userAppAndWebDeliveryOrdersQty: 0,

        totalOrdersAmount: 0,
        totalOrdersQty: 0,

        gmvCommission: BigNumber(0),
        gmvPayout: BigNumber(0),
        gmvFees: BigNumber(0),
        mdrEarned: BigNumber(0),

        gmvCommissionActual: BigNumber(0),
        gmvPayoutActual: BigNumber(0),

        razerPayoutTransactionList: [],

        lastOrderPlacedDateTime: null,

        recentPayoutTransactionDict: recentPayoutTransactionDictTemp,
        recentPayoutTransactionList: [],

        //////////////////////////////////////////////

        // details info

        detailsList: [],

        //////////////////////////////////////////////
      };
    });

    var outletDataDict = Object.assign({}, ...tempDataBefore.map(data => ({
      [data.outletId]: data,
    })));

    //////////////////////////////////////////////////////////////////////////

    // calculate orders

    // var userAppAndWebDineInOrdersAmount = 0;
    // var userAppAndWebDineInOrdersQty = 0;

    // var merchantAppOrdersAmount = 0;
    // var merchantAppOrdersQty = 0;

    // var waiterAppOrdersAmount = 0;
    // var waiterAppOrdersQty = 0;

    // var userAppAndWebTakeawayOrdersAmount = 0;
    // var userAppAndWebTakeawayOrdersQty = 0;

    // var userAppAndWebDeliveryOrdersAmount = 0;
    // var userAppAndWebDeliveryOrdersQty = 0;

    // var totalOrdersAmount = 0;
    // var totalOrdersQty = 0;

    // var lastOrderPlacedDateTime = null;

    // var payoutTransactionsFiltered = payoutTransactions.filter(order => order.outletId === outlet.uniqueId);

    const payoutTransactionsFiltered = payoutTransactions.concat(payoutTransactionsExtend).filter(transaction => {
      if (clickedBarChartDate !== null && clickedBarChartDateUnit !== null) {
        if (moment(clickedBarChartDate).isSame(
          moment(transaction.transactionDate),
          clickedBarChartDateUnit,
        )) {
          return true;
        }
        else {
          return false
        }
      }
      else {
        return true;
      }

      // if (clickedChartDate !== null && moment(clickedChartDate).isSame(
      //   moment(transaction.transactionDate),
      //   'day',
      // )) {
      //   return true;
      // }
      // else {
      //   return true;
      // }
    });

    console.log('loop');
    console.log(payoutTransactionsFiltered.length);

    for (let i = 0; i < payoutTransactionsFiltered.length; i++) {
      if (outletDataDict[payoutTransactionsFiltered[i].outletId]) {
        // var gmvRate = 0;
        // if (payoutTransactionsFiltered[i].settlementDetails) {
        //   if (payoutTransactionsFiltered[i].settlementDetails && payoutTransactionsFiltered[i].settlementDetails.processingRateTotalSalesActive) {
        //     gmvRate = payoutTransactionsFiltered[i].settlementDetails.processingRateTotalSales;
        //   }
        //   else if (payoutTransactionsFiltered[i].settlementDetails && payoutTransactionsFiltered[i].settlementDetails.processingRateRazerActive) {
        //     gmvRate = payoutTransactionsFiltered[i].settlementDetails.processingRateRazer;
        //   }
        // }

        for (let j = 0; j < payoutTransactionsFiltered[i].userOrdersFigures.length; j++) {
          if (payoutTransactionsFiltered[i].userOrdersFigures[j].combinedOrderList && payoutTransactionsFiltered[i].userOrdersFigures[j].combinedOrderList.length > 0) {
            // means this order already merged with other orders         
          }
          else {
            let userOrderFigure = payoutTransactionsFiltered[i].userOrdersFigures[j];

            //////////////////////////////////////////////////

            let toProceedPaymentType = false;
            let channel = (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel;

            if (payoutTransactionsFiltered[i].outletId === 'ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea') {
              console.log('break');
            }

            if (selectedPaymentType === PAYMENT_TYPE.ALL) {
              toProceedPaymentType = true;
            }
            else if (
              selectedPaymentType === PAYMENT_TYPE.E_WALLET &&
              (
                channel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
                channel === PAYMENT_CHANNEL_NAME.ShopeePay ||
                channel === PAYMENT_CHANNEL_NAME.GrabPay ||
                channel === PAYMENT_CHANNEL_NAME.BOOST ||
                channel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
              )
            ) {
              toProceedPaymentType = true;
            }
            else if (
              selectedPaymentType === PAYMENT_TYPE.FPX &&
              (
                channel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
                channel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
                channel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
                channel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
                channel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
                channel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
                channel === PAYMENT_CHANNEL_NAME['ALB-Paymex'] ||
                channel === PAYMENT_CHANNEL_NAME['MB2u'] ||
                channel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
                channel === PAYMENT_CHANNEL_NAME['Cash-epay'] ||
                channel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
                channel === PAYMENT_CHANNEL_NAME['FPX_HLB']
              )
            ) {
              toProceedPaymentType = true;
            }
            else if (
              selectedPaymentType === PAYMENT_TYPE.CREDIT_DEBIT &&
              (
                channel === PAYMENT_CHANNEL_NAME['credit'] ||
                channel === PAYMENT_CHANNEL_NAME['Credit'] ||
                channel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
                channel === PAYMENT_CHANNEL_NAME['CIL_Visa']
              )
            ) {
              toProceedPaymentType = true;
            }
            else if (
              selectedPaymentType === PAYMENT_TYPE.OFFLINE &&
              (
                channel.startsWith('Offline')
              )
            ) {
              toProceedPaymentType = true;
            }
            else if (
              selectedPaymentType === PAYMENT_TYPE.ONLINE &&
              (
                !channel.startsWith('Offline')
              )
            ) {
              toProceedPaymentType = true;
            }
            else if (
              selectedPaymentType === PAYMENT_TYPE.OFFLINE_WO_CASH &&
              (
                channel.startsWith('Offline') &&
                !channel.startsWith('Offline-Cash')
              )
            ) {
              toProceedPaymentType = true;
            }
            else if (
              selectedPaymentType === PAYMENT_TYPE.ALL_WO_CASH &&
              (
                !channel.startsWith('Offline-Cash')
              )
            ) {
              toProceedPaymentType = true;
            }
            else {
              toProceedPaymentType = false;
            }

            if (!toProceedPaymentType) {
              continue;
            }

            //////////////////////////////////////////////////

            // push to details list

            let toAddObj = {
              dateTime: moment(userOrderFigure.createdAt || payoutTransactionsFiltered[i].createdAt).format('YYYY/MM/DD HH:mm'),
              orderId: (userOrderFigure.orderIdHuman && userOrderFigure.orderType) ? `#${userOrderFigure.orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${userOrderFigure.orderIdHuman}` : 'N/A',
              uniqueId: userOrderFigure.orderId,

              // uniqueId: payoutTransactionsFiltered[i].uniqueId,
              isQrOrder: (userOrderFigure.appType !== undefined ? (userOrderFigure.appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No') : 'N/A'),
              netAmount: BigNumber(0).dp(2).toNumber(),
              tax: BigNumber(0).dp(2).toNumber(),
              sc: BigNumber(0).dp(2).toNumber(),
              totalAmount: BigNumber(userOrderFigure.userOrderPriceBeforeCommission).dp(2).toNumber(),
              razerCharges: BigNumber(userOrderFigure.commissionFeeFinal).dp(2).toNumber(),
              koodooCharges: BigNumber(userOrderFigure.koodooProcessingFee).dp(2).toNumber(),
              outletPayout: BigNumber(userOrderFigure.commissionFeeFinal > 0 ? userOrderFigure.userOrderPriceAfterCommissionAndFee : 0).dp(2).toNumber(),
              settlementDate: moment(payoutTransactionsFiltered[i].createdAt).format('YYYY/MM/DD'),

              ...userOrderFigure.kpfOd && {
                kpfOd: BigNumber(userOrderFigure.kpfOd).dp(2).toNumber(),
              },
              ...userOrderFigure.cfOd && {
                cfOd: BigNumber(userOrderFigure.cfOd).dp(2).toNumber(),
              },

              cfe: BigNumber(userOrderFigure.cfe ? userOrderFigure.cfe : 0).dp(2).toNumber(),

              isSettled: true,

              // gmvRate: gmvRate,

              orderTypeDetails: (userOrderFigure.appType === APP_TYPE.WEB_ORDER ?
                ((userOrderFigure.commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
                :
                ORDER_TYPE_DETAILS.POS),

              outletId: payoutTransactionsFiltered[i].outletId,
              // on: payoutTransactionsFiltered[i].outletName,

              koodooProcessingRate: payoutTransactionsFiltered[i].koodooProcessingRate || 0,

              pdChannel: (userOrderFigure.pdChannel) ? userOrderFigure.pdChannel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

              cartItemsLength: userOrderFigure.cartItems.length,
            };

            // let foundIndex = outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.findIndex(order => order.uniqueId === toAddObj.uniqueId);

            // if (foundIndex >= 0) {
            //   outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList[foundIndex] = toAddObj;
            // }
            // else {
            //   outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.push(toAddObj);
            // }           

            // if (!outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.find(order => order.uniqueId === toAddObj.uniqueId)) {
            //   outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.push(toAddObj);
            // }            

            outletDataDict[payoutTransactionsFiltered[i].outletId].detailsList.push(toAddObj);

            //////////////////////////////////////////////////////////////////////

            // 2025-01-09 - count pos w/o cash orders

            if (userOrderFigure.pdChannel !== OFFLINE_PAYMENT_METHOD_TYPE.CASH &&
              userOrderFigure.pdChannel &&
              (
                userOrderFigure.pdChannel.startsWith('Offline')
                ||
                RAZER_CHANNEL_COMMISIONS_MYR_RATE[userOrderFigure.pdChannel] !== undefined
              )
              &&
              !userOrderFigure.pdChannel.startsWith('Offline-Cash')
            ) {
              outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppWoCashAmount += userOrderFigure.userOrderPriceBeforeCommission;
              outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppWoCashOrdersQty += 1;
            }

            //////////////////////////////////////////////////////////////////////

            if (userOrderFigure.pdChannel === ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX) {
              outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOnlineOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
              // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
              outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOnlineOrdersQty += 1;

              outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

              // checkOutletFilterDict({
              //   uniqueId: payoutTransactionsFiltered[i].outletId,
              // }, userOrderFigure.createdAt, {
              //   startDate24H,
              //   endDate24H,
              //   startDate7D,
              //   endDate7D,
              //   startDate1M,
              //   endDate1M,
              //   startDate3M,
              //   endDate3M,
              // });

              continue;
            }

            if ((userOrderFigure.appType === APP_TYPE.MERCHANT ||
              userOrderFigure.appType === undefined ||
              userOrderFigure.appType === APP_TYPE.UNKNOWN) &&
              !userOrderFigure.isOnlineOrdering) {
              outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
              // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
              outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersQty += 1;

              outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

              // checkOutletFilterDict({
              //   uniqueId: payoutTransactionsFiltered[i].outletId,
              // }, userOrderFigure.createdAt, {
              //   startDate24H,
              //   endDate24H,
              //   startDate7D,
              //   endDate7D,
              //   startDate1M,
              //   endDate1M,
              //   startDate3M,
              //   endDate3M,
              // });

              continue;
            }

            if (userOrderFigure.appType === APP_TYPE.USER ||
              userOrderFigure.appType === APP_TYPE.WEB_ORDER) {
              if (userOrderFigure.orderType === ORDER_TYPE.DINEIN) {
                if (userOrderFigure.commissionFeeFinal > 0) {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
                }
                else {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;
                }

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                // checkOutletFilterDict({
                //   uniqueId: payoutTransactionsFiltered[i].outletId,
                // }, userOrderFigure.createdAt, {
                //   startDate24H,
                //   endDate24H,
                //   startDate7D,
                //   endDate7D,
                //   startDate1M,
                //   endDate1M,
                //   startDate3M,
                //   endDate3M,
                // });

                continue;
              }
              else if (userOrderFigure.orderType === ORDER_TYPE.PICKUP) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                // checkOutletFilterDict({
                //   uniqueId: payoutTransactionsFiltered[i].outletId,
                // }, userOrderFigure.createdAt, {
                //   startDate24H,
                //   endDate24H,
                //   startDate7D,
                //   endDate7D,
                //   startDate1M,
                //   endDate1M,
                //   startDate3M,
                //   endDate3M,
                // });

                continue;
              }
              else if (userOrderFigure.orderType === ORDER_TYPE.DELIVERY) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                // checkOutletFilterDict({
                //   uniqueId: payoutTransactionsFiltered[i].outletId,
                // }, userOrderFigure.createdAt, {
                //   startDate24H,
                //   endDate24H,
                //   startDate7D,
                //   endDate7D,
                //   startDate1M,
                //   endDate1M,
                //   startDate3M,
                //   endDate3M,
                // });

                continue;
              }
            }

            ////////////////////////////////////////////////////////////

            // to compatible with previous web orders

            if (
              // payoutTransactionsFiltered[i].appType === undefined &&
              userOrderFigure.isOnlineOrdering) {
              if (payoutTransactionsFiltered[i].orderType === ORDER_TYPE.DINEIN) {
                if (userOrderFigure.commissionFeeFinal > 0) {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
                }
                else {
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                  outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;
                }

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                // checkOutletFilterDict({
                //   uniqueId: payoutTransactionsFiltered[i].outletId,
                // }, userOrderFigure.createdAt, {
                //   startDate24H,
                //   endDate24H,
                //   startDate7D,
                //   endDate7D,
                //   startDate1M,
                //   endDate1M,
                //   startDate3M,
                //   endDate3M,
                // });

                continue;
              }
              else if (userOrderFigure.orderType === ORDER_TYPE.PICKUP) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                // checkOutletFilterDict({
                //   uniqueId: payoutTransactionsFiltered[i].outletId,
                // }, userOrderFigure.createdAt, {
                //   startDate24H,
                //   endDate24H,
                //   startDate7D,
                //   endDate7D,
                //   startDate1M,
                //   endDate1M,
                //   startDate3M,
                //   endDate3M,
                // });

                continue;
              }
              else if (userOrderFigure.orderType === ORDER_TYPE.DELIVERY) {
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
                outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                  outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

                // checkOutletFilterDict({
                //   uniqueId: payoutTransactionsFiltered[i].outletId,
                // }, userOrderFigure.createdAt, {
                //   startDate24H,
                //   endDate24H,
                //   startDate7D,
                //   endDate7D,
                //   startDate1M,
                //   endDate1M,
                //   startDate3M,
                //   endDate3M,
                // });

                continue;
              }
            }

            ////////////////////////////////////////////////////////////

            if (userOrderFigure.appType === APP_TYPE.WAITER) {
              outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersAmount += userOrderFigure.userOrderPriceBeforeCommission;
              outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersQty += 1;

              outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime = userOrderFigure.createdAt >
                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime ? userOrderFigure.createdAt :
                outletDataDict[payoutTransactionsFiltered[i].outletId].lastOrderPlacedDateTime;

              // checkOutletFilterDict({
              //   uniqueId: payoutTransactionsFiltered[i].outletId,
              // }, userOrderFigure.createdAt, {
              //   startDate24H,
              //   endDate24H,
              //   startDate7D,
              //   endDate7D,
              //   startDate1M,
              //   endDate1M,
              //   startDate3M,
              //   endDate3M,
              // });

              continue;
            }
          }
        }

        if (payoutTransactionsFiltered[i].linkedRazerPayoutTransactionId) {
          // means is extended razer payout transaction, no need do anything
        }
        else {
          if (payoutTransactionsFiltered[i].outletCycleKoodooMDREarned > 0) {
            console.log(payoutTransactionsFiltered[i]);
            console.log('stop');
          }

          outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommission = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommission).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleKoodooPayoutsExpected)).toNumber();
          outletDataDict[payoutTransactionsFiltered[i].outletId].gmvFees = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvFees).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleRazerPayouts)).toNumber();
          outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayout = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayout).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleMerchantPayoutsExpected)).toNumber();
          outletDataDict[payoutTransactionsFiltered[i].outletId].mdrEarned = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].mdrEarned).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleKoodooMDREarned ? payoutTransactionsFiltered[i].outletCycleKoodooMDREarned : 0)).toNumber();

          outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommissionActual = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommissionActual).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleKoodooPayoutsActual)).toNumber();
          outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayoutActual = BigNumber(outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayoutActual).plus(BigNumber(payoutTransactionsFiltered[i].outletCycleMerchantPayoutsActual)).toNumber();

          outletDataDict[payoutTransactionsFiltered[i].outletId].razerPayoutTransactionList.push({
            active: payoutTransactionsFiltered[i].userOrdersFigures.length > 0 ? true : false,
            bankCode: payoutTransactionsFiltered[i].bankCode,
            bankAccountNumber: payoutTransactionsFiltered[i].bankAccountNumber,
            mid: payoutTransactionsFiltered[i].mid,
            tid: payoutTransactionsFiltered[i].tid,

            outletCycleKoodooPayoutsExpected: payoutTransactionsFiltered[i].outletCycleKoodooPayoutsExpected,
            outletCycleRazerPayouts: payoutTransactionsFiltered[i].outletCycleRazerPayouts,
            outletCycleMerchantPayoutsExpected: payoutTransactionsFiltered[i].outletCycleMerchantPayoutsExpected,
            outletCycleKoodooPayoutsActual: payoutTransactionsFiltered[i].outletCycleKoodooPayoutsActual,
            outletCycleMerchantPayoutsActual: payoutTransactionsFiltered[i].outletCycleMerchantPayoutsActual,
            outletCycleKoodooMDREarned: payoutTransactionsFiltered[i].outletCycleKoodooMDREarned ? payoutTransactionsFiltered[i].outletCycleKoodooMDREarned : 0,

            outletCycleMerchantOverdueAmountsMdr: payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsRhb ? payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsMdr : 0,
            outletCycleMerchantOverdueAmountsGmv: payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsRhb ? payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmountsGmv : 0,

            outletId: payoutTransactionsFiltered[i].outletId,
            outletCycleFunds: payoutTransactionsFiltered[i].outletCycleFunds,
            outletCycleMerchantOverdueAmounts: payoutTransactionsFiltered[i].outletCycleMerchantOverdueAmounts,
            outletCycleMerchantPendingAmounts: payoutTransactionsFiltered[i].outletCycleMerchantPendingAmounts,

            stockUpAmount: payoutTransactionsFiltered[i].stockUpAmount,

            outletCycleMerchantPendingRefundOrdersAmount: payoutTransactionsFiltered[i].outletCycleMerchantPendingRefundOrdersAmount ? payoutTransactionsFiltered[i].outletCycleMerchantPendingRefundOrdersAmount : 0,
            outletCycleMerchantRefundOrdersAmount: payoutTransactionsFiltered[i].outletCycleMerchantRefundOrdersAmount ? payoutTransactionsFiltered[i].outletCycleMerchantRefundOrdersAmount : 0,

            payoutFee: payoutTransactionsFiltered[i].payoutFee,

            prevStockUpAmount: payoutTransactionsFiltered[i].prevStockUpAmount ? payoutTransactionsFiltered[i].prevStockUpAmount : 0,
            prevOverdueAmount: payoutTransactionsFiltered[i].prevOverdueAmount ? payoutTransactionsFiltered[i].prevOverdueAmount : 0,
            prevPendingAmount: payoutTransactionsFiltered[i].prevPendingAmount ? payoutTransactionsFiltered[i].prevPendingAmount : 0,

            transactionDate: payoutTransactionsFiltered[i].transactionDate,
            settlementDate: payoutTransactionsFiltered[i].createdAt,

            uniqueId: payoutTransactionsFiltered[i].uniqueId,

            outletCycleKoodooPayoutsOnlineOnlyExpected: payoutTransactionsFiltered[i].userOrdersFigures.reduce((accum, order) => {
              return BigNumber(accum).plus(
                (order.commissionFeeFinal > 0) ? order.koodooProcessingFee : 0);
            }, BigNumber(0)).toNumber(),

            ...(payoutTransactionsFiltered[i].rsc !== undefined) && {
              rsc: payoutTransactionsFiltered[i].rsc,
            },
          });
        }

        //////////////////////////////////////////////////////////////////////

        // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersAmount = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersAmount +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount;
        // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersQty = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersQty +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty;
      }
    }

    ////////////////////////////////////////////////////////////

    // for gmvOrdersRecent

    const gmvOrdersRecentFiltered = gmvOrdersRecent.filter(order => {
      if (clickedBarChartDate !== null && clickedBarChartDateUnit !== null) {
        if (moment(clickedBarChartDate).isSame(
          moment(order.createdAt),
          clickedBarChartDateUnit,
        )) {
          return true;
        }
        else {
          return false
        }
      }
      else {
        return true;
      }

      // if (clickedChartDate !== null && moment(clickedChartDate).isSame(
      //   moment(order.createdAt),
      //   'day',
      // )) {
      //   return true;
      // }
      // else {
      //   return true;
      // }
    });;

    console.log('loop');
    console.log(gmvOrdersRecentFiltered.length);

    for (let i = 0; i < gmvOrdersRecentFiltered.length; i++) {
      if (
        outletDataDict[gmvOrdersRecentFiltered[i].outletId] &&
        !outletDataDict[gmvOrdersRecentFiltered[i].outletId].detailsList.find(order => order.uniqueId === gmvOrdersRecentFiltered[i].uniqueId) &&
        moment(gmvOrdersRecentFiltered[i].createdAt).isBetween(ptStartDate, ptEndDate, null, '[]')
      ) {
        //////////////////////////////////////////////////

        let toProceedPaymentType = false;
        let channel = (gmvOrdersRecentFiltered[i].paymentDetails && gmvOrdersRecentFiltered[i].paymentDetails.channel) ? gmvOrdersRecentFiltered[i].paymentDetails.channel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel;

        if (gmvOrdersRecentFiltered[i].outletId === 'ca6183b3-59c7-4ae1-9fd2-b9866c30e4ea') {
          console.log('break');
        }

        if (selectedPaymentType === PAYMENT_TYPE.ALL) {
          toProceedPaymentType = true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.E_WALLET &&
          (
            channel === PAYMENT_CHANNEL_NAME['TNG-EWALLET'] ||
            channel === PAYMENT_CHANNEL_NAME.ShopeePay ||
            channel === PAYMENT_CHANNEL_NAME.GrabPay ||
            channel === PAYMENT_CHANNEL_NAME.BOOST ||
            channel === PAYMENT_CHANNEL_NAME['MB2U_QRPay-Push']
          )
        ) {
          toProceedPaymentType = true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.FPX &&
          (
            channel === PAYMENT_CHANNEL_NAME['CIMB-Clicks'] ||
            channel === PAYMENT_CHANNEL_NAME['HLB-ONL'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX-TPA'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_BIMB'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_PBB'] ||
            channel === PAYMENT_CHANNEL_NAME['RHB-ONL'] ||
            channel === PAYMENT_CHANNEL_NAME['ALB-Paymex'] ||
            channel === PAYMENT_CHANNEL_NAME['MB2u'] ||
            channel === PAYMENT_CHANNEL_NAME['Affin-EPG'] ||
            channel === PAYMENT_CHANNEL_NAME['Cash-epay'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_MB2U'] ||
            channel === PAYMENT_CHANNEL_NAME['FPX_HLB']
          )
        ) {
          toProceedPaymentType = true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.CREDIT_DEBIT &&
          (
            channel === PAYMENT_CHANNEL_NAME['credit'] ||
            channel === PAYMENT_CHANNEL_NAME['Credit'] ||
            channel === PAYMENT_CHANNEL_NAME['CIL_Mastercard'] ||
            channel === PAYMENT_CHANNEL_NAME['CIL_Visa']
          )
        ) {
          toProceedPaymentType = true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.OFFLINE &&
          (
            channel.startsWith('Offline')
          )
        ) {
          toProceedPaymentType = true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.ONLINE &&
          (
            !channel.startsWith('Offline')
          )
        ) {
          toProceedPaymentType = true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.OFFLINE_WO_CASH &&
          (
            channel.startsWith('Offline') &&
            !channel.startsWith('Offline-Cash')
          )
        ) {
          toProceedPaymentType = true;
        }
        else if (
          selectedPaymentType === PAYMENT_TYPE.ALL_WO_CASH &&
          (
            !channel.startsWith('Offline-Cash')
          )
        ) {
          toProceedPaymentType = true;
        }
        else {
          toProceedPaymentType = false;
        }

        if (!toProceedPaymentType) {
          continue;
        }

        //////////////////////////////////////////////////

        outletDataDict[gmvOrdersRecentFiltered[i].outletId].detailsList.push({
          dateTime: moment(gmvOrdersRecentFiltered[i].createdAt).format('YYYY/MM/DD HH:mm'),
          orderId: `#${gmvOrdersRecentFiltered[i].orderType === ORDER_TYPE.DINEIN ? '' : 'T'}${gmvOrdersRecentFiltered[i].orderId}`,
          uniqueId: gmvOrdersRecentFiltered[i].uniqueId,
          isQrOrder: gmvOrdersRecentFiltered[i].appType === APP_TYPE.WEB_ORDER ? 'Yes' : 'No',
          netAmount: BigNumber(gmvOrdersRecentFiltered[i].finalPrice).minus(gmvOrdersRecentFiltered[i].tax).minus(gmvOrdersRecentFiltered[i].sc).dp(2).toNumber(),
          tax: BigNumber(gmvOrdersRecentFiltered[i].tax).dp(2).toNumber(),
          sc: BigNumber(gmvOrdersRecentFiltered[i].sc).dp(2).toNumber(),
          totalAmount: BigNumber(gmvOrdersRecentFiltered[i].finalPrice).dp(2).toNumber(),
          razerCharges: BigNumber((gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal : 0).dp(2).toNumber(),
          koodooCharges: BigNumber((gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee : 0).dp(2).toNumber(),
          outletPayout: BigNumber(
            (gmvOrdersRecentFiltered[i].paymentDetails &&
              (gmvOrdersRecentFiltered[i].paymentDetails.txn_ID !== undefined ||
                gmvOrdersRecentFiltered[i].paymentDetails.txnId !== undefined)
              &&
              (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
            )
              ?
              gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee
              :
              0)
            .dp(2).toNumber(),
          settlementDate: gmvOrdersRecentFiltered[i].settlementDate ? moment(gmvOrdersRecentFiltered[i].settlementDate).format('YYYY/MM/DD') : 'N/A',

          ...gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.kpfOd && {
            kpfOd: BigNumber(gmvOrdersRecentFiltered[i].settlementDetails.kpfOd).dp(2).toNumber(),
          },
          ...gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.cfOd && {
            cfOd: BigNumber(gmvOrdersRecentFiltered[i].settlementDetails.cfOd).dp(2).toNumber(),
          },

          cfe: BigNumber((gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.cfe) ? gmvOrdersRecentFiltered[i].settlementDetails.cfe : 0).dp(2).toNumber(),

          isSettled: gmvOrdersRecentFiltered[i].settlementDate ? true : false,

          orderTypeDetails: (gmvOrdersRecentFiltered[i].appType === APP_TYPE.WEB_ORDER ?
            ((gmvOrdersRecentFiltered[i].commissionFeeFinal > 0) ? ORDER_TYPE_DETAILS.QR_ONLINE : ORDER_TYPE_DETAILS.QR_OFFLINE)
            :
            ORDER_TYPE_DETAILS.POS),

          outletId: gmvOrdersRecentFiltered[i].outletId,
          // on: gmvOrdersRecentFiltered[i].outletName,

          koodooProcessingRate: gmvOrdersRecentFiltered[i].koodooProcessingRate || 0,

          pdChannel: (gmvOrdersRecentFiltered[i].paymentDetails && gmvOrdersRecentFiltered[i].paymentDetails.channel) ? gmvOrdersRecentFiltered[i].paymentDetails.channel : OFFLINE_PAYMENT_METHOD_TYPE.CASH.channel,

          cartItemsLength: gmvOrdersRecentFiltered[i].cartItems.length,
        });

        outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvCommission = BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvCommission).plus(BigNumber((gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee : 0)).toNumber();
        outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvFees = BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvFees).plus(BigNumber((gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal : 0)).toNumber();
        outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvPayout = BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvPayout).plus(BigNumber(
          (gmvOrdersRecentFiltered[i].paymentDetails &&
            (gmvOrdersRecentFiltered[i].paymentDetails.txn_ID !== undefined ||
              gmvOrdersRecentFiltered[i].paymentDetails.txnId !== undefined)
            &&
            (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
          )
            ?
            gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee
            :
            0)).toNumber();

        // outletDataDict[payoutTransactionsFiltered[i].outletId].gmvCommissionActual = BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvCommissionActual).plus(BigNumber(userOrderFigure.outletCycleKoodooPayoutsActual)).toNumber();
        // outletDataDict[payoutTransactionsFiltered[i].outletId].gmvPayoutActual = BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].gmvPayoutActual).plus(BigNumber(userOrderFigure.outletCycleMerchantPayoutsActual)).toNumber();

        //////////////////////////////////////////////////////////////////////

        // 2022-12-11 - To show under expanded summary

        var orderStartDate = moment(gmvOrdersRecentFiltered[i].createdAt).startOf('day').valueOf();
        var orderEndDate = moment(gmvOrdersRecentFiltered[i].createdAt).endOf('day').valueOf();

        if (moment(orderEndDate).isSame(window.currToDateTime, 'day')) {
          orderEndDate = window.currToDateTime;
        }

        if (outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate]) {
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsExpected =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsExpected).plus(
              (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee : 0).toNumber();

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleRazerPayouts =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleRazerPayouts).plus(
              (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal : 0).toNumber();

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleMerchantPayoutsExpected =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleMerchantPayoutsExpected).plus(
              (gmvOrdersRecentFiltered[i].paymentDetails &&
                (gmvOrdersRecentFiltered[i].paymentDetails.txn_ID !== undefined ||
                  gmvOrdersRecentFiltered[i].paymentDetails.txnId !== undefined)
                &&
                (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
              )
                ?
                gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee
                :
                0).toNumber();

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsOnlineOnlyExpected =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderStartDate].outletCycleKoodooPayoutsOnlineOnlyExpected).plus(
              (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal > 0) ? gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee : 0).toNumber();
        }

        if (outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate]) {
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsExpected =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsExpected).plus(
              (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee) ? gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee : 0).toNumber();

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleRazerPayouts =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleRazerPayouts).plus(
              (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal) ? gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal : 0).toNumber();

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleMerchantPayoutsExpected =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleMerchantPayoutsExpected).plus(
              (gmvOrdersRecentFiltered[i].paymentDetails &&
                (gmvOrdersRecentFiltered[i].paymentDetails.txn_ID !== undefined ||
                  gmvOrdersRecentFiltered[i].paymentDetails.txnId !== undefined)
                &&
                (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee)
              )
                ?
                gmvOrdersRecentFiltered[i].settlementDetails.userOrderPriceAfterCommissionAndFee
                :
                0).toNumber();

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsOnlineOnlyExpected =
            BigNumber(outletDataDict[gmvOrdersRecentFiltered[i].outletId].recentPayoutTransactionDict[orderEndDate].outletCycleKoodooPayoutsOnlineOnlyExpected).plus(
              (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal > 0) ? gmvOrdersRecentFiltered[i].settlementDetails.koodooProcessingFee : 0).toNumber();
        }

        //////////////////////////////////////////////////////////////////////

        // 2025-01-09 - count pos w/o cash orders

        if (gmvOrdersRecentFiltered[i].paymentDetails && gmvOrdersRecentFiltered[i].paymentDetails.channel !== OFFLINE_PAYMENT_METHOD_TYPE.CASH &&
          gmvOrdersRecentFiltered[i].paymentDetails.pdChannel &&
          (
            gmvOrdersRecentFiltered[i].paymentDetails.pdChannel.startsWith('Offline')
            ||
            RAZER_CHANNEL_COMMISIONS_MYR_RATE[gmvOrdersRecentFiltered[i].paymentDetails.pdChannel] !== undefined
          )
          &&
          !gmvOrdersRecentFiltered[i].paymentDetails.channel.startsWith('Offline-Cash')
        ) {
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].merchantAppWoCashAmount += gmvOrdersRecentFiltered[i].finalPrice;
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].merchantAppWoCashOrdersQty += 1;
        }

        //////////////////////////////////////////////////////////////////////

        if (gmvOrdersRecentFiltered[i].paymentDetails &&
          gmvOrdersRecentFiltered[i].paymentDetails.channel === ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX) {
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].merchantAppOnlineOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
          // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].merchantAppOnlineOrdersQty += 1;

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

          // checkOutletFilterDict({
          //   uniqueId: gmvOrdersRecent[i].outletId,
          // }, gmvOrdersRecent[i].createdAt, {
          //   startDate24H,
          //   endDate24H,
          //   startDate7D,
          //   endDate7D,
          //   startDate1M,
          //   endDate1M,
          //   startDate3M,
          //   endDate3M,
          // });

          continue;
        }

        if ((gmvOrdersRecentFiltered[i].appType === APP_TYPE.MERCHANT ||
          gmvOrdersRecentFiltered[i].appType === undefined ||
          gmvOrdersRecentFiltered[i].appType === APP_TYPE.UNKNOWN) &&
          !gmvOrdersRecentFiltered[i].isOnlineOrdering) {
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].merchantAppOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
          // console.log(outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount);
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].merchantAppOrdersQty += 1;

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

          // checkOutletFilterDict({
          //   uniqueId: gmvOrdersRecent[i].outletId,
          // }, gmvOrdersRecent[i].createdAt, {
          //   startDate24H,
          //   endDate24H,
          //   startDate7D,
          //   endDate7D,
          //   startDate1M,
          //   endDate1M,
          //   startDate3M,
          //   endDate3M,
          // });

          continue;
        }

        if (gmvOrdersRecentFiltered[i].appType === APP_TYPE.USER ||
          gmvOrdersRecentFiltered[i].appType === APP_TYPE.WEB_ORDER) {
          if (gmvOrdersRecentFiltered[i].orderType === ORDER_TYPE.DINEIN) {
            if (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal > 0) {
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOnlineOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
            }
            else {
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;
            }

            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

            // checkOutletFilterDict({
            //   uniqueId: gmvOrdersRecent[i].outletId,
            // }, gmvOrdersRecent[i].createdAt, {
            //   startDate24H,
            //   endDate24H,
            //   startDate7D,
            //   endDate7D,
            //   startDate1M,
            //   endDate1M,
            //   startDate3M,
            //   endDate3M,
            // });

            continue;
          }
          else if (gmvOrdersRecentFiltered[i].orderType === ORDER_TYPE.PICKUP) {
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

            // checkOutletFilterDict({
            //   uniqueId: gmvOrdersRecent[i].outletId,
            // }, gmvOrdersRecent[i].createdAt, {
            //   startDate24H,
            //   endDate24H,
            //   startDate7D,
            //   endDate7D,
            //   startDate1M,
            //   endDate1M,
            //   startDate3M,
            //   endDate3M,
            // });

            continue;
          }
          else if (gmvOrdersRecentFiltered[i].orderType === ORDER_TYPE.DELIVERY) {
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

            // checkOutletFilterDict({
            //   uniqueId: gmvOrdersRecent[i].outletId,
            // }, gmvOrdersRecent[i].createdAt, {
            //   startDate24H,
            //   endDate24H,
            //   startDate7D,
            //   endDate7D,
            //   startDate1M,
            //   endDate1M,
            //   startDate3M,
            //   endDate3M,
            // });

            continue;
          }
        }

        ////////////////////////////////////////////////////////////

        // to compatible with previous web orders

        if (gmvOrdersRecentFiltered[i].appType === undefined &&
          gmvOrdersRecentFiltered[i].isOnlineOrdering) {
          if (gmvOrdersRecentFiltered[i].orderType === ORDER_TYPE.DINEIN) {
            if (gmvOrdersRecentFiltered[i].settlementDetails && gmvOrdersRecentFiltered[i].settlementDetails.commissionFeeFinal > 0) {
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOnlineOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOnlineOrdersQty += 1;
            }
            else {
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDineInOrdersQty += 1;
            }

            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

            // checkOutletFilterDict({
            //   uniqueId: gmvOrdersRecent[i].outletId,
            // }, gmvOrdersRecent[i].createdAt, {
            //   startDate24H,
            //   endDate24H,
            //   startDate7D,
            //   endDate7D,
            //   startDate1M,
            //   endDate1M,
            //   startDate3M,
            //   endDate3M,
            // });

            continue;
          }
          else if (gmvOrdersRecentFiltered[i].orderType === ORDER_TYPE.PICKUP) {
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebTakeawayOrdersQty += 1;

            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

            // checkOutletFilterDict({
            //   uniqueId: gmvOrdersRecent[i].outletId,
            // }, gmvOrdersRecent[i].createdAt, {
            //   startDate24H,
            //   endDate24H,
            //   startDate7D,
            //   endDate7D,
            //   startDate1M,
            //   endDate1M,
            //   startDate3M,
            //   endDate3M,
            // });

            continue;
          }
          else if (gmvOrdersRecentFiltered[i].orderType === gmvOrdersRecentFiltered.DELIVERY) {
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].userAppAndWebDeliveryOrdersQty += 1;

            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
              outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

            // checkOutletFilterDict({
            //   uniqueId: gmvOrdersRecent[i].outletId,
            // }, gmvOrdersRecent[i].createdAt, {
            //   startDate24H,
            //   endDate24H,
            //   startDate7D,
            //   endDate7D,
            //   startDate1M,
            //   endDate1M,
            //   startDate3M,
            //   endDate3M,
            // });

            continue;
          }
        }

        ////////////////////////////////////////////////////////////

        if (gmvOrdersRecentFiltered[i].appType === APP_TYPE.WAITER) {
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].waiterAppOrdersAmount += gmvOrdersRecentFiltered[i].finalPrice;
          outletDataDict[gmvOrdersRecentFiltered[i].outletId].waiterAppOrdersQty += 1;

          outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime = gmvOrdersRecentFiltered[i].createdAt >
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime ? gmvOrdersRecentFiltered[i].createdAt :
            outletDataDict[gmvOrdersRecentFiltered[i].outletId].lastOrderPlacedDateTime;

          // checkOutletFilterDict({
          //   uniqueId: gmvOrdersRecent[i].outletId,
          // }, gmvOrdersRecent[i].createdAt, {
          //   startDate24H,
          //   endDate24H,
          //   startDate7D,
          //   endDate7D,
          //   startDate1M,
          //   endDate1M,
          //   startDate3M,
          //   endDate3M,
          // });

          continue;
        }

        console.log('orders not categorized');
        console.log(gmvOrdersRecentFiltered[i]);

        // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersAmount = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersAmount +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersAmount +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersAmount + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersAmount;
        // outletDataDict[payoutTransactionsFiltered[i].outletId].totalOrdersQty = outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDineInOrdersQty +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].merchantAppOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].waiterAppOrdersQty +
        //   outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebTakeawayOrdersQty + outletDataDict[payoutTransactionsFiltered[i].outletId].userAppAndWebDeliveryOrdersQty;
      }
    }

    console.log('outletDataDict');
    console.log(outletDataDict);

    ////////////////////////////////////////////////////////////

    // update details automatically

    if (outletDataDict[showDetailsOutletId] && outletDataDict[showDetailsOutletId].detailsList !== undefined) {
      // var detailsListTemp = outletDataDict[showDetailsOutletId].detailsList;
      // detailsListTemp.sort(sortByDateTime);
      // setAllOutletsEmployeesDetails(detailsListTemp);
      // setToggleCompare(!toggleCompare);

      setTableDataDetails(outletDataDict[showDetailsOutletId].detailsList);
      // setTableDataDetails(tableDataSummary.reduce((allDetailsList, summary) => allDetailsList.concat(summary.detailsList), []))
    }

    ////////////////////////////////////////////////////////////

    var tempData = Object.entries(outletDataDict).map(([key, value]) => {
      // console.log('total');
      // console.log(value.userAppAndWebDineInOrdersAmount);
      // console.log(value.merchantAppOrdersAmount);
      // console.log(value.waiterAppOrdersAmount);
      // console.log(value.userAppAndWebTakeawayOrdersAmount);
      // console.log(value.userAppAndWebDeliveryOrdersAmount);

      return {
        ...value,
        totalOrdersAmount: value.userAppAndWebDineInOrdersAmount + value.userAppAndWebDineInOnlineOrdersAmount + value.merchantAppOrdersAmount + value.merchantAppOnlineOrdersAmount + value.waiterAppOrdersAmount +
          value.userAppAndWebTakeawayOrdersAmount + value.userAppAndWebDeliveryOrdersAmount,
        totalOrdersQty: value.userAppAndWebDineInOrdersQty + value.userAppAndWebDineInOnlineOrdersQty + value.merchantAppOrdersQty + value.merchantAppOnlineOrdersQty + value.waiterAppOrdersQty +
          value.userAppAndWebTakeawayOrdersQty + value.userAppAndWebDeliveryOrdersQty,

        recentPayoutTransactionList: Object.entries(value.recentPayoutTransactionDict).map(([key, value]) => ({
          ...value,
          active: true,
        })),
      };
    });

    // delete outletDataDict;
    outletDataDict = {};

    console.log('tempData');
    console.log(tempData);

    const outletLastOrderArray = Object.entries(window.pOutletFilterDict).map(
      ([key, value]) => ({ key: key, value: value }),
    );

    if (clickedChartDate !== null) {

    }
    else {
      // setMerchantDataAllLength(tempData.length);

      // // var startDate24H = moment().subtract(1, "day").startOf('day').valueOf();
      // // var endDate24H = moment().subtract(1, "day").endOf('day').valueOf();
      // setMerchantData24HLength(outletLastOrderArray.filter(temp => {
      //   // if (moment(startDate24H).isSameOrBefore(temp.value) &&
      //   //   moment(endDate24H).isAfter(temp.value)) {
      //   if (temp.value['is24H']) {
      //     return true;
      //   }
      //   else {
      //     return false;
      //   }
      // }).length);

      // // var startDate7D = moment().subtract(7, "day").startOf('day').valueOf();
      // // var endDate7D = moment().subtract(1, "day").endOf('day').valueOf();
      // setMerchantData7DLength(outletLastOrderArray.filter(temp => {
      //   // if (moment(startDate7D).isSameOrBefore(temp.value) &&
      //   //   moment(endDate7D).isAfter(temp.value)) {
      //   if (temp.value['is7D']) {
      //     return true;
      //   }
      //   else {
      //     return false;
      //   }
      // }).length);

      // // var startDate1M = moment().subtract(1, "month").startOf('month').startOf('day').valueOf();
      // // var endDate1M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
      // setMerchantData1MLength(outletLastOrderArray.filter(temp => {
      //   // if (moment(startDate1M).isSameOrBefore(temp.value) &&
      //   //   moment(endDate1M).isAfter(temp.value)) {
      //   if (temp.value['is1M']) {
      //     return true;
      //   }
      //   else {
      //     return false;
      //   }
      // }).length);

      // // var startDate3M = moment().subtract(3, "month").startOf('month').startOf('day').valueOf();
      // // var endDate3M = moment().subtract(1, "month").endOf('month').endOf('day').valueOf();
      // setMerchantData3MLength(outletLastOrderArray.filter(temp => {
      //   // if (moment(startDate3M).isSameOrBefore(temp.value) &&
      //   //   moment(endDate3M).isAfter(temp.value)) {
      //   if (temp.value['is3M']) {
      //     return true;
      //   }
      //   else {
      //     return false;
      //   }
      // }).length);
    }

    // CommonStore.update(s => {
    //   s.outletFilterDict = window.pOutletFilterDict;
    // });

    // setMerchantData3MLength(tempData.filter(temp => {
    //   // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 3) {
    //   if (moment(startDate3M).isSameOrBefore(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId]) &&
    //     moment(endDate3M).isAfter(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId])) {
    //     if (temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId]) {
    //       return true;
    //     }
    //     else {
    //       return false;
    //     }
    //   }
    //   else {
    //     return false;
    //   }
    // }).length);

    if (
      // clickedChartDate !== null
      clickedBarChartDate !== null &&
      clickedBarChartDateUnit !== null
    ) {

    }
    else {
      if (merchantDataFilterType === MERCHANT_DATA_FILTER.ALL) {
        tempData = tempData;
      }
      else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H) {
        tempData = tempData.filter(temp => {
          // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'hour') <= 24) {
          if (moment(startDate24H).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
            moment(endDate24H).isAfter(temp.lastOrderPlacedDateTime)) {
            if (temp.lastOrderPlacedDateTime) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            return false;
          }
        });
      }
      else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D) {
        tempData = tempData.filter(temp => {
          // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'day') <= 7) {
          if (moment(startDate7D).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
            moment(endDate7D).isAfter(temp.lastOrderPlacedDateTime)) {
            if (temp.lastOrderPlacedDateTime) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            return false;
          }
        });
      }
      else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M) {
        tempData = tempData.filter(temp => {
          // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 1) {
          if (moment(startDate1M).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
            moment(endDate1M).isAfter(temp.lastOrderPlacedDateTime)) {
            if (temp.lastOrderPlacedDateTime) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            return false;
          }
        });
      }
      else if (merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M) {
        tempData = tempData.filter(temp => {
          // if (moment().diff(temp.lastOrderPlacedDateTime || window.outletLastOrderDict[temp.outletId], 'month') <= 3) {
          if (moment(startDate3M).isSameOrBefore(temp.lastOrderPlacedDateTime) &&
            moment(endDate3M).isAfter(temp.lastOrderPlacedDateTime)) {
            if (temp.lastOrderPlacedDateTime) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            return false;
          }
        });
      }
    }

    //////////////////////////////////////////////////////////////////

    // sort by default

    tempData.sort(sortByTotalOrdersAmountDesc);

    //////////////////////////////////////////////////////////////////

    const tempKCRMarray = tempData;

    console.log("detailsList", tempKCRMarray);

    // setAllOutletsEmployeesAction(tempKCRMarray);
    setTableDataSummary(tempKCRMarray);

    setPageCount(Math.ceil(tempKCRMarray.length / perPage));

    //////////////////////////////////////////////////////////////////

    // save data

    await AsyncStorage.setItem('outletFilterDict', JSON.stringify(window.pOutletFilterDict));

    //////////////////////////////////////////////////////////////////
  }, [
    // merchantsOnboarded, 
    outletsOnboarded,
    ptStartDate, ptEndDate, merchantDataFilterType,
    outletUserActionDict,
    payoutTransactions,
    payoutTransactionsExtend,

    gmvOrdersRecent,

    // clickedChartDate,

    clickedBarChartDate,
    clickedBarChartDateUnit,

    selectedPaymentType,
  ]);

  ///////////////////////////////////////////////////////////////////////////////

  // 2024-03-19 - to calculate the top best selling items, and the linked/subsequent items that buy after this

  useEffect(async () => {
    if (tableDataSummaryBs.length > 0 && selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS) {
      // can proceed

      if (outletItemsLength > 0 && outletIdPrev === selectedCmpOutletId) {
        // means can skip first

      }
      else {
        const outletItemSnapshot = await firebase.firestore()
          .collection(Collections.OutletItem)
          .where('outletId', '==', selectedCmpOutletId)
          .get();

        if (outletItemSnapshot) {
          // var outletItemsTemp = [];

          for (var i = 0; i < outletItemSnapshot.size; i++) {
            const record = outletItemSnapshot.docs[i].data();

            // outletItemsTemp.push(record);

            global.outletItemDict[record.uniqueId] = {
              name: record.name,
            };
          }

          // setOutletItems(outletItemsTemp);
          // setOutletItemsDt(Date.now());
          setOutletItemsLength(outletItemSnapshot.size);
          setOutletIdPrev(selectedCmpOutletId);
        }
      }
    }
  }, [selectedCmpOutletId, tableDataSummaryBs.length, selectedPayoutTabs]);

  useEffect(async () => {
    ///////////////////////////////////////////////////////////////////////

    if (selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS) {
      let bsDict = {};
      let bsAddonDict = {};

      for (let i = 0; i < cmpOutletPayoutTransactionsProcessed.length; i++) {
        const currTransaction = cmpOutletPayoutTransactionsProcessed[i];

        let relatedItemIdList = [];

        for (let j = 0; j < currTransaction.cartItems.length; j++) {
          const currCartItem = currTransaction.cartItems[j];

          //////////////////////////////////////

          // 2024-04-05 - for addons data support

          if (currCartItem.addons && currCartItem.addons.length > 0) {
            for (let addonsIndex = 0; addonsIndex < currCartItem.addons.length; addonsIndex++) {
              const addonsKey = `${currCartItem.id} > ${currCartItem.addons[addonsIndex].name} > ${currCartItem.addons[addonsIndex].choiceNames[0]}`;
              const addonsPrice = currCartItem.addons[addonsIndex].prices[0];
              const addonsQty = currCartItem.addons[addonsIndex].quantities ? currCartItem.addons[addonsIndex].quantities[0] : 1;

              if (bsAddonDict[addonsKey]) {
                // means got this item summary already

                bsAddonDict[addonsKey].amt = BigNumber(bsAddonDict[addonsKey].amt)
                  .plus(BigNumber(addonsPrice)).toNumber();

                bsAddonDict[addonsKey].qty += addonsQty;
              }
              else {
                // need to crete the summary for this item

                bsAddonDict[addonsKey] = {
                  id: currCartItem.id,
                  amt: addonsPrice,
                  qty: addonsQty,
                  items: {},

                  name: addonsKey,
                };
              }
            }
          }

          //////////////////////////////////////

          if (bsDict[currCartItem.id]) {
            // means got this item summary alredy

            bsDict[currCartItem.id].amt = BigNumber(bsDict[currCartItem.id].amt)
              .plus(BigNumber(currCartItem.price)).toNumber();

            bsDict[currCartItem.id].qty += currCartItem.qty;
          }
          else {
            // need to crete the summary for this item

            bsDict[currCartItem.id] = {
              id: currCartItem.id,
              amt: currCartItem.price,
              qty: currCartItem.qty,
              items: {},
            };
          }

          relatedItemIdList.push(currCartItem.id);
        }

        // for linked items

        for (let j = 0; j < currTransaction.cartItems.length; j++) {
          const currCartItem = currTransaction.cartItems[j];

          for (let k = 0; k < relatedItemIdList.length; k++) {
            const relatedItemId = relatedItemIdList[k];

            if (currCartItem.id !== relatedItemId) {
              // means can proceed

              if (bsDict[relatedItemId].items[currCartItem.id]) {
                // means got this item summary alredy

                bsDict[relatedItemId].items[currCartItem.id].amt = BigNumber(bsDict[relatedItemId].items[currCartItem.id].amt)
                  .plus(BigNumber(currCartItem.price)).toNumber();

                bsDict[relatedItemId].items[currCartItem.id].qty += currCartItem.qty;
              }
              else {
                // need to crete the summary for this item

                bsDict[relatedItemId].items[currCartItem.id] = {
                  id: currCartItem.id,
                  amt: currCartItem.price,
                  qty: currCartItem.qty,
                  // items: {},
                };
              }
            }
          }
        }
      }

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      var tempDataAddons = Object.entries(bsAddonDict).map(([key, value]) => {
        return {
          id: value.id,
          amt: value.amt,
          qty: value.qty,
          items: [],

          name: value.name,
          ao: true,
        };
      });

      tempDataAddons.sort(sortByQtyDesc);

      setTableDataSummaryAddons(tempDataAddons);

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      var tempData = Object.entries(bsDict).map(([key, value]) => {
        let totalAmt = 0;
        let totalQty = 0;

        let itemsList = Object.entries(value.items).map(([key, value]) => {
          // totalAmt = BigNumber(totalAmt).plus(BigNumber(value.amt)).toNumber();
          totalQty += value.qty;

          return value;
        });

        itemsList = itemsList.map(item => {
          return {
            ...item,
            // pct: BigNumber(item.amt).dividedBy(totalAmt).multipliedBy(100).toNumber(2),
            pct: BigNumber(item.qty).dividedBy(totalQty).multipliedBy(100).toNumber(2),
          };
        })

        itemsList.sort((a, b) => b.qty - a.qty);

        return {
          id: value.id,
          amt: value.amt,
          qty: value.qty,
          items: itemsList,
        };
      });

      //////////////////////////////////////////////////////////////////

      // sort by default

      tempData.sort(sortByQtyDesc);

      //////////////////////////////////////////////////////////////////

      const tempKCRMarray = tempData;

      console.log("detailsList", tempKCRMarray);

      // setAllOutletsEmployeesAction(tempKCRMarray);

      setExpandedSummaryRowBs({});

      // setTableDataSummaryBs(tempKCRMarray.filter(item => item.id !== undefined && item.amt !== undefined && item.qty !== undefined));
      setTableDataSummaryItems(tempKCRMarray.filter(item => item.id !== undefined && item.amt !== undefined && item.qty !== undefined));

      // setCurrentPage(1);
      setPageCount(Math.ceil(tempKCRMarray.length / perPage));

      //////////////////////////////////////////////////////////////////

      // save data

      // await AsyncStorage.setItem('outletFilterDict', JSON.stringify(window.pOutletFilterDict));

      //////////////////////////////////////////////////////////////////
    }
  }, [
    cmpOutletPayoutTransactionsProcessed,

    // merchantsOnboarded, 
    // outletsOnboarded,
    // ptStartDate, ptEndDate, merchantDataFilterType,
    // outletUserActionDict,
    // payoutTransactions,
    // payoutTransactionsExtend,

    // gmvOrdersRecent,

    // clickedChartDate,

    // clickedBarChartDate,
    // clickedBarChartDateUnit,

    // selectedPaymentType,

    selectedPayoutTabs,
  ]);

  ///////////////////////////////////////////////////////////////////////////////

  const checkOutletFilterDict = (outlet, orderDateTime, dateTimeConst) => {
    const {
      startDate24H,
      endDate24H,
      startDate7D,
      endDate7D,
      startDate1M,
      endDate1M,
      startDate3M,
      endDate3M,
    } = dateTimeConst;

    if (!window.pOutletFilterDict[outlet.uniqueId]) {
      window.pOutletFilterDict[outlet.uniqueId] = {
        is24H: false,
        is7D: false,
        is1M: false,
        is3M: false,
      };
    }

    if (moment(startDate24H).isSameOrBefore(orderDateTime) &&
      moment(endDate24H).isAfter(orderDateTime)) {
      window.pOutletFilterDict[outlet.uniqueId]['is24H'] = true;
    }

    if (moment(startDate7D).isSameOrBefore(orderDateTime) &&
      moment(endDate7D).isAfter(orderDateTime)) {
      window.pOutletFilterDict[outlet.uniqueId]['is7D'] = true;
    }
    if (moment(startDate1M).isSameOrBefore(orderDateTime) &&
      moment(endDate1M).isAfter(orderDateTime)) {
      window.pOutletFilterDict[outlet.uniqueId]['is1M'] = true;
    }

    if (moment(startDate3M).isSameOrBefore(orderDateTime) &&
      moment(endDate3M).isAfter(orderDateTime)) {
      window.pOutletFilterDict[outlet.uniqueId]['is3M'] = true;
    }
  }

  // useEffect(async () => {
  //   const tempData = allOutletsEmployeesAction.map(merchant => {
  //     // var outlet = outletsOnboarded.find(outlet => outlet.merchantId === merchant.uniqueId);

  //     var employeeClock = userActions.find(clock => clock.merchantId === merchant.merchantId);      

  //     if (employeeClock) {
  //       return {
  //         ...merchant,
  //         lastActivity: employeeClock.updatedAt > merchant.lastActivity ? employeeClock.updatedAt : merchant.lastActivity,
  //       };
  //     }
  //     else {
  //       return merchant;
  //     }
  //   });

  //   const tempKCRMarray = tempData;

  //   const isEqual = JSON.prune(allOutletsEmployeesAction) === JSON.prune(tempKCRMarray);

  //   if (!isEqual) {
  //     setAllOutletsEmployeesAction(tempKCRMarray);

  //     setPageCount(Math.ceil(tempKCRMarray.length / perPage));
  //   }
  // }, [allOutletsEmployeesAction, userActions]);

  // useEffect(async () => {
  //   const tempData = allOutletsEmployeesAction.map(merchant => {
  //     // var outlet = outletsOnboarded.find(outlet => outlet.merchantId === merchant.uniqueId);

  //     var employeeClock = employeeClocks.find(clock => clock.merchantId === merchant.merchantId);

  //     if (employeeClock) {
  //       return {
  //         ...merchant,
  //         lastActivity: employeeClock.updatedAt > merchant.lastActivity ? employeeClock.updatedAt : merchant.lastActivity,
  //       };
  //     }
  //     else {
  //       return merchant;
  //     }
  //   });

  //   const tempKCRMarray = tempData;

  //   const isEqual = JSON.prune(allOutletsEmployeesAction) === JSON.prune(tempKCRMarray);

  //   if (!isEqual) {
  //     setAllOutletsEmployeesAction(tempKCRMarray);

  //     setPageCount(Math.ceil(tempKCRMarray.length / perPage));
  //   }
  // }, [allOutletsEmployeesAction, employeeClocks]);

  ///////////////////////////////////////////////////////////////////////////////////

  // useEffect(async () => {
  //   const tempData = allOutletsEmployeesAction.map(async merchant => {
  //     var outlet = outletsOnboarded.find(outlet => outlet.merchantId === merchant.uniqueId);

  //     const employeeClockSnapshot = await firebase.firestore().collection(Collections.EmployeeClock)
  //       .where('merchantId', '==', merchant.merchantId)
  //       .orderBy('updatedAt', 'desc')
  //       .limit(1)
  //       .get();

  //     var employeeClock = null;
  //     if (!employeeClockSnapshot.empty) {
  //       employeeClock = employeeClockSnapshot.docs[0].data();
  //     }

  //     if (employeeClock) {
  //       return {
  //         ...merchant,
  //         lastActivity: employeeClock.updatedAt,
  //       };
  //     }
  //     else {
  //       return merchant;
  //     }
  //   });

  //   const tempKCRMarray = tempData;

  //   const isEqual = JSON.prune(allOutletsEmployeesAction) === JSON.prune(tempKCRMarray);

  //   if (!isEqual) {
  //     setAllOutletsEmployeesAction(tempKCRMarray);

  //     setPageCount(Math.ceil(tempKCRMarray.length / perPage));
  //   }
  // }, [allOutletsEmployeesAction]);

  ///////////////////////////////////////////////////////////////////////////////////

  const setState = () => { };

  //   navigation.setOptions({
  //     headerLeft: () => (
  //       <View
  //         style={{
  //           width: Dimensions.get("screen").width * 0.17,
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Image
  //           style={{
  //             width: 124,
  //             height: 26,
  //           }}
  //           resizeMode="contain"
  //           source={require("../assets/image/logo.png")}
  //         />
  //       </View>
  //     ),
  //     headerTitle: () => (
  //       <View
  //         style={[
  //           {
  //             justifyContent: "center",
  //             alignItems: "center",
  //             // bottom: -2,
  //             bottom: switchMerchant ? "2%" : 0,
  //           },
  //           Dimensions.get("screen").width >= 768 && switchMerchant
  //             ? { right: Dimensions.get("screen").width * 0.1 }
  //             : {},
  //           Dimensions.get("screen").width <= 768
  //             ? { right: Dimensions.get("screen").width * 0.12 }
  //             : {},
  //         ]}
  //       >
  //         <Text
  //           style={{
  //             fontSize: switchMerchant ? 20 : 24,
  //             // lineHeight: 25,
  //             textAlign: "center",
  //             fontFamily: "NunitoSans-Bold",
  //             color: Colors.whiteColor,
  //             opacity: 1,
  //           }}
  //         >
  //           KCRM Agreement Report
  //         </Text>
  //       </View>
  //     ),
  //     headerRight: () => (
  //       <View
  //         style={{
  //           flexDirection: "row",
  //           alignItems: "center",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         {outletSelectDropdownView()}
  //         <View
  //           style={{
  //             backgroundColor: "white",
  //             width: 0.5,
  //             height: Dimensions.get("screen").height * 0.025,
  //             opacity: 0.8,
  //             marginHorizontal: 15,
  //             bottom: -1,
  //           }}
  //         ></View>
  //         <TouchableOpacity
  //           onPress={() => navigation.navigate("Setting")}
  //           style={{ flexDirection: "row", alignItems: "center" }}
  //         >
  //           <Text
  //             style={{
  //               fontFamily: "NunitoSans-SemiBold",
  //               fontSize: 16,
  //               color: Colors.secondaryColor,
  //               marginRight: 15,
  //             }}
  //           >
  //             {userName}
  //           </Text>
  //           <View
  //             style={{
  //               marginRight: 30,
  //               width: Dimensions.get("screen").height * 0.05,
  //               height: Dimensions.get("screen").height * 0.05,
  //               borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
  //               alignItems: "center",
  //               justifyContent: "center",
  //               backgroundColor: "white",
  //             }}
  //           >
  //             <Image
  //               style={{
  //                 width: Dimensions.get("screen").height * 0.035,
  //                 height: Dimensions.get("screen").height * 0.035,
  //                 alignSelf: "center",
  //               }}
  //               source={require("../assets/image/profile-pic.jpg")}
  //             />
  //           </View>
  //         </TouchableOpacity>
  //       </View>
  //     ),
  //   });

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };

  const nextDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage + 1 > pageCount
        ? currentDetailsPage
        : currentDetailsPage + 1
    );
  };

  const prevDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1
    );
  };

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setShowDetails(true);
          setTableDataDetails(item.detailsList);
          // setTableDataDetails(tableDataSummary.reduce((allDetailsList, summary) => allDetailsList.concat(summary.detailsList), []))

          setShowDetailsOutletId(item.outletId);
        }}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          paddingHorizontal: 3,
          paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "2%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.outletName}

            {`\n(${item.lastOrderPlacedDateTime ? moment(item.lastOrderPlacedDateTime).format('YYYY-MM-DD hh:mm A') : 'N/A'})`}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.userAppAndWebDineInOnlineOrdersAmount.toFixed(2)} (${item.userAppAndWebDineInOnlineOrdersQty.toFixed(0)})`}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.userAppAndWebDineInOrdersAmount.toFixed(2)} (${item.userAppAndWebDineInOrdersQty.toFixed(0)})`}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.merchantAppOnlineOrdersAmount.toFixed(2)} (${item.merchantAppOnlineOrdersQty.toFixed(0)})`}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.merchantAppOrdersAmount.toFixed(2)} (${item.merchantAppOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.waiterAppOrdersAmount.toFixed(2)} (${item.waiterAppOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.userAppAndWebTakeawayOrdersAmount.toFixed(2)} (${item.userAppAndWebTakeawayOrdersQty.toFixed(0)})`}
          </Text>
          {/* <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.userAppAndWebDeliveryOrdersAmount.toFixed(2)} (${item.userAppAndWebDeliveryOrdersQty.toFixed(0)})`}
          </Text> */}
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.totalOrdersAmount.toFixed(2)} (${item.totalOrdersQty.toFixed(0)})`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Bold",
              textAlign: "left",
              paddingLeft: 10,

              color: Colors.primaryColor,
            }}
          >
            {`RM ${(BigNumber(item.gmvCommissionActual).plus
              ((typeof calculatedTodayGMVActualOutletIdDict[item.outletId] === 'number') ? calculatedTodayGMVActualOutletIdDict[item.outletId] : 0)
            ).toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.gmvPayoutActual.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.gmvCommission.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.gmvPayout.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.gmvFees.toFixed(2)}`}
            {(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
              ? `\n(RM ${BigNumber(item.mdrEarned).plus
                ((typeof calculatedTodayMdrEarnedOutletIdDict[item.outletId] === 'number') ? calculatedTodayMdrEarnedOutletIdDict[item.outletId] : 0)
                .toFixed(2)})`
              : ``}
          </Text>
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.lastOrderPlacedDateTime ? moment(item.lastOrderPlacedDateTime).format('YYYY-MM-DD hh:mm A') : 'N/A'}
          </Text> */}

          <TouchableOpacity
            style={{
              width: "5%",
              // fontSize: switchMerchant ? 10 : 13,
              // fontFamily: "NunitoSans-Regular",
              // textAlign: "left",
              paddingLeft: '1.5%',

              // backgroundColor: 'red',
            }}
            onPress={() => {
              setExpandedSummaryRow({
                ...expandedSummaryRow,
                [item.outletId]: expandedSummaryRow[item.outletId] ? false : true,
              });

              setCurrExpandedRow(item.outletId === currExpandedRow.outletId ? {} : item);
            }}
          >
            {
              !expandedSummaryRow[item.outletId]
                ?
                <Icon
                  name="chevron-up"
                  size={15}
                  color={Colors.primaryColor}
                // style={{ marginLeft: 15 }}
                />
                :
                <Icon
                  name="chevron-down"
                  size={15}
                  color={Colors.primaryColor}
                // style={{ marginLeft: 15 }}
                />
            }
          </TouchableOpacity>
        </View>

        {expandedSummaryRow[item.outletId] == true ? (
          <View
            style={{
              minHeight: windowHeight * 0.35,
              marginTop: 20,
              paddingBottom: 20,

              // backgroundColor: 'red',
            }}>

            <View style={{ marginTop: 10, flexDirection: "row" }}>
              <View
                style={{
                  flexDirection: "row",
                  width: "2%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: 10,
                }}
              >
                <View
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"No.\n\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", opacity: 0 }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "8%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Settlement\nDate\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Prev.\nFunds\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Prev.\nOverdue\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              {/* <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Prev.\nPending\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View> */}

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Curr.\nFunds\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Curr.\nOverdue\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              {/* <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Curr.\nPending\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View> */}

              {/* /////////////////////// */}

              {/* 2022-12-10 - New columns */}

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",

                          color: Colors.primaryColor,
                        }}
                      >
                        {"KooDoo\nProfit\n(Actual)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"O. Sales\nPayout\n(Actual)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              {/* 2022-11-26 - New columns */}

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"KooDoo\nProfit\n(Expected)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"O. Sales\nPayout\n(Expected)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {`MDR\nFee\n${(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') ? `(Earned)` : ``}`}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {`MDR\nFee\n(Overdue)`}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              {/* /////////////////////// */}

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"KooDoo\nProfit\n(Online)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"KooDoo\nProfit\n(Overdue)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "5%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <View>
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"O. Sales\nPayout\nFee"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={{
              marginTop: 10,
            }}>

            </View>
            {
              item.razerPayoutTransactionList.concat(item.recentPayoutTransactionList).map((transaction, transactionIndex) => {
                return (
                  <View
                    // onPress={() => {
                    //   setShowDetails(true);
                    //   setTableDataDetails(item.detailsList);

                    //   setShowDetailsOutletId(item.outletId);
                    // }}
                    style={{
                      backgroundColor:
                        (transactionIndex + 1) % 2 == 0 ? Colors.lightGrey : '#d1d1d1',
                      paddingVertical: 10,
                      paddingHorizontal: 3,
                      paddingLeft: 1,
                      borderColor: "#BDBDBD",
                      borderTopWidth: (transactionIndex + 1) % 2 == 0 ? 0 : 0.5,
                      borderBottomWidth: (transactionIndex + 1) % 2 == 0 ? 0 : 0.5,
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          width: "2%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transactionIndex + 1}
                      </Text>
                      <Text
                        dataTransactionId={transaction.uniqueId}
                        style={{
                          width: "8%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,

                          // fontSize: 8,
                        }}
                        dataSet={{
                          uniqueId: transaction.uniqueId,
                        }}
                      >
                        {transaction.settlementDate ? moment(transaction.settlementDate).format('YYYY-MM-DD hh:mm A') : 'N/A'}
                        {transaction.rsc !== undefined ? `\n(${RAZER_MASS_PAYOUT_STATUS_PARSED[transaction.rsc]})` : ``}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.prevStockUpAmount !== undefined ? `RM ${transaction.prevStockUpAmount.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.prevOverdueAmount !== undefined ? `RM ${transaction.prevOverdueAmount.toFixed(2)}` : 'N/A'}
                      </Text>
                      {/* <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.prevPendingAmount !== undefined ? `RM ${transaction.prevPendingAmount.toFixed(2)}` : 'N/A'}
                        {'N/A'}
                      </Text> */}
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.stockUpAmount !== undefined ? `RM ${transaction.stockUpAmount.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleMerchantOverdueAmounts !== undefined ? `RM ${transaction.outletCycleMerchantOverdueAmounts.toFixed(2)}` : 'N/A'}
                      </Text>
                      {/* <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleMerchantPendingAmounts !== undefined ? `RM ${transaction.outletCycleMerchantPendingAmounts.toFixed(2)}` : 'N/A'}
                        {'N/A'}
                      </Text> */}
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          textAlign: "left",
                          paddingLeft: 10,

                          color: Colors.primaryColor,
                        }}
                      >
                        {transaction.outletCycleKoodooPayoutsActual !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsActual.toFixed(2)}` : ''}
                        {moment(transaction.settlementDate).add(-1, 'day').isSame(moment(), 'day') ?
                          ((typeof calculatedTodayGMVActualOutletIdDict[item.outletId] === 'number') ? `RM ${calculatedTodayGMVActualOutletIdDict[item.outletId].toFixed(2)}` : 'RM 0.00')
                          : ''}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleMerchantPayoutsActual !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsActual.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleKoodooPayoutsExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsExpected.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleMerchantPayoutsExpected !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsExpected.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleRazerPayouts !== undefined ? `RM ${transaction.outletCycleRazerPayouts.toFixed(2)}` : 'N/A'}
                        {(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') ? `\n(RM ${transaction.outletCycleKoodooMDREarned !== undefined ? transaction.outletCycleKoodooMDREarned.toFixed(2) : 'N/A'})` : ``}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleMerchantOverdueAmountsMdr !== undefined ? `RM ${transaction.outletCycleMerchantOverdueAmountsMdr.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleKoodooPayoutsOnlineOnlyExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsOnlineOnlyExpected.toFixed(2)}` : 'N/A'}
                        {/* {moment(transaction.settlementDate).add(-1, 'day').isSame(moment(), 'day') ?
                          (((userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && typeof calculatedTodayGMVActualOutletIdDict[item.outletId] === 'number') ? `RM ${calculatedTodayGMVActualOutletIdDict[item.outletId].toFixed(2)}` : 'RM 0.00')
                          : ''} */}
                      </Text>

                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.outletCycleMerchantOverdueAmountsGmv !== undefined ? `RM ${transaction.outletCycleMerchantOverdueAmountsGmv.toFixed(2)}` : 'N/A'}
                        {/* {moment(transaction.settlementDate).add(-1, 'day').isSame(moment(), 'day') ?
                          (((userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && typeof calculatedTodayGMVActualOutletIdDict[item.outletId] === 'number') ? `RM ${calculatedTodayGMVActualOutletIdDict[item.outletId].toFixed(2)}` : 'RM 0.00')
                          : ''} */}
                      </Text>

                      <Text
                        style={{
                          width: "5%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.payoutFee !== undefined ? `RM ${transaction.payoutFee.toFixed(2)}` : 'N/A'}
                      </Text>
                    </View>
                  </View>
                );
              })
            }
          </View>
        ) : null}
      </TouchableOpacity>
    );
  };

  const renderItemDetails = ({ item, index }) => {
    return (
      <View
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.dateTime}
          </Text>
          <Text
            dataOrderId={item.uniqueId}
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,

              // fontSize: 8,
            }}
            dataSet={{
              uniqueId: item.uniqueId,
            }}
          >
            {item.orderId}
            {` (${item.cartItemsLength})`}
            {/* {item.uniqueId} */}
            {/* {`${item.orderId}\n${item.uniqueId}`} */}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.isQrOrder}
          </Text>
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.netAmount.toFixed(2)}`}
          </Text> */}
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.tax.toFixed(2)}`}
          </Text> */}
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.sc.toFixed(2)}`}
          </Text> */}
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.totalAmount.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.razerCharges.toFixed(2)}`}
            {item.pdChannel === ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX ? ` (RHB)` :
              (
                item.razerCharges ? ` (Razer)` : ''
              )}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.cfOd ? `RM ${item.cfOd.toFixed(2)}` : `RM 0.00`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.kpfOd ? `RM ${item.kpfOd.toFixed(2)}` : `RM 0.00`}
          </Text>
          {
            (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
              ?
              <Text
                style={{
                  width: "8%",
                  fontSize: switchMerchant ? 10 : 13,
                  fontFamily: "NunitoSans-Regular",
                  textAlign: "left",
                  paddingLeft: 10,
                }}
              >
                {`RM ${(item.cfe ? item.cfe : 0).toFixed(2)}`}
              </Text>
              :
              <></>
          }
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.koodooCharges.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`RM ${item.outletPayout.toFixed(2)}`}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.settlementDate}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.pdChannel}
            {/* {PAYMENT_CHANNEL_NAME_PARSED[item.pdChannel] ? PAYMENT_CHANNEL_NAME_PARSED[item.pdChannel] : item.pdChannel} */}
          </Text>
          {/* <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.cartItemsLength}            
          </Text> */}
        </View>
      </View>
    );
  };

  // 2024-12-26 - export for all merchants
  // const convertDataToExcelFormat = () => {
  //   var excelData = [];

  //   if (!showDetails) {
  //     let tableDataRow = null;
  //     if (currExpandedRow && currExpandedRow.outletId) {
  //       tableDataRow = tableDataSummary.find(findRow => findRow.outletId === currExpandedRow.outletId);
  //     }

  //     if (tableDataRow) {
  //       for (let tableDataRowIndex = 0; tableDataRowIndex < tableDataSummary.length; tableDataRowIndex++) {
  //         tableDataRow = tableDataSummary[tableDataRowIndex];

  //         const transactionList = tableDataRow.razerPayoutTransactionList.concat(tableDataRow.recentPayoutTransactionList);

  //         for (var i = 0; i < transactionList.length; i++) {
  //           const transaction = transactionList[i];

  //           // const koodooProfitActual = transaction.outletCycleKoodooPayoutsActual !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsActual.toFixed(2)}` : ''}
  //           // {moment(transaction.settlementDate).add(-1, 'day').isSame(moment(), 'day') ?
  //           //   ((typeof calculatedTodayGMVActualOutletIdDict[item.outletId] === 'number') ? `RM ${calculatedTodayGMVActualOutletIdDict[item.outletId].toFixed(2)}` : 'RM 0.00')
  //           //   : '';

  //           var excelRow = {
  //             "Merchant Name": tableDataRow.outletName,
  //             "Settlement Date": `${transaction.settlementDate ? moment(transaction.settlementDate).format('YYYY-MM-DD hh:mm A') : 'N/A'}`,
  //             "Prev. Funds (RM)": `${transaction.prevStockUpAmount !== undefined ? `RM ${transaction.prevStockUpAmount.toFixed(2)}` : 'N/A'}`,
  //             "Prev. Overdue (RM)": `${transaction.prevOverdueAmount !== undefined ? `RM ${transaction.prevOverdueAmount.toFixed(2)}` : 'N/A'}`,
  //             "Prev. Pending (RM)": `${'N/A'}`,
  //             "Curr. Funds (RM)": `${transaction.stockUpAmount !== undefined ? `RM ${transaction.stockUpAmount.toFixed(2)}` : 'N/A'}`,
  //             "Curr. Overdue (RM)": `${transaction.outletCycleMerchantOverdueAmounts !== undefined ? `RM ${transaction.outletCycleMerchantOverdueAmounts.toFixed(2)}` : 'N/A'}`,
  //             "Curr. Pending (RM)": `${'N/A'}`,
  //             "KooDoo Profit (Actual) (RM)": `${transaction.outletCycleKoodooPayoutsActual !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsActual.toFixed(2)}` : ''}`,
  //             "O. Sales Payout (Actual) (RM)": `${transaction.outletCycleMerchantPayoutsActual !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsActual.toFixed(2)}` : 'N/A'}`,
  //             "KooDoo Profit (Expected) (RM)": `${transaction.outletCycleKoodooPayoutsExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsExpected.toFixed(2)}` : 'N/A'}`,
  //             "O. Sales Payout (Expected) (RM)": `${transaction.outletCycleMerchantPayoutsExpected !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsExpected.toFixed(2)}` : 'N/A'}`,
  //             "MDR Fee (RM)": `${transaction.outletCycleRazerPayouts !== undefined ? `RM ${transaction.outletCycleRazerPayouts.toFixed(2)}` : 'N/A'}`,

  //             ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
  //               "Earned MDR (RM)": `${transaction.outletCycleKoodooMDREarned !== undefined ? `RM ${transaction.outletCycleKoodooMDREarned.toFixed(2)}` : 'N/A'}`,
  //             },

  //             "KooDoo Profit (Online) (RM)": `${transaction.outletCycleKoodooPayoutsOnlineOnlyExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsOnlineOnlyExpected.toFixed(2)}` : 'N/A'}`,
  //             "O. Sales Payout Fee (RM)": `${transaction.payoutFee !== undefined ? `RM ${transaction.payoutFee.toFixed(2)}` : 'N/A'}`,
  //           };

  //           excelData.push(excelRow);
  //         }
  //       }
  //     }
  //     else {
  //       for (var i = 0; i < tableDataSummary.length; i++) {
  //         var excelRow = {
  //           "Merchant Name": tableDataSummary[i].outletName,
  //           "QR Orders Online (RM)": `${tableDataSummary[i].userAppAndWebDineInOnlineOrdersAmount.toFixed(2)}`,
  //           "QR Orders Online (Qty)": `${tableDataSummary[i].userAppAndWebDineInOnlineOrdersQty.toFixed(0)}`,
  //           "QR Orders Offline (RM)": `${tableDataSummary[i].userAppAndWebDineInOrdersAmount.toFixed(2)}`,
  //           "QR Orders Offline (Qty)": `${tableDataSummary[i].userAppAndWebDineInOrdersQty.toFixed(0)}`,
  //           "POS Orders (RM)": `${tableDataSummary[i].merchantAppOrdersAmount.toFixed(2)}`,
  //           "POS Orders (Qty)": `${tableDataSummary[i].merchantAppOrdersQty.toFixed(0)}`,
  //           "Waiter Orders (RM)": `${tableDataSummary[i].waiterAppOrdersAmount.toFixed(2)}`,
  //           "Waiter Orders (Qty)": `${tableDataSummary[i].waiterAppOrdersQty.toFixed(0)}`,
  //           "Takeaway Orders (RM)": `${tableDataSummary[i].userAppAndWebTakeawayOrdersAmount.toFixed(2)}`,
  //           "Takeaway Orders (Qty)": `${tableDataSummary[i].userAppAndWebTakeawayOrdersQty.toFixed(0)}`,
  //           "Total Orders (RM)": `${tableDataSummary[i].totalOrdersAmount.toFixed(2)}`,
  //           "Total Orders (Qty)": `${tableDataSummary[i].totalOrdersQty.toFixed(0)}`,
  //           "KooDoo Profit (Actual) (RM)": `${tableDataSummary[i].gmvCommissionActual.toFixed(2)}`,
  //           "O. Sales Payout (Actual) (RM)": `${tableDataSummary[i].gmvPayoutActual.toFixed(2)}`,
  //           "KooDoo Profit (Excpected) (RM)": `${tableDataSummary[i].gmvCommission.toFixed(2)}`,
  //           "O. Sales Payout (Expected) (RM)": `${tableDataSummary[i].gmvPayout.toFixed(2)}`,
  //           "MDR Fee (RM)": `${tableDataSummary[i].gmvFees.toFixed(2)}`,

  //           ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
  //             "Earned MDR (RM)": `${tableDataSummary[i].mdrEarned.toFixed(2)}`,
  //           },

  //           "Last Order": tableDataSummary[i].lastOrderPlacedDateTime ? moment(tableDataSummary[i].lastOrderPlacedDateTime).format('YYYY-MM-DD hh:mm A') : 'N/A',
  //         };

  //         excelData.push(excelRow);
  //       }
  //     }
  //   } else {
  //     for (var i = 0; i < tableDataDetails.length; i++) {
  //       var excelRow = {
  //         "Merchant Name": tableDataDetails[i].on,
  //         "Date Time": moment(tableDataDetails[i].dateTime).format("DD MMM hh:mm A"),
  //         "Order Id(Joined)": tableDataDetails[i].orderId,
  //         "QR Order": tableDataDetails[i].isQrOrder,
  //         "Total Amount (RM)": `${tableDataDetails[i].totalAmount.toFixed(2)}`,
  //         "Razer Charges (RM)": `${tableDataDetails[i].razerCharges.toFixed(2)}`,

  //         ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
  //           "Earned MDR (RM)": `${tableDataDetails[i].cfe.toFixed(2)}`,
  //         },

  //         "KooDoo Profit (Expected) (RM)": `${tableDataDetails[i].koodooCharges.toFixed(2)}`,
  //         "O. Sales Payout (Expected) (RM)": `${tableDataDetails[i].outletPayout.toFixed(2)}`,
  //         "Settlement Date": tableDataDetails[i].settlementDate,
  //         "Payment Method": tableDataDetails[i].pdChannel,
  //       };

  //       excelData.push(excelRow);
  //     }
  //   }

  //   console.log("excelData");
  //   console.log(excelData);

  //   return excelData;
  // };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    if (!showDetails) {
      let tableDataRow = null;
      if (currExpandedRow && currExpandedRow.outletId) {
        tableDataRow = tableDataSummary.find(findRow => findRow.outletId === currExpandedRow.outletId);
      }

      if (tableDataRow) {
        const transactionList = tableDataRow.razerPayoutTransactionList.concat(tableDataRow.recentPayoutTransactionList);

        for (var i = 0; i < transactionList.length; i++) {
          const transaction = transactionList[i];

          // const koodooProfitActual = transaction.outletCycleKoodooPayoutsActual !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsActual.toFixed(2)}` : ''}
          // {moment(transaction.settlementDate).add(-1, 'day').isSame(moment(), 'day') ?
          //   ((typeof calculatedTodayGMVActualOutletIdDict[item.outletId] === 'number') ? `RM ${calculatedTodayGMVActualOutletIdDict[item.outletId].toFixed(2)}` : 'RM 0.00')
          //   : '';

          var excelRow = {
            "Merchant Name": tableDataRow.outletName,
            "Settlement Date": `${transaction.settlementDate ? moment(transaction.settlementDate).format('YYYY-MM-DD hh:mm A') : 'N/A'}`,
            "Prev. Funds (RM)": `${transaction.prevStockUpAmount !== undefined ? `RM ${transaction.prevStockUpAmount.toFixed(2)}` : 'N/A'}`,
            "Prev. Overdue (RM)": `${transaction.prevOverdueAmount !== undefined ? `RM ${transaction.prevOverdueAmount.toFixed(2)}` : 'N/A'}`,
            "Prev. Pending (RM)": `${'N/A'}`,
            "Curr. Funds (RM)": `${transaction.stockUpAmount !== undefined ? `RM ${transaction.stockUpAmount.toFixed(2)}` : 'N/A'}`,
            "Curr. Overdue (RM)": `${transaction.outletCycleMerchantOverdueAmounts !== undefined ? `RM ${transaction.outletCycleMerchantOverdueAmounts.toFixed(2)}` : 'N/A'}`,
            "Curr. Pending (RM)": `${'N/A'}`,
            "KooDoo Profit (Actual) (RM)": `${transaction.outletCycleKoodooPayoutsActual !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsActual.toFixed(2)}` : ''}`,
            "O. Sales Payout (Actual) (RM)": `${transaction.outletCycleMerchantPayoutsActual !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsActual.toFixed(2)}` : 'N/A'}`,
            "KooDoo Profit (Expected) (RM)": `${transaction.outletCycleKoodooPayoutsExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsExpected.toFixed(2)}` : 'N/A'}`,
            "O. Sales Payout (Expected) (RM)": `${transaction.outletCycleMerchantPayoutsExpected !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsExpected.toFixed(2)}` : 'N/A'}`,
            "MDR Fee (RM)": `${transaction.outletCycleRazerPayouts !== undefined ? `RM ${transaction.outletCycleRazerPayouts.toFixed(2)}` : 'N/A'}`,

            ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
              "Earned MDR (RM)": `${transaction.outletCycleKoodooMDREarned !== undefined ? `RM ${transaction.outletCycleKoodooMDREarned.toFixed(2)}` : 'N/A'}`,
            },

            "KooDoo Profit (Online) (RM)": `${transaction.outletCycleKoodooPayoutsOnlineOnlyExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsOnlineOnlyExpected.toFixed(2)}` : 'N/A'}`,
            "O. Sales Payout Fee (RM)": `${transaction.payoutFee !== undefined ? `RM ${transaction.payoutFee.toFixed(2)}` : 'N/A'}`,
          };

          excelData.push(excelRow);
        }
      }
      else {
        for (var i = 0; i < tableDataSummary.length; i++) {
          var excelRow = {
            "Merchant Name": tableDataSummary[i].outletName,
            "QR Orders Online (RM)": `${tableDataSummary[i].userAppAndWebDineInOnlineOrdersAmount.toFixed(2)}`,
            "QR Orders Online (Qty)": `${tableDataSummary[i].userAppAndWebDineInOnlineOrdersQty.toFixed(0)}`,
            "QR Orders Offline (RM)": `${tableDataSummary[i].userAppAndWebDineInOrdersAmount.toFixed(2)}`,
            "QR Orders Offline (Qty)": `${tableDataSummary[i].userAppAndWebDineInOrdersQty.toFixed(0)}`,
            "POS Orders Online (RM)": `${tableDataSummary[i].merchantAppOnlineOrdersAmount.toFixed(2)}`,
            "POS Orders Online (Qty)": `${tableDataSummary[i].merchantAppOnlineOrdersQty.toFixed(0)}`,
            "POS Orders Offline (RM)": `${tableDataSummary[i].merchantAppOrdersAmount.toFixed(2)}`,
            "POS Orders Offline (Qty)": `${tableDataSummary[i].merchantAppOrdersQty.toFixed(0)}`,
            "Waiter Orders (RM)": `${tableDataSummary[i].waiterAppOrdersAmount.toFixed(2)}`,
            "Waiter Orders (Qty)": `${tableDataSummary[i].waiterAppOrdersQty.toFixed(0)}`,
            "Takeaway Orders (RM)": `${tableDataSummary[i].userAppAndWebTakeawayOrdersAmount.toFixed(2)}`,
            "Takeaway Orders (Qty)": `${tableDataSummary[i].userAppAndWebTakeawayOrdersQty.toFixed(0)}`,
            "Total Orders (RM)": `${tableDataSummary[i].totalOrdersAmount.toFixed(2)}`,
            "Total Orders (Qty)": `${tableDataSummary[i].totalOrdersQty.toFixed(0)}`,
            "KooDoo Profit (Actual) (RM)": `${tableDataSummary[i].gmvCommissionActual.toFixed(2)}`,
            "O. Sales Payout (Actual) (RM)": `${tableDataSummary[i].gmvPayoutActual.toFixed(2)}`,
            "KooDoo Profit (Excpected) (RM)": `${tableDataSummary[i].gmvCommission.toFixed(2)}`,
            "O. Sales Payout (Expected) (RM)": `${tableDataSummary[i].gmvPayout.toFixed(2)}`,
            "MDR Fee (RM)": `${tableDataSummary[i].gmvFees.toFixed(2)}`,

            ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
              "Earned MDR (RM)": `${tableDataSummary[i].mdrEarned.toFixed(2)}`,
            },

            "Last Order": tableDataSummary[i].lastOrderPlacedDateTime ? moment(tableDataSummary[i].lastOrderPlacedDateTime).format('YYYY-MM-DD hh:mm A') : 'N/A',
          };

          excelData.push(excelRow);
        }
      }
    } else {
      for (var i = 0; i < tableDataDetails.length; i++) {
        var excelRow = {
          "Date Time": moment(tableDataDetails[i].dateTime).format("DD MMM hh:mm A"),
          "Order Id(Joined)": tableDataDetails[i].orderId,
          "QR Order": tableDataDetails[i].isQrOrder,
          "Total Amount (RM)": `${tableDataDetails[i].totalAmount.toFixed(2)}`,
          "Razer Charges (RM)": tableDataDetails[i].pdChannel !== ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX ? `${tableDataDetails[i].razerCharges.toFixed(2)}` : `0.00`,
          "RHB Charges (RM)": tableDataDetails[i].pdChannel === ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX ? `${tableDataDetails[i].razerCharges.toFixed(2)}` : `0.00`,

          ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
            "Earned MDR (RM)": `${tableDataDetails[i].cfe.toFixed(2)}`,
          },

          "KooDoo Profit (Expected) (RM)": `${tableDataDetails[i].koodooCharges.toFixed(2)}`,
          "O. Sales Payout (Expected) (RM)": `${tableDataDetails[i].outletPayout.toFixed(2)}`,
          "Settlement Date": tableDataDetails[i].settlementDate,
          "Payment Method": tableDataDetails[i].pdChannel,
        };

        excelData.push(excelRow);
      }
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  const convertDataToExcelFormatRhb = async () => {
    const diffDays = moment(ptEndDate).diff(ptStartDate, 'day') + 1;

    var excelData = [];

    if (!showDetails) {
      for (var index = 0; index < tableDataSummary.length; index++) {
        const tableDataRow = tableDataSummary[index];

        // const currRazerOutletBankInfo = razerOutletBankInfoList.find(obj => obj.outleTId === tableDataRow.outletId);

        if (tableDataRow.totalOrdersAmount > 0) {
          const transactionList = tableDataRow.razerPayoutTransactionList
            .concat(tableDataRow.recentPayoutTransactionList)
            .filter(rpt => rpt.active);

          let transaction = tableDataRow.razerPayoutTransactionList
            .concat(tableDataRow.recentPayoutTransactionList).find(obj => obj.bankCode);
          if (transaction) {

          }
          else {
            transaction = tableDataRow.razerPayoutTransactionList
              .concat(tableDataRow.recentPayoutTransactionList)[0];
          }

          const bankName = BANK_CODE_DROPDOWN_LIST.find(bankCodeObj => bankCodeObj.value === transaction.bankCode) ?
            BANK_CODE_DROPDOWN_LIST.find(bankCodeObj => bankCodeObj.value === transaction.bankCode).label :
            '';
          const bankAccountNo = transaction.bankAccountNumber ? transaction.bankAccountNumber : '';
          const midTid = (transaction.mid || transaction.tid) ? `${transaction.mid ? transaction.mid : transaction.tid}` : '';

          // const dailyTransactionVolume = tableDataRow.totalOrdersAmount / transactionList.length;
          const dailyTransactionVolume = tableDataRow.merchantAppWoCashAmount / transactionList.length;

          // const dnqrRatio = tableDataRow.merchantAppOnlineOrdersAmount / tableDataRow.totalOrdersAmount;

          const { value1: value1Amt, value2: value2Amt } = simplifyToSingleDigitRatio(tableDataRow.merchantAppWoCashAmount, tableDataRow.totalOrdersAmount - tableDataRow.merchantAppWoCashAmount);
          // const { value1: value1Qty, value2: value2Qty } = simplifyToSingleDigitRatio(tableDataRow.merchantAppWoCashOrdersQty, tableDataRow.totalOrdersQty - tableDataRow.merchantAppWoCashOrdersQty);

          //////////////////////////////////////////////////////////

          // 2024-01-09 - get RazerOutletBankInfo

          // const razerOutletBankInfoSnapshot = await firebase.firestore()
          //   .collection(Collections.RazerOutletBankInfo)
          //   .where('outletId', '==', transaction.outletId)
          //   .limit(1)
          //   .get();

          // let razerOutletBankInfo = null;
          // if (razerOutletBankInfoSnapshot && !razerOutletBankInfoSnapshot.empty) {
          //   razerOutletBankInfo = razerOutletBankInfoSnapshot.docs[0].data();
          // }

          //////////////////////////////////////////////////////////

          var excelRow = {
            "Merchant Name": tableDataRow.outletName,
            "Bank Name": bankName,
            "Bank Account No": bankAccountNo,
            "MID/TID": midTid,
            "Daily Transaction Volume (MYR)": `${BigNumber(dailyTransactionVolume).toFixed(2)}`,
            "Current MDR Rate": `2.4%`,
            "Proposed MDR Rate": ``,
            "DNQR Ratio (DNQR : Others)": `${BigNumber(value1Amt).toFixed(1)} : ${BigNumber(value2Amt).toFixed(1)}`,
            "(DNQR : Others)": `${BigNumber(tableDataRow.merchantAppWoCashAmount).toFixed(2)} : ${BigNumber(tableDataRow.totalOrdersAmount - tableDataRow.merchantAppWoCashAmount).toFixed(2)}`,
            // "DNQR Ratio (DNQR : Others) (Qty)": `${BigNumber(value1Qty).toFixed(1)} : ${BigNumber(value2Qty).toFixed(1)}`,

            // "Settlement Date": `${transaction.settlementDate ? moment(transaction.settlementDate).format('YYYY-MM-DD hh:mm A') : 'N/A'}`,
            // "Prev. Funds (RM)": `${transaction.prevStockUpAmount !== undefined ? `RM ${transaction.prevStockUpAmount.toFixed(2)}` : 'N/A'}`,
            // "Prev. Overdue (RM)": `${transaction.prevOverdueAmount !== undefined ? `RM ${transaction.prevOverdueAmount.toFixed(2)}` : 'N/A'}`,
            // "Prev. Pending (RM)": `${'N/A'}`,
            // "Curr. Funds (RM)": `${transaction.stockUpAmount !== undefined ? `RM ${transaction.stockUpAmount.toFixed(2)}` : 'N/A'}`,
            // "Curr. Overdue (RM)": `${transaction.outletCycleMerchantOverdueAmounts !== undefined ? `RM ${transaction.outletCycleMerchantOverdueAmounts.toFixed(2)}` : 'N/A'}`,
            // "Curr. Pending (RM)": `${'N/A'}`,
            // "KooDoo Profit (Actual) (RM)": `${transaction.outletCycleKoodooPayoutsActual !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsActual.toFixed(2)}` : ''}`,
            // "O. Sales Payout (Actual) (RM)": `${transaction.outletCycleMerchantPayoutsActual !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsActual.toFixed(2)}` : 'N/A'}`,
            // "KooDoo Profit (Expected) (RM)": `${transaction.outletCycleKoodooPayoutsExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsExpected.toFixed(2)}` : 'N/A'}`,
            // "O. Sales Payout (Expected) (RM)": `${transaction.outletCycleMerchantPayoutsExpected !== undefined ? `RM ${transaction.outletCycleMerchantPayoutsExpected.toFixed(2)}` : 'N/A'}`,
            // "MDR Fee (RM)": `${transaction.outletCycleRazerPayouts !== undefined ? `RM ${transaction.outletCycleRazerPayouts.toFixed(2)}` : 'N/A'}`,

            // ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
            //   "Earned MDR (RM)": `${transaction.outletCycleKoodooMDREarned !== undefined ? `RM ${transaction.outletCycleKoodooMDREarned.toFixed(2)}` : 'N/A'}`,
            // },

            // "KooDoo Profit (Online) (RM)": `${transaction.outletCycleKoodooPayoutsOnlineOnlyExpected !== undefined ? `RM ${transaction.outletCycleKoodooPayoutsOnlineOnlyExpected.toFixed(2)}` : 'N/A'}`,
            // "O. Sales Payout Fee (RM)": `${transaction.payoutFee !== undefined ? `RM ${transaction.payoutFee.toFixed(2)}` : 'N/A'}`,

            // "Merchant Name": tableDataSummary[i].outletName,
            // "QR Orders Online (RM)": `${tableDataSummary[i].userAppAndWebDineInOnlineOrdersAmount.toFixed(2)}`,
            // "QR Orders Online (Qty)": `${tableDataSummary[i].userAppAndWebDineInOnlineOrdersQty.toFixed(0)}`,
            // "QR Orders Offline (RM)": `${tableDataSummary[i].userAppAndWebDineInOrdersAmount.toFixed(2)}`,
            // "QR Orders Offline (Qty)": `${tableDataSummary[i].userAppAndWebDineInOrdersQty.toFixed(0)}`,
            // "POS Orders (RM)": `${tableDataSummary[i].merchantAppOrdersAmount.toFixed(2)}`,
            // "POS Orders (Qty)": `${tableDataSummary[i].merchantAppOrdersQty.toFixed(0)}`,
            // "Waiter Orders (RM)": `${tableDataSummary[i].waiterAppOrdersAmount.toFixed(2)}`,
            // "Waiter Orders (Qty)": `${tableDataSummary[i].waiterAppOrdersQty.toFixed(0)}`,
            // "Takeaway Orders (RM)": `${tableDataSummary[i].userAppAndWebTakeawayOrdersAmount.toFixed(2)}`,
            // "Takeaway Orders (Qty)": `${tableDataSummary[i].userAppAndWebTakeawayOrdersQty.toFixed(0)}`,
            // "Total Orders (RM)": `${tableDataSummary[i].totalOrdersAmount.toFixed(2)}`,
            // "Total Orders (Qty)": `${tableDataSummary[i].totalOrdersQty.toFixed(0)}`,
            // "KooDoo Profit (Actual) (RM)": `${tableDataSummary[i].gmvCommissionActual.toFixed(2)}`,
            // "O. Sales Payout (Actual) (RM)": `${tableDataSummary[i].gmvPayoutActual.toFixed(2)}`,
            // "KooDoo Profit (Excpected) (RM)": `${tableDataSummary[i].gmvCommission.toFixed(2)}`,
            // "O. Sales Payout (Expected) (RM)": `${tableDataSummary[i].gmvPayout.toFixed(2)}`,
            // "MDR Fee (RM)": `${tableDataSummary[i].gmvFees.toFixed(2)}`,

            // ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
            //   "Earned MDR (RM)": `${tableDataSummary[i].mdrEarned.toFixed(2)}`,
            // },

            // "Last Order": tableDataSummary[i].lastOrderPlacedDateTime ? moment(tableDataSummary[i].lastOrderPlacedDateTime).format('YYYY-MM-DD hh:mm A') : 'N/A',
          };

          excelData.push(excelRow);
        }
      }
    } else {
      for (var i = 0; i < tableDataDetails.length; i++) {
        var excelRow = {
          "Date Time": moment(tableDataDetails[i].dateTime).format("DD MMM hh:mm A"),
          "Order Id(Joined)": tableDataDetails[i].orderId,
          "QR Order": tableDataDetails[i].isQrOrder,
          "Total Amount (RM)": `${tableDataDetails[i].totalAmount.toFixed(2)}`,
          "Razer Charges (RM)": tableDataDetails[i].pdChannel !== ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX ? `${tableDataDetails[i].razerCharges.toFixed(2)}` : `0.00`,
          "RHB Charges (RM)": tableDataDetails[i].pdChannel === ONLINE_PAYMENT_METHOD_TYPE.RHB_REFLEX ? `${tableDataDetails[i].razerCharges.toFixed(2)}` : `0.00`,

          ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
            "Earned MDR (RM)": `${tableDataDetails[i].cfe.toFixed(2)}`,
          },

          "KooDoo Profit (Expected) (RM)": `${tableDataDetails[i].koodooCharges.toFixed(2)}`,
          "O. Sales Payout (Expected) (RM)": `${tableDataDetails[i].outletPayout.toFixed(2)}`,
          "Settlement Date": tableDataDetails[i].settlementDate,
          "Payment Method": tableDataDetails[i].pdChannel,
        };

        excelData.push(excelRow);
      }
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  const handleExportExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(convertDataToExcelFormat());

    XLSX.utils.book_append_sheet(wb, ws, "koodoo-payout-report");
    XLSX.writeFile(wb, `koodoo_payout_report-${moment(ptStartDate).format("YYYY-MM-DD")}-${moment(ptEndDate).format("YYYY-MM-DD")}.xlsx`);
  };

  const handleExportExcelRhb = async () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(await convertDataToExcelFormatRhb());

    XLSX.utils.book_append_sheet(wb, ws, "koodoo-payout-rhb-report");
    XLSX.writeFile(wb, `koodoo_payout_rhb_report-${moment(ptStartDate).format("YYYY-MM-DD")}-${moment(ptEndDate).format("YYYY-MM-DD")}.xlsx`);
  };

  ///////////////////////////////////////////////

  // 2024-03-19 - for best seller items export

  const convertDataToExcelFormatBs = () => {
    var excelData = [];

    if (!showDetailsBs) {
      for (var i = 0; i < tableDataSummaryBs.length; i++) {
        var excelRow = {
          "Product Name": global.outletItemDict[tableDataSummaryBs[i].id] ? global.outletItemDict[tableDataSummaryBs[i].id].name : 'N/A',
          "Qty": `${tableDataSummaryBs[i].qty.toFixed(0)}`,
          "Sales (RM)": `${tableDataSummaryBs[i].amt.toFixed(2)}`,
        };

        excelData.push(excelRow);
      }
    } else {
      for (var i = 0; i < tableDataDetails.length; i++) {
        var excelRow = {

        };

        excelData.push(excelRow);
      }
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  const handleExportExcelBs = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(convertDataToExcelFormatBs());

    XLSX.utils.book_append_sheet(wb, ws, "koodoo-best-sellers-report");
    XLSX.writeFile(wb, `koodoo_best_sellers_report-${moment(ptStartDate).format("YYYY-MM-DD")}-${moment(ptEndDate).format("YYYY-MM-DD")}.xlsx`);
  };

  ///////////////////////////////////////////////

  const emailVariant = () => {
    const excelData = convertDataToExcelFormat();

    var body = {
      // data: CsvData,
      //data: convertArrayToCSV(todaySalesChart.dataSource.data),
      data: JSON.stringify(excelData),
      //data: convertDataToExcelFormat(),
      email: exportEmail,
    };

    ApiClient.POST(API.emailDashboard, body, false).then((result) => {
      if (result !== null) {
        Alert.alert(
          "Success",
          "Email has been sent",
          [{ text: "OK", onPress: () => { } }],
          { cancelable: false }
        );
      }
    });

    setVisible(false);
  };

  ///////////////////////////////////

  // 2024-03-19 - best seller table changes

  const renderItemBs = ({ item, index }) => {
    return (
      <TouchableOpacity
        disabled={true}
        onPress={() => {
          // setShowDetailsBs(true);
          // setTableDataDetailsBs(item.detailsList);

          // setShowDetailsOutletId(item.outletId);
        }}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          paddingHorizontal: 3,
          paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "2%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "30%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {global.outletItemDict[item.id] ? (
              !item.ao
                ?
                global.outletItemDict[item.id].name
                :
                `${item.name.replace(item.id, global.outletItemDict[item.id].name)}`
            ) : 'N/A'}
          </Text>

          {console.log('item')}
          {console.log(item)}

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`${item.qty.toFixed(0)}`}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`${item.amt.toFixed(2)}`}
          </Text>

          {/* ///////////////////// */}

          <>
            {
              item.items.length > 0
                ?
                <TouchableOpacity
                  style={{
                    width: "5%",
                    // fontSize: switchMerchant ? 10 : 13,
                    // fontFamily: "NunitoSans-Regular",
                    // textAlign: "left",
                    paddingLeft: '1.5%',

                    // backgroundColor: 'red',
                  }}
                  onPress={() => {
                    setExpandedSummaryRowBs({
                      ...expandedSummaryRowBs,
                      [item.id]: expandedSummaryRowBs[item.id] ? false : true,
                    });
                  }}
                >
                  {
                    !expandedSummaryRowBs[item.id]
                      ?
                      <Icon
                        name="chevron-up"
                        size={15}
                        color={Colors.primaryColor}
                      // style={{ marginLeft: 15 }}
                      />
                      :
                      <Icon
                        name="chevron-down"
                        size={15}
                        color={Colors.primaryColor}
                      // style={{ marginLeft: 15 }}
                      />
                  }
                </TouchableOpacity>
                :
                <Text
                  style={{
                    width: "5%",
                    fontSize: switchMerchant ? 10 : 13,
                    fontFamily: "NunitoSans-Regular",
                    textAlign: "left",
                    // paddingLeft: 10,
                    paddingLeft: '1.5%',
                  }}
                >
                  {`N/A`}
                </Text>
            }
          </>
        </View>

        {expandedSummaryRowBs[item.id] == true ? (
          <View
            style={{
              minHeight: windowHeight * 0.35,
              // marginTop: 20,
              paddingBottom: 20,

              // backgroundColor: 'red',
            }}>

            <View style={{ marginTop: 10, flexDirection: "row" }}>
              <View
                style={{
                  flexDirection: "row",
                  width: "2%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: 10,
                }}
              >
                <View
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"No.\n\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", opacity: 0 }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "30%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Product\nName\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Qty"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Sales\n(RM)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Pct\n(%)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <View style={{
              marginTop: 10,
            }}>

            </View>
            {
              item.items.map((transaction, transactionIndex) => {
                return (
                  <View
                    // onPress={() => {
                    //   setShowDetails(true);
                    //   setTableDataDetails(item.detailsList);

                    //   setShowDetailsOutletId(item.outletId);
                    // }}
                    style={{
                      backgroundColor:
                        (transactionIndex + 1) % 2 == 0 ? Colors.lightGrey : '#d1d1d1',
                      paddingVertical: 10,
                      paddingHorizontal: 3,
                      paddingLeft: 1,
                      borderColor: "#BDBDBD",
                      borderTopWidth: (transactionIndex + 1) % 2 == 0 ? 0 : 0.5,
                      borderBottomWidth: (transactionIndex + 1) % 2 == 0 ? 0 : 0.5,
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          width: "2%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transactionIndex + 1}
                      </Text>
                      <Text
                        dataTransactionId={transaction.uniqueId}
                        style={{
                          width: "30%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,

                          // fontSize: 8,
                        }}
                        dataSet={{
                          uniqueId: transaction.uniqueId,
                        }}
                      >
                        {global.outletItemDict[transaction.id] ? global.outletItemDict[transaction.id].name : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.qty !== undefined ? `${transaction.qty.toFixed(0)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.amt !== undefined ? `${transaction.amt.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.pct !== undefined ? `${transaction.pct.toFixed(1)}` : 'N/A'}
                      </Text>
                    </View>
                  </View>
                );
              })
            }
          </View>
        ) : null}
      </TouchableOpacity>
    );
  };

  ///////////////////////////////////

  // 2024-08-11 - ar overview table changes

  // 2024-03-19 - best seller table changes

  const renderItemAo = ({ item, index }) => {
    // let arUpselling = item[]

    return (
      <View
        disabled={true}
        onPress={() => {
          // setShowDetailsBs(true);
          // setTableDataDetailsBs(item.detailsList);

          // setShowDetailsOutletId(item.outletId);
        }}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          paddingHorizontal: 3,
          paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "2%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "30%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.name}
          </Text>

          {/* {console.log('item')}
          {console.log(item)} */}

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`${item.qty.toFixed(0)}`}
          </Text>

          <Text
            style={{
              width: "7%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {`${item.amt.toFixed(2)}`}
          </Text>

          {/* ///////////////////// */}

          <>
            {
              item.items.length > 0
                ?
                <TouchableOpacity
                  style={{
                    width: "5%",
                    // fontSize: switchMerchant ? 10 : 13,
                    // fontFamily: "NunitoSans-Regular",
                    // textAlign: "left",
                    paddingLeft: '1.5%',

                    // backgroundColor: 'red',
                  }}
                  onPress={() => {
                    setExpandedSummaryRowBs({
                      ...expandedSummaryRowBs,
                      [item.id]: expandedSummaryRowBs[item.id] ? false : true,
                    });
                  }}
                >
                  {
                    !expandedSummaryRowBs[item.id]
                      ?
                      <Icon
                        name="chevron-up"
                        size={15}
                        color={Colors.primaryColor}
                      // style={{ marginLeft: 15 }}
                      />
                      :
                      <Icon
                        name="chevron-down"
                        size={15}
                        color={Colors.primaryColor}
                      // style={{ marginLeft: 15 }}
                      />
                  }
                </TouchableOpacity>
                :
                <Text
                  style={{
                    width: "5%",
                    fontSize: switchMerchant ? 10 : 13,
                    fontFamily: "NunitoSans-Regular",
                    textAlign: "left",
                    // paddingLeft: 10,
                    paddingLeft: '1.5%',
                  }}
                >
                  {`N/A`}
                </Text>
            }
          </>
        </View>

        {expandedSummaryRowBs[item.id] == true ? (
          <View
            style={{
              minHeight: windowHeight * 0.35,
              // marginTop: 20,
              paddingBottom: 20,

              // backgroundColor: 'red',
            }}>

            <View style={{ marginTop: 10, flexDirection: "row" }}>
              <View
                style={{
                  flexDirection: "row",
                  width: "2%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: 10,
                }}
              >
                <View
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"No.\n\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", opacity: 0 }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "30%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Product\nName\n"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Qty"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Sales\n(RM)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "7%",
                  borderRightWidth: 1,
                  borderRightColor: "lightgrey",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: 'center' }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        numberOfLines={3}
                        style={{
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.darkBgColor,
                        }}
                      >
                        {"Pct\n(%)"}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 8 : 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                    <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: Colors.descriptionColor,
                        }}
                      ></Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <View style={{
              marginTop: 10,
            }}>

            </View>
            {
              item.items.map((transaction, transactionIndex) => {
                return (
                  <View
                    // onPress={() => {
                    //   setShowDetails(true);
                    //   setTableDataDetails(item.detailsList);

                    //   setShowDetailsOutletId(item.outletId);
                    // }}
                    style={{
                      backgroundColor:
                        (transactionIndex + 1) % 2 == 0 ? Colors.lightGrey : '#d1d1d1',
                      paddingVertical: 10,
                      paddingHorizontal: 3,
                      paddingLeft: 1,
                      borderColor: "#BDBDBD",
                      borderTopWidth: (transactionIndex + 1) % 2 == 0 ? 0 : 0.5,
                      borderBottomWidth: (transactionIndex + 1) % 2 == 0 ? 0 : 0.5,
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          width: "2%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transactionIndex + 1}
                      </Text>
                      <Text
                        dataTransactionId={transaction.uniqueId}
                        style={{
                          width: "30%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,

                          // fontSize: 8,
                        }}
                        dataSet={{
                          uniqueId: transaction.uniqueId,
                        }}
                      >
                        {global.outletItemDict[transaction.id] ? global.outletItemDict[transaction.id].name : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.qty !== undefined ? `${transaction.qty.toFixed(0)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.amt !== undefined ? `${transaction.amt.toFixed(2)}` : 'N/A'}
                      </Text>
                      <Text
                        style={{
                          width: "7%",
                          fontSize: switchMerchant ? 10 : 13,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "left",
                          paddingLeft: 10,
                          color: Colors.darkBgColor,
                        }}
                      >
                        {transaction.pct !== undefined ? `${transaction.pct.toFixed(1)}` : 'N/A'}
                      </Text>
                    </View>
                  </View>
                );
              })
            }
          </View>
        ) : null}
      </View>
    );
  };

  ///////////////////////////////////

  const flatListRef = useRef();

  const filterItem = (item) => {
    if (search !== "") {
      if ((item.outletName ? item.outletName : '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else if ((item.outletName ? item.outletName : '').toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const filterItemDetails = (item) => {
    if (search !== "") {
      if ((item.companyName ? item.companyName : '').toLowerCase().includes(search.toLowerCase())) {
        return true;
        //   } else if (
        //     moment(item.actionDate, "x")
        //       .format("DD MMM YYY hh:mma")
        //       .toLowerCase()
        //       .includes(search.toLowerCase())
        //   ) {
        //     return true;
      } else {
        return false;
      }
    }
    // else {
    //   // check if there is data between the dates
    //   return moment(item.actionDate, "x").isBetween(ptStartDate, ptEndDate);
    // }
    return true;
  };

  // sort func
  const sortingAlphabetically = (a, b, compareName) => {
    if (a[compareName] < b[compareName]) {
      return -1;
    }
    if (a[compareName] > b[compareName]) {
      return 1;
    }
    return 0;
  };
  const sortingAlphabeticallyDesc = (a, b, compareName) => {
    if (a[compareName] < b[compareName]) {
      return 1;
    }
    if (a[compareName] > b[compareName]) {
      return -1;
    }
    return 0;
  };

  const sortingAlphabeticallySafe = (a, b, compareName) => {
    let aC = a[compareName] !== undefined ? (a[compareName] ? a[compareName] : -1) : -2;
    let bC = b[compareName] !== undefined ? (b[compareName] ? b[compareName] : -1) : -2;

    if (aC < bC) {
      return -1;
    }
    if (aC > bC) {
      return 1;
    }
    return 0;
  };
  const sortingAlphabeticallySafeDesc = (a, b, compareName) => {
    let aC = a[compareName] !== undefined ? (a[compareName] ? a[compareName] : -1) : -2;
    let bC = b[compareName] !== undefined ? (b[compareName] ? b[compareName] : -1) : -2;

    if (aC < bC) {
      return 1;
    }
    if (aC > bC) {
      return -1;
    }
    return 0;
  };

  const sortByName = (a, b, compareName) => {
    const aName = a[compareName] ? a[compareName] : 'N/A';
    const bName = b[compareName] ? b[compareName] : 'N/A';

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };

  const sortByNameDesc = (a, b, compareName) => {
    const aName = a[compareName] ? a[compareName] : 'N/A';
    const bName = b[compareName] ? b[compareName] : 'N/A';

    if (aName < bName) {
      return 1;
    }
    if (aName > bName) {
      return -1;
    }
    return 0;
  };

  const sortingAlphabeticallyProductName = (a, b, compareName) => {
    const aName = global.outletItemDict[a.id] ? global.outletItemDict[a.id].name : 'N/A';
    const bName = global.outletItemDict[b.id] ? global.outletItemDict[b.id].name : 'N/A';

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };
  const sortingAlphabeticallyProductNameDesc = (a, b, compareName) => {
    const aName = global.outletItemDict[a.id] ? global.outletItemDict[a.id].name : 'N/A';
    const bName = global.outletItemDict[b.id] ? global.outletItemDict[b.id].name : 'N/A';

    if (bName < bName) {
      return 1;
    }
    if (bName > bName) {
      return -1;
    }
    return 0;
  };

  const sortingAlphabeticallyCustomGmv = (a, b) => {
    const aValue = BigNumber(a['gmvCommissionActual']).plus(
      ((typeof calculatedTodayGMVActualOutletIdDict[a.outletId] === 'number') ? calculatedTodayGMVActualOutletIdDict[a.outletId] : 0)
    ).toNumber();

    const bValue = BigNumber(b['gmvCommissionActual']).plus(
      ((typeof calculatedTodayGMVActualOutletIdDict[b.outletId] === 'number') ? calculatedTodayGMVActualOutletIdDict[b.outletId] : 0)
    ).toNumber();

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };
  const sortingAlphabeticallyCustomGmvDesc = (a, b) => {
    const aValue = BigNumber(a['gmvCommissionActual']).plus(
      ((typeof calculatedTodayGMVActualOutletIdDict[a.outletId] === 'number') ? calculatedTodayGMVActualOutletIdDict[a.outletId] : 0)
    ).toNumber();

    const bValue = BigNumber(b['gmvCommissionActual']).plus(
      ((typeof calculatedTodayGMVActualOutletIdDict[b.outletId] === 'number') ? calculatedTodayGMVActualOutletIdDict[b.outletId] : 0)
    ).toNumber();

    if (aValue < bValue) {
      return 1;
    }
    if (aValue > bValue) {
      return -1;
    }
    return 0;
  };

  // sort by date
  const sortByCreatedDate = (a, b) =>
    moment(a["createdAt"]).valueOf() - moment(b["createdAt"]).valueOf();
  const sortByCreatedDateDesc = (a, b) =>
    moment(b["createdAt"]).valueOf() - moment(a["createdAt"]).valueOf();

  const sortByStartDate = (a, b) =>
    moment(a["createdAt"]).valueOf() - moment(b["createdAt"]).valueOf();
  const sortByStartDateDesc = (a, b) =>
    moment(b["createdAt"]).valueOf() - moment(a["createdAt"]).valueOf();

  const sortByCompName = (a, b) => {
    let compareName = "companyName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompNameDesc = (a, b) => {
    let compareName = "companyName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByCompRegistration = (a, b) => {
    let compareName = "companyRegistrationNo";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompRegistrationDesc = (a, b) => {
    let compareName = "companyRegistrationNo";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByCompAddr = (a, b) => {
    let compareName = "companyAddress";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCompAddrDesc = (a, b) => {
    let compareName = "companyAddress";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByDirectorName = (a, b) => {
    let compareName = "directorName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByDirectorNameDesc = (a, b) => {
    let compareName = "directorName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByMerchantEmail = (a, b) => {
    let compareName = "merchantEmail";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantEmailDesc = (a, b) => {
    let compareName = "merchantEmail";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByMerchantName = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantNameDesc = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOutletName = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOutletNameDesc = (a, b) => {
    let compareName = "outletName";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByOnboarderEmail = (a, b) => {
    let compareName = "onboarderEmail";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOnboarderEmailDesc = (a, b) => {
    let compareName = "onboarderEmail";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };
  const sortByLastActivityDate = (a, b) =>
    moment(a["lastActivity"]).valueOf() - moment(b["lastActivity"]).valueOf();
  const sortByLastActivityDateDesc = (a, b) =>
    moment(b["lastActivity"]).valueOf() - moment(a["lastActivity"]).valueOf();

  //////////////////////////////////////////////////////////////

  const sortByUserAppAndWebDineInOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDineInOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDineInOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDineInOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebDineInOnlineOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDineInOnlineOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDineInOnlineOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDineInOnlineOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByMerchantAppOnlineOrdersAmount = (a, b) => {
    let compareName = "merchantAppOnlineOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantAppOnlineOrdersAmountDesc = (a, b) => {
    let compareName = "merchantAppOnlineOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByMerchantAppOrdersAmount = (a, b) => {
    let compareName = "merchantAppOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByMerchantAppOrdersAmountDesc = (a, b) => {
    let compareName = "merchantAppOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByWaiterAppOrdersAmount = (a, b) => {
    let compareName = "waiterAppOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByWaiterAppOrdersAmountDesc = (a, b) => {
    let compareName = "waiterAppOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebTakeawayOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebTakeawayOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebTakeawayOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebTakeawayOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByUserAppAndWebDeliveryOrdersAmount = (a, b) => {
    let compareName = "userAppAndWebDeliveryOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByUserAppAndWebDeliveryOrdersAmountDesc = (a, b) => {
    let compareName = "userAppAndWebDeliveryOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTotalOrdersAmount = (a, b) => {
    let compareName = "totalOrdersAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTotalOrdersAmountDesc = (a, b) => {
    // let compareName = "totalOrdersAmount";
    return sortingAlphabeticallyDesc(a, b, 'totalOrdersAmount');
  };

  const sortByGmvCommission = (a, b) => {
    let compareName = "gmvCommission";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvCommissionDesc = (a, b) => {
    let compareName = "gmvCommission";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvFees = (a, b) => {
    let compareName = "gmvFees";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvFeesDesc = (a, b) => {
    let compareName = "gmvFees";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvPayout = (a, b) => {
    let compareName = "gmvPayout";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvPayoutDesc = (a, b) => {
    let compareName = "gmvPayout";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvCommissionActual = (a, b) => {
    let compareName = "gmvCommissionActual";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvCommissionActualDesc = (a, b) => {
    let compareName = "gmvCommissionActual";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByGmvPayoutActual = (a, b) => {
    let compareName = "gmvPayoutActual";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByGmvPayoutActualDesc = (a, b) => {
    let compareName = "gmvPayoutActual";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByLastOrderPlacedDateTime = (a, b) => {
    let compareName = "lastOrderPlacedDateTime";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByLastOrderPlacedDateTimeDesc = (a, b) => {
    let compareName = "lastOrderPlacedDateTime";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  // for details

  const sortByDateTime = (a, b) => {
    let compareName = "dateTime";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByDateTimeDesc = (a, b) => {
    let compareName = "dateTime";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOrderId = (a, b) => {
    let compareName = "orderId";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOrderIdDesc = (a, b) => {
    let compareName = "orderId";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  // const sortByOrderId = (a, b) => {
  //   let compareName = "orderId";
  //   return sortingAlphabetically(a, b, compareName);
  // };
  // const sortByOrderIdDesc = (a, b) => {
  //   let compareName = "orderId";
  //   return sortingAlphabeticallyDesc(a, b, compareName);
  // };

  const sortByIsQrOrder = (a, b) => {
    let compareName = "isQrOrder";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByIsQrOrderDesc = (a, b) => {
    let compareName = "isQrOrder";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByNetAmount = (a, b) => {
    let compareName = "netAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByNetAmountDesc = (a, b) => {
    let compareName = "netAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTax = (a, b) => {
    let compareName = "tax";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTaxDesc = (a, b) => {
    let compareName = "tax";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortBySc = (a, b) => {
    let compareName = "sc";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByScDesc = (a, b) => {
    let compareName = "sc";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByTotalAmount = (a, b) => {
    let compareName = "totalAmount";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByTotalAmountDesc = (a, b) => {
    let compareName = "totalAmount";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByRazerCharges = (a, b) => {
    let compareName = "razerCharges";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByRazerChargesDesc = (a, b) => {
    let compareName = "razerCharges";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByCfOd = (a, b) => {
    let compareName = "cfOd";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByCfOdDesc = (a, b) => {
    let compareName = "cfOd";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByKpfOd = (a, b) => {
    let compareName = "kpfOd";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByKpfOdDesc = (a, b) => {
    let compareName = "kpfOd";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByEarnedMdr = (a, b) => {
    let compareName = "cfe";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByEarnedMdrDesc = (a, b) => {
    let compareName = "cfe";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByKoodooCharges = (a, b) => {
    let compareName = "koodooCharges";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByKoodooChargesDesc = (a, b) => {
    let compareName = "koodooCharges";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByOutletPayout = (a, b) => {
    let compareName = "outletPayout";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByOutletPayoutDesc = (a, b) => {
    let compareName = "outletPayout";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortBySettlementDate = (a, b) => {
    let compareName = "settlementDate";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortBySettlementDateDesc = (a, b) => {
    let compareName = "settlementDate";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByPaymentMethod = (a, b) => {
    let compareName = "pdChannel";
    return sortingAlphabetically(a, b, compareName);
  };
  const sortByPaymentMethodDesc = (a, b) => {
    let compareName = "pdChannel";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  // 2024-03-19 - for best seller table

  const sortByProductName = (a, b) => {
    let compareName = "productName";
    return sortingAlphabeticallyProductName(a, b, compareName);
  };

  const sortByProductNameDesc = (a, b) => {
    let compareName = "productName";
    return sortingAlphabeticallyProductNameDesc(a, b, compareName);
  };

  const sortByQty = (a, b) => {
    let compareName = "qty";
    return sortingAlphabetically(a, b, compareName);
  };

  const sortByQtyDesc = (a, b) => {
    let compareName = "qty";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByAmt = (a, b) => {
    let compareName = "amt";
    return sortingAlphabetically(a, b, compareName);
  };

  const sortByAmtDesc = (a, b) => {
    let compareName = "amt";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByPct = (a, b) => {
    let compareName = "pct";
    return sortingAlphabetically(a, b, compareName);
  };

  const sortByPctDesc = (a, b) => {
    let compareName = "pct";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  // 2024-08-11 - Overview AR

  const sortByARUpselling = (a, b) => {
    let compareName = "arUpselling";
    return sortingAlphabetically(a, b, compareName);
  };

  const sortByARUpsellingDesc = (a, b) => {
    let compareName = "arUpselling";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByARLoyalty = (a, b) => {
    let compareName = "arLoyalty";
    return sortingAlphabetically(a, b, compareName);
  };

  const sortByARLoyaltyDesc = (a, b) => {
    let compareName = "arLoyalty";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByARPromotion = (a, b) => {
    let compareName = "arPromotion";
    return sortingAlphabetically(a, b, compareName);
  };

  const sortByARPromotionDesc = (a, b) => {
    let compareName = "arPromotion";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  const sortByARCredit = (a, b) => {
    let compareName = "arCredit";
    return sortingAlphabetically(a, b, compareName);
  };

  const sortByARCreditDesc = (a, b) => {
    let compareName = "arCredit";
    return sortingAlphabeticallyDesc(a, b, compareName);
  };

  //////////////////////////////////////////////////////////////

  return (
    <View
      style={[styles.container, { height: windowHeight, width: windowWidth, backgroundColor: Colors.lightPrimary, }]}
    >
      {/* <View
        style={[
          styles.sidebar,
          switchMerchant
            ? {
                // width: '10%'
              }
            : {},
        ]}
      >
        <SideBar
          navigation={props.navigation}
          selectedTab={8}
          expandReport={true}
        />
      </View> */}

      {/* <ScrollView horizontal={false}>
        
      </ScrollView> */}

      <TouchableWithoutFeedback onPress={() => {
        console.log('focus');

        // rootFocusRef && rootFocusRef.current && rootFocusRef.current.focus();

        var rootFocusRef = document.getElementById('rootFocusRef');

        if (rootFocusRef) {
          rootFocusRef.focus();
        }
      }} style={{
        width: '100%',
        height: '100%',

        backgroundColor: Colors.lightPrimary,
      }}>
        <ScrollView
          id={'rootFocusRef'}
          contentContainerStyle={{
            // flex: 1,

            backgroundColor: Colors.highlightColor,
            paddingVertical: 20,
            paddingBottom: windowHeight * 0.05,

            backgroundColor: Colors.lightPrimary,
          }}
          horizontal={false}

          showsVerticalScrollIndicator={false}
        >
          {/* <TextInput style={{
            opacity: 0,
            width: 0,
            height: 0,
          }} ref={rootFocusRef}>

          </TextInput> */}

          {/* <ScrollView horizontal={true} nestedScrollEnabled={true}>
          
        </ScrollView> */}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "space-between",
              paddingHorizontal: 18,
              marginTop: 5,
              width: "100%",
            }}
          >
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 20 : 26,
                  fontFamily: "NunitoSans-Bold",

                  ...isMobile() && {
                    fontSize: 16,
                  },
                }}
              >
                KooDoo GMV Profit
              </Text>

              {/* <Text
                style={{
                  color: Colors.primaryColor,
                  fontSize: switchMerchant ? 18 : 24,
                  fontFamily: 'NunitoSans-Bold',

                  marginLeft: 20,
                  marginTop: 2,
                }}>
                {
                  `GMV (Payable): RM ${calculatedGmvActual.toFixed(2)}, Active Merchants: ${calculatedActiveMerchants.toFixed(0)}`
                }
              </Text> */}
            </View>

            <View
              style={{
                flexDirection: "row",
              }}
            >
              {/* <TouchableOpacity
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    //width: 140,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                    marginRight: 10,
                  }}
                  onPress={() => {
                    setExportModalVisibility(true);
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon
                      name="download"
                      size={switchMerchant ? 10 : 20}
                      color={Colors.whiteColor}
                    />
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      DOWNLOAD
                    </Text>
                  </View>
                </TouchableOpacity> */}

              {/* <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 5,
                    paddingHorizontal: 15,
                    height: switchMerchant ? 35 : 39,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    marginTop: 1,
                    paddingTop: 1,

                    marginRight: 15,
                  }}
                  onPress={() => {
                    navigation.navigate('KooDoo Statistics');
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {`Agreements`}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 5,
                    paddingHorizontal: 15,
                    height: switchMerchant ? 35 : 39,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    marginTop: 1,
                    paddingTop: 1,

                    marginRight: 15,
                  }}
                  onPress={() => {
                    navigation.navigate('Merchants - KooDoo Statistics');
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {`Merchants`}
                  </Text>
                </TouchableOpacity> */}

              <View
                style={[
                  {
                    height: switchMerchant ? 35 : 40,

                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 5,
                    paddingHorizontal: isMobile() ? 10 : 15,
                    height: switchMerchant ? 35 : 40,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    paddingTop: 1,

                    marginRight: 10,
                  }}
                  onPress={() => {
                    navigation.navigate('KooDoo Settlements - KooDoo Statistics');
                  }}>
                  {
                    isMobile()
                      ?
                      <Icon
                        name="list"
                        size={15}
                        color={Colors.whiteColor}
                      // style={{ marginLeft: 15 }}
                      />
                      :
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: 'NunitoSans-Bold',
                        }}>
                        {`SETTLEMENTS`}
                      </Text>
                  }
                </TouchableOpacity>

                <View
                  style={{
                    width: switchMerchant ? 200 : 250,
                    height: switchMerchant ? 35 : 40,
                    backgroundColor: "white",
                    borderRadius: 5,
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",

                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                    borderWidth: 1,
                    borderColor: "#E5E5E5",

                    ...isMobile() && {
                      width: 120,

                      marginRight: 5,
                    },
                  }}
                >
                  <Icon
                    name="search"
                    size={switchMerchant ? 13 : 18}
                    color={Colors.primaryColor}
                    style={{ marginLeft: 15 }}
                  />
                  <TextInput
                    editable={!loading}
                    underlineColorAndroid={Colors.whiteColor}
                    style={{
                      width: switchMerchant ? 180 : 220,
                      fontSize: switchMerchant ? 10 : 15,
                      fontFamily: "NunitoSans-Regular",
                      paddingLeft: 5,
                      height: 45,
                    }}
                    placeholderTextColor={Platform.select({
                      ios: "#a9a9a9",
                    })}
                    clearButtonMode="while-editing"
                    placeholder=" Search"
                    onChangeText={(text) => {
                      setSearch(text);
                    }}
                    value={search}
                  />
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              // flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "center",
              justifyContent: "space-between",
              paddingHorizontal: 18,
              marginTop: 15,
              width: "100%",

              flexDirection: 'row',

              zIndex: 99999,

              ...isMobile() && {
                flexDirection: 'column',
                alignItems: 'flex-end',
                paddingRight: 0,

                paddingBottom: 1,
              },
            }}
          >
            <View style={{
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "center",
              justifyContent: "space-between",

              ...isMobile() && {
                width: '100%',
              },
            }}>
              <Text
                style={{
                  color: Colors.primaryColor,
                  fontSize: isMobile() ? 16 : 22,
                  fontFamily: 'NunitoSans-SemiBold',

                  // marginLeft: 20,
                  // marginTop: 2,
                }}>
                {
                  `KooDoo Profit (Actual): RM ${calculatedGmvActual.toFixed(2)}`
                }
              </Text>

              {
                (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
                  ?
                  <Text
                    style={{
                      color: Colors.primaryColor,
                      fontSize: isMobile() ? 16 : 22,
                      fontFamily: 'NunitoSans-SemiBold',

                      // marginLeft: 20,
                      // marginTop: 2,
                    }}>
                    {
                      `KooDoo MDR (Earned): RM ${calculatedMdrEarned.toFixed(2)}`
                    }
                  </Text>
                  :
                  <></>
              }

              <Text
                style={{
                  color: Colors.primaryColor,
                  fontSize: isMobile() ? 16 : 22,
                  fontFamily: 'NunitoSans-SemiBold',

                  // marginLeft: 20,
                  marginTop: 2,

                  marginTop: 5,
                }}>
                {
                  `Active Merchants: ${calculatedActiveMerchants.toFixed(0)}`
                }
              </Text>
            </View>

            <View style={{
              flexDirection: "row",

              ...isMobile() && {
                flexDirection: 'column',

                justifyContent: 'flex-end',
                // backgroundColor: 'blue',

                marginTop: 15,
              },
            }}>
              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  paddingHorizontal: 10,
                  height: 40,
                  alignItems: "center",
                  shadowOpacity: 0,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  marginRight: 10,

                  top: 1,

                  ...isMobile() && {
                    marginRight: 30,
                    marginBottom: 15,

                    // marginRight: 10,
                  },
                }}
                onPress={() => {
                  // throw new Error('Test exception!');

                  setExportModalVisibility(true);
                }}
              >
                <View
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Icon
                    name="download"
                    size={20}
                    color={Colors.whiteColor}
                  />
                  {
                    isMobile()
                      ?
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        DOWNLOAD
                      </Text>
                      :
                      <></>
                  }
                </View>
              </TouchableOpacity>

              <View
                style={[
                  {
                    paddingHorizontal: 15,
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 10,
                    paddingVertical: 10,
                    justifyContent: "center",
                    backgroundColor: Colors.whiteColor,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,

                    marginRight: 10,

                    ...isMobile() && {
                      marginRight: 30,
                      // marginBottom: 10,
                    },

                    zIndex: 100,
                  },
                ]}
              >
                <View
                  style={{
                    alignSelf: "center",
                    // marginRight: 5,
                    style: '10%'
                  }}
                  onPress={() => {
                    setState({
                      pickerMode: "date",
                      showDateTimePicker: true,
                    });
                  }}
                >
                  <GCalendar
                    width={switchMerchant ? 15 : 20}
                    height={switchMerchant ? 15 : 20}
                  />
                </View>

                <View style={{
                  width: '5%',
                }}></View>

                <View style={{
                  width: '40%',
                }}>
                  <DatePicker
                    // selected={ptStartDate.toDate()}
                    selected={moment(ptStartDate).toDate()}
                    onChange={(date) => {
                      // setRev_date(moment(date).startOf('day'));

                      setClickedBarChartDate(null);
                      setClickedBarChartDateUnit(null);

                      setMerchantDataFilterType(MERCHANT_DATA_FILTER.ALL);

                      CommonStore.update(s => {
                        s.ptStartDate = moment(date).startOf("day").valueOf();
                      });
                    }}
                    maxDate={moment(ptEndDate).toDate()}
                  />
                </View>

                <View style={{
                  width: '5%',
                }}>
                  <Icon
                    name="minus"
                    size={10}
                    color={Colors.blackColor}
                    style={{
                      transform: 'translate(-1px, 0)',
                    }}
                  />
                </View>

                <View style={{
                  width: '40%',
                }}>
                  <DatePicker
                    // selected={ptEndDate.toDate()}
                    selected={moment(ptEndDate).toDate()}
                    onChange={(dateParam) => {
                      // setRev_date1(moment(date).endOf('day'));

                      setClickedBarChartDate(null);
                      setClickedBarChartDateUnit(null);

                      setMerchantDataFilterType(MERCHANT_DATA_FILTER.ALL);

                      var date = moment(dateParam).endOf("day").valueOf();
                      if (moment(date).isSame(window.currToDateTime, 'day')) {
                        date = window.currToDateTime;
                      }

                      CommonStore.update(s => {
                        // s.ptEndDate = moment(date).endOf("day").valueOf();
                        s.ptEndDate = moment(date).valueOf();
                      });
                    }}
                    minDate={moment(ptStartDate).toDate()}
                  />
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 8,
                  width: 220,
                  backgroundColor: "white",

                  // marginLeft: 15,
                  top: 1,

                  zIndex: gmMultiSelectZIndex,

                  ...isMobile() && {
                    marginLeft: 0,

                    width: 250,
                    marginRight: 30,

                    marginTop: 10,
                  },
                }}
              >
                {
                  tabOutletDropdownList.find(item => item.value === selectedTabOutletId)
                    ?
                    <MultiSelect
                      clearable={false}
                      singleSelect={true}
                      defaultValue={selectedTabOutletId}
                      placeholder={"Select Outlet"}
                      onChange={(value) => {
                        if (value) { // if choose the same option again, value = ''
                          setSelectedTabOutletId(value);
                        }
                      }}
                      onMenuOpen={() => {
                        // here can set the zindex

                        setGmMultiSelectZIndex(51000);
                      }}
                      onMenuClose={() => {
                        // here can set the zindex

                        setGmMultiSelectZIndex(99);
                      }}
                      options={tabOutletDropdownList}
                      className="msl-varsHEADER"
                    />
                    :
                    <></>
                }
              </View>
            </View>
          </View>

          <View
            style={{
              backgroundColor: Colors.lightPrimary,

              flexDirection: isMobile() ? 'column-reverse' : "row",
              //backgroundColor: '#ffffff',
              justifyContent: isMobile() ? "flex-end" : "space-between",

              alignItems: 'center',

              //padding: 18,
              marginTop: 20,
              width: "100%",

              marginBottom: -5,

              ...isMobile() && {
                alignItems: 'flex-end',

                marginTop: 0,
              },

              ...!isMobile() && {
                paddingLeft: switchMerchant
                  ? 0
                  : windowWidth <= 1823 && windowWidth >= 1820
                    ? "1.5%"
                    : "1%",
                paddingRight: switchMerchant
                  ? 0
                  : windowWidth <= 1823 && windowWidth >= 1820
                    ? "1.5%"
                    : "1%",
              },
            }}
          >

            <ScrollView
              nativeID={'tabButtons'}
              horizontal={isMobile() ? true : true}
              nestedScrollEnabled={true}
              style={{
                width: '100%',
                // display: 'flex',
                // flexDirection: 'row',
                // alignItems: 'center',

                // marginTop: 30,
                // padding: 20,
                paddingBottom: 0,
                zIndex: -50,

                // backgroundColor: 'red',

                marginTop: 5,

                marginRight: 15,

                ...isMobile() && {
                  // marginTop: 50,
                  // width: '200%',

                  marginRight: 30,

                  marginTop: 0,
                },
              }}
              contentContainerStyle={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',

                paddingBottom: 5,
                paddingRight: 5,

                ...isMobile() && {
                  // alignItems: 'flex-end',
                  // flexDirection: 'column',

                  alignItems: 'center',

                  paddingRight: 0,

                  paddingLeft: 50,
                },

                // flexWrap: 'wrap',
              }}
              showsHorizontalScrollIndicator={isMobile() ? false : true}
            >
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.DAILY_ACTIVE_MERCHANTS ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.DAILY_ACTIVE_MERCHANTS ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  // borderTopLeftRadius: 0,
                  // borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.DAILY_ACTIVE_MERCHANTS);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.DAILY_ACTIVE_MERCHANTS ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Daily Active Merchants`}
                </Text>
              </TouchableOpacity>

              {/* <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_ACTUAL ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_ACTUAL ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.KOODOO_PROFIT_ACTUAL);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_ACTUAL ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`KooDoo Profit (Actual)`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_QR_ONLINE ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_QR_ONLINE ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.KOODOO_PROFIT_QR_ONLINE);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_QR_ONLINE ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`KooDoo Profit (QR Online)`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_TOTAL ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_TOTAL ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.KOODOO_PROFIT_TOTAL);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_TOTAL ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`KooDoo Profit (Total)`}
                </Text>
              </TouchableOpacity> */}

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_EXPECTED_ACTUAL_PROFIT ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.KOODOO_EXPECTED_ACTUAL_PROFIT ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.KOODOO_EXPECTED_ACTUAL_PROFIT);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.KOODOO_EXPECTED_ACTUAL_PROFIT ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`GMV Profit`}
                </Text>
              </TouchableOpacity>

              {
                (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
                  ?
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: selectedPayoutTabs === PAYOUT_TABS.MDR ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: selectedPayoutTabs === PAYOUT_TABS.MDR ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      // marginRight: 15,
                      marginRight: 1,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,

                      ...isMobile() && {
                        marginRight: 0,

                        marginTop: 10,
                      },
                    }}
                    onPress={() => {
                      // setClickedBarChartDate(null);
                      // setClickedBarChartDateUnit(null);

                      setSelectedPayoutTabs(PAYOUT_TABS.MDR);

                      // CommonStore.update(s => {
                      //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                      //   // s.ptEndDate = moment().endOf('day').valueOf();
                      //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                      // });
                    }}>
                    <Text
                      style={{
                        color: selectedPayoutTabs === PAYOUT_TABS.MDR ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`MDR`}
                    </Text>
                  </TouchableOpacity>
                  :
                  <></>
              }

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.COMPARISON ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.COMPARISON ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.COMPARISON);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.COMPARISON ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Comparison`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.AOV ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.AOV ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.AOV);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.AOV ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Additional Revenue`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.BEST_SELLERS);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Best Sellers`}
                </Text>
              </TouchableOpacity>

              {/* <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.AR_OVERVIEW ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.AR_OVERVIEW ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.AR_OVERVIEW);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.AR_OVERVIEW ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`AR Overview`}
                </Text>
              </TouchableOpacity> */}

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedPayoutTabs === PAYOUT_TABS.NEW_CUSTOMER ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedPayoutTabs === PAYOUT_TABS.NEW_CUSTOMER ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  // marginRight: 15,
                  marginRight: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  // borderTopRightRadius: 0,
                  // borderBottomRightRadius: 0,

                  ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com' || userEmail === 'izhar.azizol@mykoodoo.com' || userEmail === 'haziq.mochtar@mykoodoo.com' || userEmail === 'syahid.kz@mykoodoo.com') && {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },

                  ...isMobile() && {
                    marginRight: 0,

                    marginTop: 10,
                  },
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedPayoutTabs(PAYOUT_TABS.NEW_CUSTOMER);

                  // CommonStore.update(s => {
                  //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                  //   // s.ptEndDate = moment().endOf('day').valueOf();
                  //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                  // });
                }}>
                <Text
                  style={{
                    color: selectedPayoutTabs === PAYOUT_TABS.NEW_CUSTOMER ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`New Customer`}
                </Text>
              </TouchableOpacity>

              {
                (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com' || userEmail === 'izhar.azizol@mykoodoo.com' || userEmail === 'haziq.mochtar@mykoodoo.com' || userEmail === 'syahid.kz@mykoodoo.com')
                  ?
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: selectedPayoutTabs === PAYOUT_TABS.AOV_UPSELLING ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: selectedPayoutTabs === PAYOUT_TABS.AOV_UPSELLING ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      // marginRight: 15,
                      marginRight: 1,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      // borderTopRightRadius: 0,
                      // borderBottomRightRadius: 0,

                      ...(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') && {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },

                      ...isMobile() && {
                        marginRight: 0,

                        marginTop: 10,
                      },
                    }}
                    onPress={() => {
                      // setClickedBarChartDate(null);
                      // setClickedBarChartDateUnit(null);

                      setSelectedPayoutTabs(PAYOUT_TABS.AOV_UPSELLING);

                      // CommonStore.update(s => {
                      //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                      //   // s.ptEndDate = moment().endOf('day').valueOf();
                      //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                      // });
                    }}>
                    <Text
                      style={{
                        color: selectedPayoutTabs === PAYOUT_TABS.AOV_UPSELLING ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`Upselling AOV`}
                    </Text>
                  </TouchableOpacity>
                  :
                  <></>
              }

              {
                (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
                  ?
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: selectedPayoutTabs === PAYOUT_TABS.UPSELLING ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: selectedPayoutTabs === PAYOUT_TABS.UPSELLING ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      // marginRight: 15,
                      marginRight: 1,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      // borderTopRightRadius: 0,
                      // borderBottomRightRadius: 0,

                      ...isMobile() && {
                        marginRight: 0,

                        marginTop: 10,
                      },
                    }}
                    onPress={() => {
                      // setClickedBarChartDate(null);
                      // setClickedBarChartDateUnit(null);

                      setSelectedPayoutTabs(PAYOUT_TABS.UPSELLING);

                      // CommonStore.update(s => {
                      //   s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                      //   // s.ptEndDate = moment().endOf('day').valueOf();
                      //   s.ptEndDate = moment(window.currToDateTime).valueOf();
                      // });
                    }}>
                    <Text
                      style={{
                        color: selectedPayoutTabs === PAYOUT_TABS.UPSELLING ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`Upselling Sales`}
                    </Text>
                  </TouchableOpacity>
                  :
                  <></>
              }
            </ScrollView>


          </View>

          <View
            style={{
              // backgroundColor: 'red',

              zIndex: -1,

              marginTop: 5,
              marginBottom: -15,

              padding: 20,
              // paddingHorizontal: '2%',
              paddingBottom: 0,
            }}
          >
            {
              selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_ACTUAL
                ?
                <>
                  <FusionCharts
                    {...{
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      // height: windowHeight * 0.5,
                      type: CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES]
                        .type, //msline
                      dataFormat: salesLineChart.dataFormat, //json
                      dataSource: salesLineChart.dataSource,
                      events: {
                        'dataPlotClick': function (ev, props) {
                          // var infoElem = document.getElementById("infolbl");
                          console.log(ev);
                          console.log(props);

                          // setClickedChartDate(props.id);
                        },
                      }
                    }}
                  />
                </>
                :
                <></>
            }

            {/* 2023-02-12 - Online QR sales line chart */}

            {/* {
              selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_QR_ONLINE
                ?
                <>
                  <View
                    style={{
                      // backgroundColor: 'red',

                      zIndex: -1,

                      // marginTop: 5,
                      marginBottom: -15,

                      // padding: 20,
                      // paddingTop: 10,

                      // paddingHorizontal: '2%',
                      paddingBottom: 10,
                    }}
                  >
                    <FusionCharts
                      {...{
                        width: "100%",
                        // width: windowWidth *
                        //   (0.84 - Styles.sideBarWidth),
                        height: windowHeight * 0.9,
                        ...isMobile() && {
                          // height: windowHeight * 0.9,
                          height: windowHeight * 1.25,
                        },
                        type: CHART_DATA[CHART_TYPE.REPORT_ONLINE_QR_SALES]
                          .type, //msline
                        dataFormat: onlineQrSalesChart.dataFormat, //json
                        dataSource: onlineQrSalesChart.dataSource,
                        events: {
                          'dataPlotClick': function (ev, props) {
                            // var infoElem = document.getElementById("infolbl");
                            console.log(ev);
                            console.log(props);

                            if (props && props.id) {
                              // var clickedBarChartDateTemp = props.id.split('|')[0];
                              // var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                              // setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                              // setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);

                              var clickedOutletId = props.id;

                              // if (changedBoldTimestamp !== clickedOutletId) {
                              //   setChangedBoldTimestamp(clickedOutletId);

                              //   window.boldOutletQrSalesLineDict[clickedOutletId] = !window.boldOutletQrSalesLineDict[clickedOutletId];
                              // }

                              // setChangedBoldTimestamp(Date.now());

                              // setBoldOutletQrSalesLineDict({
                              //   ...boldOutletQrSalesLineDict,
                              //   [clickedOutletId]: !boldOutletQrSalesLineDict[clickedOutletId],
                              // });
                            }

                            // setClickedChartDate(props.id);
                          },
                        }
                      }}
                    />
                  </View>
                </>
                :
                <></>
            } */}

            {/* {
              selectedPayoutTabs === PAYOUT_TABS.KOODOO_PROFIT_TOTAL
                ?
                <View
                  style={{
                    // backgroundColor: 'red',

                    zIndex: -1,

                    // marginTop: 5,
                    marginBottom: -15,

                    // padding: 20,
                    // paddingTop: 10,

                    // paddingHorizontal: '2%',
                    paddingBottom: 10,
                  }}
                >
                  <FusionCharts
                    {...{
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      // height: windowHeight * 0.5,
                      height: windowHeight * 0.8,
                      ...isMobile() && {
                        // height: windowHeight * 0.9,
                        height: windowHeight * 1.2,
                      },
                      type: CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES]
                        .type, //msline
                      dataFormat: productSalesChart.dataFormat, //json
                      dataSource: productSalesChart.dataSource,
                      events: {
                        'dataPlotClick': function (ev, props) {
                          // var infoElem = document.getElementById("infolbl");
                          console.log(ev);
                          console.log(props);

                          if (props && props.id) {
                            var clickedBarChartDateTemp = props.id.split('|')[0];
                            var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                            setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                            setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);
                          }

                          // setClickedChartDate(props.id);
                        },
                      }
                    }}
                  />
                </View>
                :
                <></>
            } */}

            {/* 2023-02-12 - Online QR sales line chart */}

            {
              selectedPayoutTabs === PAYOUT_TABS.KOODOO_EXPECTED_ACTUAL_PROFIT
                ?
                <>
                  <View
                    style={{
                      // backgroundColor: 'red',

                      zIndex: -1,

                      // marginTop: 5,
                      marginBottom: -15,

                      // padding: 20,
                      // paddingTop: 10,

                      // paddingHorizontal: '2%',
                      paddingBottom: 10,
                    }}
                  >
                    <FusionCharts
                      {...{
                        width: "100%",
                        // width: windowWidth *
                        //   (0.84 - Styles.sideBarWidth),
                        height: windowHeight * 0.9,
                        ...isMobile() && {
                          // height: windowHeight * 0.9,
                          height: windowHeight * 1.25,
                        },
                        type: CHART_DATA[CHART_TYPE.REPORT_EXPECTED_ACTUAL_PROFIT]
                          .type, //msline
                        dataFormat: expectedActualProfitChart.dataFormat, //json
                        dataSource: expectedActualProfitChart.dataSource,
                        events: {
                          'dataPlotClick': function (ev, props) {
                            // var infoElem = document.getElementById("infolbl");
                            console.log(ev);
                            console.log(props);

                            if (props && props.id) {
                              // var clickedBarChartDateTemp = props.id.split('|')[0];
                              // var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                              // setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                              // setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);

                              var clickedOutletId = props.id;

                              // if (changedBoldTimestamp !== clickedOutletId) {
                              //   setChangedBoldTimestamp(clickedOutletId);

                              //   window.boldOutletQrSalesLineDict[clickedOutletId] = !window.boldOutletQrSalesLineDict[clickedOutletId];
                              // }

                              // setChangedBoldTimestamp(Date.now());

                              // setBoldOutletQrSalesLineDict({
                              //   ...boldOutletQrSalesLineDict,
                              //   [clickedOutletId]: !boldOutletQrSalesLineDict[clickedOutletId],
                              // });
                            }

                            // setClickedChartDate(props.id);
                          },
                        }
                      }}
                    />
                  </View>

                  <View
                    // horizontal={true}
                    // nestedScrollEnabled={true}
                    style={{
                      width: '100%',
                      // display: 'flex',
                      // flexDirection: 'row',
                      // alignItems: 'center',

                      // marginTop: 30,
                      padding: 20,
                      paddingHorizontal: 0,
                      paddingBottom: 0,
                      // zIndex: -50,

                      zIndex: 10000,

                      // backgroundColor: 'red',

                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',

                      paddingBottom: 5,
                      paddingRight: 5,

                      ...isMobile() && {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      },
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 8,
                        // width: 150,
                        backgroundColor: "white",

                        // marginLeft: 15,
                        top: 1,

                        zIndex: 50000,

                        paddingHorizontal: 15,
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 10,
                        paddingVertical: 8.5,
                        justifyContent: "center",
                        backgroundColor: Colors.whiteColor,
                        shadowOpacity: 0,
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,

                        ...isMobile() && {
                          marginBottom: 15,
                        },
                      }}
                    >
                      <View
                        style={{
                          alignSelf: "center",
                          // marginRight: 5,
                          style: '10%'
                        }}
                        onPress={() => {
                          setState({
                            pickerMode: "date",
                            showDateTimePicker: true,
                          });
                        }}
                      >
                        <GCalendar
                          width={switchMerchant ? 15 : 20}
                          height={switchMerchant ? 15 : 20}
                        />
                      </View>

                      <View style={{
                        width: '5%',
                      }}></View>

                      <View style={{
                        width: '40%',
                      }}>
                        <DatePicker
                          // selected={ptStartDate.toDate()}
                          selected={moment(rptDsStartDate).toDate()}
                          onChange={(date) => {
                            CommonStore.update(s => {
                              s.rptDsStartDate = moment(date).startOf("day").valueOf();
                            });
                          }}
                          maxDate={moment(rptDsEndDate).toDate()}
                        />
                      </View>

                      <View style={{
                        width: '5%',
                      }}>
                        <Icon
                          name="minus"
                          size={10}
                          color={Colors.blackColor}
                          style={{
                            transform: 'translate(-1px, 0)',
                          }}
                        />
                      </View>

                      <View style={{
                        width: '40%',
                      }}>
                        <DatePicker
                          // selected={ptEndDate.toDate()}
                          selected={moment(rptDsEndDate).toDate()}
                          onChange={(dateParam) => {
                            var date = moment(dateParam).endOf("day").valueOf();

                            CommonStore.update(s => {
                              s.rptDsEndDate = moment(date).valueOf();
                            });
                          }}
                          minDate={moment(rptDsStartDate).toDate()}
                        />
                      </View>

                    </View>


                  </View>
                </>
                :
                <></>
            }
          </View>

          {/* <View style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

            marginTop: 50,
            marginBottom: 10,

            padding: 20,
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: -50,

            // backgroundColor: 'red',
          }}>
            <Text
              style={{
                color: Colors.primaryColor,
                fontSize: switchMerchant ? 10 : 16,
                fontFamily: 'NunitoSans-Bold',
              }}>
              {`QR Orders Online (Sales): RM ${tableDataSummary.reduce((accum, row) => accum + row.userAppAndWebDineInOnlineOrdersAmount, 0).toFixed(2)} (${tableDataSummary.reduce((accum, row) => accum + row.userAppAndWebDineInOnlineOrdersQty, 0).toFixed(0)})`}
            </Text>

            <Text
              style={{
                color: Colors.primaryColor,
                fontSize: switchMerchant ? 10 : 16,
                fontFamily: 'NunitoSans-Bold',
              }}>
              {`QR Orders Online (Commission): RM ${tableDataSummary.reduce((accum, row) =>
                BigNumber(accum).plus(row.detailsList.reduce((accumDetails, rowDetails) => {
                  if (rowDetails.razerCharges > 0 && rowDetails.koodooCharges > 0) {
                    return BigNumber(accumDetails).plus(rowDetails.koodooCharges).toNumber();
                  }
                  else {
                    return accumDetails;
                  }
                }, 0)).toNumber()
                , 0).toFixed(2)} (${tableDataSummary.reduce((accum, row) => accum + row.userAppAndWebDineInOnlineOrdersQty, 0).toFixed(0)})`}
            </Text>

            <Text
              style={{
                color: Colors.primaryColor,
                fontSize: switchMerchant ? 10 : 16,
                fontFamily: 'NunitoSans-Bold',
              }}>
              {`GMV Commission (Payable): RM ${tableDataSummary.reduce((accum, row) => accum + BigNumber(row.gmvCommissionActual).toNumber(), 0).toFixed(2)}`}
            </Text>

          </View> */}

          {
            selectedPayoutTabs === PAYOUT_TABS.DAILY_ACTIVE_MERCHANTS
              ?
              <>
                <ScrollView
                  horizontal={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    zIndex: -50,

                    // backgroundColor: 'red',
                  }}
                  contentContainerStyle={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,
                  }}
                  showsHorizontalScrollIndicator={false}
                >
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      marginRight: 15,
                    }}
                    onPress={() => {
                      setClickedBarChartDate(null);
                      setClickedBarChartDateUnit(null);

                      setMerchantDataFilterType(MERCHANT_DATA_FILTER.ALL);

                      CommonStore.update(s => {
                        s.ptStartDate = moment().subtract(12, "month").startOf("day").valueOf();
                        // s.ptEndDate = moment().endOf('day').valueOf();
                        s.ptEndDate = moment(window.currToDateTime).valueOf();
                      });
                    }}>
                    <Text
                      style={{
                        color: merchantDataFilterType === MERCHANT_DATA_FILTER.ALL ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`All (${merchantDataAllLength} in total${merchantDataAllLength > 1 ? 's' : ''})`}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      marginRight: 15,
                    }}
                    onPress={() => {
                      setClickedBarChartDate(null);
                      setClickedBarChartDateUnit(null);

                      setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_24H);

                      CommonStore.update(s => {
                        s.ptStartDate = moment().startOf('day').valueOf();
                        // s.ptEndDate = moment().endOf('day').valueOf();
                        s.ptEndDate = moment(window.currToDateTime).valueOf();
                      });
                    }}>
                    <Text
                      style={{
                        color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_24H ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`Today (${merchantData24HLength} active${merchantData24HLength > 1 ? 's' : ''})`}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      marginRight: 15,
                    }}
                    onPress={() => {
                      setClickedBarChartDate(null);
                      setClickedBarChartDateUnit(null);

                      setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_7D);

                      CommonStore.update(s => {
                        s.ptStartDate = moment().subtract(7, "day").startOf('day').valueOf();
                        // s.ptEndDate = moment().endOf('day').valueOf();
                        s.ptEndDate = moment(window.currToDateTime).valueOf();
                      });
                    }}>
                    <Text
                      style={{
                        color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_7D ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`Last 7d (${merchantData7DLength} active${merchantData7DLength > 1 ? 's' : ''})`}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      marginRight: 15,
                    }}
                    onPress={() => {
                      setClickedBarChartDate(null);
                      setClickedBarChartDateUnit(null);

                      setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_1M);

                      CommonStore.update(s => {
                        s.ptStartDate = moment().subtract(1, "month").startOf('day').valueOf();
                        // s.ptEndDate = moment().endOf('day').valueOf();
                        s.ptEndDate = moment(window.currToDateTime).valueOf();
                      });
                    }}>
                    <Text
                      style={{
                        color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_1M ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`Last 1m (${merchantData1MLength} active${merchantData1MLength > 1 ? 's' : ''})`}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.primaryColor : Colors.whiteColor,
                      backgroundColor: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.primaryColor : Colors.whiteColor,
                      borderRadius: 5,
                      paddingHorizontal: 15,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,

                      paddingTop: 1,

                      marginRight: 15,
                    }}
                    onPress={() => {
                      setClickedBarChartDate(null);
                      setClickedBarChartDateUnit(null);

                      setMerchantDataFilterType(MERCHANT_DATA_FILTER.ACTIVE_3M);

                      CommonStore.update(s => {
                        s.ptStartDate = moment().subtract(3, "month").startOf('day').valueOf();
                        // s.ptEndDate = moment().endOf('day').valueOf();
                        s.ptEndDate = moment(window.currToDateTime).valueOf();
                      });
                    }}>
                    <Text
                      style={{
                        color: merchantDataFilterType === MERCHANT_DATA_FILTER.ACTIVE_3M ? Colors.whiteColor : Colors.primaryColor,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {`Last 3m (${merchantData3MLength} active${merchantData3MLength > 1 ? 's' : ''})`}
                    </Text>
                  </TouchableOpacity>

                  {
                    (clickedBarChartDate !== null && clickedBarChartDateUnit !== null)
                      ?
                      <Text
                        style={{
                          color: Colors.primaryColor,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: 'NunitoSans-Bold',
                        }}>
                        {
                          clickedBarChartDateUnit === 'hour'
                            ?
                            `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM-DD, hh:mm A')} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                            :
                            ``
                        }

                        {
                          clickedBarChartDateUnit === 'day'
                            ?
                            `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM-DD')} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                            :
                            ``
                        }

                        {
                          clickedBarChartDateUnit === 'week'
                            ?
                            `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM')} W${countWeekdayOccurrencesInMonth(clickedBarChartDate)} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                            :
                            ``
                        }

                        {
                          clickedBarChartDateUnit === 'month'
                            ?
                            `Chart selection applied for ${moment(clickedBarChartDate).format('YYYY-MM')} (${clickedBarChartDateUnit}), click any date filter/range selection to reset`
                            :
                            ``
                        }
                      </Text>
                      :
                      <></>
                  }
                </ScrollView>

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",
                    }}
                  >
                    <MultiSelect
                      clearable={false}
                      singleSelect={true}
                      defaultValue={selectedPaymentType}
                      placeholder={"Select Type"}
                      onChange={(value) => {
                        if (value) { // if choose the same option again, value = ''
                          setSelectedPaymentType(value);
                        }
                      }}
                      options={PAYMENT_TYPE_DROPDOWN_LIST}
                      className="msl-varsHEADER"
                    />
                  </View>

                  <TouchableOpacity
                    style={[
                      {
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,

                        opacity: !showDetails ? 0 : 100,
                        // opacity: 100,

                        ...isMobile() && {
                          marginLeft: 30,
                        },

                        marginLeft: 15,
                      },
                    ]}
                    onPress={() => {
                      setShowDetails(false);
                      setPageCount(
                        Math.ceil(tableDataSummary.length / perPage)
                      );
                      setCurrentPage(pageReturn);

                      setShowDetailsOutletId('');

                      console.log("Returning to page");
                      console.log(pageReturn);
                    }}
                    disabled={!showDetails}
                  >
                    <AntDesign
                      name="arrowleft"
                      size={switchMerchant ? 10 : 20}
                      color={Colors.whiteColor}
                      style={{}}
                    />
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",

                        ...isMobile() && {
                          marginLeft: 0,
                        },
                      }}
                    >
                      {isMobile() ? '' : 'SUMMARY'}
                    </Text>
                  </TouchableOpacity>
                </View>

                {/* /////////////////////////////////////////////////////// */}

                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  nestedScrollEnabled={true}
                  contentContainerStyle={{
                    // minWidth: windowWidth,
                    width: "100%",
                    // paddingLeft: 0,

                    // ...isMobile() && {
                    //   flexDirection: 'column',
                    // },

                    flexDirection: 'column',

                    ...isMobile() && {
                      width: 1280,
                    },
                  }}
                  style={{
                    // minWidth: windowWidth,
                    // width: "100%",
                    marginTop: 0,
                    padding: 20,
                    zIndex: -50,
                    // backgroundColor: 'red',
                  }}

                >
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      width: "100%",
                      height: isMobile() ? (windowHeight * 0.9) : (windowHeight * 0.66),
                      marginTop: 0,
                      // marginHorizontal: 30,
                      marginBottom: 10,
                      alignSelf: "center",
                      borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,

                      // ...isMobile() && {
                      //   width: 1280,
                      // },

                      zIndex: -50,
                    }}
                  >
                    {!showDetails ? (
                      <View style={{ marginTop: 10, flexDirection: "row" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "2%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            paddingLeft: 10,
                          }}
                        >
                          <View
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"No.\n\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", opacity: 0 }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByMerchantName);
                              } else {
                                temp.sort(sortByMerchantNameDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Merchant\nName\n(Act. Date)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByUserAppAndWebDineInOnlineOrdersAmount);
                              } else {
                                temp.sort(sortByUserAppAndWebDineInOnlineOrdersAmountDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"QR\nOrders\nOnline"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByUserAppAndWebDineInOrdersAmount);
                              } else {
                                temp.sort(sortByUserAppAndWebDineInOrdersAmountDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"QR\nOrders\nOffline"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByMerchantAppOnlineOrdersAmount);
                              } else {
                                temp.sort(sortByMerchantAppOnlineOrdersAmountDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"POS\nOrders\nOnline"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByMerchantAppOrdersAmount);
                              } else {
                                temp.sort(sortByMerchantAppOrdersAmountDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"POS\nOrders\nOffline"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByWaiterAppOrdersAmount);
                              } else {
                                temp.sort(sortByWaiterAppOrdersAmountDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Waiter\nOrders\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByUserAppAndWebTakeawayOrdersAmount);
                              } else {
                                temp.sort(sortByUserAppAndWebTakeawayOrdersAmountDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Takeaway\nOrders\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* <View
                  style={{
                    flexDirection: "row",
                    width: "7%",
                    borderRightWidth: 1,
                    borderRightColor: "lightgrey",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: 10,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      let temp = [...tableDataSummary];

                      if (toggleCompare) {
                        temp.sort(sortByUserAppAndWebDeliveryOrdersAmount);
                      } else {
                        temp.sort(sortByUserAppAndWebDeliveryOrdersAmountDesc);
                      }

                      setTableDataSummary(temp);

                      setToggleCompare(!toggleCompare);
                    }}
                  >
                    <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      <View style={{ flexDirection: "column" }}>
                        <Text
                          numberOfLines={3}
                          style={{
                            fontSize: switchMerchant ? 10 : 13,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          {"Delivery\nOrders\n"}
                        </Text>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 8 : 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                      <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={Colors.descriptionColor}
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={Colors.descriptionColor}
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View> */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByTotalOrdersAmount);
                              } else {
                                temp.sort(sortByTotalOrdersAmountDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Total\nOrders\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* /////////////////////// */}

                        {/* 2022-12-10 - New columns */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              // if (toggleCompare) {
                              //   temp.sort(sortByGmvCommissionActual);
                              // } else {
                              //   temp.sort(sortByGmvCommissionActualDesc);
                              // }

                              if (toggleCompare) {
                                temp.sort(sortingAlphabeticallyCustomGmv);
                              } else {
                                temp.sort(sortingAlphabeticallyCustomGmvDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",

                                    color: Colors.primaryColor,
                                  }}
                                >
                                  {"KooDoo\nProfit\n(Actual)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByGmvPayoutActual);
                              } else {
                                temp.sort(sortByGmvPayoutActualDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"O. Sales\nPayout\n(Actual)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* 2022-11-26 - New columns */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByGmvCommission);
                              } else {
                                temp.sort(sortByGmvCommissionDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"KooDoo\nProfit\n(Expected)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByGmvPayout);
                              } else {
                                temp.sort(sortByGmvPayoutDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"O. Sales\nPayout\n(Expected)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByGmvFees);
                              } else {
                                temp.sort(sortByGmvFeesDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {`MDR\nFee\n${(userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com') ? `(Earned)` : ``}`}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* /////////////////////// */}

                        {/* <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummary];

                              if (toggleCompare) {
                                temp.sort(sortByLastOrderPlacedDateTime);
                              } else {
                                temp.sort(sortByLastOrderPlacedDateTimeDesc);
                              }

                              setTableDataSummary(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Last\nOrder"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View> */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "5%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <View>
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Action"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : (
                      // for details page

                      <View style={{ marginTop: 10, flexDirection: "row" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            paddingLeft: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByDateTime);
                              } else {
                                temp.sort(sortByDateTimeDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Date\nTime"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", opacity: 100 }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByOrderId);
                              } else {
                                temp.sort(sortByOrderIdDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Order\nID\n(Joined)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByIsQrOrder);
                              } else {
                                temp.sort(sortByIsQrOrderDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"QR\nOrder"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByNetAmount);
                          } else {
                            temp.sort(sortByNetAmountDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Net\nAmount"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                        {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByTax);
                          } else {
                            temp.sort(sortByTaxDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Tax\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                        {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortBySc);
                          } else {
                            temp.sort(sortByScDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"SC\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByTotalAmount);
                              } else {
                                temp.sort(sortByTotalAmountDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Total\nAmount"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByRazerCharges);
                              } else {
                                temp.sort(sortByRazerChargesDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"MDR\nCharges\n(Type)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByCfOd);
                              } else {
                                temp.sort(sortByCfOdDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Earned\nMDR\n(Overdue)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByKpfOd);
                              } else {
                                temp.sort(sortByKpfOdDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"KooDoo\nProfit\n(Overdue)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByRazerCharges);
                              } else {
                                temp.sort(sortByRazerChargesDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"RHB\nCharges"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View> */}

                        {
                          (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
                            ?
                            <View
                              style={{
                                flexDirection: "row",
                                width: "8%",
                                borderRightWidth: 1,
                                borderRightColor: "lightgrey",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                padding: 10,
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  let temp = [...tableDataDetails];

                                  if (toggleCompare) {
                                    temp.sort(sortByEarnedMdr);
                                  } else {
                                    temp.sort(sortByEarnedMdrDesc);
                                  }

                                  setTableDataDetails(temp);

                                  setToggleCompare(!toggleCompare);
                                }}
                              >
                                <View style={{ flexDirection: "row", alignItems: 'center' }}>
                                  <View style={{ flexDirection: "column" }}>
                                    <Text
                                      numberOfLines={3}
                                      style={{
                                        fontSize: switchMerchant ? 10 : 13,
                                        fontFamily: "NunitoSans-Bold",
                                      }}
                                    >
                                      {"Earned\nMDR"}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: switchMerchant ? 8 : 10,
                                        color: Colors.descriptionColor,
                                      }}
                                    ></Text>
                                  </View>
                                  <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                    <Entypo
                                      name="triangle-up"
                                      size={switchMerchant ? 7 : 14}
                                      color={Colors.descriptionColor}
                                    ></Entypo>

                                    <Entypo
                                      name="triangle-down"
                                      size={switchMerchant ? 7 : 14}
                                      color={Colors.descriptionColor}
                                    ></Entypo>
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                      }}
                                    ></Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                            :
                            <></>
                        }

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByKoodooCharges);
                              } else {
                                temp.sort(sortByKoodooChargesDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"KooDoo\nProfit\n(Expected)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByOutletPayout);
                              } else {
                                temp.sort(sortByOutletPayoutDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"O. Sales\nPayout\n(Expected)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortBySettlementDate);
                              } else {
                                temp.sort(sortBySettlementDateDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Settlement\nDate"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByPaymentMethod);
                              } else {
                                temp.sort(sortByPaymentMethodDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Payment\nMethod"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* Test columns */}

                        {/* <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByPaymentMethod);
                              } else {
                                temp.sort(sortByPaymentMethodDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Order\nItems"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View> */}
                      </View>
                    )}

                    {!showDetails ? (
                      <>
                        {tableDataSummary.filter((item) => {
                          return filterItem(item);
                        }).length > 0 ? (
                          <FlatList
                            showsVerticalScrollIndicator={false}
                            ref={flatListRef}
                            data={tableDataSummary
                              .filter((item) => {
                                return filterItem(item);
                              })
                              .filter(item => {
                                if (selectedTabOutletId === 'ALL' || item.outletId === selectedTabOutletId) {
                                  return true;
                                }
                                else {
                                  return false;
                                }
                              })
                              .slice(
                                (currentPage - 1) * perPage,
                                currentPage * perPage
                              )}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => String(index)}
                            style={{ marginTop: 10 }}
                          />
                        ) : (
                          <View
                            style={{
                              height: windowHeight * 0.5,
                            }}
                          >
                            <View
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              {/* <Text style={{ color: Colors.descriptionColor }}>
                            Loading...
                          </Text> */}
                            </View>
                          </View>
                        )}
                      </>
                    ) : (
                      <>
                        {tableDataDetails.filter((item) => {
                          return filterItemDetails(item);
                        }).length > 0 ? (
                          <FlatList
                            showsVerticalScrollIndicator={false}
                            ref={flatListRef}
                            data={tableDataDetails
                              .filter((item) => {
                                return filterItemDetails(item);
                              })
                              .slice(
                                (currentDetailsPage - 1) * perPage,
                                currentDetailsPage * perPage
                              )}
                            renderItem={renderItemDetails}
                            keyExtractor={(item, index) => String(index)}
                            style={{ marginTop: 10 }}
                          />
                        ) : (
                          <View
                            style={{
                              height: windowHeight * 0.5,
                            }}
                          >
                            <View
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              {/* <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text> */}
                            </View>
                          </View>
                        )}
                      </>
                    )}
                  </View>

                  {!showDetails ? (
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        width: "100%",
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "flex-end",
                        top:
                          Platform.OS == "ios"
                            ? pushPagingToTop && keyboardHeight > 0
                              ? -keyboardHeight * 1
                              : 0
                            : 0,
                        borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                        paddingHorizontal:
                          pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          marginRight: "1%",
                        }}
                      >
                        Items Showed
                      </Text>
                      <View
                        style={{
                          width: Platform.OS === "ios" ? 65 : "13%", //65,
                          height: switchMerchant ? 20 : 35,
                          backgroundColor: Colors.whiteColor,
                          borderRadius: 10,
                          justifyContent: "center",
                          paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                          //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                          // paddingTop: '-60%',
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          marginRight: "1%",
                        }}
                      >
                        {/* <RNPickerSelect
                        useNativeAndroidPickerStyle={false}
                        style={{
                          inputIOS: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            textAlign: "center",
                          },
                          inputAndroid: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            justifyContent: "center",
                            textAlign: "center",
                            height: 40,
                            color: "black",
                          },
                          inputAndroidContainer: { width: "100%" },
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: "none",
                          },
                          chevronDown: {
                            display: "none",
                          },
                          chevronUp: {
                            display: "none",
                          },
                        }}
                        items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: "All",
                          value: !showDetails
                            ? allOutletsEmployeesAction.length
                            : allOutletsEmployeesDetails.length,
                        })}
                        value={perPage}
                        onValueChange={(value) => {
                          setPerPage(value);
                        }}
                      /> */}
                        <Picker
                          selectedValue={perPage}
                          style={{}}
                          onValueChange={(value, text) => {
                            setPerPage(value);
                          }}
                        >
                          {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                            label: "All",
                            value: !showDetails
                              ? tableDataSummary.length
                              : tableDataDetails.length,
                          }).map((value, index) => {
                            return (
                              <Picker.Item
                                key={index}
                                label={value.label}
                                value={value.value}
                              />
                            );
                          })}
                        </Picker>
                      </View>

                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          marginRight: "1%",
                        }}
                      >
                        Page
                      </Text>
                      <View
                        style={{
                          width: switchMerchant ? 65 : 70,
                          height: switchMerchant ? 20 : 35,
                          backgroundColor: Colors.whiteColor,
                          borderRadius: 10,
                          justifyContent: "center",
                          paddingHorizontal: 22,
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                        }}
                      >
                        {console.log("currentPage")}
                        {console.log(currentPage)}

                        <TextInput
                          onChangeText={(text) => {
                            var currentPageTemp =
                              text.length > 0 ? parseInt(text) : 1;

                            setCurrentPage(
                              currentPageTemp > pageCount
                                ? pageCount
                                : currentPageTemp < 1
                                  ? 1
                                  : currentPageTemp
                            );
                          }}
                          placeholder={
                            pageCount !== 0 ? currentPage.toString() : "0"
                          }
                          placeholderTextColor={Platform.select({
                            ios: "#a9a9a9",
                          })}
                          style={{
                            color: "black",
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            marginTop: Platform.OS === "ios" ? 0 : -15,
                            marginBottom: Platform.OS === "ios" ? 0 : -15,
                            textAlign: "center",
                            width: "100%",
                          }}
                          value={pageCount !== 0 ? currentPage.toString() : "0"}
                          defaultValue={
                            pageCount !== 0 ? currentPage.toString() : "0"
                          }
                          keyboardType={"numeric"}
                          onFocus={() => {
                            setPushPagingToTop(true);
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          marginLeft: "1%",
                          marginRight: "1%",
                        }}
                      >
                        of {pageCount}
                      </Text>
                      <TouchableOpacity
                        style={{
                          width: switchMerchant ? 30 : 45,
                          height: switchMerchant ? 20 : 28,
                          backgroundColor: Colors.primaryColor,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          prevPage();
                        }}
                      >
                        <ArrowLeft color={Colors.whiteColor} />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: switchMerchant ? 30 : 45,
                          height: switchMerchant ? 20 : 28,
                          backgroundColor: Colors.primaryColor,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          nextPage();
                        }}
                      >
                        <ArrowRight color={Colors.whiteColor} />
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        width: '100%',
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "flex-end",
                        top:
                          Platform.OS == "ios"
                            ? pushPagingToTop && keyboardHeight > 0
                              ? -keyboardHeight * 1
                              : 0
                            : 0,
                        borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                        paddingHorizontal:
                          pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          marginRight: "1%",
                        }}
                      >
                        Items Showed
                      </Text>
                      <View
                        style={{
                          width: Platform.OS === "ios" ? 65 : "13%", //65,
                          height: switchMerchant ? 20 : 35,
                          backgroundColor: Colors.whiteColor,
                          borderRadius: 10,
                          justifyContent: "center",
                          paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                          //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                          // paddingTop: '-60%',
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          marginRight: "1%",
                        }}
                      >
                        {/* <RNPickerSelect
                        useNativeAndroidPickerStyle={false}
                        style={{
                          inputIOS: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            textAlign: "center",
                          },
                          inputAndroid: {
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            justifyContent: "center",
                            textAlign: "center",
                            height: 40,
                            color: "black",
                          },
                          inputAndroidContainer: { width: "100%" },
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: "none",
                          },
                          chevronDown: {
                            display: "none",
                          },
                          chevronUp: {
                            display: "none",
                          },
                        }}
                        items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: "All",
                          value: !showDetails
                            ? allOutletsEmployeesAction.length
                            : allOutletsEmployeesDetails.length,
                        })}
                        value={perPage}
                        onValueChange={(value) => {
                          setPerPage(value);
                        }}
                      /> */}
                        <Picker
                          selectedValue={perPage}
                          style={{}}
                          onValueChange={(value, text) => {
                            setPerPage(value);
                          }}
                        >
                          {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                            label: "All",
                            value: !showDetails
                              ? tableDataSummary.length
                              : tableDataDetails.length,
                          }).map((value, index) => {
                            return (
                              <Picker.Item
                                key={index}
                                label={value.label}
                                value={value.value}
                              />
                            );
                          })}
                        </Picker>
                      </View>

                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          marginRight: "1%",
                        }}
                      >
                        Page
                      </Text>
                      <View
                        style={{
                          width: switchMerchant ? 65 : 70,
                          height: switchMerchant ? 20 : 35,
                          backgroundColor: Colors.whiteColor,
                          borderRadius: 10,
                          justifyContent: "center",
                          paddingHorizontal: 22,
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                        }}
                      >
                        {console.log("currentDetailsPage")}
                        {console.log(currentDetailsPage)}

                        <TextInput
                          onChangeText={(text) => {
                            var currentPageTemp =
                              text.length > 0 ? parseInt(text) : 1;
                            console.log("currentDetailsPage pending");
                            console.log(
                              currentPageTemp > pageCount
                                ? pageCount
                                : currentPageTemp < 1
                                  ? 1
                                  : currentPageTemp
                            );
                            setCurrentDetailsPage(
                              currentPageTemp > pageCount
                                ? pageCount
                                : currentPageTemp < 1
                                  ? 1
                                  : currentPageTemp
                            );
                          }}
                          placeholder={
                            pageCount !== 0 ? currentDetailsPage.toString() : "0"
                          }
                          placeholderTextColor={Platform.select({
                            ios: "#a9a9a9",
                          })}
                          style={{
                            color: "black",
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Regular",
                            marginTop: Platform.OS === "ios" ? 0 : -15,
                            marginBottom: Platform.OS === "ios" ? 0 : -15,
                            textAlign: "center",
                            width: "100%",
                          }}
                          value={
                            pageCount !== 0 ? currentDetailsPage.toString() : "0"
                          }
                          defaultValue={
                            pageCount !== 0 ? currentDetailsPage.toString() : "0"
                          }
                          keyboardType={"numeric"}
                          onFocus={() => {
                            setPushPagingToTop(true);
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          marginLeft: "1%",
                          marginRight: "1%",
                        }}
                      >
                        of {pageCount}
                      </Text>
                      <TouchableOpacity
                        style={{
                          width: switchMerchant ? 30 : 45,
                          height: switchMerchant ? 20 : 28,
                          backgroundColor: Colors.primaryColor,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          prevDetailsPage();
                        }}
                      >
                        <ArrowLeft color={Colors.whiteColor} />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: switchMerchant ? 30 : 45,
                          height: switchMerchant ? 20 : 28,
                          backgroundColor: Colors.primaryColor,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          nextDetailsPage();
                        }}
                      >
                        <ArrowRight color={Colors.whiteColor} />
                      </TouchableOpacity>
                    </View>
                  )}
                </ScrollView>

                {/* /////////////////////////////////////////////////////// */}
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.MDR
              ?
              <>
                {/* 2023-05-22 - Comparison sales chart */}

                <View
                  style={{
                    // backgroundColor: 'red',

                    zIndex: -50,

                    marginTop: 5,
                    marginBottom: -15,

                    padding: 20,
                    paddingTop: 10,

                    // paddingHorizontal: '2%',
                    paddingBottom: 10,
                  }}

                  // testID='idCmpSalesChart'
                  testID='idMdrChart'
                >
                  <FusionCharts
                    {...{
                      zIndex: -1,
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      height: windowHeight * 0.8,
                      ...isMobile() && {
                        // height: windowHeight * 0.8,
                        height: windowHeight * 1.2,
                      },
                      type: CHART_DATA[CHART_TYPE.REPORT_MDR]
                        .type, //msline
                      dataFormat: mdrChart.dataFormat, //json
                      dataSource: mdrChart.dataSource,
                      events: {
                        'dataPlotClick': function (ev, props) {
                          // var infoElem = document.getElementById("infolbl");
                          console.log(ev);
                          console.log(props);

                          if (props && props.id) {
                            // var clickedBarChartDateTemp = props.id.split('|')[0];
                            // var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                            // setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                            // setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);

                            var clickedOutletId = props.id;

                            // if (changedBoldTimestamp !== clickedOutletId) {
                            //   setChangedBoldTimestamp(clickedOutletId);

                            //   window.boldOutletQrSalesLineDict[clickedOutletId] = !window.boldOutletQrSalesLineDict[clickedOutletId];
                            // }

                            // setChangedBoldTimestamp(Date.now());

                            // setBoldOutletQrSalesLineDict({
                            //   ...boldOutletQrSalesLineDict,
                            //   [clickedOutletId]: !boldOutletQrSalesLineDict[clickedOutletId],
                            // });
                          }

                          // setClickedChartDate(props.id);
                        },
                      }
                    }}
                  />
                </View>

                {/* 2023-05-22 - Date range control for mdr sales chart */}

                <ScrollView
                  horizontal={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    marginTop: 0,
                    padding: 20,
                    // paddingTop: 10,
                    paddingBottom: 0,

                    // zIndex: 50000,

                    // backgroundColor: 'red',

                    display: 'none',
                  }}
                  contentContainerStyle={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    paddingBottom: 500,

                    // zIndex: 50000,
                  }}
                  showsHorizontalScrollIndicator={false}
                >
                </ScrollView>

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    ...isMobile() && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      // width: 150,
                      backgroundColor: "white",

                      // marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      paddingHorizontal: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      paddingVertical: 8.5,
                      justifyContent: "center",
                      backgroundColor: Colors.whiteColor,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,

                      ...isMobile() && {
                        marginBottom: 15,
                      },
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "center",
                        // marginRight: 5,
                        style: '10%'
                      }}
                      onPress={() => {
                        setState({
                          pickerMode: "date",
                          showDateTimePicker: true,
                        });
                      }}
                    >
                      <GCalendar
                        width={switchMerchant ? 15 : 20}
                        height={switchMerchant ? 15 : 20}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}></View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptStartDate.toDate()}
                        selected={moment(cmpStartDate).toDate()}
                        onChange={(date) => {
                          CommonStore.update(s => {
                            s.cmpStartDate = moment(date).startOf("day").valueOf();
                          });
                        }}
                        maxDate={moment(cmpEndDate).toDate()}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}>
                      <Icon
                        name="minus"
                        size={10}
                        color={Colors.blackColor}
                        style={{
                          transform: 'translate(-1px, 0)',
                        }}
                      />
                    </View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptEndDate.toDate()}
                        selected={moment(cmpEndDate).toDate()}
                        onChange={(dateParam) => {
                          var date = moment(dateParam).endOf("day").valueOf();

                          CommonStore.update(s => {
                            s.cmpEndDate = moment(date).valueOf();
                          });
                        }}
                        minDate={moment(cmpStartDate).toDate()}
                      />
                    </View>

                    {/* {
                monthYearDropdownList.find(item => item.value === selectedMonthYear)
                  ?
                  <MultiSelect
                    clearable={false}
                    singleSelect={true}
                    defaultValue={selectedMonthYear}
                    placeholder={"Select Month"}
                    onChange={(value) => {
                      if (value) { // if choose the same option again, value = ''
                        setSelectedMonthYear(value);

                        var dateTimeParsed = parseInt(value);

                        CommonStore.update(s => {
                          s.aovStartDate = moment(dateTimeParsed).startOf('month').startOf('day').valueOf();
                          s.aovEndDate = moment(dateTimeParsed).endOf('month').endOf('day').valueOf();
                        });
                      }
                    }}
                    options={monthYearDropdownList}
                    className="msl-varsHEADER"
                  />
                  :
                  <></>
              } */}
                  </View>

                  {/* 2023-05-22 - Outlet picker for mdr sales chart */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: cmpMultiSelectZIndex,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    {
                      outletDropdownList.find(item => item.value === selectedCmpOutletId)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedCmpOutletId}
                          placeholder={"Select Outlet"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedCmpOutletId(value);
                            }
                          }}
                          onMenuOpen={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(51000);
                          }}
                          onMenuClose={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(9999);
                          }}
                          options={outletDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>
                </View>
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.COMPARISON
              ?
              <>
                {/* 2023-05-22 - Comparison sales chart */}

                <View
                  style={{
                    // backgroundColor: 'red',

                    zIndex: -50,

                    marginTop: 5,
                    marginBottom: -15,

                    padding: 20,
                    paddingTop: 10,

                    // paddingHorizontal: '2%',
                    paddingBottom: 10,
                  }}

                  testID='idCmpSalesChart'
                >
                  <FusionCharts
                    {...{
                      zIndex: -1,
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      height: windowHeight * 0.8,
                      ...isMobile() && {
                        // height: windowHeight * 0.8,
                        height: windowHeight * 1.2,
                      },
                      type: CHART_DATA[CHART_TYPE.REPORT_CMP_AMT_PCT_SALES]
                        .type, //msline
                      dataFormat: cmpSalesChart.dataFormat, //json
                      dataSource: cmpSalesChart.dataSource,
                      events: {
                        'dataPlotClick': function (ev, props) {
                          // var infoElem = document.getElementById("infolbl");
                          console.log(ev);
                          console.log(props);

                          if (props && props.id) {
                            // var clickedBarChartDateTemp = props.id.split('|')[0];
                            // var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                            // setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                            // setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);

                            var clickedOutletId = props.id;

                            // if (changedBoldTimestamp !== clickedOutletId) {
                            //   setChangedBoldTimestamp(clickedOutletId);

                            //   window.boldOutletQrSalesLineDict[clickedOutletId] = !window.boldOutletQrSalesLineDict[clickedOutletId];
                            // }

                            // setChangedBoldTimestamp(Date.now());

                            // setBoldOutletQrSalesLineDict({
                            //   ...boldOutletQrSalesLineDict,
                            //   [clickedOutletId]: !boldOutletQrSalesLineDict[clickedOutletId],
                            // });
                          }

                          // setClickedChartDate(props.id);
                        },
                      }
                    }}
                  />
                </View>

                {/* 2023-05-22 - Date range control for Comparison sales chart */}

                <ScrollView
                  horizontal={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    marginTop: 0,
                    padding: 20,
                    // paddingTop: 10,
                    paddingBottom: 0,

                    // zIndex: 50000,

                    // backgroundColor: 'red',

                    display: 'none',
                  }}
                  contentContainerStyle={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    paddingBottom: 500,

                    // zIndex: 50000,
                  }}
                  showsHorizontalScrollIndicator={false}
                >
                </ScrollView>

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    ...isMobile() && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      // width: 150,
                      backgroundColor: "white",

                      // marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      paddingHorizontal: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      paddingVertical: 8.5,
                      justifyContent: "center",
                      backgroundColor: Colors.whiteColor,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,

                      ...isMobile() && {
                        marginBottom: 15,
                      },
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "center",
                        // marginRight: 5,
                        style: '10%'
                      }}
                      onPress={() => {
                        setState({
                          pickerMode: "date",
                          showDateTimePicker: true,
                        });
                      }}
                    >
                      <GCalendar
                        width={switchMerchant ? 15 : 20}
                        height={switchMerchant ? 15 : 20}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}></View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptStartDate.toDate()}
                        selected={moment(cmpStartDate).toDate()}
                        onChange={(date) => {
                          CommonStore.update(s => {
                            s.cmpStartDate = moment(date).startOf("day").valueOf();
                          });
                        }}
                        maxDate={moment(cmpEndDate).toDate()}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}>
                      <Icon
                        name="minus"
                        size={10}
                        color={Colors.blackColor}
                        style={{
                          transform: 'translate(-1px, 0)',
                        }}
                      />
                    </View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptEndDate.toDate()}
                        selected={moment(cmpEndDate).toDate()}
                        onChange={(dateParam) => {
                          var date = moment(dateParam).endOf("day").valueOf();

                          CommonStore.update(s => {
                            s.cmpEndDate = moment(date).valueOf();
                          });
                        }}
                        minDate={moment(cmpStartDate).toDate()}
                      />
                    </View>

                    {/* {
                monthYearDropdownList.find(item => item.value === selectedMonthYear)
                  ?
                  <MultiSelect
                    clearable={false}
                    singleSelect={true}
                    defaultValue={selectedMonthYear}
                    placeholder={"Select Month"}
                    onChange={(value) => {
                      if (value) { // if choose the same option again, value = ''
                        setSelectedMonthYear(value);

                        var dateTimeParsed = parseInt(value);

                        CommonStore.update(s => {
                          s.aovStartDate = moment(dateTimeParsed).startOf('month').startOf('day').valueOf();
                          s.aovEndDate = moment(dateTimeParsed).endOf('month').endOf('day').valueOf();
                        });
                      }
                    }}
                    options={monthYearDropdownList}
                    className="msl-varsHEADER"
                  />
                  :
                  <></>
              } */}
                  </View>

                  {/* 2023-05-22 - Outlet picker for Comparison sales chart */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: cmpMultiSelectZIndex,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    {
                      outletDropdownList.find(item => item.value === selectedCmpOutletId)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedCmpOutletId}
                          placeholder={"Select Outlet"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedCmpOutletId(value);
                            }
                          }}
                          onMenuOpen={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(51000);
                          }}
                          onMenuClose={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(9999);
                          }}
                          options={outletDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>
                </View>
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.AOV
              ?
              <>
                {/* 2023-05-08 - AOV sales chart */}

                <View
                  style={{
                    // backgroundColor: 'red',

                    zIndex: -50,

                    marginTop: 5,
                    // marginTop: 30,
                    marginBottom: -15,

                    padding: 20,
                    paddingTop: 10,

                    // paddingHorizontal: '2%',
                    paddingBottom: 10,
                  }}
                >
                  <FusionCharts
                    {...{
                      zIndex: -1,
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      height: windowHeight * 0.8,
                      ...isMobile() && {
                        // height: windowHeight * 0.8,
                        height: windowHeight * 1.2,
                      },
                      type: CHART_DATA[CHART_TYPE.REPORT_AOV_SALES]
                        .type, //msline
                      dataFormat: aovSalesChart.dataFormat, //json
                      dataSource: aovSalesChart.dataSource,
                      events: {
                        'dataPlotClick': function (ev, props) {
                          // var infoElem = document.getElementById("infolbl");
                          console.log(ev);
                          console.log(props);

                          if (props && props.id) {
                            // var clickedBarChartDateTemp = props.id.split('|')[0];
                            // var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                            // setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                            // setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);

                            var clickedOutletId = props.id;

                            // if (changedBoldTimestamp !== clickedOutletId) {
                            //   setChangedBoldTimestamp(clickedOutletId);

                            //   window.boldOutletQrSalesLineDict[clickedOutletId] = !window.boldOutletQrSalesLineDict[clickedOutletId];
                            // }

                            // setChangedBoldTimestamp(Date.now());

                            // setBoldOutletQrSalesLineDict({
                            //   ...boldOutletQrSalesLineDict,
                            //   [clickedOutletId]: !boldOutletQrSalesLineDict[clickedOutletId],
                            // });
                          }

                          // setClickedChartDate(props.id);
                        },
                      }
                    }}
                  />
                </View>

                {/* 2023-05-08 - Date range control for AOV sales chart */}

                <ScrollView
                  horizontal={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    marginTop: 0,
                    padding: 20,
                    // paddingTop: 10,
                    paddingBottom: 0,

                    // zIndex: 50000,

                    // backgroundColor: 'red',

                    display: 'none',
                  }}
                  contentContainerStyle={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    paddingBottom: 500,

                    // zIndex: 50000,
                  }}
                  showsHorizontalScrollIndicator={false}
                >
                  {/* <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedAovDateRange === AOV_DATE_RANGE.PAST_1_MONTH ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedAovDateRange === AOV_DATE_RANGE.PAST_1_MONTH ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedAovDateRange(AOV_DATE_RANGE.PAST_1_MONTH);

                  CommonStore.update(s => {
                    s.aovStartDate = moment().subtract(1, "month").startOf('day').valueOf();
                    // s.ptEndDate = moment().endOf('day').valueOf();
                    s.aovEndDate = moment(window.currToDateTime).valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: selectedAovDateRange === AOV_DATE_RANGE.PAST_1_MONTH ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Last 1m`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedAovDateRange === AOV_DATE_RANGE.PAST_2_MONTHS ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedAovDateRange === AOV_DATE_RANGE.PAST_2_MONTHS ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedAovDateRange(AOV_DATE_RANGE.PAST_2_MONTHS);

                  CommonStore.update(s => {
                    s.aovStartDate = moment().subtract(2, "month").startOf('day').valueOf();
                    // s.ptEndDate = moment().endOf('day').valueOf();
                    s.aovEndDate = moment(window.currToDateTime).valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: selectedAovDateRange === AOV_DATE_RANGE.PAST_2_MONTHS ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Last 2m`}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: selectedAovDateRange === AOV_DATE_RANGE.PAST_3_MONTHS ? Colors.primaryColor : Colors.whiteColor,
                  backgroundColor: selectedAovDateRange === AOV_DATE_RANGE.PAST_3_MONTHS ? Colors.primaryColor : Colors.whiteColor,
                  borderRadius: 5,
                  paddingHorizontal: 15,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,

                  paddingTop: 1,

                  marginRight: 15,
                }}
                onPress={() => {
                  // setClickedBarChartDate(null);
                  // setClickedBarChartDateUnit(null);

                  setSelectedAovDateRange(AOV_DATE_RANGE.PAST_3_MONTHS);

                  CommonStore.update(s => {
                    s.aovStartDate = moment().subtract(3, "month").startOf('day').valueOf();
                    // s.ptEndDate = moment().endOf('day').valueOf();
                    s.aovEndDate = moment(window.currToDateTime).valueOf();
                  });
                }}>
                <Text
                  style={{
                    color: selectedAovDateRange === AOV_DATE_RANGE.PAST_3_MONTHS ? Colors.whiteColor : Colors.primaryColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  {`Last 3m`}
                </Text>
              </TouchableOpacity> */}

                  {/* <View
                style={[
                  {
                    paddingHorizontal: 15,
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 10,
                    paddingVertical: 10,
                    justifyContent: "center",
                    backgroundColor: Colors.whiteColor,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,

                    ...isMobile() && {
                      marginRight: 30,
                      // marginBottom: 10,
                    },
                  },
                ]}
              >
                <View
                  style={{ alignSelf: "center", marginRight: 5 }}
                  onPress={() => {
                    setState({
                      pickerMode: "date",
                      showDateTimePicker: true,
                    });
                  }}
                >
                  <GCalendar
                    width={switchMerchant ? 15 : 20}
                    height={switchMerchant ? 15 : 20}
                  />
                </View>

                <DatePicker
                  // selected={ptStartDate.toDate()}
                  selected={moment(aovStartDate).toDate()}
                  onChange={(date) => {
                    // setRev_date(moment(date).startOf('day'));

                    // setClickedBarChartDate(null);
                    // setClickedBarChartDateUnit(null);

                    setSelectedAovDateRange(AOV_DATE_RANGE.NONE);

                    CommonStore.update(s => {
                      s.aovStartDate = moment(date).startOf("day").valueOf();
                    });
                  }}
                  maxDate={moment(aovEndDate).toDate()}
                />

                <Text
                  style={
                    switchMerchant
                      ? { fontSize: 10, fontFamily: "NunitoSans-Regular" }
                      : { fontFamily: "NunitoSans-Regular" }
                  }
                >
                  -
                </Text>

                <DatePicker
                  // selected={ptEndDate.toDate()}
                  selected={moment(aovEndDate).toDate()}
                  onChange={(dateParam) => {
                    // setRev_date1(moment(date).endOf('day'));

                    // setClickedBarChartDate(null);
                    // setClickedBarChartDateUnit(null);

                    setMerchantDataFilterType(AOV_DATE_RANGE.NONE);

                    var date = moment(dateParam).endOf("day").valueOf();
                    if (moment(date).isSame(window.currToDateTime, 'day')) {
                      date = window.currToDateTime;
                    }

                    CommonStore.update(s => {
                      // s.ptEndDate = moment(date).endOf("day").valueOf();
                      s.aovEndDate = moment(date).valueOf();
                    });
                  }}
                  minDate={moment(aovStartDate).toDate()}
                />
              </View> */}
                </ScrollView>

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    ...isMobile() && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 150,
                      backgroundColor: "white",

                      // marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      ...isMobile() && {
                        marginBottom: 15,
                      },
                    }}
                  >
                    {
                      monthYearDropdownList.find(item => item.value === selectedMonthYear)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedMonthYear}
                          placeholder={"Select Month"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedMonthYear(value);

                              var dateTimeParsed = parseInt(value);

                              CommonStore.update(s => {
                                s.aovStartDate = moment(dateTimeParsed).startOf('month').startOf('day').valueOf();
                                s.aovEndDate = moment(dateTimeParsed).endOf('month').endOf('day').valueOf();
                              });
                            }
                          }}
                          options={monthYearDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>

                  {/* 2023-05-08 - Outlet picker for AOV sales chart */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    {
                      outletDropdownList.find(item => item.value === selectedAovOutletId)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedAovOutletId}
                          placeholder={"Select Outlet"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedAovOutletId(value);
                            }
                          }}
                          options={outletDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>
                </View>
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS
              ?
              <>
                {/* /////////////////////////////////////////////////////// */}

                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  nestedScrollEnabled={true}
                  contentContainerStyle={{
                    // minWidth: windowWidth,
                    width: "100%",
                    // paddingLeft: 0,

                    // ...isMobile() && {
                    //   flexDirection: 'column',
                    // },

                    flexDirection: 'column',

                    ...isMobile() && {
                      width: 1280,
                    },
                  }}
                  style={{
                    // minWidth: windowWidth,
                    // width: "100%",
                    marginTop: 0,
                    padding: 20,
                    zIndex: -50,
                    // backgroundColor: 'red',
                  }}

                >
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      width: "100%",
                      height: isMobile() ? (windowHeight * 0.9) : (windowHeight * 0.66),
                      marginTop: 0,
                      // marginHorizontal: 30,
                      marginBottom: 10,
                      alignSelf: "center",
                      borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,

                      // ...isMobile() && {
                      //   width: 1280,
                      // },

                      zIndex: -50,
                    }}
                  >
                    {!showDetailsBs ? (
                      <View style={{ marginTop: 10, flexDirection: "row" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "2%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            paddingLeft: 10,
                          }}
                        >
                          <View
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"No.\n\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", opacity: 0 }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "30%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryBs];

                              if (toggleCompareBs) {
                                temp.sort(sortByProductName);
                              } else {
                                temp.sort(sortByProductNameDesc);
                              }

                              setTableDataSummaryBs(temp);

                              setToggleCompareBs(!toggleCompareBs);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Product\nName\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryBs];

                              if (toggleCompareBs) {
                                temp.sort(sortByQty);
                              } else {
                                temp.sort(sortByQtyDesc);
                              }

                              setTableDataSummaryBs(temp);

                              setToggleCompareBs(!toggleCompareBs);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Qty"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryBs];

                              if (toggleCompareBs) {
                                temp.sort(sortByAmt);
                              } else {
                                temp.sort(sortByAmtDesc);
                              }

                              setTableDataSummaryBs(temp);

                              setToggleCompareBs(!toggleCompareBs);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Sales\n(RM)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* /////////////////////// */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "5%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <View>
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Action"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : (
                      // for details page

                      <View style={{ marginTop: 10, flexDirection: "row" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            paddingLeft: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByDateTime);
                              } else {
                                temp.sort(sortByDateTimeDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Date\nTime"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", opacity: 100 }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByOrderId);
                              } else {
                                temp.sort(sortByOrderIdDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Order\nID\n(Joined)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByIsQrOrder);
                              } else {
                                temp.sort(sortByIsQrOrderDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"QR\nOrder"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByNetAmount);
                          } else {
                            temp.sort(sortByNetAmountDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Net\nAmount"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                        {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortByTax);
                          } else {
                            temp.sort(sortByTaxDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Tax\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                        {/* <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          let temp = [...allOutletsEmployeesDetails];

                          if (toggleCompare) {
                            temp.sort(sortBySc);
                          } else {
                            temp.sort(sortByScDesc);
                          }

                          setAllOutletsEmployeesDetails(temp);

                          setToggleCompare(!toggleCompare);
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={3}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"SC\n"}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>

                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 7 : 14}
                              color={Colors.descriptionColor}
                            ></Entypo>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByTotalAmount);
                              } else {
                                temp.sort(sortByTotalAmountDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Total\nAmount"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByRazerCharges);
                              } else {
                                temp.sort(sortByRazerChargesDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"MDR\nCharges\n(Type)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByCfOd);
                              } else {
                                temp.sort(sortByCfOdDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Earned\nMDR\n(Overdue)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByKpfOd);
                              } else {
                                temp.sort(sortByKpfOdDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"KooDoo\nProfit\n(Overdue)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByRazerCharges);
                              } else {
                                temp.sort(sortByRazerChargesDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"RHB\nCharges"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View> */}

                        {
                          (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
                            ?
                            <View
                              style={{
                                flexDirection: "row",
                                width: "8%",
                                borderRightWidth: 1,
                                borderRightColor: "lightgrey",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                padding: 10,
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  let temp = [...tableDataDetails];

                                  if (toggleCompare) {
                                    temp.sort(sortByEarnedMdr);
                                  } else {
                                    temp.sort(sortByEarnedMdrDesc);
                                  }

                                  setTableDataDetails(temp);

                                  setToggleCompare(!toggleCompare);
                                }}
                              >
                                <View style={{ flexDirection: "row", alignItems: 'center' }}>
                                  <View style={{ flexDirection: "column" }}>
                                    <Text
                                      numberOfLines={3}
                                      style={{
                                        fontSize: switchMerchant ? 10 : 13,
                                        fontFamily: "NunitoSans-Bold",
                                      }}
                                    >
                                      {"Earned\nMDR"}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: switchMerchant ? 8 : 10,
                                        color: Colors.descriptionColor,
                                      }}
                                    ></Text>
                                  </View>
                                  <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                    <Entypo
                                      name="triangle-up"
                                      size={switchMerchant ? 7 : 14}
                                      color={Colors.descriptionColor}
                                    ></Entypo>

                                    <Entypo
                                      name="triangle-down"
                                      size={switchMerchant ? 7 : 14}
                                      color={Colors.descriptionColor}
                                    ></Entypo>
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                      }}
                                    ></Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                            :
                            <></>
                        }

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByKoodooCharges);
                              } else {
                                temp.sort(sortByKoodooChargesDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"KooDoo\nProfit\n(Expected)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByOutletPayout);
                              } else {
                                temp.sort(sortByOutletPayoutDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"O. Sales\nPayout\n(Expected)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortBySettlementDate);
                              } else {
                                temp.sort(sortBySettlementDateDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Settlement\nDate"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByPaymentMethod);
                              } else {
                                temp.sort(sortByPaymentMethodDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Payment\nMethod"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* Test columns */}

                        {/* <View
                          style={{
                            flexDirection: "row",
                            width: "8%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataDetails];

                              if (toggleCompare) {
                                temp.sort(sortByPaymentMethod);
                              } else {
                                temp.sort(sortByPaymentMethodDesc);
                              }

                              setTableDataDetails(temp);

                              setToggleCompare(!toggleCompare);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Order\nItems"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View> */}
                      </View>
                    )}

                    {!showDetailsBs ? (
                      <>
                        {
                          // tableDataSummaryBs.filter((item) => {
                          //   return filterItem(item);
                          // })
                          tableDataSummaryBs
                            .length > 0 ? (
                            <FlatList
                              showsVerticalScrollIndicator={false}
                              ref={flatListRef}
                              data={tableDataSummaryBs
                                // .filter((item) => {
                                //   return filterItem(item);
                                // })
                                // .filter(item => {
                                //   if (selectedTabOutletId === 'ALL' || item.outletId === selectedTabOutletId) {
                                //     return true;
                                //   }
                                //   else {
                                //     return false;
                                //   }
                                // })
                                // .slice(
                                //   (currentPage - 1) * perPage,
                                //   currentPage * perPage
                                // )
                              }
                              renderItem={renderItemBs}
                              keyExtractor={(item, index) => String(index)}
                              style={{ marginTop: 10 }}
                            />
                          ) : (
                            <View
                              style={{
                                height: windowHeight * 0.5,
                              }}
                            >
                              <View
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100%",
                                }}
                              >
                                {/* <Text style={{ color: Colors.descriptionColor }}>
                            Loading...
                          </Text> */}
                              </View>
                            </View>
                          )}
                      </>
                    ) : (
                      <>
                        {tableDataDetails.filter((item) => {
                          return filterItemDetails(item);
                        }).length > 0 ? (
                          <FlatList
                            showsVerticalScrollIndicator={false}
                            ref={flatListRef}
                            data={tableDataDetails
                              .filter((item) => {
                                return filterItemDetails(item);
                              })
                              .slice(
                                (currentDetailsPage - 1) * perPage,
                                currentDetailsPage * perPage
                              )}
                            renderItem={renderItemDetails}
                            keyExtractor={(item, index) => String(index)}
                            style={{ marginTop: 10 }}
                          />
                        ) : (
                          <View
                            style={{
                              height: windowHeight * 0.5,
                            }}
                          >
                            <View
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              {/* <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text> */}
                            </View>
                          </View>
                        )}
                      </>
                    )}
                  </View>

                  {/* 2024-03-19 - remove paging widget first */}

                </ScrollView>

                {/* /////////////////////////////////////////////////////// */}

                {/* 2023-05-22 - Date range control for Comparison sales chart */}

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,

                    paddingTop: 0,

                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    ...isMobile() && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      // width: 150,
                      backgroundColor: "white",

                      // marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      paddingHorizontal: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      paddingVertical: 8.5,
                      justifyContent: "center",
                      backgroundColor: Colors.whiteColor,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,

                      ...isMobile() && {
                        marginBottom: 15,
                      },
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "center",
                        // marginRight: 5,
                        style: '10%'
                      }}
                      onPress={() => {
                        setState({
                          pickerMode: "date",
                          showDateTimePicker: true,
                        });
                      }}
                    >
                      <GCalendar
                        width={switchMerchant ? 15 : 20}
                        height={switchMerchant ? 15 : 20}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}></View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptStartDate.toDate()}
                        selected={moment(cmpStartDate).toDate()}
                        onChange={(date) => {
                          CommonStore.update(s => {
                            s.cmpStartDate = moment(date).startOf("day").valueOf();
                          });
                        }}
                        maxDate={moment(cmpEndDate).toDate()}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}>
                      <Icon
                        name="minus"
                        size={10}
                        color={Colors.blackColor}
                        style={{
                          transform: 'translate(-1px, 0)',
                        }}
                      />
                    </View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptEndDate.toDate()}
                        selected={moment(cmpEndDate).toDate()}
                        onChange={(dateParam) => {
                          var date = moment(dateParam).endOf("day").valueOf();

                          CommonStore.update(s => {
                            s.cmpEndDate = moment(date).valueOf();
                          });
                        }}
                        minDate={moment(cmpStartDate).toDate()}
                      />
                    </View>

                  </View>

                  {/* 2023-05-22 - Outlet picker for Comparison sales chart */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: cmpMultiSelectZIndex,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    {
                      outletDropdownList.find(item => item.value === selectedCmpOutletId)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedCmpOutletId}
                          placeholder={"Select Outlet"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedCmpOutletId(value);
                            }
                          }}
                          onMenuOpen={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(51000);
                          }}
                          onMenuClose={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(9999);
                          }}
                          options={outletDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>

                  {/* 2024-04-05 - Tab picker to choose items/addons view */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: cmpMultiSelectZIndex,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    <MultiSelect
                      clearable={false}
                      singleSelect={true}
                      defaultValue={tabBestSeller}
                      placeholder={"Select Items/Addons"}
                      onChange={(value) => {
                        if (value) { // if choose the same option again, value = ''
                          setTabBestSeller(value);
                        }
                      }}
                      onMenuOpen={() => {
                        // here can set the zindex

                        setCmpMultiSelectZIndex(51000);
                      }}
                      onMenuClose={() => {
                        // here can set the zindex

                        setCmpMultiSelectZIndex(9999);
                      }}
                      options={[
                        {
                          label: 'Items',
                          value: 'ITEMS',
                        },
                        {
                          label: 'Addons',
                          value: 'ADDONS',
                        },
                      ]}
                      className="msl-varsHEADER"
                    />
                  </View>
                </View>
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.AR_OVERVIEW
              ?
              <>
                {/* /////////////////////////////////////////////////////// */}

                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  nestedScrollEnabled={true}
                  contentContainerStyle={{
                    // minWidth: windowWidth,
                    width: "100%",
                    // paddingLeft: 0,

                    // ...isMobile() && {
                    //   flexDirection: 'column',
                    // },

                    flexDirection: 'column',

                    ...isMobile() && {
                      width: 1280,
                    },
                  }}
                  style={{
                    // minWidth: windowWidth,
                    // width: "100%",
                    marginTop: 0,
                    padding: 20,
                    zIndex: -50,
                    // backgroundColor: 'red',
                  }}

                >
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      width: "100%",
                      height: isMobile() ? (windowHeight * 0.9) : (windowHeight * 0.66),
                      marginTop: 0,
                      // marginHorizontal: 30,
                      marginBottom: 10,
                      alignSelf: "center",
                      borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,

                      // ...isMobile() && {
                      //   width: 1280,
                      // },

                      zIndex: -50,
                    }}
                  >
                    {!showDetailsBs ? (
                      <View style={{ marginTop: 10, flexDirection: "row" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "2%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            paddingLeft: 10,
                          }}
                        >
                          <View
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"No.\n\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", opacity: 0 }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "30%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryAo];

                              if (toggleCompareAo) {
                                temp.sort(sortByName);
                              } else {
                                temp.sort(sortByNameDesc);
                              }

                              setTableDataSummaryAo(temp);

                              setToggleCompareAo(!toggleCompareAo);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Outlet\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryAo];

                              if (toggleCompareAo) {
                                temp.sort(sortByARUpselling);
                              } else {
                                temp.sort(sortByARUpsellingDesc);
                              }

                              setTableDataSummaryAo(temp);

                              setToggleCompareAo(!toggleCompareAo);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Upselling (%)\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryAo];

                              if (toggleCompareAo) {
                                temp.sort(sortByARLoyalty);
                              } else {
                                temp.sort(sortByARLoyaltyDesc);
                              }

                              setTableDataSummaryAo(temp);

                              setToggleCompareAo(!toggleCompareAo);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Loyalty (%)\n"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/* /////////////////////// */}

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryAo];

                              if (toggleCompareAo) {
                                temp.sort(sortByARPromotion);
                              } else {
                                temp.sort(sortByARPromotionDesc);
                              }

                              setTableDataSummaryAo(temp);

                              setToggleCompareAo(!toggleCompareAo);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Delivery/Online\nConversion (%)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "7%",
                            borderRightWidth: 1,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              let temp = [...tableDataSummaryAo];

                              if (toggleCompareAo) {
                                temp.sort(sortByARCredit);
                              } else {
                                temp.sort(sortByARCreditDesc);
                              }

                              setTableDataSummaryAo(temp);

                              setToggleCompareAo(!toggleCompareAo);
                            }}
                          >
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    fontSize: switchMerchant ? 10 : 13,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"Credit (%)"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 8 : 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                              <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                                <Entypo
                                  name="triangle-up"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>

                                <Entypo
                                  name="triangle-down"
                                  size={switchMerchant ? 7 : 14}
                                  color={Colors.descriptionColor}
                                ></Entypo>
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: Colors.descriptionColor,
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    ) : (
                      // for details page

                      <></>

                      //   <View style={{ marginTop: 10, flexDirection: "row" }}>
                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         paddingLeft: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByDateTime);
                      //           } else {
                      //             temp.sort(sortByDateTimeDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"Date\nTime"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", opacity: 100 }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>
                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByOrderId);
                      //           } else {
                      //             temp.sort(sortByOrderIdDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"Order\nID\n(Joined)"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>
                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByIsQrOrder);
                      //           } else {
                      //             temp.sort(sortByIsQrOrderDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"QR\nOrder"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>
                      //     {/* <View
                      //   style={{
                      //     flexDirection: "row",
                      //     width: "8%",
                      //     borderRightWidth: 1,
                      //     borderRightColor: "lightgrey",
                      //     alignItems: "center",
                      //     justifyContent: "flex-start",
                      //     padding: 10,
                      //   }}
                      // >
                      //   <TouchableOpacity
                      //     onPress={() => {
                      //       let temp = [...allOutletsEmployeesDetails];

                      //       if (toggleCompare) {
                      //         temp.sort(sortByNetAmount);
                      //       } else {
                      //         temp.sort(sortByNetAmountDesc);
                      //       }

                      //       setAllOutletsEmployeesDetails(temp);

                      //       setToggleCompare(!toggleCompare);
                      //     }}
                      //   >
                      //     <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //       <View style={{ flexDirection: "column" }}>
                      //         <Text
                      //           numberOfLines={3}
                      //           style={{
                      //             fontSize: switchMerchant ? 10 : 13,
                      //             fontFamily: "NunitoSans-Bold",
                      //           }}
                      //         >
                      //           {"Net\nAmount"}
                      //         </Text>
                      //         <Text
                      //           style={{
                      //             fontSize: switchMerchant ? 8 : 10,
                      //             color: Colors.descriptionColor,
                      //           }}
                      //         ></Text>
                      //       </View>
                      //       <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //         <Entypo
                      //           name="triangle-up"
                      //           size={switchMerchant ? 7 : 14}
                      //           color={Colors.descriptionColor}
                      //         ></Entypo>

                      //         <Entypo
                      //           name="triangle-down"
                      //           size={switchMerchant ? 7 : 14}
                      //           color={Colors.descriptionColor}
                      //         ></Entypo>
                      //         <Text
                      //           style={{
                      //             fontSize: 10,
                      //             color: Colors.descriptionColor,
                      //           }}
                      //         ></Text>
                      //       </View>
                      //     </View>
                      //   </TouchableOpacity>
                      // </View> */}

                      //     {/* <View
                      //   style={{
                      //     flexDirection: "row",
                      //     width: "8%",
                      //     borderRightWidth: 1,
                      //     borderRightColor: "lightgrey",
                      //     alignItems: "center",
                      //     justifyContent: "flex-start",
                      //     padding: 10,
                      //   }}
                      // >
                      //   <TouchableOpacity
                      //     onPress={() => {
                      //       let temp = [...allOutletsEmployeesDetails];

                      //       if (toggleCompare) {
                      //         temp.sort(sortByTax);
                      //       } else {
                      //         temp.sort(sortByTaxDesc);
                      //       }

                      //       setAllOutletsEmployeesDetails(temp);

                      //       setToggleCompare(!toggleCompare);
                      //     }}
                      //   >
                      //     <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //       <View style={{ flexDirection: "column" }}>
                      //         <Text
                      //           numberOfLines={3}
                      //           style={{
                      //             fontSize: switchMerchant ? 10 : 13,
                      //             fontFamily: "NunitoSans-Bold",
                      //           }}
                      //         >
                      //           {"Tax\n"}
                      //         </Text>
                      //         <Text
                      //           style={{
                      //             fontSize: switchMerchant ? 8 : 10,
                      //             color: Colors.descriptionColor,
                      //           }}
                      //         ></Text>
                      //       </View>
                      //       <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //         <Entypo
                      //           name="triangle-up"
                      //           size={switchMerchant ? 7 : 14}
                      //           color={Colors.descriptionColor}
                      //         ></Entypo>

                      //         <Entypo
                      //           name="triangle-down"
                      //           size={switchMerchant ? 7 : 14}
                      //           color={Colors.descriptionColor}
                      //         ></Entypo>
                      //         <Text
                      //           style={{
                      //             fontSize: 10,
                      //             color: Colors.descriptionColor,
                      //           }}
                      //         ></Text>
                      //       </View>
                      //     </View>
                      //   </TouchableOpacity>
                      // </View> */}

                      //     {/* <View
                      //   style={{
                      //     flexDirection: "row",
                      //     width: "8%",
                      //     borderRightWidth: 1,
                      //     borderRightColor: "lightgrey",
                      //     alignItems: "center",
                      //     justifyContent: "flex-start",
                      //     padding: 10,
                      //   }}
                      // >
                      //   <TouchableOpacity
                      //     onPress={() => {
                      //       let temp = [...allOutletsEmployeesDetails];

                      //       if (toggleCompare) {
                      //         temp.sort(sortBySc);
                      //       } else {
                      //         temp.sort(sortByScDesc);
                      //       }

                      //       setAllOutletsEmployeesDetails(temp);

                      //       setToggleCompare(!toggleCompare);
                      //     }}
                      //   >
                      //     <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //       <View style={{ flexDirection: "column" }}>
                      //         <Text
                      //           numberOfLines={3}
                      //           style={{
                      //             fontSize: switchMerchant ? 10 : 13,
                      //             fontFamily: "NunitoSans-Bold",
                      //           }}
                      //         >
                      //           {"SC\n"}
                      //         </Text>
                      //         <Text
                      //           style={{
                      //             fontSize: switchMerchant ? 8 : 10,
                      //             color: Colors.descriptionColor,
                      //           }}
                      //         ></Text>
                      //       </View>
                      //       <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //         <Entypo
                      //           name="triangle-up"
                      //           size={switchMerchant ? 7 : 14}
                      //           color={Colors.descriptionColor}
                      //         ></Entypo>

                      //         <Entypo
                      //           name="triangle-down"
                      //           size={switchMerchant ? 7 : 14}
                      //           color={Colors.descriptionColor}
                      //         ></Entypo>
                      //         <Text
                      //           style={{
                      //             fontSize: 10,
                      //             color: Colors.descriptionColor,
                      //           }}
                      //         ></Text>
                      //       </View>
                      //     </View>
                      //   </TouchableOpacity>
                      // </View> */}

                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByTotalAmount);
                      //           } else {
                      //             temp.sort(sortByTotalAmountDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"Total\nAmount"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>

                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByRazerCharges);
                      //           } else {
                      //             temp.sort(sortByRazerChargesDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"Razer\nCharges"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>

                      //     {
                      //       (userEmail === 'ng.vincent@mykoodoo.com' || userEmail === 'herks@perksense.com')
                      //         ?
                      //         <View
                      //           style={{
                      //             flexDirection: "row",
                      //             width: "8%",
                      //             borderRightWidth: 1,
                      //             borderRightColor: "lightgrey",
                      //             alignItems: "center",
                      //             justifyContent: "flex-start",
                      //             padding: 10,
                      //           }}
                      //         >
                      //           <TouchableOpacity
                      //             onPress={() => {
                      //               let temp = [...tableDataDetails];

                      //               if (toggleCompare) {
                      //                 temp.sort(sortByEarnedMdr);
                      //               } else {
                      //                 temp.sort(sortByEarnedMdrDesc);
                      //               }

                      //               setTableDataDetails(temp);

                      //               setToggleCompare(!toggleCompare);
                      //             }}
                      //           >
                      //             <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //               <View style={{ flexDirection: "column" }}>
                      //                 <Text
                      //                   numberOfLines={3}
                      //                   style={{
                      //                     fontSize: switchMerchant ? 10 : 13,
                      //                     fontFamily: "NunitoSans-Bold",
                      //                   }}
                      //                 >
                      //                   {"Earned\nMDR"}
                      //                 </Text>
                      //                 <Text
                      //                   style={{
                      //                     fontSize: switchMerchant ? 8 : 10,
                      //                     color: Colors.descriptionColor,
                      //                   }}
                      //                 ></Text>
                      //               </View>
                      //               <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //                 <Entypo
                      //                   name="triangle-up"
                      //                   size={switchMerchant ? 7 : 14}
                      //                   color={Colors.descriptionColor}
                      //                 ></Entypo>

                      //                 <Entypo
                      //                   name="triangle-down"
                      //                   size={switchMerchant ? 7 : 14}
                      //                   color={Colors.descriptionColor}
                      //                 ></Entypo>
                      //                 <Text
                      //                   style={{
                      //                     fontSize: 10,
                      //                     color: Colors.descriptionColor,
                      //                   }}
                      //                 ></Text>
                      //               </View>
                      //             </View>
                      //           </TouchableOpacity>
                      //         </View>
                      //         :
                      //         <></>
                      //     }

                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByKoodooCharges);
                      //           } else {
                      //             temp.sort(sortByKoodooChargesDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"KooDoo\nProfit\n(Expected)"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>

                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByOutletPayout);
                      //           } else {
                      //             temp.sort(sortByOutletPayoutDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"O. Sales\nPayout\n(Expected)"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>

                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortBySettlementDate);
                      //           } else {
                      //             temp.sort(sortBySettlementDateDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"Settlement\nDate"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>

                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByPaymentMethod);
                      //           } else {
                      //             temp.sort(sortByPaymentMethodDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"Payment\nMethod"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>

                      //     {/* Test columns */}

                      //     <View
                      //       style={{
                      //         flexDirection: "row",
                      //         width: "8%",
                      //         borderRightWidth: 1,
                      //         borderRightColor: "lightgrey",
                      //         alignItems: "center",
                      //         justifyContent: "flex-start",
                      //         padding: 10,
                      //       }}
                      //     >
                      //       <TouchableOpacity
                      //         onPress={() => {
                      //           let temp = [...tableDataDetails];

                      //           if (toggleCompare) {
                      //             temp.sort(sortByPaymentMethod);
                      //           } else {
                      //             temp.sort(sortByPaymentMethodDesc);
                      //           }

                      //           setTableDataDetails(temp);

                      //           setToggleCompare(!toggleCompare);
                      //         }}
                      //       >
                      //         <View style={{ flexDirection: "row", alignItems: 'center' }}>
                      //           <View style={{ flexDirection: "column" }}>
                      //             <Text
                      //               numberOfLines={3}
                      //               style={{
                      //                 fontSize: switchMerchant ? 10 : 13,
                      //                 fontFamily: "NunitoSans-Bold",
                      //               }}
                      //             >
                      //               {"Order\nItems"}
                      //             </Text>
                      //             <Text
                      //               style={{
                      //                 fontSize: switchMerchant ? 8 : 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //           <View style={{ marginLeft: "3%", marginTop: '0.5%' }}>
                      //             <Entypo
                      //               name="triangle-up"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>

                      //             <Entypo
                      //               name="triangle-down"
                      //               size={switchMerchant ? 7 : 14}
                      //               color={Colors.descriptionColor}
                      //             ></Entypo>
                      //             <Text
                      //               style={{
                      //                 fontSize: 10,
                      //                 color: Colors.descriptionColor,
                      //               }}
                      //             ></Text>
                      //           </View>
                      //         </View>
                      //       </TouchableOpacity>
                      //     </View>
                      //   </View>
                    )}

                    {!
                      // showDetailsBs
                      false
                      ? (
                        <>
                          {
                            // tableDataSummaryBs.filter((item) => {
                            //   return filterItem(item);
                            // })
                            tableDataSummaryAo
                              .length > 0 ? (
                              <FlatList
                                showsVerticalScrollIndicator={false}
                                ref={flatListRef}
                                data={tableDataSummaryAo
                                  // .filter((item) => {
                                  //   return filterItem(item);
                                  // })
                                  // .filter(item => {
                                  //   if (selectedTabOutletId === 'ALL' || item.outletId === selectedTabOutletId) {
                                  //     return true;
                                  //   }
                                  //   else {
                                  //     return false;
                                  //   }
                                  // })
                                  // .slice(
                                  //   (currentPage - 1) * perPage,
                                  //   currentPage * perPage
                                  // )
                                }
                                renderItem={renderItemAo}
                                keyExtractor={(item, index) => String(index)}
                                style={{ marginTop: 10 }}
                              />
                            ) : (
                              <View
                                style={{
                                  height: windowHeight * 0.5,
                                }}
                              >
                                <View
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                  }}
                                >
                                  {/* <Text style={{ color: Colors.descriptionColor }}>
                            Loading...
                          </Text> */}
                                </View>
                              </View>
                            )}
                        </>
                      ) : (
                        <>
                          {/* {tableDataDetails.filter((item) => {
                            return filterItemDetails(item);
                          }).length > 0 ? (
                            <FlatList
                              showsVerticalScrollIndicator={false}
                              ref={flatListRef}
                              data={tableDataDetails
                                .filter((item) => {
                                  return filterItemDetails(item);
                                })
                                .slice(
                                  (currentDetailsPage - 1) * perPage,
                                  currentDetailsPage * perPage
                                )}
                              renderItem={renderItemDetails}
                              keyExtractor={(item, index) => String(index)}
                              style={{ marginTop: 10 }}
                            />
                          ) : (
                            <View
                              style={{
                                height: windowHeight * 0.5,
                              }}
                            >
                              <View
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100%",
                                }}
                              >
                              </View>
                            </View>
                          )} */}
                        </>
                      )}
                  </View>

                  {/* 2024-03-19 - remove paging widget first */}

                </ScrollView>

                {/* /////////////////////////////////////////////////////// */}

                {/* 2023-05-22 - Date range control for Comparison sales chart */}

                {/* removed */}
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.NEW_CUSTOMER
              ?
              <>
                <View
                  style={{
                    // backgroundColor: 'red',

                    // zIndex: -1,

                    // // marginTop: 5,
                    // marginBottom: -15,

                    // // padding: 20,
                    // // paddingTop: 10,

                    // // paddingHorizontal: '2%',
                    // paddingBottom: 10,

                    zIndex: -50,

                    marginTop: 5,
                    marginBottom: -15,

                    padding: 20,
                    paddingTop: 10,

                    // paddingHorizontal: '2%',
                    paddingBottom: 10,
                  }}
                >
                  <FusionCharts
                    {...{
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      // height: windowHeight * 0.5,
                      height: windowHeight * 0.9,
                      ...isMobile() && {
                        // height: windowHeight * 0.9,
                        height: windowHeight * 1.25,
                      },
                      type: CHART_DATA[CHART_TYPE.REPORT_REPEAT_CUST]
                        .type, //msline
                      dataFormat: repeatCustNumChart.dataFormat, //json
                      dataSource: repeatCustNumChart.dataSource,

                      id: 'chartReportRepeatCust',

                      // events: {
                      //   'dataPlotClick': function (ev, props) {
                      //     // var infoElem = document.getElementById("infolbl");
                      //     console.log(ev);
                      //     console.log(props);

                      //     if (props && props.id) {
                      //       var clickedBarChartDateTemp = props.id.split('|')[0];
                      //       var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                      //       setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                      //       setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);
                      //     }

                      //     // setClickedChartDate(props.id);
                      //   },
                      // }
                    }}
                  />
                </View>

                {/* 2023-05-22 - Date range control for Comparison sales chart */}

                <ScrollView
                  horizontal={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    marginTop: 0,
                    padding: 20,
                    // paddingTop: 10,
                    paddingBottom: 0,

                    // zIndex: 50000,

                    // backgroundColor: 'red',

                    display: 'none',
                  }}
                  contentContainerStyle={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    paddingBottom: 500,

                    // zIndex: 50000,
                  }}
                  showsHorizontalScrollIndicator={false}
                >
                </ScrollView>

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    ...isMobile() && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      // width: 150,
                      backgroundColor: "white",

                      // marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      paddingHorizontal: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      paddingVertical: 8.5,
                      justifyContent: "center",
                      backgroundColor: Colors.whiteColor,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,

                      ...isMobile() && {
                        marginBottom: 15,
                      },
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "center",
                        // marginRight: 5,
                        style: '10%'
                      }}
                      onPress={() => {
                        setState({
                          pickerMode: "date",
                          showDateTimePicker: true,
                        });
                      }}
                    >
                      <GCalendar
                        width={switchMerchant ? 15 : 20}
                        height={switchMerchant ? 15 : 20}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}></View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptStartDate.toDate()}
                        selected={moment(cmpStartDate).toDate()}
                        onChange={(date) => {
                          CommonStore.update(s => {
                            s.cmpStartDate = moment(date).startOf("day").valueOf();
                          });
                        }}
                        maxDate={moment(cmpEndDate).toDate()}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}>
                      <Icon
                        name="minus"
                        size={10}
                        color={Colors.blackColor}
                        style={{
                          transform: 'translate(-1px, 0)',
                        }}
                      />
                    </View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptEndDate.toDate()}
                        selected={moment(cmpEndDate).toDate()}
                        onChange={(dateParam) => {
                          var date = moment(dateParam).endOf("day").valueOf();

                          CommonStore.update(s => {
                            s.cmpEndDate = moment(date).valueOf();
                          });
                        }}
                        minDate={moment(cmpStartDate).toDate()}
                      />
                    </View>

                    {/* {
                monthYearDropdownList.find(item => item.value === selectedMonthYear)
                  ?
                  <MultiSelect
                    clearable={false}
                    singleSelect={true}
                    defaultValue={selectedMonthYear}
                    placeholder={"Select Month"}
                    onChange={(value) => {
                      if (value) { // if choose the same option again, value = ''
                        setSelectedMonthYear(value);

                        var dateTimeParsed = parseInt(value);

                        CommonStore.update(s => {
                          s.aovStartDate = moment(dateTimeParsed).startOf('month').startOf('day').valueOf();
                          s.aovEndDate = moment(dateTimeParsed).endOf('month').endOf('day').valueOf();
                        });
                      }
                    }}
                    options={monthYearDropdownList}
                    className="msl-varsHEADER"
                  />
                  :
                  <></>
              } */}
                  </View>

                  {/* 2023-05-22 - Outlet picker for Comparison sales chart */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: cmpMultiSelectZIndex,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    {
                      outletDropdownList.find(item => item.value === selectedCmpOutletId)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedCmpOutletId}
                          placeholder={"Select Outlet"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedCmpOutletId(value);
                            }
                          }}
                          onMenuOpen={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(51000);
                          }}
                          onMenuClose={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(9999);
                          }}
                          options={outletDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>
                </View>
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.UPSELLING
              ?
              <>
                <View
                  style={{
                    // backgroundColor: 'red',

                    // zIndex: -1,

                    // // marginTop: 5,
                    // marginBottom: -15,

                    // // padding: 20,
                    // // paddingTop: 10,

                    // // paddingHorizontal: '2%',
                    // paddingBottom: 10,

                    zIndex: -50,

                    marginTop: 5,
                    marginBottom: -15,

                    padding: 20,
                    paddingTop: 10,

                    // paddingHorizontal: '2%',
                    paddingBottom: 10,
                  }}
                >
                  <FusionCharts
                    {...{
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      // height: windowHeight * 0.5,
                      height: windowHeight * 0.9,
                      ...isMobile() && {
                        // height: windowHeight * 0.9,
                        height: windowHeight * 1.25,
                      },
                      type: CHART_DATA[CHART_TYPE.REPORT_UPSELLING]
                        .type, //msline
                      dataFormat: upsellingSalesChart.dataFormat, //json
                      dataSource: upsellingSalesChart.dataSource,

                      id: 'chartReportUpselling',

                      // events: {
                      //   'dataPlotClick': function (ev, props) {
                      //     // var infoElem = document.getElementById("infolbl");
                      //     console.log(ev);
                      //     console.log(props);

                      //     if (props && props.id) {
                      //       var clickedBarChartDateTemp = props.id.split('|')[0];
                      //       var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                      //       setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                      //       setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);
                      //     }

                      //     // setClickedChartDate(props.id);
                      //   },
                      // }
                    }}
                  />
                </View>

                {/* 2023-05-22 - Date range control for Comparison sales chart */}

                <ScrollView
                  horizontal={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    marginTop: 0,
                    padding: 20,
                    // paddingTop: 10,
                    paddingBottom: 0,

                    // zIndex: 50000,

                    // backgroundColor: 'red',

                    display: 'none',
                  }}
                  contentContainerStyle={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    paddingBottom: 500,

                    // zIndex: 50000,
                  }}
                  showsHorizontalScrollIndicator={false}
                >
                </ScrollView>

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    ...isMobile() && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      // width: 150,
                      backgroundColor: "white",

                      // marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      paddingHorizontal: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      paddingVertical: 8.5,
                      justifyContent: "center",
                      backgroundColor: Colors.whiteColor,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,

                      ...isMobile() && {
                        marginBottom: 15,
                      },
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "center",
                        // marginRight: 5,
                        style: '10%'
                      }}
                      onPress={() => {
                        setState({
                          pickerMode: "date",
                          showDateTimePicker: true,
                        });
                      }}
                    >
                      <GCalendar
                        width={switchMerchant ? 15 : 20}
                        height={switchMerchant ? 15 : 20}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}></View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptStartDate.toDate()}
                        selected={moment(cmpStartDate).toDate()}
                        onChange={(date) => {
                          CommonStore.update(s => {
                            s.cmpStartDate = moment(date).startOf("day").valueOf();
                          });
                        }}
                        maxDate={moment(cmpEndDate).toDate()}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}>
                      <Icon
                        name="minus"
                        size={10}
                        color={Colors.blackColor}
                        style={{
                          transform: 'translate(-1px, 0)',
                        }}
                      />
                    </View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptEndDate.toDate()}
                        selected={moment(cmpEndDate).toDate()}
                        onChange={(dateParam) => {
                          var date = moment(dateParam).endOf("day").valueOf();

                          CommonStore.update(s => {
                            s.cmpEndDate = moment(date).valueOf();
                          });
                        }}
                        minDate={moment(cmpStartDate).toDate()}
                      />
                    </View>

                    {/* {
                monthYearDropdownList.find(item => item.value === selectedMonthYear)
                  ?
                  <MultiSelect
                    clearable={false}
                    singleSelect={true}
                    defaultValue={selectedMonthYear}
                    placeholder={"Select Month"}
                    onChange={(value) => {
                      if (value) { // if choose the same option again, value = ''
                        setSelectedMonthYear(value);

                        var dateTimeParsed = parseInt(value);

                        CommonStore.update(s => {
                          s.aovStartDate = moment(dateTimeParsed).startOf('month').startOf('day').valueOf();
                          s.aovEndDate = moment(dateTimeParsed).endOf('month').endOf('day').valueOf();
                        });
                      }
                    }}
                    options={monthYearDropdownList}
                    className="msl-varsHEADER"
                  />
                  :
                  <></>
              } */}
                  </View>

                  {/* 2023-05-22 - Outlet picker for Comparison sales chart */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: cmpMultiSelectZIndex,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    {
                      outletDropdownList.find(item => item.value === selectedCmpOutletId)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedCmpOutletId}
                          placeholder={"Select Outlet"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedCmpOutletId(value);
                            }
                          }}
                          onMenuOpen={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(51000);
                          }}
                          onMenuClose={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(9999);
                          }}
                          options={outletDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>
                </View>
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}

          {
            selectedPayoutTabs === PAYOUT_TABS.AOV_UPSELLING
              ?
              <>
                <View
                  style={{
                    // backgroundColor: 'red',

                    // zIndex: -1,

                    // // marginTop: 5,
                    // marginBottom: -15,

                    // // padding: 20,
                    // // paddingTop: 10,

                    // // paddingHorizontal: '2%',
                    // paddingBottom: 10,

                    zIndex: -50,

                    marginTop: 5,
                    marginBottom: -15,

                    padding: 20,
                    paddingTop: 10,

                    // paddingHorizontal: '2%',
                    paddingBottom: 10,
                  }}
                >
                  <FusionCharts
                    {...{
                      width: "100%",
                      // width: windowWidth *
                      //   (0.84 - Styles.sideBarWidth),
                      // height: windowHeight * 0.5,
                      height: windowHeight * 0.9,
                      ...isMobile() && {
                        // height: windowHeight * 0.9,
                        height: windowHeight * 1.25,
                      },
                      type: CHART_DATA[CHART_TYPE.REPORT_AOV_UPSELLING]
                        .type, //msline
                      dataFormat: aovUpsellingSalesChart.dataFormat, //json
                      dataSource: aovUpsellingSalesChart.dataSource,

                      id: 'chartReportAovUpselling',

                      // events: {
                      //   'dataPlotClick': function (ev, props) {
                      //     // var infoElem = document.getElementById("infolbl");
                      //     console.log(ev);
                      //     console.log(props);

                      //     if (props && props.id) {
                      //       var clickedBarChartDateTemp = props.id.split('|')[0];
                      //       var clickedBarChartDateUnitTemp = props.id.split('|')[1];

                      //       setClickedBarChartDate(parseInt(clickedBarChartDateTemp));
                      //       setClickedBarChartDateUnit(clickedBarChartDateUnitTemp);
                      //     }

                      //     // setClickedChartDate(props.id);
                      //   },
                      // }
                    }}
                  />
                </View>

                {/* 2023-05-22 - Date range control for Comparison sales chart */}

                <ScrollView
                  horizontal={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    marginTop: 0,
                    padding: 20,
                    // paddingTop: 10,
                    paddingBottom: 0,

                    // zIndex: 50000,

                    // backgroundColor: 'red',

                    display: 'none',
                  }}
                  contentContainerStyle={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    paddingBottom: 500,

                    // zIndex: 50000,
                  }}
                  showsHorizontalScrollIndicator={false}
                >
                </ScrollView>

                <View
                  // horizontal={true}
                  // nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',

                    // marginTop: 30,
                    padding: 20,
                    paddingBottom: 0,
                    // zIndex: -50,

                    zIndex: 10000,

                    // backgroundColor: 'red',

                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    paddingBottom: 5,
                    paddingRight: 5,

                    ...isMobile() && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      // width: 150,
                      backgroundColor: "white",

                      // marginLeft: 15,
                      top: 1,

                      zIndex: 50000,

                      paddingHorizontal: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      paddingVertical: 8.5,
                      justifyContent: "center",
                      backgroundColor: Colors.whiteColor,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,

                      ...isMobile() && {
                        marginBottom: 15,
                      },
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "center",
                        // marginRight: 5,
                        style: '10%'
                      }}
                      onPress={() => {
                        setState({
                          pickerMode: "date",
                          showDateTimePicker: true,
                        });
                      }}
                    >
                      <GCalendar
                        width={switchMerchant ? 15 : 20}
                        height={switchMerchant ? 15 : 20}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}></View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptStartDate.toDate()}
                        selected={moment(cmpStartDate).toDate()}
                        onChange={(date) => {
                          CommonStore.update(s => {
                            s.cmpStartDate = moment(date).startOf("day").valueOf();
                          });
                        }}
                        maxDate={moment(cmpEndDate).toDate()}
                      />
                    </View>

                    <View style={{
                      width: '5%',
                    }}>
                      <Icon
                        name="minus"
                        size={10}
                        color={Colors.blackColor}
                        style={{
                          transform: 'translate(-1px, 0)',
                        }}
                      />
                    </View>

                    <View style={{
                      width: '40%',
                    }}>
                      <DatePicker
                        // selected={ptEndDate.toDate()}
                        selected={moment(cmpEndDate).toDate()}
                        onChange={(dateParam) => {
                          var date = moment(dateParam).endOf("day").valueOf();

                          CommonStore.update(s => {
                            s.cmpEndDate = moment(date).valueOf();
                          });
                        }}
                        minDate={moment(cmpStartDate).toDate()}
                      />
                    </View>

                    {/* {
                monthYearDropdownList.find(item => item.value === selectedMonthYear)
                  ?
                  <MultiSelect
                    clearable={false}
                    singleSelect={true}
                    defaultValue={selectedMonthYear}
                    placeholder={"Select Month"}
                    onChange={(value) => {
                      if (value) { // if choose the same option again, value = ''
                        setSelectedMonthYear(value);

                        var dateTimeParsed = parseInt(value);

                        CommonStore.update(s => {
                          s.aovStartDate = moment(dateTimeParsed).startOf('month').startOf('day').valueOf();
                          s.aovEndDate = moment(dateTimeParsed).endOf('month').endOf('day').valueOf();
                        });
                      }
                    }}
                    options={monthYearDropdownList}
                    className="msl-varsHEADER"
                  />
                  :
                  <></>
              } */}
                  </View>

                  {/* 2023-05-22 - Outlet picker for Comparison sales chart */}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 8,
                      width: 250,
                      backgroundColor: "white",

                      marginLeft: 15,
                      top: 1,

                      zIndex: cmpMultiSelectZIndex,

                      ...isMobile() && {
                        marginLeft: 0,
                      },
                    }}
                  >
                    {
                      outletDropdownList.find(item => item.value === selectedCmpOutletId)
                        ?
                        <MultiSelect
                          clearable={false}
                          singleSelect={true}
                          defaultValue={selectedCmpOutletId}
                          placeholder={"Select Outlet"}
                          onChange={(value) => {
                            if (value) { // if choose the same option again, value = ''
                              setSelectedCmpOutletId(value);
                            }
                          }}
                          onMenuOpen={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(51000);
                          }}
                          onMenuClose={() => {
                            // here can set the zindex

                            setCmpMultiSelectZIndex(9999);
                          }}
                          options={outletDropdownList}
                          className="msl-varsHEADER"
                        />
                        :
                        <></>
                    }
                  </View>
                </View>
              </>
              :
              <></>
          }

          {/* /////////////////////////////////////////////////////// */}
        </ScrollView>
      </TouchableWithoutFeedback>

      <Modal
        style={{}}
        visible={exportModalVisibility}
        supportedOrientations={["portrait", "landscape"]}
        transparent={true}
        animationType={"fade"}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: Colors.modalBgColor,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              height: Dimensions.get("screen").width * 0.12,
              width: Dimensions.get("screen").width * 0.18,
              backgroundColor: Colors.whiteColor,
              borderRadius: 12,
              padding: Dimensions.get("screen").width * 0.03,
              alignItems: "center",
              justifyContent: "center",

              ...isMobile() && {
                height: 200,
                width: 300,
              },
            }}
          >
            <TouchableOpacity
              disabled={isLoading}
              style={{
                position: "absolute",
                right: Dimensions.get("screen").width * 0.015,
                top: Dimensions.get("screen").width * 0.01,

                elevation: 1000,
                zIndex: 1000,

                ...isMobile() && {
                  right: 20,
                  top: 20,
                },
              }}
              onPress={() => {
                setExportModalVisibility(false);
              }}
            >
              <AntDesign
                name="closecircle"
                size={isMobile() ? 20 : 25}
                color={Colors.fieldtTxtColor}
              />
            </TouchableOpacity>
            <View
              style={{
                alignItems: "center",
                top: "20%",
                position: "absolute",
              }}
            >
              <Text
                style={{
                  fontFamily: "NunitoSans-Bold",
                  textAlign: "center",
                  fontSize: isMobile() ? 16 : 24,
                }}
              >
                Download Report
              </Text>
            </View>
            <View style={{ top: switchMerchant ? "14%" : "10%" }}>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: 30,
                }}
              >
                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 100 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    // marginRight: 15,
                  }}
                  onPress={() => {
                    if (selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS) {
                      handleExportExcelBs();
                    }
                    else {
                      handleExportExcel();
                    }
                  }}
                >
                  {isLoading && isExcel ? (
                    <ActivityIndicator
                      size={"small"}
                      color={Colors.whiteColor}
                    />
                  ) : (
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      EXCEL
                    </Text>
                  )}
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 180 : 180,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    // marginRight: 15,

                    marginTop: 15,
                  }}
                  onPress={async () => {
                    if (selectedPayoutTabs === PAYOUT_TABS.BEST_SELLERS) {
                      handleExportExcelBs();
                    }
                    else {
                      await handleExportExcelRhb();
                    }
                  }}
                >
                  {isLoading && isExcel ? (
                    <ActivityIndicator
                      size={"small"}
                      color={Colors.whiteColor}
                    />
                  ) : (
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      EXCEL (RHB)
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      {/* <Modal
        style={{}}
        visible={exportModalVisibility}
        supportedOrientations={["portrait", "landscape"]}
        transparent={true}
        animationType={"fade"}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: Colors.modalBgColor,
            alignItems: "center",
            justifyContent: "center",
            top:
              Platform.OS === "android"
                ? 0
                : keyboardHeight > 0
                  ? -keyboardHeight * 0.45
                  : 0,
          }}
        >
          <View
            style={{
              backgroundColor: Colors.whiteColor,
              borderRadius: 12,
              padding: windowWidth * 0.03,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              disabled={isLoading}
              style={{
                position: "absolute",
                right: windowWidth * 0.02,
                top: windowWidth * 0.02,

                elevation: 1000,
                zIndex: 1000,
              }}
              onPress={() => {
                setExportModalVisibility(false);
              }}
            >
              <AntDesign
                name="closecircle"
                size={switchMerchant ? 15 : 25}
                color={Colors.fieldtTxtColor}
              />
            </TouchableOpacity>
            <View
              style={{
                alignItems: "center",
                top: "20%",
                position: "absolute",
              }}
            >
              <Text
                style={{
                  fontFamily: "NunitoSans-Bold",
                  textAlign: "center",
                  fontSize: switchMerchant ? 16 : 24,
                }}
              >
                Download Report
              </Text>
            </View>
            <View style={{ top: switchMerchant ? "14%" : "10%" }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 20,
                  fontFamily: "NunitoSans-Bold",
                }}
              >
                Email Address:
              </Text>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? 240 : 370,
                  height: switchMerchant ? 35 : 50,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                  paddingLeft: 10,
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholderStyle={{ padding: 5 }}
                placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                placeholder="Enter Your Email"
                onChangeText={(text) => {
                  setExportEmail(text);
                }}
                value={exportEmail}
              />
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 20,
                  fontFamily: "NunitoSans-Bold",
                  marginTop: 15,
                }}
              >
                Send As:
              </Text>

              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 100 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                    marginRight: 15,
                  }}
                  onPress={() => {
                    if (exportEmail.length > 0) {
                      CommonStore.update((s) => {
                        s.isLoading = true;
                      });

                      setIsExcel(true);

                      const excelData = convertDataToExcelFormat();

                      generateEmailReport(
                        EMAIL_REPORT_TYPE.EXCEL,
                        excelData,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report.xlsx",
                        `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                        exportEmail,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report",
                        () => {
                          CommonStore.update((s) => {
                            s.isLoading = false;
                          });

                          setIsExcel(false);

                          Alert.alert(
                            "Success",
                            "Report will be sent to the email address soon"
                          );

                          setExportModalVisibility(false);
                        }
                      );
                    } else {
                      Alert.alert("Info", "Invalid email address");
                    }
                  }}
                >
                  {isLoading && isExcel ? (
                    <ActivityIndicator
                      size={"small"}
                      color={Colors.whiteColor}
                    />
                  ) : (
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      EXCEL
                    </Text>
                  )}
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 100 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => {
                    if (exportEmail.length > 0) {
                      CommonStore.update((s) => {
                        s.isLoading = true;
                      });

                      setIsCsv(true);

                      const csvData = convertArrayToCSV(
                        tableDataSummary
                      );

                      generateEmailReport(
                        EMAIL_REPORT_TYPE.CSV,
                        csvData,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report.csv",
                        `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                        exportEmail,
                        "KooDoo Add-Ons Sales Report",
                        "KooDoo Add-Ons Sales Report",
                        () => {
                          CommonStore.update((s) => {
                            s.isLoading = false;
                          });

                          setIsCsv(false);

                          Alert.alert(
                            "Success",
                            "Report will be sent to the email address soon"
                          );

                          setExportModalVisibility(false);
                        }
                      );
                    } else {
                      Alert.alert("Info", "Invalid email address");
                    }
                  }}
                >
                  {isLoading && isCsv ? (
                    <ActivityIndicator
                      size={"small"}
                      color={Colors.whiteColor}
                    />
                  ) : (
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      CSV
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal> */}

      <DateTimePickerModal
        isVisible={showDateTimePicker}
        mode={"date"}
        onConfirm={(text) => {
          // setRev_date(moment(text).startOf("day"));

          setClickedBarChartDate(null);
          setClickedBarChartDateUnit(null);

          CommonStore.update(s => {
            s.ptStartDate = moment(text).startOf("day").valueOf();
          });

          setShowDateTimePicker(false);

          setSalesLineChartPeriod(CHART_PERIOD.NONE);
        }}
        onCancel={() => {
          setShowDateTimePicker(false);
        }}
        maximumDate={moment(ptStartDate).toDate()}
      />

      <DateTimePickerModal
        isVisible={showDateTimePicker1}
        mode={"date"}
        onConfirm={(dateParam) => {
          // setRev_date1(moment(text).endOf("day"));

          setClickedBarChartDate(null);
          setClickedBarChartDateUnit(null);

          var date = moment(dateParam).endOf("day").valueOf();
          if (moment(date).isSame(window.currToDateTime, 'day')) {
            date = window.currToDateTime;
          }

          CommonStore.update(s => {
            // s.ptEndDate = moment(dateParam).endOf("day").valueOf();
            s.ptEndDate = moment(date).valueOf();
          });

          setShowDateTimePicker1(false);

          setSalesLineChartPeriod(CHART_PERIOD.NONE);
        }}
        onCancel={() => {
          setShowDateTimePicker1(false);
        }}
        minimumDate={moment(ptEndDate).toDate()}
      />

      <Modal
        style={{ flex: 1 }}
        visible={visible}
        transparent={true}
        animationType="slide"
      >
        <KeyboardAvoidingView
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            minHeight: windowHeight,
            top:
              Platform.OS === "ios" && keyboardHeight > 0
                ? -keyboardHeight * 0.5
                : 0,
          }}
        >
          <View style={styles.confirmBox1}>
            <Text
              style={{
                fontSize: 24,
                justifyContent: "center",
                alignSelf: "center",
                marginTop: 40,
                fontFamily: "NunitoSans-Bold",
              }}
            >
              Enter your email
            </Text>
            <View
              style={{
                justifyContent: "center",
                alignSelf: "center",
                alignContent: "center",
                marginTop: 20,
                flexDirection: "row",
                width: "80%",
              }}
            >
              <View style={{ justifyContent: "center", marginHorizontal: 5 }}>
                <Text
                  style={{ color: Colors.descriptionColor, fontSize: 20 }}
                >
                  email:
                </Text>
              </View>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={[styles.textInput8, { paddingLeft: 5 }]}
                placeholder="Enter your email"
                // style={{
                //     // paddingLeft: 1,
                // }}
                //defaultValue={extentionCharges}
                onChangeText={(text) => {
                  // setState({ exportEmail: text });
                  setExportEmail(text);
                }}
                placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                value={exportEmail}
              />
            </View>
            <Text
              style={{
                fontSize: 20,
                fontFamily: "NunitoSans-Bold",
                marginTop: 25,
                justifyContent: "center",
                alignSelf: "center",
                alignContent: "center",
              }}
            >
              Share As:
            </Text>

            {/* Share file using email */}
            <View
              style={{
                justifyContent: "space-between",
                alignSelf: "center",
                marginTop: 10,
                flexDirection: "row",
                width: "80%",
              }}
            >
              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}
              >
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}
                >
                  Excel
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}
              >
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}
                >
                  CSV
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}
              >
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}
                >
                  PDF
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                alignSelf: "center",
                marginTop: 20,
                justifyContent: "center",
                alignItems: "center",
                // width: 260,
                width: windowWidth * 0.2,
                height: 60,
                alignContent: "center",
                flexDirection: "row",
                marginTop: 40,
              }}
            >
              <TouchableOpacity
                onPress={emailVariant}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: 60,
                  borderBottomLeftRadius: 10,
                  borderRightWidth: StyleSheet.hairlineWidth,
                  borderTopWidth: StyleSheet.hairlineWidth,
                }}
              >
                <Text
                  style={{
                    fontSize: 22,
                    color: Colors.primaryColor,
                    fontFamily: "NunitoSans-SemiBold",
                  }}
                >
                  Email
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  // setState({ visible: false });
                  setVisible(false);
                }}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: 60,
                  borderBottomRightRadius: 10,
                  borderTopWidth: StyleSheet.hairlineWidth,
                }}
              >
                <Text
                  style={{
                    fontSize: 22,
                    color: Colors.descriptionColor,
                    fontFamily: "NunitoSans-SemiBold",
                  }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </View >
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: "#ffffff",
    flexDirection: "row",
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    // padding: 20,
    paddingVertical: 20,
    backgroundColor: Colors.highlightColor,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  confirmBox: {
    // width: '30%',
    // height: '30%',
    // borderRadius: 30,
    // backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.3,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.2,
    width: Dimensions.get("screen").width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.02,
    top: Dimensions.get("screen").width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: Dimensions.get("screen").width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  modalSaveButton1: {
    width: Dimensions.get("screen").width * 0.1,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  confirmBox1: {
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.4,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  submitText: {
    height:
      Platform.OS == "ios"
        ? Dimensions.get("screen").height * 0.06
        : Dimensions.get("screen").height * 0.07,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
});
export default KooDooPayoutScreen;
